import {PresenceTransition} from "native-base";
import type {FC} from "react";
import React, {useState, useEffect} from "react";

import type {Props as AlertProps} from "@/client/components/Alert";
import Alert from "@/client/components/Alert";

export interface Props {
    color?: AlertProps["color"];
    actionText?: string;
    action?: string | (() => void);
    onClose?: (show: boolean) => void;
}
const Banner: FC<Props> = ({children, color = "blue", actionText, action, onClose}) => {
    const [hide, setHide] = useState(false);
    const [animate, setAnimate] = useState(true);

    function handleCloseError() {
        setAnimate(true);
        setTimeout(() => {
            setHide(true);
            onClose?.(false);
        }, 500);
    }

    useEffect(() => {
        setTimeout(() => {
            setAnimate(false);
        }, 1500);
    }, []);

    if (hide) {
        return null;
    }

    return (
        <PresenceTransition
            visible={!animate}
            initial={{
                opacity: 0
            }}
            animate={{
                opacity: 1,
                transition: {
                    duration: 250
                }
            }}
        >
            <Alert
                variant="solid"
                color={color}
                px={[5, 5, 6]}
                borderRadius={0}
                primaryText={actionText}
                primaryAction={action}
                secondaryAction={handleCloseError}
                secondaryText="Dismiss"
            >
                {children}
            </Alert>
        </PresenceTransition>
    );
};

export default Banner;
