import type {Theme} from "native-base";
import {theme} from "native-base";

type Badge = Theme["components"]["Badge"];

export const Badge: Partial<Badge> = {
    variants: {
        ...theme.components.Badge.variants,
        solid: (props: Record<string, any>) => {
            const {colorScheme} = props;
            return {
                _text: {
                    color: "text.50"
                },
                _icon: {
                    color: "text.50",
                    size: "xs"
                },
                bg: colorScheme === "amber" ? "amber.500" : `${colorScheme}.600`,
                borderWidth: "1",
                borderColor: "transparent",
                borderRadius: "4px"
            };
        },
        subtle: (props: Record<string, any>) => {
            const {colorScheme} = props;
            return {
                _text: {color: `${colorScheme}.600`},
                _icon: {color: `${colorScheme}.600`, size: "xs"},
                bg: `${colorScheme}.50`,
                _dark: {bg: `${colorScheme}.300`},
                borderWidth: "1",
                borderRadius: "4px",
                borderColor: `${colorScheme}.200`
            };
        },
        outline: (props: Record<string, any>) => {
            const {colorScheme} = props;
            return {
                _text: {color: `${colorScheme}.600`},
                _icon: {color: `${colorScheme}.600`, size: "xs"},
                borderColor: `${colorScheme}.200`,
                _dark: {
                    _text: {
                        color: `${colorScheme}.300`
                    },
                    _icon: {
                        color: `${colorScheme}.300`
                    },
                    borderColor: `${colorScheme}.300`
                },
                borderRadius: "4px",
                borderWidth: "1"
            };
        }
    },
    baseStyle: {
        ...theme.components.Badge.baseStyle,
        px: "1",
        py: "0.5",
        alignItems: "center",
        _text: {fontSize: "xs", fontWeight: "medium"}
    },
    defaultProps: {
        size: "md",
        variant: "subtle",
        colorScheme: "blueGray"
    }
};
