import type {ComponentType} from "react";
import React, {memo} from "react";

import type {Props as IconProps} from "./Icon";
import Icon from "./Icon";

export const iconMaker = (
    reactComponent: React.ComponentType,
    displayName: string,
    fillStyle: "stroke" | "fill" = "fill"
): ComponentType<IconProps> => {
    const IconComponent = (props: IconProps) => {
        return <Icon as={reactComponent} {...props} fillStyle={fillStyle} />;
    };

    IconComponent.displayName = displayName;

    return memo(IconComponent);
};
