import {v4 as uuidv4} from "uuid";

import hostToolLogo from "../img/logo-icon-square.png";

import {getLocal, setLocal} from "./storage";

enum BrowserNotiPermission {
    GRANTED = "granted",
    DEFAULT = "default",
    DENIED = "denied"
}

const TAB_HAS_PERMISSION_NOTIFY = "tab-has-permission-notify";

export class BrowserNotification {
    private window;

    private document;

    private tabId: string;

    private permissions: string[] = [
        BrowserNotiPermission.GRANTED,
        BrowserNotiPermission.DEFAULT,
        BrowserNotiPermission.DENIED
    ];

    private Notification;

    constructor() {
        this.window = window;
        this.document = window.document;
        this.Notification = window.Notification;
        this.tabId = "";
    }

    public registerPermissionToNotifyToBrowser() {
        this.tabId = uuidv4();
        this.registerTabPermissionToNotify();
        this.document.addEventListener("visibilitychange", this.registerTabPermissionToNotify);
    }

    public showNotification(title: string, options: NotificationOptions, onClick?: () => void) {
        const defaultOptions: Partial<NotificationOptions> = {
            silent: true,
            tag: "message-from-guest",
            icon: hostToolLogo
        };
        if (this.isSupportNotificationAPI() && !this.isInWeb() && this.hasTabPermission()) {
            if (this.hasPermission()) {
                this.sendNotification(title, {...defaultOptions, ...options}, onClick);
            } else {
                this.requestAndShowPermission(title, {...defaultOptions, ...options}, onClick);
            }
        }
    }

    public requestPermission(callback: NotificationPermissionCallback) {
        return this.Notification.requestPermission(callback);
    }

    public hasPermission() {
        return this.getPermission() === BrowserNotiPermission.GRANTED;
    }

    public shouldAskPermission() {
        return (
            this.getPermission() === BrowserNotiPermission.DEFAULT &&
            this.isSupportNotificationAPI()
        );
    }

    public unregisterEvent() {
        this.document.removeEventListener("visibilitychange", this.registerTabPermissionToNotify);
    }

    /**
     * Gets the permission level
     * @return {Permission} The permission level
     * source: https://github.com/Nickersoft/push.js
     */
    private getPermission() {
        let permission;

        /* Safari 6+, Chrome 23+ */
        if (this.Notification && this.Notification.permission)
            permission = this.Notification.permission;
        else if (this.window.webkitNotifications && this.window.webkitNotifications.checkPermission)
            /* Legacy webkit browsers */
            permission = this.permissions[this.window.webkitNotifications.checkPermission()];
        else {
            permission = BrowserNotiPermission.DENIED;
        }

        return permission;
    }

    private requestAndShowPermission(
        title: string,
        options: NotificationOptions,
        onClick?: () => void
    ) {
        this.requestPermission(permission => {
            if (permission === BrowserNotiPermission.GRANTED) {
                this.sendNotification(title, options, onClick);
            }
        });
    }

    private sendNotification(title: string, options: NotificationOptions, onClick?: () => void) {
        const notification = new this.Notification(title, options);
        notification.onclick = () => {
            notification.close();
            window.parent.focus();
            onClick?.();
        };
    }

    private registerTabPermissionToNotify = () => {
        if (this.document.visibilityState === "visible") {
            setLocal(TAB_HAS_PERMISSION_NOTIFY, this.tabId);
        }
    };

    private hasTabPermission = () => {
        return getLocal(TAB_HAS_PERMISSION_NOTIFY) === this.tabId;
    };

    private isInWeb() {
        return this.document.visibilityState === "visible";
    }

    public isSupportNotificationAPI() {
        return "Notification" in this.window;
    }
}
