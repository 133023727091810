import {useTimeout} from "@hosttools/frontend";
import React, {useCallback, useMemo, useRef} from "react";

type FormContextValues = {
    setItemRef: (name: string) => (node: HTMLDivElement) => void;
    getItemRef: (name: string) => HTMLElement | undefined;
    scrollToField: (name: string) => void;
    setFieldGroup: (name: string, groupId: string) => void;
    setNavigateToGroup: (navigateToGroup: (group: string) => void) => void;
};

export const FormContext = React.createContext<FormContextValues>({
    setItemRef: () => () => {},
    getItemRef: () => undefined,
    scrollToField: () => {},
    setFieldGroup: () => {},
    setNavigateToGroup: () => {}
});

export default function useForm() {
    const fieldsRef = useRef<Record<string, HTMLDivElement>>({});
    const mapFieldGroup = useRef<Record<string, string>>({});
    const navigateToGroupRef = useRef<(group: string) => void>();

    const scrollIntoViewTimeout = useTimeout((el: HTMLElement) => {
        el.scrollIntoView({behavior: "auto", block: "center"});
    }, 100);

    const setItemRef = useCallback(
        (name: string) => (node: HTMLDivElement) => {
            fieldsRef.current[name] = node;
        },
        []
    );

    const getItemRef = useCallback(
        (name: string) => {
            return fieldsRef.current[name];
        },
        [fieldsRef]
    );

    const scrollToElement = useCallback(
        async (name: string, el: HTMLElement) => {
            const groupId = mapFieldGroup.current[name];
            if (groupId && navigateToGroupRef.current) {
                navigateToGroupRef.current(groupId);

                scrollIntoViewTimeout(el);
            } else {
                el.scrollIntoView({behavior: "auto", block: "center"});
            }
        },
        [scrollIntoViewTimeout]
    );

    const scrollToField = useCallback(
        (name: string) => {
            const ref = getItemRef(name);
            if (ref) {
                scrollToElement(name, ref);
            }
        },
        [getItemRef, scrollToElement]
    );

    const setFieldGroup = useCallback((name: string, group: string) => {
        mapFieldGroup.current[name] = group;
    }, []);

    const setNavigateToGroup = useCallback((navigateToGroup: (group: string) => void) => {
        navigateToGroupRef.current = navigateToGroup;
    }, []);

    return useMemo(
        () => ({
            scrollToField,
            setItemRef,
            getItemRef,
            scrollToElement,
            setFieldGroup,
            setNavigateToGroup
        }),
        [getItemRef, scrollToField, setItemRef, scrollToElement, setFieldGroup, setNavigateToGroup]
    );
}
