import type {IMenuItemProps} from "native-base";
import {Menu} from "native-base";
import React, {memo} from "react";

type Props = IMenuItemProps;

const DropdownItem: React.FC<Props> = props => {
    return (
        <Menu.Item
            testID="dropdown-menu-item"
            pl={0}
            py={2}
            borderRadius={2}
            // configure this instaed
            // colorScheme="blueGray"
            _hover={{bg: "blueGray.100"}}
            _focus={{bg: "blueGray.100"}}
            {...props}
        />
    );
};

export default memo(DropdownItem);
