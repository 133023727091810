import {useAccountService} from "@hosttools/frontend";
import type {Listing} from "@hosttools/frontend/models/listing";
import React, {useCallback} from "react";

import useConfirmModal from "./useConfirmModal";

import Token from "@/client/components/ModalConfirm/Token";

function useConfirmCloseChannelConnector(listing: Listing) {
    const accountService = useAccountService();

    const [onRemoveChannel, updateConfirmModal] = useConfirmModal({
        title: "Disable",
        message: "Are you sure you want to disable this listing?",
        buttonText: "Delete",
        data: "",
        onOk: async channel => {
            if (channel) {
                const channelObject = listing.channels.ChannelConnector.channels?.find(
                    c => c.channel === channel
                );
                if (channelObject?.channelABB) {
                    await accountService.post("/closeChannel", {
                        listingID: listing._id,
                        channelABB: channelObject.channelABB
                    });
                }
            }
        }
    });

    return useCallback(
        (channel: string) => {
            onRemoveChannel();
            updateConfirmModal({
                title: `Disable ${channel}`,
                data: channel,
                message: (
                    <>
                        Are you sure you want to disable this listing on <Token>{channel}</Token>?
                    </>
                )
            });
        },
        [onRemoveChannel, updateConfirmModal]
    );
}

export default useConfirmCloseChannelConnector;
