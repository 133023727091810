import {HStack} from "native-base";
import React from "react";
import {components} from "react-select";
import type {Components} from "react-select/src/components";

import Text from "@/client/components/Text";

export const SingleValue: Components["SingleValue"] = ({children, ...props}) => {
    const {data} = props;
    return (
        <components.SingleValue {...props}>
            <HStack space={1}>
                {data.prefix}
                <Text variant="sm" color="blueGray.800" isTruncated>
                    {children}
                </Text>
                {data.suffix}
            </HStack>
        </components.SingleValue>
    );
};
