import {type Partner as RawPartner} from "@hosttools/core/config/types";

import * as logoCleanliSense from "../assets/logo-cleanlisense.svg";
import * as logoJervis from "../assets/logo-jervis.svg";
import * as logoPriceLabs from "../assets/logo-pricelabs.svg";
import * as logoTidy from "../assets/logo-tidy.svg";
import * as logoTouchStay from "../assets/logo-touchstay.svg";
import * as logoTurnoverBnb from "../assets/logo-turnoverbnb.svg";
import * as logoWebDesignVR from "../assets/logo-webdesignvr.svg";

const partnerLogoMaps = {
    tidy: {
        label: "TIDY",
        Logo: logoTidy.ReactComponent
    },
    PriceLabs: {
        label: "PriceLabs",
        Logo: logoPriceLabs.ReactComponent
    },
    CleanliSense: {
        label: "CleanliSense",
        Logo: logoCleanliSense.ReactComponent
    },
    TurnoverBnB: {
        label: "Turno",
        Logo: logoTurnoverBnb.ReactComponent
    },
    touchstay: {
        label: "Touch Stay",
        Logo: logoTouchStay.ReactComponent
    },
    webdesignvr: {
        label: "WebDesignVR",
        Logo: logoWebDesignVR.ReactComponent
    },
    jervis: {
        label: "Jervis",
        Logo: logoJervis.ReactComponent
    }
} as Record<
    RawPartner["name"],
    {
        label: string;
        Logo: typeof logoTidy.ReactComponent | null;
    }
>;

class Partner {
    name: string;

    isConnected: boolean;

    label: string;

    Logo: null | typeof logoTidy.ReactComponent;

    constructor(raw: RawPartner) {
        this.name = raw.name;
        this.isConnected = raw.isConnected;
        const {label, Logo} = partnerLogoMaps[raw.name] ?? {label: raw.name};
        this.label = label ?? "";
        this.Logo = Logo ?? null;
    }
}

export default Partner;
