import type {IIconProps} from "native-base";
import {InfoIcon, QuestionIcon} from "native-base";
import React, {memo} from "react";

import Tooltip from "../Tooltip";

export type Props = {
    label: React.ReactNode;
    icon?: "info" | "question";
    iconProps?: IIconProps;
};

const PopoverInfo: React.FC<Props> = ({label, icon = "question", iconProps}) => {
    return (
        <Tooltip label={label} interaction trigger={["hover", "click"]}>
            {icon === "question" ? (
                <QuestionIcon color="blueGray.400" size="3" {...iconProps} />
            ) : (
                <InfoIcon color="blueGray.400" size="3" {...iconProps} />
            )}
        </Tooltip>
    );
};

export default memo(PopoverInfo);
