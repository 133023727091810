import {Box, Link, Image, HStack} from "native-base";
import React, {memo} from "react";

import AppStore from "../img/app-store.svg";
import GooglePlay from "../img/google-play.svg";

import AppStore2 from "@/admin/img/app-store-2.svg";
import GooglePlay2 from "@/admin/img/google-play-2.svg";
import Text from "@/client/components/Text";
import useNewDesign from "@/client/hooks/useNewDesign";

const styles = {
    container: {
        height: "36px",
        px: [2, 7]
    },
    wrapperImage: {
        display: ["none", "flex"]
    }
};

const imageAppStore = {uri: AppStore2};
const imageGooglePlay = {uri: GooglePlay2};

const Footer = () => {
    const isNewUIEnabled = useNewDesign();
    const year = new Date().getFullYear();
    if (isNewUIEnabled) {
        return (
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                borderTopColor="blueGray.200"
                borderTopWidth={1}
                borderTopStyle="solid"
                bgColor="white"
                py={2}
                marginTop="auto"
                {...styles.container}
            >
                <Box {...styles.wrapperImage} flexDirection="row">
                    <Link href="https://apps.apple.com/app/host-tools/id1576749305" isExternal>
                        <Image
                            source={imageAppStore}
                            alt="App Store"
                            height={18}
                            width={60}
                            resizeMode="contain"
                        />
                    </Link>
                    <Link
                        href="https://play.google.com/store/apps/details?id=com.hosttools.hosttools"
                        isExternal
                        ml={1}
                    >
                        <Image
                            source={imageGooglePlay}
                            alt="Google Play Store"
                            height={18}
                            width={60}
                            resizeMode="contain"
                        />
                    </Link>
                </Box>
                <Text variant="2xs" color="blueGray.800">
                    &copy;{` ${year} Host Tools`}
                </Text>
                <HStack space={2}>
                    <Link href="https://hosttools.com/PrivacyPolicy" isUnderlined={false}>
                        <Text variant="2xs" color="blueGray.800">
                            Privacy Policy
                        </Text>
                    </Link>
                    <Link href="https://hosttools.com/TermsOfUse" isUnderlined={false}>
                        <Text variant="2xs" color="blueGray.800">
                            Terms Of Use
                        </Text>
                    </Link>
                </HStack>
            </Box>
        );
    }

    return (
        <div className="az-footer">
            <div className="container">
                <span>&copy;{` ${year} Host Tools`}</span>
                <span>
                    <a
                        className="d-none d-sm-inline"
                        href="https://apps.apple.com/app/host-tools/id1576749305"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src={AppStore} alt="App Store" height={18} />
                    </a>
                    <a
                        className="d-none d-sm-inline ml-3"
                        href="https://play.google.com/store/apps/details?id=com.hosttools.hosttools"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src={GooglePlay} alt="Google Play Store" height={18} />
                    </a>
                </span>
                <span>
                    <a className="text-muted ml-3" href="https://hosttools.com/PrivacyPolicy">
                        Privacy Policy
                    </a>
                    <a className="text-muted ml-3" href="https://hosttools.com/TermsOfUse">
                        Terms Of Use
                    </a>
                </span>
            </div>
        </div>
    );
};
export default memo(Footer);
