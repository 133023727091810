import React, {createContext, useCallback, useMemo, useState} from "react";

export interface ErrorContextType {
    onRefresh?: () => Promise<void>;
    updateOnRefresh: (onRefesh: () => Promise<void>) => void;
}

const defaultValue: ErrorContextType = {
    updateOnRefresh: () => undefined
};

export const ErrorContext = createContext<ErrorContextType>(defaultValue);

export const ErrorConsumer = ErrorContext.Consumer;

const ErrorProvider: React.FC = ({children}) => {
    const [value, setValue] = useState<ErrorContextType>(defaultValue);

    const updateOnRefresh = useCallback((onRefresh: () => Promise<void>) => {
        setValue(prev => ({...prev, onRefresh}));
    }, []);

    const nextValue = useMemo(
        () => ({
            ...value,
            updateOnRefresh
        }),
        [updateOnRefresh, value]
    );

    return <ErrorContext.Provider value={nextValue}>{children}</ErrorContext.Provider>;
};

export default ErrorProvider;
