import {useContext, useMemo} from "react";

import {UserContext} from "../../contexts";

export function useAccount(accountID: string | undefined) {
    const {accounts} = useContext(UserContext);
    return useMemo(() => {
        if (!accountID) {
            return;
        }
        return accounts.find(elem => elem._id === accountID);
    }, [accountID, accounts]);
}
