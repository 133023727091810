import type {V33xTheme} from "native-base";

import {Alert} from "./Alert";
import {Badge} from "./Badge";
import {Button} from "./Button";
import {ZINDEX_MODAL, ZINDEX_POPOVER} from "./constant";
import {IconButtonTheme} from "./IconButton";
import {InputTheme} from "./Input";
import {Toast} from "./Toast";

type Text = V33xTheme["components"]["Text"];
type Badge = V33xTheme["components"]["Badge"];
type Heading = V33xTheme["components"]["Heading"];
type ErrorMessage = V33xTheme["components"]["FormControlErrorMessage"];
type FormControl = V33xTheme["components"]["FormControl"];
type Checkbox = V33xTheme["components"]["Checkbox"];
type TextArea = V33xTheme["components"]["TextArea"];
type Box = V33xTheme["components"]["Box"];
type View = V33xTheme["components"]["View"];
type Stack = V33xTheme["components"]["Stack"];

const baseStyle: Text["baseStyle"] = () => {
    return {} as ReturnType<Text["baseStyle"]>;
};

export const components = {
    components: {
        Input: InputTheme,
        Text: {
            baseStyle,
            defaultProps: {
                color: "blueGray.600",
                size: "md",
                fontFamily: "body"
            },
            sizes: {
                "4xl": {
                    fontSize: "36px"
                },
                "3xl": {
                    fontSize: "30px"
                },
                "2xl": {
                    fontSize: "24px"
                },
                xl: {
                    fontSize: "20px"
                },
                lg: {
                    fontSize: "18px"
                },
                md: {
                    fontSize: "16px"
                },
                sm: {
                    fontSize: "14px"
                },
                xs: {
                    fontSize: "12px"
                },
                "2xs": {
                    fontSize: "10px"
                }
            }
        } as Text,
        Heading: {
            defaultProps: {
                color: "blueGray.800",
                size: "xl"
            },
            sizes: {
                "4xl": {
                    fontSize: ["60px"]
                },
                "3xl": {
                    fontSize: ["42px", "48px"]
                },
                "2xl": {
                    fontSize: ["32px", "36px"]
                },
                xl: {
                    fontSize: ["28px", "32px"]
                },
                lg: {
                    fontSize: ["20px", "24px"]
                },
                md: {
                    fontSize: "20px"
                },
                sm: {
                    fontSize: "16px"
                },
                xs: {
                    fontSize: "14px"
                }
            }
        } as Partial<Heading>,
        View: {
            baseStyle: {},
            defaultProps: {
                zIndex: "unset"
                // weird bug that makes content no longer be within the padding of a box
                // minHeight: "auto"
            }
        } as View,
        Box: {
            baseStyle: {},
            defaultProps: {
                zIndex: "unset"
                // minHeight: "auto"
            }
        } as Box,
        Stack: {
            baseStyle: {},
            defaultProps: {
                zIndex: "unset"
                // minHeight: "auto"
            },
            sizes: {} as Stack["sizes"]
        } as Stack,
        Badge,
        Popover: {
            baseStyle: () => {
                return {
                    _overlay: {
                        style: {
                            zIndex: ZINDEX_POPOVER
                        },
                        unmountOnExit: true
                    }
                };
            }
        },
        Modal: {
            sizes: {
                xs: {
                    contentSize: {
                        width: "60%",
                        maxWidth: "280"
                    }
                },
                sm: {
                    contentSize: {
                        width: "65%",
                        maxWidth: "320"
                    }
                },
                md: {
                    contentSize: {
                        width: "75%",
                        maxWidth: "500"
                    }
                },
                lg: {
                    contentSize: {
                        width: "80%",
                        maxWidth: "800"
                    }
                },
                xl: {
                    contentSize: {
                        width: "90%",
                        maxWidth: "1000"
                    }
                },
                full: {
                    contentSize: {
                        width: "100%"
                    }
                }
            },
            baseStyle: {
                _overlay: {
                    style: {
                        zIndex: ZINDEX_MODAL
                    }
                }
            }
        },
        FormControlErrorMessage: {
            defaultProps: {
                _text: {fontSize: "sm", fontWeight: "normal"}
            }
        } as Partial<ErrorMessage>,
        FormControl: {
            defaultProps: {
                zIndex: "auto"
            }
        } as Partial<FormControl>,
        Checkbox: {
            sizes: {
                lg: {_icon: {size: 4}, _text: {fontSize: "md"}},
                md: {_icon: {size: 3}, _text: {fontSize: "sm"}},
                sm: {_icon: {size: 3}, _text: {fontSize: "xs"}}
            }
        } as Partial<Checkbox>,
        IconButton: IconButtonTheme,
        TextArea: {
            baseStyle: {
                multiline: true,
                p: "2",
                textAlignVertical: "top",
                h: 40,
                totalLines: 5
            }
        } as Partial<TextArea>,
        Alert,
        Menu: {
            baseStyle: {
                _overlay: {
                    style: {
                        zIndex: ZINDEX_POPOVER
                    }
                }
            }
        },
        PopoverBody: {
            baseStyle: () => ({
                p: "3",
                shadow: "6",
                bg: "white",
                _text: {
                    color: "text.800"
                }
            })
        },
        PopoverContent: {
            baseStyle: () => ({
                // TODO: Box inside PopperContent is not able to resolve shadow
                // shadow: '6',
                borderColor: "blueGray.200",
                _text: {
                    color: "text.900"
                },
                borderWidth: 1,
                rounded: "lg",
                overflow: "hidden",
                style: {
                    boxShadow:
                        "rgba(30, 41, 59, 0.1) 0px 4px 6px 0px, rgba(30, 41, 59, 0.1) 0px 2px 4px 0px"
                }
            })
        },
        PopoverArrow: {
            baseStyle: () => ({
                bg: "white",
                borderColor: "blueGray.200",
                borderTopLeftRadius: 8
            })
        },
        Button,
        Toast
    }
};
