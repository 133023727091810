import {useEffect, useRef} from "react";

const useMounted = () => {
    const isMounted = useRef<boolean>(false);

    useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        };
    }, []);

    // Don't return isMounted.current which won't work correctly
    // if you use it in a function such as `debounce`
    return isMounted;
};

export default useMounted;
