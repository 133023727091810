function replace<T>(arr: T[], predicate: (elem: T) => boolean, props: T): T[] | undefined {
    const idx = arr.findIndex(predicate);
    if (idx < 0) {
        return;
    }
    const newOne: T = {...props};

    return Object.assign([...arr], {[idx]: newOne});
}

export default replace;
