import type {ListingStatuses} from "@hosttools/core/constant";
import {useMemo} from "react";

function useStatusLabel(status?: ListingStatuses) {
    return useMemo(() => {
        const labelsMapping = {
            listed: "Listed",
            pending: "Pending",
            unlisted: "Unlisted",
            "not found": "Not Found"
        } satisfies Record<ListingStatuses, string>;

        return status ? labelsMapping[status] : "";
    }, [status]);
}

export default useStatusLabel;
