import {type AccountType} from "@hosttools/frontend/models/account";
import React, {type FC, memo, useState, useMemo} from "react";
import {Route, Switch, useParams, useRouteMatch} from "react-router-dom";

import {Context, type ConnectAccountContextType} from "./Context";
import ImportListingsStep from "./ImportListingsStep";

// the param for the root parent root
type Params = {
    accountType: AccountType;
};

const ImportListings: FC = () => {
    const {path, url} = useRouteMatch();
    const {accountType} = useParams<Params>();
    const [context, setContext] = useState<ConnectAccountContextType>({
        accountType
    });

    const value = useMemo(() => ({...context, setContext}), [context]);

    return (
        <Context.Provider value={value}>
            <Switch>
                <Route path={`${path}`} exact>
                    <ImportListingsStep type="importListings" parentPath={url} />
                </Route>
                <Route path={`${path}/connect-channels`}>
                    <ImportListingsStep type="connectChannels" parentPath={url} />
                </Route>
            </Switch>
        </Context.Provider>
    );
};

export default memo(ImportListings);
