import type {AxiosInstance, AxiosRequestConfig} from "axios";

import type {LanguageCode} from "../../constant/languages";
import type {MessageRule} from "../../models/messageRule";
import type {UploadFilePayload} from "../../utils/file";
import {generateImageUploadFormData} from "../../utils/file";

type AddImagePayload = UploadFilePayload & {
    messageRuleID: string;
};

interface DeleteImagePayload {
    messageRuleID: string;
    keys: string[];
}

export type MessageRuleCustom = MessageRule & {type: string};

class MessageRuleService {
    private http: AxiosInstance;

    constructor(http: AxiosInstance) {
        this.http = http;
    }

    async deleteImagesFromMessageRule(
        {messageRuleID, keys}: DeleteImagePayload,
        config?: AxiosRequestConfig
    ) {
        const url = "/deleteImagesMessageRule";
        const {data} = await this.http.post(
            url,
            {
                messageRuleID,
                keys
            },
            config
        );

        return data;
    }

    async addImageMessageRule(
        {messageRuleID, ...rest}: AddImagePayload,
        config?: AxiosRequestConfig
    ) {
        const url = "/addImageMessageRule";
        const formData = generateImageUploadFormData(rest);
        formData.append("messageRuleID", messageRuleID);

        const {data} = await this.http.post(url, formData, {
            headers: {"Content-Type": "multipart/form-data"},
            ...config
        });

        return data;
    }

    async getMessageRules() {
        const url = "/getMessageRules";
        const {data} = await this.http.get<MessageRuleCustom[]>(url);
        return data;
    }

    async previewMessage(language: LanguageCode, value: string) {
        const url = "/previewMessageRule";
        const {data} = await this.http.post<{message: string}>(url, {
            preferredLocale: language,
            messages: {
                default: value,
                [language]: value
            }
        });
        return data.message;
    }
}

export default MessageRuleService;
