import React, {memo, useContext} from "react";

import {FormContext} from "./useForm";

type Props = {
    name: string;
};

const FormAnchor: React.FC<Props> = ({name}) => {
    const formContext = useContext(FormContext);

    return (
        <div
            ref={formContext.setItemRef(name)}
            data-field-type="anchor-field"
            data-field-name={name}
            data-testid={`anchor-field-${name}`}
            style={{visibility: "hidden", maxHeight: 0, maxWidth: 0, overflow: "hidden"}}
        />
    );
};

export default memo(FormAnchor);
