import {type Listing} from "@hosttools/frontend/models/listing";
import {HStack, VStack} from "native-base";
import React, {type FC, memo, useMemo} from "react";

import ListingChannelsLogo from "../ListingChannelsLogo";
import ListingThumbnail, {type Size} from "../ListingThumbnail";

import Text, {type Variants, type SupportedColors} from "@/client/components/Text";

type Props = {
    listing: Listing;
    size?: Size;
    color?: SupportedColors;
    numberOfLines?: number;
    // this sometimes is helpful on mobile view
    hideImage?: boolean;
    includeChannel?: boolean;
    fullWidth?: boolean;
};

const ListingSlat: FC<Props> = ({
    listing,
    size = "md",
    color,
    numberOfLines = 2,
    hideImage = false,
    includeChannel = true,
    fullWidth = true
}) => {
    const variants = useMemo(() => {
        return {
            xs: {
                textSize: "xs",
                space: 1,
                channelLogoSize: "xs"
            },
            sm: {
                textSize: "sm",
                space: 2,
                channelLogoSize: "sm"
            },
            md: {
                textSize: "md",
                space: 3,
                channelLogoSize: "sm"
            }
        } satisfies Record<
            Size,
            {
                textSize: Variants;
                space: number;
                channelLogoSize: Size;
            }
        >;
    }, []);

    const styles = variants[size];

    return (
        <HStack
            testID="listing-slat"
            flex={fullWidth ? 1 : "unset"}
            space={styles.space}
            alignItems="center"
        >
            {!hideImage && <ListingThumbnail thumbnail={listing.airbnbThumbnailUrl} size={size} />}
            <VStack flexShrink="unset" space={1}>
                <Text
                    testID="listing-title"
                    isTruncated
                    variant={styles.textSize}
                    numberOfLines={numberOfLines}
                    color={color}
                >
                    {listing.name}
                </Text>
                {includeChannel && (
                    <ListingChannelsLogo listing={listing} size={styles.channelLogoSize} />
                )}
            </VStack>
        </HStack>
    );
};

export default memo(ListingSlat);
