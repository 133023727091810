import {useContext, useEffect, useState} from "react";

import type {Tag} from "../../../models/tag";
import TagService from "../../../services/tag";
import {updateListWS} from "../../../utils";
import {HTTPContext, WebSocketContext} from "../../contexts";

export const useWatchTag = (loadOnMount?: boolean) => {
    const http = useContext(HTTPContext);
    const websocket = useContext(WebSocketContext);

    const [tags, setTags] = useState<Tag[] | undefined>();

    useEffect(() => {
        async function init() {
            const tagService = new TagService(http);
            const tags = await tagService.fetchTags();
            if (tags) {
                setTags(tags);
            }
        }
        if (loadOnMount) {
            init();
        }
    }, [http, loadOnMount]);

    useEffect(() => {
        function syncTag(payload: WSPayload<Tag>) {
            setTags(prev => {
                if (!prev) {
                    return prev;
                }
                const tags = updateListWS(prev, payload);
                return tags;
            });
        }

        websocket?.on<WSRoom>("tag", syncTag);

        return () => {
            websocket?.off("tag", syncTag);
        };
    }, [websocket]);

    return [tags, setTags] as const;
};
