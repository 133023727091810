import {isReactElement} from "@hosttools/frontend";
import {type Props as IconProps} from "@hosttools/frontend/react/components/Icons/Icon";
import {HStack} from "native-base";
import React, {type ComponentType, type ReactNode, memo, useMemo, type ReactElement} from "react";

import type {Props as TextProps} from "@/client/components/Text";
import Text from "@/client/components/Text";

type Size = "xs" | "sm";

interface Props {
    Icon: ComponentType<IconProps> | ReactElement<unknown>;
    children: ReactNode;
    size?: Size;
    suffix?: ReactNode;
    iconColor?: "red.500" | "blueGray.400";
    textColor?: "danger.600";
}

const DropdownContent: React.FC<Props> = ({
    children,
    Icon,
    size = "xs",
    iconColor = "blueGray.400",
    textColor,
    suffix
}) => {
    const sizeStyles = useMemo(() => {
        const sizes = {
            xs: {
                iconSize: "sm",
                textSize: "xs"
            },
            sm: {
                iconSize: "md",
                textSize: "sm"
            }
        } satisfies Record<Size, {iconSize: IconProps["size"]; textSize: TextProps["variant"]}>;

        return sizes[size];
    }, [size]);

    const iconNode = isReactElement(Icon) ? (
        Icon
    ) : (
        <Icon size={sizeStyles.iconSize} color={iconColor} />
    );

    return (
        <HStack alignItems="center" space={2} testID="dropdown-content">
            {iconNode}
            <Text variant={sizeStyles.textSize} color={textColor}>
                {children}
            </Text>
            {suffix}
        </HStack>
    );
};

export default memo(DropdownContent);
