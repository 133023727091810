import {ThreeDotVerticalIcon} from "@hosttools/frontend";
import {Button} from "native-base";
import React from "react";

import ButtonIcon from "../ButtonIcon";

import {useDefaultTrigger} from "./useDefaultTrigger";

interface Props {
    testID?: string;
    placement?: "left" | "right";
    isLoading?: boolean;
    size?: "xs";
}

export function useThreeDotsTrigger({testID, placement, isLoading, size, ...props}: Props = {}) {
    return useDefaultTrigger(
        isLoading ? (
            <Button size="xs" variant="ghost" isLoading />
        ) : (
            <ButtonIcon
                testID={testID ?? "btn-more-menu"}
                tooltip="More options"
                placement={placement ?? "left"}
                size={size}
                {...props}
                icon={ThreeDotVerticalIcon}
            />
        ),
        true
    );
}
