import debounce from "lodash/debounce";
import {useEffect, useMemo, useRef} from "react";

const useDebounceFn = <T extends (...args: any) => ReturnType<T>>(fn: T, ms: number) => {
    const fnRef = useRef(fn);
    fnRef.current = fn;
    const fnHandler = useMemo(() => debounce(fnRef.current, ms), [ms]);

    useEffect(() => {
        return fnHandler.cancel;
    }, [fnHandler]);

    return fnHandler;
};

export default useDebounceFn;
