import {memoWithType} from "@hosttools/frontend";
import React, {useCallback, useState} from "react";

import ModalV2 from "@/client/components/Modal";

interface BaseProps {
    show: boolean;
    title: string;
    message: React.ReactNode;
    size?: "md" | "xl";
    buttonText: string;
    btnColorScheme?: "danger" | "primary";
    autoHide?: boolean;
    onHide: () => void;
}

export interface PropsWithData<T> extends BaseProps {
    data: T;
    onOk?: (data: T) => Promise<void> | void;
}

export interface PropsNoData<_T> extends BaseProps {
    onOk?: () => Promise<void> | void;
}

export type Props<T = unknown> = PropsWithData<T> | PropsNoData<T>;

const ModalConfirm = <T,>({
    show,
    title,
    message,
    size,
    btnColorScheme = "primary",
    buttonText,
    autoHide = true,
    onHide,
    ...props
}: Props<T>) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleConfirm = useCallback(async () => {
        setIsLoading(true);
        try {
            if ("data" in props) {
                await props.onOk?.(props.data);
            } else {
                await props.onOk?.();
            }
            if (autoHide) {
                onHide();
            }
        } finally {
            setIsLoading(false);
        }
    }, [autoHide, props, onHide]);

    return (
        <ModalV2
            size={size}
            title={title}
            subTitle={message}
            isOpen={show}
            isLoading={isLoading}
            btnTextOk={buttonText}
            btnColorScheme={btnColorScheme}
            responsive={false}
            onSubmit={handleConfirm}
            onClose={onHide}
        />
    );
};

export default memoWithType(ModalConfirm);
