import {Box, Input as InputNB, mergeRefs} from "native-base";
import {type InterfaceInputProps} from "native-base/lib/typescript/components/primitives/Input/types";
import React, {forwardRef, memo, useEffect, useRef, type ForwardRefRenderFunction} from "react";

import Text from "../Text";

export type Props = Omit<
    InterfaceInputProps,
    "type" | "value" | "InputLeftElement" | "InputRightElement"
> & {
    value?: string | number;
    name?: string;
    type?: InterfaceInputProps["type"] | "number";
    InputLeftElement?: React.ReactElement | string;
    InputRightElement?: React.ReactElement | string;
    onChangeText?: (text: string) => void;
};

const Input: ForwardRefRenderFunction<HTMLInputElement, Props> = (
    {type, name, value, variant = "outlined", InputLeftElement, InputRightElement, ...props},
    ref
) => {
    const innerRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        innerRef.current?.setAttribute("type", type || "text");
        if (innerRef.current && name) {
            innerRef.current.name = name;
        }
    }, [name, type]);

    return (
        <InputNB
            ref={mergeRefs([ref, innerRef])}
            type={type !== "number" ? type : "text"}
            size="md"
            variant={variant}
            bgColor={variant === "filled" ? "blueGray.100" : undefined}
            borderWidth={variant === "outlined" ? "1px" : undefined}
            borderColor={variant === "outlined" ? "blueGray.200" : undefined}
            borderRadius={variant === "outlined" ? "4px" : undefined}
            backgroundColor={variant === "outlined" ? "white" : undefined}
            InputLeftElement={
                InputLeftElement ? (
                    typeof InputLeftElement === "string" ? (
                        <Text ml={3} color="blueGray.400" variant="sm">
                            {InputLeftElement}
                        </Text>
                    ) : (
                        <Box ml={3}>{InputLeftElement}</Box>
                    )
                ) : undefined
            }
            InputRightElement={
                InputRightElement ? (
                    typeof InputRightElement === "string" ? (
                        <Text mr={3} color="blueGray.400" variant="sm">
                            {InputRightElement}
                        </Text>
                    ) : (
                        InputRightElement
                    )
                ) : undefined
            }
            value={typeof value !== "undefined" ? `${value}` : undefined}
            {...props}
        />
    );
};

export default memo(forwardRef(Input));
