import {useCallback, useEffect, useRef} from "react";

function useTimeout<Args extends any[], F extends (...args: Args) => void>(
    callback: F,
    delay: number | null
): (...args: Parameters<F>) => void {
    const savedCallbackRef = useRef(callback);
    const idRef = useRef<number>();

    useEffect(() => {
        savedCallbackRef.current = callback;
    }, [callback]);

    useEffect(() => {
        return () => {
            if (typeof idRef.current === "number") {
                clearTimeout(idRef.current);
            }
        };
    }, [delay]);

    return useCallback(
        (...args: Args) => {
            // Don't schedule if no delay is specified.
            // Note: 0 is a valid value for delay.
            if (!delay && delay !== 0) {
                return;
            }
            const id = window.setTimeout(() => savedCallbackRef.current(...args), delay);
            idRef.current = id;
        },
        [delay]
    );
}

export default useTimeout;
