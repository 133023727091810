import axios from "axios";
import {useCallback} from "react";

const useBuildAxiosError = () => {
    return useCallback((error: unknown, fallbackMessage: string) => {
        if (axios.isAxiosError(error)) {
            const message = error.response?.data?.message ?? fallbackMessage;
            if (typeof message === "string") {
                return message;
            }
        }
        return fallbackMessage;
    }, []);
};

export default useBuildAxiosError;
