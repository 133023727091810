import {useContext, useEffect, useState} from "react";

import type {Account} from "../../../models/account";
import {sanitizeAccount} from "../../../models/account";
import {updateListWS} from "../../../utils";
import {UserContext} from "../../contexts/UserContext";
import WebSocketContext from "../../contexts/WebSocketContext";
import {useUserService} from "../useUserService";

export const useWatchAccount = () => {
    const {isAuthenticated, permissions} = useContext(UserContext);
    const websocket = useContext(WebSocketContext);
    const [accounts, setAccounts] = useState<Account[]>();
    const userService = useUserService();

    useEffect(() => {
        // only starts rendering as soon as the websocket is ready to work
        if (isAuthenticated && websocket) {
            if (permissions.accounts) {
                (async () => {
                    const accounts = await userService.fetchAccounts();
                    if (accounts) {
                        setAccounts(accounts);
                    }
                })();
            } else {
                setAccounts([]);
            }
        }
    }, [isAuthenticated, permissions, websocket, userService]);

    useEffect(() => {
        if (!permissions.accounts) {
            return;
        }

        function syncAccounts(payload: WSPayload<Account>) {
            setAccounts(prev => {
                if (!prev && (payload.event === "update" || payload.event === "delete")) {
                    return;
                }
                const accounts = updateListWS(prev ?? [], payload, sanitizeAccount);
                return accounts;
            });
        }

        websocket?.on<WSRoom>("account", syncAccounts);
        return () => {
            websocket?.off("account", syncAccounts);
        };
    }, [websocket, permissions.accounts]);

    return accounts;
};
