import "@/client/css/carousel.scss";
import type {IBoxProps, IStackProps, ITextProps} from "native-base";
import {Image, VStack, View} from "native-base";
import React, {memo, useMemo} from "react";
import {Carousel} from "react-bootstrap";
import {Carousel as ResponsiveCarousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import Aamna from "../img/users/Aamna.jpg";
import Adam from "../img/users/Adam.jpg";
import Amanda from "../img/users/Amanda.jpg";
import Caley from "../img/users/Caley.jpg";
import Hilary from "../img/users/Hilary.jpg";
import Monica from "../img/users/Monica.jpg";

import type {Variants as HeadingVariants} from "@/client/components/Heading";
import Heading from "@/client/components/Heading";
import Paragraph from "@/client/components/Paragraph";
import type {Variants as TextVariants} from "@/client/components/Text";
import Text from "@/client/components/Text";
import useNewDesign from "@/client/hooks/useNewDesign";

const items = [
    {
        avatarUrl: Amanda,
        name: "Amanda S.",
        title: "Excellent functionality; great value!",
        statement:
            "It was clearly created by a fellow property owner and addresses our biggest concerns without unnecessary fluff. The price is fair, and we feel fortunate to have found it."
    },
    {
        avatarUrl: Adam,
        name: "Adam",
        title: "Host Tools has been a lifesaver!",
        statement:
            "It has saved me countless hours by automating my Airbnb and VRBO messaging. It also helps me keep my listings availability in sync and pricing up to date."
    },
    {
        avatarUrl: Monica,
        name: "Monica-Hisano",
        title: "Never miss another booking opportunity!",
        statement:
            "'Host Tools' gives me the security of knowing that my business is being taken care of 24/7 and is the most efficient and affordable product in the vacation rental hosting market."
    },
    {
        avatarUrl: Aamna,
        name: "Aamna",
        title: "It’s is so easy to use",
        statement: "Love it. It is less expensive as compared to other similar platforms."
    },
    {
        avatarUrl: Hilary,
        name: "Hilary",
        title: "Host Tools is a Rental Property Live Saver!",
        statement:
            "I feel like I have seen it evolve into a critical tool that I use to run my rental properties. I have recommended it to several friends who also use it."
    },
    {
        avatarUrl: Caley,
        name: "Caley",
        title: "Wish I'd Found It Sooner",
        statement:
            "Our guests have gushed about how great our communication is - and we know we were lacking in that area previously."
    }
];

interface Props {
    size?: "sm" | "md";
}

const SocialValidation: React.FC<Props> = ({size = "md"}) => {
    const isNewUIEnabled = useNewDesign();

    const variants = useMemo(() => {
        return {
            sm: {
                containerBorderRadius: 16,
                containerBorderWidth: 0,
                containerBorderColor: "blueGray.200",
                containerPadding: 6,
                headingSize: "lg",
                headingSpace: 4,
                nameSize: "xl",
                nameAlign: "center",
                imageSize: 16
            },
            md: {
                containerBorderRadius: 12,
                containerBorderWidth: 1,
                containerBorderColor: "blueGray.200",
                containerPadding: 4,
                headingSize: "xl",
                headingSpace: 2,
                nameSize: "lg",
                nameAlign: "end",
                imageSize: 12
            }
        } as Record<
            "sm" | "md",
            {
                containerBorderRadius: IBoxProps["borderRadius"];
                containerBorderWidth: IBoxProps["borderWidth"];
                containerBorderColor: IBoxProps["borderColor"];
                containerPadding: IBoxProps["padding"];
                headingSize: TextVariants;
                headingSpace: IStackProps["space"];
                nameSize: HeadingVariants;
                nameAlign: IBoxProps["alignSelf"];
                imageSize: ITextProps["fontSize"];
            }
        >;
    }, []);

    if (isNewUIEnabled) {
        const variant = variants[size];
        return (
            <View
                maxWidth="full"
                height="full"
                bg="white"
                borderRadius={variant.containerBorderRadius}
                borderWidth={variant.containerBorderWidth}
                borderColor={variant.containerBorderColor}
                p={variant.containerPadding}
            >
                <ResponsiveCarousel
                    autoPlay
                    showThumbs={false}
                    showIndicators={false}
                    showStatus={false}
                    showArrows={false}
                    stopOnHover={false}
                >
                    {items.map(({avatarUrl, name, statement, title}) => (
                        <View key={name} height="full" justifyContent="space-between">
                            <VStack space={variant.headingSpace}>
                                <Text variant={variant.headingSize}>
                                    &quot;
                                    {title}
                                    &quot;
                                </Text>
                                {size === "md" ? (
                                    <Text variant="lg">{statement}</Text>
                                ) : (
                                    <Paragraph variant="sm">{statement}</Paragraph>
                                )}
                            </VStack>
                            <View
                                flexDirection="row"
                                alignItems={variant.nameAlign}
                                justifyContent="space-between"
                            >
                                <Heading variant={variant.nameSize}>{name}</Heading>
                                <Image
                                    rounded="full"
                                    width={variant.imageSize}
                                    height={variant.imageSize}
                                    source={{uri: avatarUrl}}
                                    alt="profile"
                                />
                            </View>
                        </View>
                    ))}
                </ResponsiveCarousel>
            </View>
        );
    }

    return (
        <Carousel className="custom-carousel" controls={false} indicators interval={2000}>
            {items.map(({avatarUrl, name, statement, title}) => (
                <Carousel.Item key={name}>
                    <div className="d-flex flex-column pd-b-60 mg-x-20 mg-t-20">
                        <h5>
                            &quot;
                            <span dangerouslySetInnerHTML={{__html: title}} />
                            &quot;
                        </h5>
                        <p className="tx-12" dangerouslySetInnerHTML={{__html: statement}} />
                        <img
                            className="rounded-circle mg-b-10 d-none d-md-block"
                            style={{width: 60, height: 60}}
                            src={avatarUrl}
                            alt="profile"
                        />
                        <div className="tx-12 mg-b-10 d-none d-md-block">{name}</div>
                    </div>
                </Carousel.Item>
            ))}
        </Carousel>
    );
};

export default memo(SocialValidation);
