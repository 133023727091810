import type {ChannelListingObject} from "@hosttools/core/channels/types";
import type {Endpoints} from "@hosttools/web/server/routes/routes.listings.types";
import type {AxiosRequestConfig} from "axios";

import type {AccountType} from "../../models/account";
import type {ChannelSetting, SeasonalRule} from "../../models/channelSetting";
import type {Listing} from "../../models/listing";
import {sanitizeListings} from "../../models/listing";
import type {ListingGroup} from "../../models/listingGroup";
import type {UploadFilePayload} from "../../utils/file";
import {generateImageUploadFormData} from "../../utils/file";
import {Base} from "../base";

type AddImagePayload = UploadFilePayload & {
    listingID: string;
};

interface DeleteImagePayload {
    keys: string[];
}

export type AccountListing = Listing & {
    isConnected: boolean;
};

type ChannelSettingParams = ChannelSetting & {
    removeSeasonalRules?: string[];
    setSeasonalRules?: SeasonalRule[];
};

class ListingService extends Base<Endpoints> {
    async fetchAllListings() {
        const url = "/listings/all";
        const {data} = await this.http.get<Listing[]>(url);
        return sanitizeListings(data);
    }

    async fetchAllListingGroups() {
        const url = "/listingGroups";
        const res = await this.http.get<Record<string, ListingGroup>>(url);
        return res.data;
    }

    async fetchListingsFromAccount(accountID: string) {
        const url = `/listingsFromChannel/${accountID}`;
        const {data} = await this.http.get<(ChannelListingObject & {isConnected: boolean})[]>(url);
        // merge them into a single type probably a mini listing type
        return data.map(elem => {
            // differentiate listing type by return value for now
            let _id: string | undefined;
            if ("channels.Airbnb.id" in elem) {
                _id = elem["channels.Airbnb.id"];
            } else if ("channels.Booking.id" in elem) {
                _id = elem["channels.Booking.id"];
            } else if ("channels.Houfy.id" in elem) {
                _id = elem["channels.Houfy.id"];
            } else {
                _id = elem["channels.HomeAway.id"];
            }
            if (!_id) {
                throw new Error("No external listingID found");
            }
            return {
                _id,
                name: elem.airbnbName,
                airbnbThumbnailUrl: "airbnbThumbnailUrl" in elem ? elem.airbnbThumbnailUrl : "",
                channels: {},
                isConnected: elem.isConnected
            } as AccountListing;
        });
    }

    async fetchListingObjectFromChannel<T extends "/listingObjFromChannel">({
        listingID,
        channel
    }: Endpoints[T][1]) {
        const url = "/listingObjFromChannel" as T;
        const {data} = await this.http.post<Endpoints[T][2]>(url, {
            listingID,
            channel
        });

        return data;
    }

    async importListings(accountID: string, externalListingIDs: string[]) {
        await this.http.post("/importListingsFromChannel", {
            accountID,
            externalListingIDs
        });
    }

    async connectListingToAccount(accountID: string, listingID: string, externalListingID: string) {
        await this.http.post("/connectListingToAccount", {
            accountID,
            listingID,
            externalListingID
        });
    }

    async disconnectListingFromChannel(listingID: string, channel: AccountType) {
        await this.http.post("/disconnectListingFromChannel", {
            listingID,
            channel
        });
    }

    async getChannelSetting(listingID: string, accountID: string) {
        const url = `/getChannelSettings?listingID=${listingID}&accountID=${accountID}`;
        const response = await this.http.get<ChannelSetting>(url);
        const {data} = response;

        data.defaultPricingRules = data.defaultPricingRules?.map((rule, idx) => ({
            ...rule,
            id: `${idx}`
        }));
        data.seasonalRules = data.seasonalRules?.map(rule => ({
            ...rule,
            pricingRules: rule.pricingRules?.map((pricingRule, idx) => ({
                ...pricingRule,
                id: `${idx}`
            }))
        }));

        data.passThroughTaxes = data.passThroughTaxes?.map((tax, idx) => ({
            ...tax,
            id: `${idx}`
        }));

        data.standardFees = data.standardFees?.map((fee, idx) => ({
            ...fee,
            id: `${idx}`
        }));

        return data;
    }

    async uploadImage({listingID, ...rest}: AddImagePayload, config?: AxiosRequestConfig) {
        const url = "/uploadListingImage";
        const formData = generateImageUploadFormData(rest);
        formData.append("id", listingID);

        const {data} = await this.http.post<{
            url: string;
            publicId: string;
        }>(url, formData, {
            headers: {"Content-Type": "multipart/form-data"},
            ...config
        });

        return data;
    }

    async deleteImages({keys}: DeleteImagePayload, config?: AxiosRequestConfig) {
        const url = "/deleteListingImages";
        const {data} = await this.http.post(
            url,
            {
                keys
            },
            config
        );

        return data;
    }

    async submitChannelSetting(
        listingID: string,
        accountID: string,
        channelSettingsObj: ChannelSettingParams
    ) {
        const url = "/setChannelSettings";

        await this.http.post(url, {
            listingID,
            accountID,
            channelSettingsObj
        });
    }

    async deleteListing(id: string) {
        const url = "/deleteListing";

        await this.http.delete(url, {
            data: {
                id
            }
        });
    }

    async getListingAttributeOptions() {
        const {data} = await this.http.get<
            {
                value: string;
                label: string;
            }[]
        >("/listingAttributeOptions");
        return data;
    }
}

export default ListingService;
