import type {Listing} from "@hosttools/frontend/models/listing";
import {useMemo} from "react";

function useFilteredListings(listings: Listing[], query: string) {
    return useMemo(() => {
        const filteredQuery = query.toLowerCase().trim();
        return listings.filter(listing => {
            let matchesQuery = true;
            if (filteredQuery !== "") {
                if (
                    listing.airbnbName.toLowerCase().indexOf(filteredQuery) === -1 &&
                    (!listing.nickname ||
                        listing.nickname.toLowerCase().indexOf(filteredQuery) === -1)
                ) {
                    matchesQuery = false;
                }
            }
            return matchesQuery;
        });
    }, [listings, query]);
}

export default useFilteredListings;
