import {originalChannels} from "@hosttools/core/constant";
import buildChannels from "@hosttools/core/shared/buildChannels";
import {type AccountType} from "@hosttools/frontend/models/account";
import type {Listing} from "@hosttools/frontend/models/listing";
import {HStack} from "native-base";
import React, {type FC, memo, useMemo} from "react";

import type {Props as ChannelLogoProps} from "../ChannelLogo";
import ChannelLogo from "../ChannelLogo";

type Props = {
    listing: Listing;
    showStatus?: boolean;
    onRemove?: (channel: AccountType | (string & {})) => void;
} & Pick<ChannelLogoProps, "size" | "variant" | "status">;

// this should be shared somewhere
// I'll move later on when I find a good place to put it into

const ListingChannelsLogo: FC<Props> = ({
    listing,
    size,
    showStatus,
    variant = "default",
    onRemove
}) => {
    const channels = useMemo(() => buildChannels(listing), [listing]);

    if (channels.length) {
        return (
            <HStack space={2} testID="channels-logo" alignItems="center">
                {channels.map(channel => {
                    if (originalChannels.includes(channel)) {
                        return (
                            <ChannelLogo
                                key={channel}
                                channel={channel}
                                size={size}
                                variant={variant}
                                onRemove={onRemove}
                            />
                        );
                    }
                    const channelObj = listing.channels[channel];

                    return channelObj.channels?.map(channelItem => {
                        return (
                            <ChannelLogo
                                key={channelItem.channel}
                                channel={channelItem.channel}
                                logoUrl={channelItem.logo}
                                size={size}
                                variant={variant}
                                status={showStatus ? channelItem.status : undefined}
                                onRemove={channelItem.status === "listed" ? onRemove : undefined}
                            />
                        );
                    });
                })}
            </HStack>
        );
    }
    return null;
};

export default memo(ListingChannelsLogo);
