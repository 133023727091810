import React, {memo} from "react";

import Text from "@/client/components/Text";

interface Props {
    children: string;
}

const Token: React.FC<Props> = ({children}) => {
    return (
        <Text display="contents" variant="sm" color="blueGray.800" bold>
            {children}
        </Text>
    );
};

export default memo(Token);
