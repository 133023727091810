import React, {createContext, useCallback, useEffect, useMemo, useState} from "react";

import {type Props as ConfirmModalProps} from "@/admin/components/ModalConfirm";
import {setNewUIFlag} from "@/admin/utils";

interface AppContextType {
    isNewDesignEnabled: boolean;
    isMobile: boolean;
    confirmModal: ConfirmModalProps<any> | undefined;
    isTouchDevice: boolean;
    setConfirmModal: (
        modal:
            | ConfirmModalProps<any>
            | ((prev: ConfirmModalProps<any>) => ConfirmModalProps<any>)
            | undefined
    ) => void;
    setNewUIContext: (value: boolean) => void;
    setIsMobile: (value: boolean) => void;
}

const init: AppContextType = {
    isNewDesignEnabled: false,
    confirmModal: undefined,
    isMobile: false,
    isTouchDevice: false,
    setConfirmModal: () => {},
    setNewUIContext: () => {},
    setIsMobile: () => {}
};

export const AppContext = createContext<AppContextType>(init);

export const AppProvider: React.FC<{
    isNewDesignEnabled?: boolean;
}> = ({isNewDesignEnabled = true, children}) => {
    const [context, setContext] = useState<AppContextType>({
        ...init,
        isNewDesignEnabled
    });
    const query = useMemo(() => new URLSearchParams(window.location.search), []);

    const setConfirmModal = useCallback<AppContextType["setConfirmModal"]>(modal => {
        if (typeof modal === "function") {
            setContext(prev => {
                return {
                    ...prev,
                    confirmModal: prev.confirmModal ? modal(prev.confirmModal) : undefined
                };
            });
            return;
        }
        setContext(prev => ({
            ...prev,
            confirmModal: modal
                ? {
                      ...prev.confirmModal,
                      ...modal
                  }
                : undefined
        }));
    }, []);

    const setNewUIContext = useCallback((value: boolean) => {
        setNewUIFlag(value);
        if (value) {
            setContext(prev => ({
                ...prev,
                isNewDesignEnabled: value
            }));
        } else {
            window.location.reload();
        }
    }, []);

    const setIsMobile = useCallback((isMobile: boolean) => {
        setContext(prev => ({...prev, isMobile}));
    }, []);

    useEffect(() => {
        let isNewDesignEnabled = true;
        if (query.get("newui") === "0") {
            isNewDesignEnabled = false;
        }
        setContext(prev => {
            if (prev.isNewDesignEnabled !== isNewDesignEnabled) {
                return {...prev, isNewDesignEnabled};
            }
            return prev;
        });
    }, [query]);

    const value = useMemo(
        () => ({
            ...context,
            setConfirmModal,
            setNewUIContext,
            setIsMobile
        }),
        [context, setConfirmModal, setIsMobile, setNewUIContext]
    );

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
