import {useListing, useListingService} from "@hosttools/frontend";
import {type AccountType} from "@hosttools/frontend/models/account";
import useChannel from "@hosttools/frontend/react/hooks/useChannel";
import {AddIcon, ArrowBackIcon, Button, Center, HStack, VStack} from "native-base";
import React, {memo, useCallback, useEffect, useState, type FC} from "react";
import {useHistory, useParams, useRouteMatch} from "react-router-dom";

import TableAccountListings from "../AddListing/ImportListings/TableAccountListings";

import ModalAddAccount from "@/admin/components/ModalAddAccount";
import useSearchQuery from "@/admin/hooks/useSearchQuery";
import ContentWrapper from "@/client/components/ContentWrapper";
import Heading from "@/client/components/Heading";
import Paragraph from "@/client/components/Paragraph";
import Text from "@/client/components/Text";
import useAccountsByType from "@/client/hooks/useAccountsByType";

type Params = {
    listingID: string;
    accountType: AccountType;
};

// parent route elemnt shouldn't take more params
const ConnectListing: FC = () => {
    const {listingID, accountType} = useParams<Params>();
    const search = useSearchQuery();
    const {url} = useRouteMatch();
    const [addAccountType, setAddAccountType] = useState<AccountType>();
    const listingService = useListingService();
    const accounts = useAccountsByType(accountType);
    const history = useHistory();

    const goBack = useCallback(() => {
        const returnUrl = search.get("returnUrl");
        if (returnUrl) {
            history.push(decodeURIComponent(returnUrl));
        }
    }, [search, history]);

    const handleConnect = useCallback(
        async (accountID: string, externalListingID: string) => {
            await listingService.connectListingToAccount(accountID, listingID, externalListingID);
            goBack();
        },
        [listingID, listingService, goBack]
    );

    const hideAddAccountModal = useCallback(() => {
        setAddAccountType(undefined);
    }, []);

    const handleAddAdditionalAccount = useCallback(() => {
        setAddAccountType(accountType);
    }, [accountType]);

    const {label} = useChannel(accountType);
    const listing = useListing(listingID);

    // auto open the connect account modal in case of no connected accounts
    useEffect(() => {
        if (accounts.length < 1) {
            handleAddAdditionalAccount();
        }
    }, [accounts.length, handleAddAdditionalAccount]);

    return (
        <ContentWrapper fitHeight>
            <VStack p={4}>
                <VStack space={3}>
                    <Heading variant="2xl">{`${label} listings`}</Heading>
                    <Paragraph variant="lg">
                        Select the matching listing for <Text variant="lg">{listing?.name}</Text> on{" "}
                        <Text variant="lg">{label}</Text>
                    </Paragraph>
                </VStack>
                <VStack space={4}>
                    {accounts.map(elem => (
                        <TableAccountListings
                            key={elem._id}
                            type="matchListing"
                            accountID={elem._id}
                            onConnect={handleConnect}
                        />
                    ))}

                    {accounts.length > 0 && (
                        <Button
                            mt={4}
                            variant="outline"
                            leftIcon={<AddIcon />}
                            onPress={handleAddAdditionalAccount}
                        >
                            Connect additional account
                        </Button>
                    )}
                </VStack>
            </VStack>

            {!accounts.length && (
                <Center flex={1}>
                    <Text variant="xl">{`Let's connect your first ${label} account`}</Text>
                    <Button mt={4} leftIcon={<AddIcon />} onPress={handleAddAdditionalAccount}>
                        Connect account
                    </Button>
                </Center>
            )}

            <HStack p={4} alignSelf="flex-end">
                <Button variant="outline" leftIcon={<ArrowBackIcon />} onPress={goBack}>
                    Back
                </Button>
            </HStack>

            {addAccountType && (
                <ModalAddAccount
                    show
                    oAuthReturnUrl={
                        // consider `/add-listing` is the main landing page
                        typeof ONBOARDING_OAUTH_RETURN_URL !== "undefined"
                            ? ONBOARDING_OAUTH_RETURN_URL
                            : `${window.location.origin}/add-listing`
                    }
                    oAuthStateParameter={url}
                    channel={addAccountType}
                    onHide={hideAddAccountModal}
                    onConnected={hideAddAccountModal}
                />
            )}
        </ContentWrapper>
    );
};

export default memo(ConnectListing);
