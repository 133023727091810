import {memoWithType} from "@hosttools/frontend";
import {PencilIcon, TrashIcon} from "@hosttools/frontend/react/components/Icons";
import {HStack} from "native-base";
import React, {useCallback} from "react";

import ButtonIcon from "../ButtonIcon";
import Dropdown from "../Dropdown";
import DropdownItemLink from "../Dropdown/DropdownItemLink";
import {useThreeDotsTrigger} from "../Dropdown/useThreeDotsTrigger";

import useMobileView from "@/client/hooks/useMobileView";

type Props<T, ID> = {
    item: T;
    id: ID;
    onEdit?: (item: T, id: ID) => void;
    onRemove?: (id: ID) => void;
};

function TableCellEditRemoveActions<T, ID>({item, id, onEdit, onRemove}: Props<T, ID>) {
    const isMobile = useMobileView();
    const handleEdit = useCallback(() => {
        onEdit?.(item, id);
    }, [id, item, onEdit]);

    const handleRemove = useCallback(() => {
        onRemove?.(id);
    }, [id, onRemove]);

    const triggerListingDropdown = useThreeDotsTrigger({
        testID: "table-actions-dropdown"
    });

    if (isMobile) {
        return (
            <Dropdown trigger={triggerListingDropdown} placement="bottom right">
                <DropdownItemLink
                    testID="dropdown-edit-item"
                    label="Edit"
                    Icon={PencilIcon}
                    onPress={handleEdit}
                />
                {onRemove && (
                    <DropdownItemLink
                        testID="dropdown-delete-item"
                        label="Delete"
                        Icon={TrashIcon}
                        onPress={handleRemove}
                    />
                )}
            </Dropdown>
        );
    }

    return (
        <HStack space={1} alignItems="center">
            {onEdit && (
                <ButtonIcon
                    tooltip="Edit"
                    icon={PencilIcon}
                    testID="btn-edit-tag"
                    onPress={handleEdit}
                />
            )}
            {onRemove && (
                <ButtonIcon
                    tooltip="Delete"
                    icon={TrashIcon}
                    testID="btn-delete-tag"
                    onPress={handleRemove}
                />
            )}
        </HStack>
    );
}

export default memoWithType(TableCellEditRemoveActions);
