import type {ListingGroup} from "@hosttools/frontend/models/listingGroup";
import {HStack, VStack} from "native-base";
import React, {memo} from "react";

import MenuItem from "../MenuItem";

import {getListingGroupUrl} from "./util";

import Text from "@/client/components/Text";

type Props = {
    listingGroups: ListingGroup[];
    showAllListing: boolean;
    onClick?: () => void;
};

const ListingGroupsMenu: React.FC<Props> = ({listingGroups, showAllListing, onClick}) => {
    return (
        <VStack space={3}>
            <Text variant="md" color="blueGray.800" px={[0, 0, 2]}>
                Listing Groups
            </Text>
            <VStack>
                {showAllListing && (
                    <MenuItem path={getListingGroupUrl("all")} onClick={onClick}>
                        All listings
                    </MenuItem>
                )}
                {listingGroups.map(listingGroup => {
                    return (
                        <MenuItem
                            key={listingGroup._id}
                            testID="listing-group-item"
                            path={getListingGroupUrl(listingGroup._id)}
                            onClick={onClick}
                        >
                            <HStack maxWidth="100%">
                                <Text variant="sm" flex={1} isTruncated tooltipAsTruncated>
                                    {listingGroup.name}&nbsp;
                                </Text>
                                <Text variant="sm" flex={0}>
                                    {`(${
                                        "uniqueMessageRulesCount" in listingGroup
                                            ? listingGroup.uniqueMessageRulesCount
                                            : ""
                                    })`}
                                </Text>
                            </HStack>
                        </MenuItem>
                    );
                })}
            </VStack>
        </VStack>
    );
};

export default memo(ListingGroupsMenu);
