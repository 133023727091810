import {type AccountType} from "@hosttools/frontend/models/account";
import type {Dispatch} from "react";
import {type SetStateAction, createContext} from "react";

import noop from "@/shared/noop";

export type ConnectAccountContextType = {
    accountType: AccountType;
};

export const Context = createContext<
    ConnectAccountContextType & {
        setContext: Dispatch<SetStateAction<ConnectAccountContextType>>;
    }
>({
    accountType: "Airbnb",
    setContext: noop
});
