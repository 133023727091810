import {useCallback, useContext, useEffect, useState} from "react";

import type {Message} from "../../../models/message";
import type {Timeline} from "../../../models/timeline";
import MessageService from "../../../services/message";
import TimelineService from "../../../services/timeline";
import {updateListWS} from "../../../utils";
import {WebSocketContext} from "../../contexts";
import HTTPContext from "../../contexts/HTTPContext";
import useAbortController from "../useAbortController";

function useFetchTimelines(reservationID: string | undefined): {
    timelines: Timeline[] | undefined;
    isLoading: boolean;
    removeTimelines: (ids: string[]) => void;
    refetch: () => Promise<[Timeline[] | undefined]>;
};
function useFetchTimelines(
    reservationID: string | undefined,
    includeMessages: boolean
): {
    timelines: Timeline[] | undefined;
    messages: Message[] | undefined;
    isLoading: boolean;
    removeTimelines: (ids: string[]) => void;
    removeMessages: (ids: string[]) => void;
    refetch: () => Promise<[Timeline[] | undefined, Message[] | undefined]>;
};
function useFetchTimelines(reservationID: string | undefined, includeMessages?: boolean) {
    const http = useContext(HTTPContext);
    const ws = useContext(WebSocketContext);
    const [timelines, setTimelines] = useState<Timeline[] | undefined>(undefined);
    const [messages, setMessages] = useState<Message[] | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const abortRef = useAbortController(reservationID);

    const removeTimelines = useCallback((ids: string[]) => {
        setTimelines(prev => {
            if (prev) {
                return prev.filter(timeline => !ids.includes(timeline._id));
            }
            return prev;
        });
    }, []);

    const removeMessages = useCallback((ids: string[]) => {
        setMessages(prev => {
            if (prev) {
                return prev.filter(message => !ids.includes(message._id));
            }
            return prev;
        });
    }, []);

    const refetch = useCallback(
        async (id: string) => {
            try {
                setIsLoading(true);

                const timelineService = new TimelineService(http);
                const messageService = new MessageService(http);

                if (!includeMessages) {
                    return Promise.all([
                        timelineService.fetchTimelines(id, {
                            signal: abortRef.current?.signal
                        })
                    ]);
                }
                return Promise.all([
                    timelineService.fetchTimelines(id, {signal: abortRef.current?.signal}),
                    messageService.fetchMessages(id, {signal: abortRef.current?.signal})
                ]);
            } finally {
                setIsLoading(false);
            }
        },
        [includeMessages, abortRef, http]
    );

    useEffect(() => {
        function syncTimelines(payload: WSPayload<Timeline>) {
            setTimelines(prev => {
                if (!prev) {
                    return prev;
                }
                if (payload.event === "insert") {
                    if (payload.document.reservationID === reservationID) {
                        return updateListWS(prev, payload);
                    }
                    return prev;
                }
                return updateListWS(prev, payload);
            });
        }

        ws?.on<WSRoom>("timeline", syncTimelines);

        return () => {
            ws?.off("timeline", syncTimelines);
        };
    }, [reservationID, ws]);

    useEffect(() => {
        let active = true;
        if (reservationID) {
            setTimelines(undefined);
            setMessages(undefined);
            refetch(reservationID).then(([timelines, messages]) => {
                if (active) {
                    setTimelines(timelines);
                    setMessages(messages);
                }
            });
        }
        return () => {
            active = false;
        };
    }, [reservationID, refetch]);

    if (includeMessages) {
        return {refetch, removeTimelines, removeMessages, timelines, messages, isLoading};
    }
    return {refetch, removeTimelines, timelines, isLoading};
}

export default useFetchTimelines;
