import {Button} from "native-base";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {components} from "react-select";
import type {Components} from "react-select/src/components";

export const MenuList: Components["MenuList"] = ({children, ...props}) => {
    const maxOptions = props.selectProps?.maxOptions;
    const initNumberOfItemDisplay = useMemo(() => {
        const selectedValues = props.getValue();
        if (selectedValues.length === 1) {
            const selectedValue = selectedValues[0];
            const selectedIndex = props.options.findIndex(
                option => option.value === selectedValue.value
            );
            return Math.max(selectedIndex + 1, maxOptions);
        }
        return maxOptions;
    }, [maxOptions, props]);

    const [numberOfItemDisplay, setNumberOfItemDisplay] = useState<number | undefined>(
        initNumberOfItemDisplay
    );

    const handleSeeMore = useCallback(() => {
        setNumberOfItemDisplay(prev => (prev ?? 0) + maxOptions);
    }, [maxOptions]);

    const menuRef = useRef<HTMLDivElement>();
    // auto scroll to the list which is helpful for users specifically on modal
    // where the view is a bit narrow
    useEffect(() => {
        menuRef.current?.scrollIntoView({
            block: "end"
        });
    }, []);

    return (
        <components.MenuList {...props} innerRef={menuRef}>
            {Array.isArray(children) ? (
                <>
                    {numberOfItemDisplay ? children.slice(0, numberOfItemDisplay) : children}
                    {typeof numberOfItemDisplay === "number" &&
                        children.length > numberOfItemDisplay && (
                            <Button variant="link" onPress={handleSeeMore} mx={3}>
                                See more
                            </Button>
                        )}
                </>
            ) : (
                children
            )}
        </components.MenuList>
    );
};
