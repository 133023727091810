import {useContext, useEffect} from "react";

import type {UserRaw} from "../../../models/user";
import {UserContext, WebSocketContext} from "../../contexts";

const useWatchUser = () => {
    const websocket = useContext(WebSocketContext);
    const {user, setUser} = useContext(UserContext);

    useEffect(() => {
        function handleUserChange(payload: WSPayload<UserRaw>) {
            if (payload.event === "update" && user._id === payload.document._id) {
                setUser(payload.document);
            }
        }

        if (websocket && user._id) {
            websocket.on<WSRoom>("user", handleUserChange);
        }

        return () => {
            if (websocket && user._id) {
                websocket.off("user", handleUserChange);
            }
        };
    }, [websocket, user._id, setUser]);
};

export default useWatchUser;
