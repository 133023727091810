import {Image, VStack, View} from "native-base";
import type {ReactNode} from "react";
import React, {memo, useMemo} from "react";

import Paragraph from "../Paragraph";
import Text from "../Text";

import calculateDimension, {type AspectRatio} from "./calculateDimension";

type Props = {
    imgAspectRatio?: AspectRatio;
    primaryText: string;
    secondaryText?: ReactNode;
    children?: ReactNode;
} & StrictUnion<
    | {
          SVGComponent?: React.FC<React.SVGProps<any>>;
          imgWidth?: number;
          imgHeight?: number;
      }
    | {
          imgUrl?: string;
          imgWidth?: number | number[];
          imgHeight?: number | number[];
      }
>;

const EmptyState: React.FC<Props> = ({
    primaryText,
    secondaryText,
    children,
    imgUrl,
    SVGComponent,
    imgWidth,
    imgHeight,
    imgAspectRatio,
    ...rest
}) => {
    const source = useMemo(() => ({uri: imgUrl}), [imgUrl]);

    return (
        <View
            testID="emtpy-state"
            flexGrow={1}
            flexShrink={1}
            alignItems="center"
            justifyContent="center"
            {...rest}
        >
            <VStack
                justifyContent="center"
                alignItems="center"
                space={6}
                maxWidth={["100%", "50%"]}
            >
                <VStack space={2} justifyContent="center" alignItems="center">
                    {imgUrl && (
                        <Image
                            source={source}
                            alt="Empty image"
                            maxWidth="none"
                            resizeMode="contain"
                            {...calculateDimension(
                                imgWidth ?? [220, 346],
                                imgHeight,
                                imgAspectRatio
                            )}
                        />
                    )}
                    {SVGComponent && (
                        <SVGComponent
                            {...calculateDimension(imgWidth ?? 346, imgHeight, imgAspectRatio)}
                        />
                    )}
                    <Text variant="2xl" textAlign="center">
                        {primaryText}
                    </Text>
                    {secondaryText &&
                        (typeof secondaryText === "string" ? (
                            <Paragraph variant="md" textAlign="center">
                                {secondaryText}
                            </Paragraph>
                        ) : (
                            secondaryText
                        ))}
                </VStack>
                {children}
            </VStack>
        </View>
    );
};

export default memo(EmptyState);
