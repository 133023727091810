import {type Partner as PartnerRaw} from "@hosttools/core/config/types";
import type {User as UserModel} from "@hosttools/core/generated/models";
import type {Permissions as PermissionsRaw, Role} from "@hosttools/core/shared/model/user";
import type {Permissions as PermissionsLegacy} from "@hosttools/core/shared/utils/utils.permission";
import {
    convertPermissions,
    getRoleByPermissions
} from "@hosttools/core/shared/utils/utils.permission";
import {isSameDay} from "date-fns";
import gravatar from "gravatar";

import type {Account} from "./account";
import type {ListingGroup} from "./listingGroup";
import type {Lock} from "./lock";
import {sanitizeLock} from "./lock";
import Partner from "./partner";
import type {Tag} from "./tag";

export type PermissionValue = "edit" | "view";

export type PermissionKey =
    | "inbox"
    | "messageRules"
    | "pricing"
    | "availability"
    | "accounts"
    | "listings"
    | "listingGroups"
    | "reservationBasic"
    | "reservationFinancial"
    | "reservationFull"
    | "settings"
    | "billing"
    | "userManagement";

export type Permissions = Partial<Record<PermissionKey, PermissionValue>>;

export type UserRaw = MongooseModel2Client<UserModel> & {
    intercomHash: string;
    locks?: Lock[];
    accounts?: Account[];
    listingGroups?: ListingGroup[];
    tags?: Tag[];
    partners: PartnerRaw[];
};

export type UpdateStaffParams = Partial<
    Pick<
        UserRaw,
        | "_id"
        | "username"
        | "firstName"
        | "lastName"
        | "password"
        | "permissions"
        | "listingGroupIDs"
    >
>;

function isViewable(value?: string): boolean {
    return value === "view" || value === "edit";
}

function isEditable(value?: string): boolean {
    return value === "edit";
}

export function getFullName({firstName, lastName}: {firstName?: string; lastName?: string}) {
    return `${firstName ? `${firstName} ` : ""}${lastName}`;
}

export class User {
    _id: string;

    firstName: string;

    lastName: string;

    fullName: string;

    username: string;

    subscriptionStatus: string | undefined;

    locks: Lock[];

    intercomHash: string;

    isBeta: boolean;

    trialLengthEndDate?: Date;

    createdAt?: string;

    thumbnailUrl: string;

    originUserID?: string;

    permissionsRaw?: PermissionsRaw;

    permissions: PermissionsLegacy;

    role?: Role;

    phone?: string;

    /**
     * accounts should be fetched separately
     * @deprecated
     */
    accounts: Account[];

    /**
     * @deprecated
     */
    listingGroups: ListingGroup[];

    listingGroupIDs?: string[];

    tags: Tag[];

    lockPinLength: number | undefined;

    // Customized ones
    // canViewListingGroups: boolean;
    // canEditListingGroups: boolean;
    // canvViewAndEditMessageRules: boolean;
    canViewAvailability = true;

    canViewAndEditInbox = true;

    canViewInbox = true;

    canViewReservationBasic = true;

    canViewReservationFinancial = true;

    canEditReservationFinancial = true;

    canViewReservationFull = true;

    canEditReservationFull = true;

    canViewPrice = true;

    canEditPrice = true;

    canEditAvailability = true;

    canViewListingGroup = true;

    canEditListingGroup = true;

    canEditAccount = true;

    canEditListing = true;

    canEditUser = true;

    canEditBilling = true;

    canViewMessageRules = true;

    canEditMessageRules = true;

    isSubUser = false;

    apiKeys: UserRaw["apiKeys"];

    frequency: UserRaw["frequency"];

    authToken: string;

    partners: Partner[];

    raw?: UserRaw;

    isFeedbackGiven?: boolean;

    constructor(raw: UserRaw) {
        this._id = raw._id;
        this.firstName = raw.firstName ?? "";
        this.lastName = raw.lastName ?? "";
        this.fullName = getFullName({firstName: this.firstName, lastName: this.lastName});
        this.username = raw.username;
        this.subscriptionStatus = raw.subscriptionStatus;
        this.intercomHash = raw.intercomHash;
        this.isBeta = raw.isBeta ?? false;
        this.trialLengthEndDate = raw.trialLengthEndDate
            ? new Date(raw.trialLengthEndDate)
            : undefined;
        this.createdAt = raw.createdAt;
        this.permissionsRaw = raw.permissions as PermissionsRaw;
        this.originUserID = raw.originUserID;
        this.apiKeys = raw.apiKeys;
        this.accounts = raw.accounts
            ? raw.accounts.map(elem => ({
                  ...elem,
                  username: elem.username ?? elem.airbnbUsername
              }))
            : [];
        this.listingGroups = raw.listingGroups
            ? raw.listingGroups.map(elem => ({
                  ...elem,
                  name: elem.name ?? ""
              }))
            : [];
        this.frequency = raw.frequency;
        this.listingGroupIDs = raw.listingGroupIDs;
        this.locks = raw.locks ? raw.locks.map(sanitizeLock) : [];
        this.tags = raw.tags ?? [];
        this.lockPinLength = raw.lockPinLength;
        this.authToken = raw.authToken ?? "";
        this.permissions = convertPermissions(raw.permissions, raw.originUserID);

        const avatar = gravatar.url(raw.username, {s: "120", r: "pg", d: "mp"}, true);
        this.thumbnailUrl = avatar;
        this.partners = raw.partners ? raw.partners.map(elem => new Partner(elem)) : [];
        this.role = this.permissionsRaw ? getRoleByPermissions(this.permissionsRaw) : undefined;
        this.phone = raw.phone;

        const lastAnsweredFeedback = raw.cancellation?.reasonDate || raw.trialEnded?.reasonDate;
        this.isFeedbackGiven =
            !!lastAnsweredFeedback &&
            !!raw.last &&
            isSameDay(new Date(lastAnsweredFeedback), new Date(raw.last));

        if (this.originUserID) {
            const {permissions = {}} = raw;
            this.canViewAvailability = isViewable(permissions.availability);
            this.canViewInbox = isViewable(permissions.inbox);
            this.canViewAndEditInbox = isEditable(permissions.inbox);
            this.canViewReservationFull = isViewable(permissions.reservationFull);
            this.canEditReservationFull = isEditable(permissions.reservationFull);
            this.canEditReservationFinancial =
                isEditable(permissions.reservationFinancial) || this.canEditReservationFull;
            this.canViewReservationFinancial =
                isViewable(permissions.reservationFinancial) || this.canViewReservationFull;
            this.canViewReservationBasic =
                isViewable(permissions.reservationBasic) || this.canViewReservationFinancial;
            this.canViewPrice = isViewable(permissions.pricing);
            this.canEditPrice = isEditable(permissions.pricing);
            this.canEditAvailability = isEditable(permissions.availability);
            this.canViewListingGroup = isViewable(permissions.listingGroups);
            this.canEditListingGroup = isEditable(permissions.listingGroups);
            this.canEditAccount = isEditable(permissions.accounts);
            this.canEditListing = isEditable(permissions.listings);
            this.canEditBilling = isEditable(permissions.billing);
            this.canEditUser = isEditable(permissions.userManagement);
            this.canViewMessageRules = isViewable(permissions.messageRules);
            this.canEditMessageRules = isEditable(permissions.messageRules);
            this.isSubUser = true;
        }
        this.raw = raw;
    }
}
