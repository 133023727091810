import React, {memo, useContext, useState, useEffect} from "react";
import {Modal, ProgressBar} from "react-bootstrap";

import {UserContext} from "../../providers/UserProvider";

type Props = {
    onHide: () => void;
};

const ModalDownloading: React.FC<Props> = ({onHide}) => {
    const {
        user: {accounts}
    } = useContext(UserContext);

    const [progress, setProgress] = useState<number>(0);

    useEffect(() => {
        const accountsDownloadedCompleted = accounts.filter(account => account.listingsUpdatedLast);

        setProgress(Math.round((accountsDownloadedCompleted.length / accounts.length) * 100));
    }, [accounts]);

    useEffect(() => {
        if (progress >= 100) {
            onHide();
        }
    }, [progress, onHide]);

    return (
        <Modal show centered backdrop="static">
            <Modal.Body>
                <div className="tx-center pd-y-30 pd-x-15">
                    <div className="tx-30 tx-medium text-dark mg-b-15">
                        Downloading your listing data
                    </div>
                    <p className="tx-16">
                        Host Tools mobile app for iOS and Android helps you manage day-to-day tasks
                        on the go.
                    </p>
                    <p className="text-muted">
                        You can see your calendars, add a manual reservation, edit details of an
                        existing reservation, message with guests, and view, send, edit, or cancel a
                        scheduled message.
                    </p>
                    <ProgressBar animated now={progress} />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default memo(ModalDownloading);
