import {useState, useCallback, useEffect} from "react";

export const useEllipsisVisible = (disabled: boolean = false, numberOfLines: number = 1) => {
    const [overflowingNode, setOverflowingNode] = useState<HTMLElement>();
    const [isEllipsisVisible, setEllipsisVisible] = useState(false);

    const ellipsisVisibleRef = useCallback((node: HTMLElement) => {
        if (node instanceof HTMLElement) {
            setOverflowingNode(node);
        }
    }, []);

    useEffect(() => {
        if (disabled) {
            return;
        }
        if (overflowingNode instanceof HTMLElement) {
            if (numberOfLines > 1) {
                const lineHeight = parseFloat(getComputedStyle(overflowingNode).lineHeight);
                const maxHeight = lineHeight * numberOfLines;
                setEllipsisVisible(overflowingNode.scrollHeight > maxHeight);
                return;
            }

            setEllipsisVisible(overflowingNode.scrollWidth > overflowingNode.offsetWidth);
        }
    }, [disabled, numberOfLines, overflowingNode]);

    return [isEllipsisVisible, ellipsisVisibleRef] as const;
};
