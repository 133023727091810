import type {ChannelObject} from "@hosttools/frontend/models/channel";
import {HStack, Icon, Radio, Text, VStack} from "native-base";
import type {FC} from "react";
import React, {useCallback, useEffect, useState} from "react";
import {FiMail, FiMessageSquare, FiPhone} from "react-icons/fi";

import type {Errors, Method} from "./types";

import Alert from "@/client/components/Alert";
import useNewDesign from "@/client/hooks/useNewDesign";

interface Props {
    channel: ChannelObject;
    methods: Method[];
    info: string;
    errors: Errors;
    onChange: (code: number) => void;
}

const Verification: FC<Props> = props => {
    const isNewUIEnabled = useNewDesign();

    const {channel, methods, errors: initErrors = {}, info = "", onChange} = props;

    const [methodIndex, setMethodIndex] = useState(0);
    const [errors, setErrors] = useState(initErrors);

    useEffect(() => {
        setErrors(initErrors);
    }, [initErrors]);

    function handleChangeMethod(code: number) {
        setMethodIndex(code);
        onChange(code);
    }

    const handleChangeMethodCode = useCallback(
        (code: string) => {
            setMethodIndex(Number(code));
            onChange(Number(code));
        },
        [onChange]
    );

    if (isNewUIEnabled) {
        return (
            <VStack space={5}>
                <Text>
                    {channel.label} needs to verify your account. Please choose from one of the
                    options below.
                </Text>
                <Radio.Group
                    name="radio"
                    value={`${methodIndex}`}
                    onChange={handleChangeMethodCode}
                >
                    <VStack space={3} maxW="full">
                        {methods.map((method, i) => {
                            const {type, message} = method;
                            return (
                                <Radio key={type} size="sm" value={`${i}`} my={1}>
                                    <HStack space={2} maxW="full" alignItems="center">
                                        {type === "sms" && <Icon as={FiMessageSquare} />}
                                        {type === "call" && <Icon as={FiPhone} />}
                                        {type === "email" && <Icon as={FiMail} />}
                                        <Text size="sm" color="blueGray.500" maxW="full" flex={1}>
                                            {message}
                                        </Text>
                                    </HStack>
                                </Radio>
                            );
                        })}
                    </VStack>
                </Radio.Group>
                {errors.error && <Alert color="danger">{errors.error}</Alert>}
                {info && <Alert>{info}</Alert>}
            </VStack>
        );
    }

    return (
        <>
            <p>
                {channel.label} needs to verify your account. Please choose from one of the options
                below.
            </p>
            <div className="pd-md-20">
                <div className="form-group">
                    {methods.map((method, i) => {
                        const {type, message} = method;
                        return (
                            <label key={type + i} className="radiobox">
                                <input
                                    id={type + i}
                                    type="radio"
                                    name="radio"
                                    value={i}
                                    checked={methodIndex === i}
                                    onChange={() => {
                                        handleChangeMethod(i);
                                    }}
                                />
                                <span>
                                    {type === "sms" && <FiMessageSquare className="mx-1" />}
                                    {type === "call" && <FiPhone className="mx-1" />}
                                    {type === "email" && <FiMail className="mx-1" />}
                                    {message}
                                </span>
                            </label>
                        );
                    })}
                </div>
            </div>
            {errors.error && <div className="alert alert-danger">{errors.error}</div>}
            {info && <div className="alert alert-info">{info}</div>}
        </>
    );
};

export default Verification;
