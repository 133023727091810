import * as amplitude from "@amplitude/analytics-browser";
import {UserContext} from "@hosttools/frontend";
import Intercom from "@intercom/messenger-js-sdk";
import LogRocket from "logrocket";
import type React from "react";
import {memo, useContext, useEffect} from "react";
import ReactPixel from "react-facebook-pixel";
import {FullStoryAPI} from "react-fullstory";
import TagManager from "react-gtm-module";
import {hotjar} from "react-hotjar";

const ExternalServices: React.FC = () => {
    const {user, visibleListings} = useContext(UserContext);

    useEffect(() => {
        if (__DEV__) {
            return;
        }

        amplitude.init(
            __IS_BETA__ ? "1d6a794b4b3f13c77d14c5d3b5e7c994" : "5bee0782367d6c51f29fed1d8869f86b",
            user._id,
            {
                autocapture: true
            }
        );

        TagManager.initialize({
            gtmId: "GTM-59XQPWR",
            dataLayer: {
                userId: user._id
            }
        });
        ReactPixel.init("2039536889686642", {
            external_id: user._id
        });
        ReactPixel.pageView(); // For tracking page view

        if (user.subscriptionStatus !== "active" && !user.isBeta) {
            LogRocket.init("acu09k/superhost-tools");
            LogRocket.identify(user._id, {
                email: user.username,
                name: user.fullName
                // // Add your own custom user variables here, ie:
                // subscriptionType: "pro"
            });

            hotjar.initialize(1683480, 6);
            hotjar.identify(user._id, {
                email: user.username,
                name: user.fullName
            });
        }
    }, [user._id, user.fullName, user.username, user.subscriptionStatus, user.isBeta]);

    // fullstory
    useEffect(() => {
        if (__DEV__) {
            return;
        }

        FullStoryAPI("identify", user._id, {
            displayName: user.fullName,
            email: user.username
        });
    }, [user._id, user.fullName, user.username]);

    // Intercom
    useEffect(() => {
        if (INTERCOM_APP_ID) {
            Intercom({
                app_id: INTERCOM_APP_ID,
                user_id: user._id,
                name: user.fullName,
                email: user.username,
                user_hash: user.intercomHash,
                created_at: user.createdAt ? new Date(user.createdAt).getTime() / 1000 : undefined,
                server: user.isBeta ? "beta" : "main",
                status: user.isBeta ? "beta" : user.subscriptionStatus,
                listings: visibleListings.length
            });
        }
    }, [
        user._id,
        user.fullName,
        user.username,
        user.intercomHash,
        user.createdAt,
        user.isBeta,
        user.subscriptionStatus,
        visibleListings.length
    ]);

    return null;
};

export default memo(ExternalServices);
