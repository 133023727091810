import type {Listing} from "@hosttools/frontend/models/listing";
import type {ListingGroup} from "@hosttools/frontend/models/listingGroup";
import {Box, Stack} from "native-base";
import React, {memo} from "react";

import ListingGroupsMenu from "./ListingGroupsMenu";
import ListingsMenu from "./ListingsMenu";

type Props = {
    visibleListings: Listing[];
    listingGroups: ListingGroup[];
    onClick?: () => void;
};

const MessagingMenu: React.FC<Props> = ({visibleListings, listingGroups, onClick}) => {
    return (
        <Stack
            space={[4, 4, 6]}
            width={["full", "full", "600px"]}
            direction={["column", "column", "row"]}
            p={0}
            pl={[0, 0, 3]}
        >
            <Box
                maxW={["full", "full", 48]}
                py={[0, 0, 3]}
                maxH={["full", "full", 96]}
                overflowY="auto"
            >
                <ListingGroupsMenu
                    listingGroups={listingGroups}
                    showAllListing={visibleListings.length > 1}
                    onClick={onClick}
                />
            </Box>
            <Box
                p={4}
                flex={1}
                borderRadius={10}
                bg="blueGray.50"
                borderWidth={1}
                borderStyle="solid"
                borderColor="blueGray.200"
                maxW={["full", "full", "unset"]}
                maxH={["full", "full", 96]}
                overflowY="auto"
            >
                <ListingsMenu listings={visibleListings} to="messaging" onClick={onClick} />
            </Box>
        </Stack>
    );
};

export default memo(MessagingMenu);
