import {UserContext, useAccounts} from "@hosttools/frontend";
import {type AccountType} from "@hosttools/frontend/models/account";
import {useContext, useMemo} from "react";

const useAccountsByType = (accountType: AccountType) => {
    const {accounts} = useContext(UserContext);
    const {channels} = useAccounts(accounts);

    return useMemo(
        () => channels.filter(elem => elem.type === accountType),
        [accountType, channels]
    );
};

export default useAccountsByType;
