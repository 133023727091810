import shadows from "@hosttools/frontend/theme/shadows";
import type {Theme} from "native-base";
import {theme} from "native-base";

type ButtonType = Theme["components"]["Button"];

const supportedSizes = ["lg", "md", "sm", "xs"] as const;

const ghostSizes = {
    lg: {
        px: 1,
        py: 0.5
    },
    md: {
        px: 0.5,
        py: 0.5
    },
    sm: {
        px: 0.5,
        py: 0.5
    },
    xs: {
        px: 0.5,
        py: 0.5
    }
} satisfies Record<(typeof supportedSizes)[number], {px: number; py: number}>;

export const Button: Partial<ButtonType> = {
    baseStyle: props => {
        const baseStyleProps = theme.components.Button.baseStyle(props);
        const commonStyles = {
            ...baseStyleProps,
            _text: {
                fontWeight: "medium"
            },
            minWidth: 100,
            _loading: {
                opacity: "100"
            },
            _disabled: {
                opacity: "100"
            }
        };
        if (props.variant === "ghost" && supportedSizes.includes(props.size)) {
            return {
                ...commonStyles,
                _web: {
                    ...baseStyleProps._web,
                    ...ghostSizes[props.size]
                }
            };
        }
        return commonStyles;
    },
    defaultProps: {
        variant: "solid",
        size: "md",
        colorScheme: "blue"
    },
    sizes: {
        lg: {
            px: "4",
            py: "2",
            _text: {
                fontSize: "md"
            },
            _icon: {
                size: "md"
            }
        },
        md: {
            px: "2",
            py: "2",
            _text: {
                fontSize: "sm"
            },
            _icon: {
                size: "sm"
            }
        },
        sm: {
            px: "2",
            py: "2",
            _text: {
                fontSize: "xs"
            },
            _icon: {
                size: "xs"
            }
        },
        xs: {
            px: "2",
            py: "2",
            _text: {
                fontSize: "2xs"
            },
            _icon: {
                size: "xs"
            }
        }
    },
    variants: {
        ...theme.components.Button.variants,
        solid: ({colorScheme, theme, disabled}: Record<string, any>) => {
            const {colors} = theme;
            const spreadShadow = `${shadows.spread.shadowOffset.width}px ${shadows.spread.shadowOffset.height}px ${shadows.spread.shadowRadius}px ${shadows.spread.elevation}px ${colors[colorScheme][600]}80`;
            return {
                borderWidth: "1px",
                borderColor: "transparent",
                _text: {
                    color: disabled ? "blueGray.400" : "white"
                },
                _icon: {
                    color: disabled ? "blueGray.400" : "white"
                },
                _spinner: {
                    color: disabled ? "blueGray.400" : "white"
                },
                bg: disabled ? "blueGray.200" : `${colorScheme}.600`,
                _hover: {
                    bg: `${colorScheme}.700`
                },
                _pressed: {
                    bg: `${colorScheme}.600`,
                    style: {
                        boxShadow: spreadShadow
                    }
                },
                _focus: {
                    bg: `${colorScheme}.600`,
                    style: {
                        boxShadow: spreadShadow
                    }
                },
                _disabled: {
                    _text: {
                        color: "blueGray.400"
                    },
                    _icon: {
                        color: "blueGray.400"
                    },
                    _spinner: {
                        color: "blueGray.400"
                    },
                    bg: "blueGray.200",
                    opacity: "100"
                },
                _dark: {
                    bg: `${colorScheme}.600`,
                    _hover: {
                        bg: `${colorScheme}.700`
                    },
                    _pressed: {
                        bg: `${colorScheme}.800`
                    }
                }
            };
        },
        outline: ({colorScheme, disabled}: Record<string, any>) => {
            return {
                borderWidth: "1px",
                borderColor: disabled ? "transparent" : `${colorScheme}.200`,
                bg: disabled ? "blueGray.200" : "white",
                _text: {
                    color: disabled ? "blueGray.400" : `${colorScheme}.600`
                },
                _icon: {
                    color: disabled ? "blueGray.400" : `${colorScheme}.600`
                },
                _spinner: {
                    color: disabled ? "blueGray.400" : `${colorScheme}.600`
                },
                _hover: {
                    bg: `${colorScheme}.100`
                },
                _pressed: {
                    bg: `${colorScheme}.100`,
                    borderColor: `${colorScheme}.300`
                },
                _focus: {
                    bg: `${colorScheme}.100`,
                    borderColor: `${colorScheme}.300`
                },
                _disabled: {
                    _text: {
                        color: "blueGray.400"
                    },
                    _icon: {
                        color: "blueGray.400"
                    },
                    _spinner: {
                        color: "blueGray.400"
                    },
                    bg: "blueGray.200",
                    borderColor: "transparent",
                    opacity: "100"
                },
                _dark: {
                    borderColor: "muted.700",
                    _text: {
                        color: `${colorScheme}.500`
                    },
                    _icon: {
                        color: `${colorScheme}.500`
                    },
                    _spinner: {
                        color: `${colorScheme}.500`
                    },
                    _hover: {
                        bg: `${colorScheme}.500:alpha.10`
                    },
                    _pressed: {
                        bg: `${colorScheme}.500:alpha.20`
                    }
                }
            };
        },
        ghost: ({colorScheme, disabled}: Record<string, any>) => {
            const disabledColorScheme = ["error", "red", "danger"].includes(colorScheme)
                ? "error"
                : "blueGray";

            return {
                borderWidth: "1px",
                borderColor: "transparent",
                _text: {
                    color: disabled ? `${disabledColorScheme}.200` : `${colorScheme}.600`
                },
                _icon: {
                    color: disabled ? `${disabledColorScheme}.200` : `${colorScheme}.600`
                },
                _spinner: {
                    color: disabled ? `${disabledColorScheme}.200` : `${colorScheme}.600`
                },
                _hover: {
                    bg: `${colorScheme}.100`,
                    borderColor: `${colorScheme}.200`
                },
                _pressed: {
                    bg: `${colorScheme}.100`,
                    borderColor: `${colorScheme}.300`
                },
                _focus: {
                    bg: `${colorScheme}.100`,
                    borderColor: `${colorScheme}.300`
                },
                _disabled: {
                    _text: {
                        color: `${disabledColorScheme}.200`
                    },
                    _icon: {
                        color: `${disabledColorScheme}.200`
                    },
                    _spinner: {
                        color: `${disabledColorScheme}.200`
                    },
                    opacity: "100"
                },
                _dark: {
                    _text: {
                        color: `${colorScheme}.500`
                    },
                    _icon: {
                        color: `${colorScheme}.500`
                    },
                    _spinner: {
                        color: `${colorScheme}.500`
                    },
                    _hover: {
                        bg: `${colorScheme}.500:alpha.10`
                    },
                    _pressed: {
                        bg: `${colorScheme}.500:alpha.20`
                    }
                }
            };
        }
    }
};
