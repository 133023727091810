import {useQuery} from "@tanstack/react-query";
import {useCallback} from "react";

import {sanitizeListing} from "../../../models/listing";
import {useListingService} from "../useListingService";

export const useFetchListingID = (listingID: string | undefined, noCache: boolean = false) => {
    const listingService = useListingService();

    return useQuery({
        retry: 0,
        gcTime: noCache ? 0 : undefined,
        queryKey: ["useFetchListingID", listingID ?? ""],
        queryFn: useCallback(async () => {
            if (!listingID) {
                return Promise.resolve(null);
            }
            const data = await listingService.get("/getListing/:listingID", {listingID});
            return sanitizeListing(data);
        }, [listingID, listingService])
    });
};
