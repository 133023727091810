import {useContext, useMemo} from "react";

import UserService from "../../../services/user";
import {HTTPContext} from "../../contexts";

export const useUserService = () => {
    const http = useContext(HTTPContext);
    const userService = useMemo(() => new UserService(http), [http]);
    return userService;
};
