import {isReactElement, useTimeout} from "@hosttools/frontend";
import type {Props as IIconProps} from "@hosttools/frontend/react/components/Icons/Icon";
import mobile from "is-mobile";
import type {IIconButtonProps} from "native-base";
import {IconButton, Spinner} from "native-base";
import type {FC, ReactElement} from "react";
import React, {memo, useCallback, useMemo, useRef} from "react";
import type {NativeSyntheticEvent, NativeTouchEvent} from "react-native";

import type {TooltipRef} from "../Tooltip";
import Tooltip from "../Tooltip";

type Props = {
    testID?: string;
    icon: ReactElement<IIconProps> | React.ComponentType<IIconProps>;
    tooltip: React.ReactNode;
    isLoading?: boolean;
    size?: "lg" | "md" | "sm" | "xs";
    color?: "primary" | "blueGray" | "error";
    placement?: "left" | "right";
    disabled?: boolean;
    variant?: "outline" | "solid";
    borderStyle?: "dashed" | "solid";
    borderRadius?: "full";
    onPress?: IIconButtonProps["onPress"];
};

const ButtonIcon: FC<Props> = ({
    testID,
    icon: Icon,
    color = "blueGray",
    isLoading,
    tooltip,
    size = "md",
    placement,
    disabled,
    variant,
    borderRadius,
    borderStyle,
    onPress
}) => {
    const iconProps = useMemo(
        () =>
            onPress
                ? {}
                : ({size, color: `${color}.600`} satisfies {
                      size?: "lg" | "md" | "sm" | "xs";
                      color: "primary.600" | "blueGray.600" | "error.600";
                  }),
        [color, onPress, size]
    );
    const iconNode = isReactElement(Icon) ? Icon : <Icon {...iconProps} />;
    const ref = useRef<TooltipRef>(null);
    const closeTooltipTimeout = useTimeout(() => {
        ref.current?.closeTooltip();
    }, 1_000);

    const handlePress = useCallback(
        (e: NativeSyntheticEvent<NativeTouchEvent>) => {
            if (mobile({tablet: true, featureDetect: true})) {
                ref.current?.openTooltip();
                closeTooltipTimeout();
            }
            onPress?.(e);
        },
        [closeTooltipTimeout, onPress]
    );

    const node = onPress ? (
        <IconButton
            testID={testID}
            size={size}
            aria-label={typeof tooltip === "string" ? tooltip : undefined}
            variant={variant}
            borderRadius={borderRadius}
            borderStyle={borderStyle}
            isDisabled={disabled}
            colorScheme={color}
            icon={isLoading ? <Spinner size="sm" /> : iconNode}
            onPress={handlePress}
        />
    ) : (
        iconNode
    );

    return (
        <Tooltip ref={ref} label={tooltip} placement={placement}>
            {node}
        </Tooltip>
    );
};

export default memo(ButtonIcon);
