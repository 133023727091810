import type {Account, AccountCustom} from "../models/account";
import type {Listing} from "../models/listing";
import type {Lock} from "../models/lock";

export function mapListings2Accounts<T = AccountCustom["type"]>(
    accounts: AccountCustom<T>[],
    allListings: Listing[]
) {
    return accounts.map<AccountCustom<T>>(account => {
        const {type} = account;
        if (type === "Seam" || type === "August") {
            return {
                ...account,
                locks: "locks" in account ? account.locks : [],
                listings: []
            };
        }
        const listings = allListings.reduce((result, listing) => {
            if (listing.channels[type]?.accountID !== account._id) {
                return result;
            }
            // Fill out some information for listing. Some function will no longer without these info
            // Make sure listingEnabled returns true or false because on
            // old accounts, listingEnabled can be null when the listing is enabled
            listing.listingEnabled = listing.listingEnabled !== false;
            listing.airbnbUserID = account.airbnbUserID;

            result.push(listing);

            return result;
        }, [] as Listing[]);

        return {
            ...account,
            locks: [],
            listings
        };
    });
}

export function buildLocks2Accounts<T extends AccountCustom["type"]>(
    accounts: AccountCustom<T>[],
    locks: Lock[]
) {
    return accounts.map(account => {
        const result = locks.reduce((result, lock) => {
            if (account._id === lock.accountID) {
                result.push(lock);
            }
            return result;
        }, [] as Lock[]);

        return {
            ...account,
            listings: "listings" in account ? account.listings : [],
            locks: result
        } as AccountCustom<T>;
    });
}

export function setListingsIntoAccounts(accounts: Account[], allListings: Listing[]) {
    return accounts.map<AccountCustom>(account => {
        const {type} = account;
        if (type === "Seam" || type === "August") {
            return {
                ...account,
                locks: [],
                listings: []
            };
        }
        const listings = allListings.reduce((result, listing) => {
            if (listing.channels[type]?.accountID !== account._id) {
                return result;
            }
            // Fill out some information for listing. Some function will no longer without these info
            // Make sure listingEnabled returns true or false because on
            // old accounts, listingEnabled can be null when the listing is enabled
            listing.listingEnabled = listing.listingEnabled !== false;
            listing.airbnbUserID = account.airbnbUserID;

            result.push(listing);

            return result;
        }, [] as Listing[]);

        return {
            ...account,
            locks: [],
            listings
        };
    });
}

export function getAccountID(account: Account): string | undefined {
    return (
        (account.type === "Airbnb" && account.airbnbUserID) ||
        (account.type === "Booking" && account?.booking?.propertyID) ||
        undefined
    );
}

export function buildChannelConnectorURL(accountID: string, path?: string) {
    const baseUrl = `/settings/channels/${accountID}`;
    if (path) {
        const encodedPath = encodeURIComponent(path);
        return `${baseUrl}?path=${encodedPath}`;
    }
    return baseUrl;
}
