import {isProPlan} from "@hosttools/core/shared/utils/utils.listing";
import {useMemo} from "react";

import type {Listing} from "../../../models/listing";
import {useUserContext} from "../useUserContext";

export function useProFeatures(listing: Listing) {
    const {locks, listingGroups} = useUserContext();
    const {isPro, ...rest} = useMemo(
        () =>
            isProPlan(
                listing,
                locks,
                listingGroups.filter(elem => elem.accessCode)
            ),
        [listing, locks, listingGroups]
    );

    const features = useMemo(
        () =>
            Object.keys(rest)
                .filter(elem => rest[elem])
                .reduce((acc, key) => {
                    if (key === "isTurnover") {
                        acc.push("Turnover Calendar");
                    }
                    if (key === "isPricing") {
                        acc.push("Calendar Syncing");
                    }
                    if (key === "hasLock") {
                        acc.push("Smart Locks");
                    }
                    return acc;
                }, [] as string[]),
        [rest]
    );

    return {
        isPro,
        label: isPro ? "PRO" : "Messaging",
        features: isPro ? `Pro features: ${features.join(", ")}` : undefined
    } as StrictUnion<
        | {
              isPro: true;
              label: string;
              features: string;
          }
        | {
              isPro: false;
              label: string;
          }
    >;
}
