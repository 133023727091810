import {useState, useEffect, useRef} from "react";

export function useDebounce<T, F extends (...args: [T, ...any[]]) => void>(
    value: T,
    delay: number,
    callback?: F
) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    const callbackRef = useRef<F>();
    callbackRef.current = callback;

    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedValue(value);
            callbackRef.current?.(value);
        }, delay);

        return () => {
            clearTimeout(timer);
        };
    }, [value, delay]);

    return debouncedValue;
}
