import {HStack, VStack, Divider, ChevronLeftIcon, Button, Box, Center} from "native-base";
import type {ReactNode} from "react";
import React from "react";

import Heading from "@/client/components/Heading";
import useMobileView from "@/client/hooks/useMobileView";

interface Props {
    title?: string;
    children: [ReactNode, ReactNode];
    onHide?: () => void;
}

// consider using this component inside the `Drawer` only
const DrawerLayout: React.FC<Props> = ({title, children, onHide}) => {
    const isMobile = useMobileView();

    let body = children[1];
    // support filter out falsy element to prevent having unnecessary divider
    if (body && React.isValidElement(body)) {
        const bodyChildren = body.props.children;
        if (Array.isArray(bodyChildren)) {
            const validChildren = bodyChildren.filter(elem => {
                if (
                    React.isValidElement(elem) &&
                    typeof elem.props === "object" &&
                    elem.props &&
                    "children" in elem.props
                ) {
                    const childChildren = elem.props?.children;
                    if (!childChildren) {
                        return false;
                    }
                    if (Array.isArray(childChildren) && childChildren.filter(Boolean).length < 1) {
                        return false;
                    }
                }
                return true;
            });

            if (validChildren.length !== bodyChildren.length) {
                body = React.cloneElement(body, {
                    ...body.props,
                    children: validChildren
                });
            }
        }
    }

    return (
        <VStack flex={1} testID="drawer-layout">
            <Box
                position="relative"
                p={5}
                justifyContent="space-between"
                borderBottomWidth={[4, 4, 0]}
                borderColor="blueGray.100"
            >
                {title && (
                    <Center
                        position="absolute"
                        p={5}
                        left={0}
                        top={0}
                        px={20}
                        width="full"
                        height="full"
                    >
                        <Heading variant="lg">{title}</Heading>
                    </Center>
                )}
                <HStack alignItems="center" justifyContent="space-between" space={1} flex={1}>
                    {isMobile && (
                        <Button
                            testID="reservation-detail-back"
                            variant="ghost"
                            minWidth="auto"
                            colorScheme="blueGray"
                            startIcon={<ChevronLeftIcon />}
                            onPress={onHide}
                        >
                            Back
                        </Button>
                    )}
                    {children[0]}
                </HStack>
            </Box>
            <VStack
                flex={1}
                pt={4}
                pb={6}
                overflowY="auto"
                space={5}
                // px is likely to conflict with divider
                divider={<Divider height={["4px", "4px", "1px"]} bg="blueGray.100" />}
            >
                {body}
            </VStack>
        </VStack>
    );
};

export default DrawerLayout;
