import React, {memo} from "react";

import Paragraph from "../Paragraph";

const SeamCredential: React.FC = () => {
    return (
        <Paragraph variant="xs">
            Each smart lock linked to an enabled listing in Host Tools, will incur a $3/month
            fee.&nbsp;
            <Paragraph variant="xs" color="blue.600">
                <a
                    href="https://help.hosttools.com/en/articles/8160685-how-much-do-smart-locks-cost"
                    target="_blank"
                    rel="noreferrer"
                >
                    Read more.
                </a>
            </Paragraph>
        </Paragraph>
    );
};

export default memo(SeamCredential);
