import {ExternalIcon} from "@hosttools/frontend";
import {type AccountType} from "@hosttools/frontend/models/account";
import {type Listing} from "@hosttools/frontend/models/listing";
import {AddIcon, Button, CheckIcon, HStack} from "native-base";
import React, {memo, useCallback, type FC} from "react";

import ConnectChannelItemLabel from "../ConnectChannelItemLabel";

interface Props {
    listing: Listing;
    accountType: AccountType;
    isConnected: boolean;
    isDisabled?: boolean;
    isLoading?: boolean;
    position?: "first" | "last" | "middle";
    onToggleChannel: (listing: Listing, accountType: AccountType, isConnected: boolean) => void;
}

const ChannelItem: FC<Props> = ({
    listing,
    accountType,
    isConnected,
    isDisabled,
    isLoading,
    position,
    onToggleChannel
}) => {
    const handlePress = useCallback(async () => {
        onToggleChannel(listing, accountType, isConnected);
    }, [listing, accountType, isConnected, onToggleChannel]);

    return (
        <HStack
            justifyContent="space-between"
            px={4}
            py={accountType === "ChannelConnector" ? 3 : 4}
            borderWidth={1}
            borderTopWidth={!position || position === "first" ? 1 : 0}
            borderColor="blueGray.200"
            borderTopRadius={!position || position === "first" ? 4 : 0}
            borderBottomRadius={!position || position === "last" ? 4 : 0}
            backgroundColor={position ? "white" : "blueGray.50"}
        >
            <ConnectChannelItemLabel size="md" accountType={accountType} />
            <HStack alignItems="center">
                {accountType === "ChannelConnector" ? (
                    <Button
                        variant="outline"
                        colorScheme="blueGray"
                        isLoading={isLoading}
                        isDisabled={isDisabled}
                        leftIcon={isConnected ? <ExternalIcon /> : <AddIcon />}
                        onPress={handlePress}
                    >
                        {isConnected ? "Open" : "Connect account"}
                    </Button>
                ) : (
                    <Button
                        variant="outline"
                        colorScheme="blueGray"
                        isLoading={isLoading}
                        isDisabled={isDisabled}
                        leftIcon={isConnected ? <CheckIcon /> : <AddIcon />}
                        onPress={handlePress}
                    >
                        {isConnected ? "Connected" : "Connect account"}
                    </Button>
                )}
            </HStack>
        </HStack>
    );
};

export default memo(ChannelItem);
