import useChannel from "@hosttools/frontend/react/hooks/useChannel";
import React, {memo, useCallback} from "react";

import FormField from "../FormField";
import Input from "../Input";

import type {CredentialsType, Errors} from "@/admin/components/ModalAddAccount/types";

export type Props = {
    credentials: CredentialsType;
    errors?: Errors;
    onChangeCredentials: (field: "username" | "password", value: string) => void;
};

const AugustCredential: React.FC<Props> = ({credentials, errors, onChangeCredentials}) => {
    const {label} = useChannel("August");

    const handleChangeUsername = useCallback(
        (text: string) => {
            onChangeCredentials("username", text);
        },
        [onChangeCredentials]
    );

    const handleChangePassword = useCallback(
        (text: string) => {
            onChangeCredentials("password", text);
        },
        [onChangeCredentials]
    );

    return (
        <>
            <FormField
                error={errors?.username}
                label={`${label} Email`}
                tooltip={`Enter the email address you use to login to your ${label} account.`}
            >
                <Input
                    id="username"
                    placeholder={`${label} Email...`}
                    name="username"
                    type="text"
                    autoComplete="off"
                    value={credentials.username}
                    onChangeText={handleChangeUsername}
                    isRequired
                />
            </FormField>
            <FormField
                error={errors?.password}
                tooltip={`Enter your ${label} password.`}
                label={`${label} Password`}
            >
                <Input
                    id="password"
                    placeholder={`${label} Password...`}
                    name="password"
                    type="password"
                    autoComplete="new-password"
                    value={credentials.password}
                    onChangeText={handleChangePassword}
                    isRequired
                />
            </FormField>
        </>
    );
};

export default memo(AugustCredential);
