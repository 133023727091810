import {isExternalReservation} from "@hosttools/core/shared/utils/utils.reservation";
import {useCallback, useContext, useEffect, useRef, useState} from "react";

import type {ReservationRaw} from "../../../models/reservation";
import {Reservation} from "../../../models/reservation";
import {WebSocketContext} from "../../contexts";
import useDebounceFn from "../useDebounceFn";

import {mapListingReservation} from "./share";
import type {ReservationListing} from "./types";

// https://github.com/facebook/react-native/issues/31551
// https://github.com/tc39/proposal-numeric-separator
// this proposal doesn't seem to add as part of metro
const defaultTimeout = 3000;

function useWatchReservationListings<L extends {_id: string}>(
    reservations: Reservation[] | undefined,
    listings: L[],
    onSetReservations?: (reservations: Reservation[]) => void
) {
    const websocket = useContext(WebSocketContext);
    const [reservationListings, setReservationListings] = useState<ReservationListing<L>[]>();
    const updatedReservationMapRef = useRef<Map<string, ReservationRaw>>(new Map());

    useEffect(() => {
        if (reservations && listings) {
            // filter based on the old logic
            const nextReservations = reservations.filter((newReservation, index, self) => {
                // Houfy is one thread per reservation and doesn't have a threadID so ignore it.
                if (isExternalReservation(newReservation) && !newReservation.airbnbThreadID) {
                    return true;
                }
                // show internal or iCal reservation and have custom.email
                if (!isExternalReservation(newReservation)) {
                    return newReservation.email;
                }
                const newIndex = self.findIndex(elem => {
                    return (
                        elem.airbnbThreadID === newReservation.airbnbThreadID &&
                        elem.accountID === newReservation.accountID
                    );
                });
                const isDuplicate = index !== newIndex;
                return !isDuplicate;
            });

            const result = mapListingReservation(nextReservations, listings);
            setReservationListings(result);
        }
    }, [reservations, listings]);

    const startUpdating = useCallback(() => {
        setReservationListings(prev => {
            if (prev) {
                // merge the updated reservations
                const updatedMap = updatedReservationMapRef.current;
                const next = prev.map(elem => {
                    const {listing, reservation} = elem;
                    if (updatedMap.has(reservation._id)) {
                        const nextReservation = new Reservation({
                            ...reservation.raw,
                            ...updatedMap.get(reservation._id)
                        });
                        return {listing, reservation: nextReservation};
                    }
                    return elem;
                });
                updatedReservationMapRef.current.clear();
                return next;
            }
            return prev;
        });
    }, []);

    const updateDebounceHandler = useDebounceFn(startUpdating, defaultTimeout);

    const registerUpdate = useCallback(
        (doc: ReservationRaw, immediate = false) => {
            const updatedMap = updatedReservationMapRef.current;
            updatedMap.set(doc._id, doc);
            if (immediate) {
                updateDebounceHandler.cancel();
                startUpdating();
                return;
            }
            // console.log("detect changes and will update in 5s", doc._id, doc.unread);
            updateDebounceHandler();
        },
        [updateDebounceHandler, startUpdating]
    );

    useEffect(() => {
        function handleReservationsChange(payload: WSPayload<ReservationRaw>) {
            // we should also cover the case of deletion
            if (payload.event === "update") {
                registerUpdate(payload.document);
            }
            if (payload.event === "insert") {
                if (onSetReservations) {
                    const res = new Reservation(payload.document);
                    onSetReservations([res]);
                }
            }
        }

        websocket?.on<WSRoom>("reservation", handleReservationsChange);
        return () => {
            websocket?.off("reservation", handleReservationsChange);
        };
    }, [websocket, registerUpdate, onSetReservations]);

    return [reservationListings, registerUpdate, setReservationListings] as const;
}

export default useWatchReservationListings;
