import {useEventListener} from "@hosttools/frontend";
import {Backdrop, Box, CloseIcon, type IBoxProps, Overlay, Slide} from "native-base";
import React, {memo, type ReactNode} from "react";

import ButtonIcon from "../ButtonIcon";

import useOverlayStyle from "@/client/hooks/useOverlayStyle";

export type Props = {
    isOpen: boolean;
    size?: number;
    fullSize?: boolean;
    children?: ReactNode;
    placement?: "top" | "left" | "right" | "bottom";
    onClose?: () => void;
};

const Drawer: React.FC<Props> = ({
    children,
    isOpen,
    onClose,
    placement = "right",
    size = 500,
    fullSize
}) => {
    const placementStyles = React.useMemo(() => {
        const styles: IBoxProps = {
            position: "absolute"
        };

        if (placement === "top") {
            styles.top = 0;
            styles.left = 0;
            styles.right = 0;
            styles.height = fullSize ? "100%" : `${size}px`;
            styles.overflowY = "auto";
            styles.overflowX = "hidden";
        } else if (placement === "bottom") {
            styles.bottom = 0;
            styles.left = 0;
            styles.right = 0;
            // this works great on mobile rather than 100vh which fits the container
            styles.height = "100%";
            styles.width = "100%";
            styles.overflowY = "hidden";
            styles.overflowX = "hidden";
        } else if (placement === "right") {
            styles.right = 0;
            styles.top = 0;
            styles.bottom = 0;
            styles.height = "100%";
            styles.width = fullSize ? "100%" : `${size}px`;
            styles.overflowY = "auto";
            styles.overflowX = "hidden";
        } else {
            styles.top = 0;
            styles.bottom = 0;
            styles.left = 0;
            styles.height = "100%";
            styles.width = fullSize ? "100%" : `${size}px`;
            styles.overflowY = "auto";
            styles.overflowX = "hidden";
        }
        return styles;
    }, [fullSize, placement, size]);

    useEventListener("keydown", e => {
        if (e.key === "Escape") {
            onClose?.();
        }
    });

    const overlay = useOverlayStyle("drawer");

    if (!isOpen) return null;

    // I noted this component re-renders the children multiple times
    // there is likely a bug with the library
    return (
        <Overlay isOpen={isOpen} style={overlay.style}>
            {!fullSize && <Backdrop onPress={onClose} />}
            <Slide testID="drawer" in={isOpen} placement={placement} _overlay={overlay}>
                <Box {...placementStyles} opacity={isOpen ? 1 : 0} bg="white">
                    {placement === "right" && (
                        <Box position="fixed" right={`${size}px`} top={0}>
                            <ButtonIcon
                                testID="btn-icon-close"
                                tooltip="Close"
                                icon={CloseIcon}
                                onPress={onClose}
                            />
                        </Box>
                    )}
                    <Box height="100%" overflowY="auto">
                        {children}
                    </Box>
                </Box>
            </Slide>
        </Overlay>
    );
};

export default memo(Drawer);
