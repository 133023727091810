import {useTheme} from "native-base";
import {useMemo} from "react";

import stringPathFromObject from "../../utils/stringPathFromObject";

const usePathColors = () => {
    const {colors} = useTheme();

    return useMemo(() => stringPathFromObject(colors), [colors]);
};

export default usePathColors;
