import {useDisclose} from "@hosttools/frontend";
import {useEffect, useRef, useCallback} from "react";

import waterDrop from "../sound/waterDrop.mp3";

const useNotifySound = () => {
    const audioRef = useRef(new Audio(waterDrop));
    const {isOpen: playing, onToggle: togglePlaying, onClose: stopPlaying} = useDisclose();
    const audio = audioRef.current;

    const handleAudioEnded = useCallback(() => {
        stopPlaying();
    }, [stopPlaying]);

    useEffect(() => {
        if (playing) {
            audio.play().catch(() => {
                stopPlaying();
            });
        } else {
            audio.pause();
        }
    }, [audio, playing, stopPlaying]);

    useEffect(() => {
        audio.addEventListener("ended", handleAudioEnded);
        return () => {
            audio.removeEventListener("ended", handleAudioEnded);
        };
    }, [audio, handleAudioEnded]);

    return [playing, togglePlaying] as const;
};

export default useNotifySound;
