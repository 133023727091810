import type {AccountType} from "@hosttools/frontend/models/account";
import {VStack, Button, HStack, ArrowBackIcon, AddIcon} from "native-base";
import React, {memo, useCallback} from "react";
import {useHistory} from "react-router-dom";

import ChannelItem from "./ChannelItem";

import Heading from "@/client/components/Heading";
import Paragraph from "@/client/components/Paragraph";

const importingChannels = ["Airbnb", "HomeAway", "Booking", "Houfy"] as const;

const SelectChannels: React.FC = () => {
    const history = useHistory();

    const addManually = useCallback(() => {
        history.push("/listing/new");
    }, [history]);

    const goBack = useCallback(() => {
        history.goBack();
    }, [history]);

    const handlePressChannel = useCallback(
        (accountType: AccountType) => {
            history.push(`/add-listing/import/${accountType}`);
        },
        [history]
    );

    return (
        <VStack py={6} space={6}>
            <VStack>
                <Heading variant="2xl">Connect channel</Heading>
                <Paragraph variant="lg">
                    Import your listings from one of these channels or manually create a listing on
                    Host Tools
                </Paragraph>
            </VStack>
            <VStack>
                {importingChannels.map((elem, idx) => {
                    return (
                        <ChannelItem
                            key={elem}
                            accountType={elem}
                            isDisabled={false}
                            position={
                                idx === 0
                                    ? "first"
                                    : idx === importingChannels.length - 1
                                    ? "last"
                                    : "middle"
                            }
                            onPress={handlePressChannel}
                        />
                    );
                })}
            </VStack>
            <Button variant="outline" flex={1} leftIcon={<AddIcon />} onPress={addManually}>
                Add manually
            </Button>
            <HStack alignSelf="flex-end">
                <Button variant="outline" leftIcon={<ArrowBackIcon />} onPress={goBack}>
                    Back
                </Button>
            </HStack>
        </VStack>
    );
};

export default memo(SelectChannels);
