import {VStack, View} from "native-base";
import React, {memo} from "react";

import Paragraph from "../Paragraph";
import Text from "../Text";

const AirbnbCredential: React.FC = () => {
    return (
        <>
            <View mb={4}>
                <Paragraph variant="xs">
                    After connecting your Airbnb account to Host Tools, some of the Airbnb account
                    settings will change.&nbsp;
                    <Paragraph variant="xs" color="blue.600">
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://help.hosttools.com/en/articles/6289414-what-will-change-on-my-airbnb-account-when-i-connect-it-to-host-tools"
                        >
                            Read more.
                        </a>
                    </Paragraph>
                </Paragraph>
            </View>
            <VStack ml={2} space={4}>
                <Text variant="sm">• Fee settings may change to host-only.</Text>
                <Text variant="sm">• Instant book will be enabled on all listings.</Text>
                <Text variant="sm">• All imported iCals will be removed from Airbnb.</Text>
            </VStack>
        </>
    );
};

export default memo(AirbnbCredential);
