import type {AccountType} from "@hosttools/core/constant";
import {integrations, originalChannels} from "@hosttools/core/constant";
import {type Listing} from "@hosttools/frontend/models/listing";
import {type AccountListing} from "@hosttools/frontend/services/listing";
import {HStack, AddIcon, Button} from "native-base";
import React, {memo, useCallback, useState} from "react";

import ButtonIcon from "@/client/components/ButtonIcon";
import ListingChannelsLogo from "@/client/components/ListingChannelsLogo";
import useConfirmCloseChannelConnector from "@/client/hooks/useConfirmCloseChannelConnector";
import useConfirmRemoveChannel from "@/client/hooks/useConfirmRemoveChannel";

interface ConnectChannelsProps {
    type: "connectChannels";
    listing: Listing;
    onPress: (listing: Listing) => void;
}

interface MatchListingProps {
    type: "matchListing";
    listing: AccountListing;
    accountID: string;
    onConnect: (accountID: string, externalListingID: string) => Promise<void>;
}

type Props = StrictUnion<ConnectChannelsProps | MatchListingProps>;

const TableActionsColumn: React.FC<Props> = ({listing, type, accountID, onPress, onConnect}) => {
    const [spinner, setSpinner] = useState(false);
    const handleRemoveChannel = useConfirmRemoveChannel(listing);
    const handleCloseChannelConnector = useConfirmCloseChannelConnector(listing);

    const handleConnect = useCallback(async () => {
        try {
            setSpinner(true);
            await onConnect?.(accountID, listing._id);
        } finally {
            setSpinner(false);
        }
    }, [accountID, listing, onConnect]);

    const handlePress = useCallback(() => {
        onPress?.(listing);
    }, [listing, onPress]);

    const handleRemove = useCallback(
        (channel: AccountType | (string & {})) => {
            if (originalChannels.includes(channel) || integrations.includes(channel)) {
                handleRemoveChannel(channel);
                return;
            }

            handleCloseChannelConnector(channel);
        },
        [handleCloseChannelConnector, handleRemoveChannel]
    );

    if (type === "matchListing") {
        return (
            <Button
                leftIcon={<AddIcon />}
                variant="outline"
                isLoading={spinner}
                isLoadingText="Connecting..."
                isDisabled={listing.isConnected}
                onPress={handleConnect}
            >
                Connect
            </Button>
        );
    }
    return (
        <HStack space={2} alignItems="center">
            <ListingChannelsLogo listing={listing} size="lg" onRemove={handleRemove} />
            <ButtonIcon
                variant="outline"
                tooltip="Connect channels"
                borderRadius="full"
                size="xs"
                icon={AddIcon}
                onPress={handlePress}
            />
        </HStack>
    );
};

export default memo(TableActionsColumn);
