import type {Channel} from "@hosttools/core/shared/model/channel";
import {useEffect, useState} from "react";
import type * as io from "socket.io-client";

export interface Message {
    title: string;
    subTitle: string;
    message: string;
    logoKey: "Avatar" | keyof typeof Channel;
}

export interface NotificationWS<T> extends WSPayloadUpdateOrInsert<T> {
    type:
        | "reservationChange"
        | "newInquiry"
        | "newPending"
        | "newReservation"
        | "newCancelled"
        | "newPost";
    message: Message;
}

export const useWatchNotification = <T>(ws?: io.Socket) => {
    const [notification, setNotification] = useState<NotificationWS<T> | null>(null);

    useEffect(() => {
        function generateNewNotification(payload: NotificationWS<T>) {
            setNotification(payload);
        }

        ws?.on<WSRoom>("notification", generateNewNotification);
        return () => {
            ws?.off("notification", generateNewNotification);
        };
    }, [ws]);

    return notification;
};
