import {useAccount, useListingsByAccountID} from "@hosttools/frontend";
import type {AccountType} from "@hosttools/frontend/models/account";
import {type Listing} from "@hosttools/frontend/models/listing";
import type {AccountListing} from "@hosttools/frontend/services/listing";
import {type Row, createColumnHelper} from "@tanstack/react-table";
import {Box, VStack} from "native-base";
import React, {type FC, type ReactNode, memo, useMemo, useRef, useCallback, useState} from "react";

import useFetchListingsFromAccount from "../useFetchListingsFromAccount";

import Header from "./Header";
import TableActionColumn from "./TableActionColumn";
import TableThumbnailColumn from "./TableThumbnailColumn";

import Loader from "@/admin/components/Loader";
import Alert from "@/client/components/Alert";
import ModalConnectChannels from "@/client/components/ModalConnectChannels";
import TableFetch, {type RefValue as TableRefValue} from "@/client/components/TableFetch";
import TableCellTextColumn from "@/client/components/TableFetch/TableCellTextColumn";
import TableCheckBoxColumn from "@/client/components/TableFetch/TableCheckBoxColumn";
import TableCheckBoxHeader from "@/client/components/TableFetch/TableCheckBoxHeader";

interface BaseProps {
    accountID: string;
}

interface ImportListingsProps extends BaseProps {
    type: "importListings";
    onSelectListings: (ids: string[], accountID: string) => void;
}

interface ConnectChannelsProps extends BaseProps {
    type: "connectChannels";
    onToggleChannel?: (listing: Listing, accountType: AccountType, isConnected: boolean) => void;
}

interface MatchListingProps extends BaseProps {
    type: "matchListing";
    onConnect: (accountID: string, externalListingID: string) => Promise<void>;
}

type Props = StrictUnion<ImportListingsProps | ConnectChannelsProps | MatchListingProps>;

const TableAccountListings: FC<Props> = ({
    accountID,
    type,
    onSelectListings,
    onToggleChannel,
    onConnect
}) => {
    const tableRef = useRef<TableRefValue<Listing | AccountListing>>(null);
    const columnHelper = useMemo(() => createColumnHelper<Listing | AccountListing>(), []);
    const [connectingListing, setConnectingListing] = useState<Listing>();

    const handleCloseModal = useCallback(() => {
        setConnectingListing(undefined);
    }, []);

    const columns = useMemo(
        () =>
            [
                type === "importListings"
                    ? columnHelper.accessor(row => row._id, {
                          id: "select",
                          enableSorting: false,
                          header: TableCheckBoxHeader,
                          cell: TableCheckBoxColumn
                      })
                    : undefined,
                columnHelper.accessor(row => row.airbnbThumbnailUrl, {
                    id: "name",
                    header: "Name",
                    cell: TableThumbnailColumn
                }),
                columnHelper.accessor(row => row.airbnbAddress ?? "-", {
                    id: "address",
                    header: "Address",
                    cell: TableCellTextColumn
                }),
                type === "connectChannels"
                    ? columnHelper.display({
                          id: "action",
                          header: "Connected channels",
                          cell: ({row: {original}}) =>
                              (
                                  <TableActionColumn
                                      type={type}
                                      listing={original}
                                      onPress={setConnectingListing}
                                  />
                              ) as ReactNode
                      })
                    : type === "matchListing"
                    ? columnHelper.display({
                          id: "action",
                          header: "",
                          cell: ({row: {original}}) =>
                              (
                                  <TableActionColumn
                                      type={type}
                                      accountID={accountID}
                                      listing={original as AccountListing}
                                      onConnect={onConnect}
                                  />
                              ) as ReactNode
                      })
                    : undefined
            ].filter(Boolean),
        [type, accountID, columnHelper, onConnect]
    );

    // no fetch in case of importing
    const {data: listingsFromAccount, isError} = useFetchListingsFromAccount(
        type === "importListings" || type === "matchListing" ? accountID : undefined
    );
    const account = useAccount(accountID);
    const connectedListings = useListingsByAccountID(accountID);

    const handleStateChange = useCallback(() => {
        const table = tableRef.current?.table;
        if (table && type === "importListings") {
            const {rowSelection} = table.getState();
            const ids = Object.keys(rowSelection);
            onSelectListings(ids, accountID);
        }
    }, [type, accountID, onSelectListings]);

    const data =
        type === "importListings" || type === "matchListing"
            ? listingsFromAccount
            : connectedListings;

    const isRowSelectionEnabled = useCallback(({original}: Row<Listing | AccountListing>) => {
        return "isConnected" in original ? !original.isConnected : true;
    }, []);

    if (!account) {
        return null;
    }

    return (
        <Box>
            {/*
                DON'T put any modal under a VStack that has `space` property which ends up creating more spacer
                as soon as the modal shows
            */}
            <VStack
                space={4}
                minHeight={isError ? undefined : 160}
                p={4}
                borderRadius={12}
                background="blueGray.50"
                position="relative"
            >
                <Header account={account} />
                {!data ? (
                    isError ? (
                        <Alert color="danger">Failed to load listings for this account</Alert>
                    ) : (
                        <Loader center>Fetching listings...</Loader>
                    )
                ) : (
                    <TableFetch
                        ref={tableRef}
                        testID="import-listings"
                        columns={columns}
                        rowId="_id"
                        mode="offline"
                        gridTemplateColumns={
                            type === "importListings"
                                ? "minmax(64px, auto) minmax(320px, 1fr) minmax(200px, auto)"
                                : "minmax(300px, 1fr) minmax(200px, auto) minmax(min-content, auto)"
                        }
                        data={data}
                        enableRowSelection={isRowSelectionEnabled}
                        enableGlobalFilter={false}
                        onStateChange={handleStateChange}
                    />
                )}
            </VStack>
            {type === "connectChannels" && connectingListing && (
                <ModalConnectChannels
                    listing={connectingListing}
                    onClose={handleCloseModal}
                    onToggleChannel={onToggleChannel}
                />
            )}
        </Box>
    );
};

export default memo(TableAccountListings);
