import React, {useState, memo} from "react";
import {Carousel} from "react-bootstrap";
import {FiExternalLink} from "react-icons/fi";

import AppImg from "../../img/onboarding/app.png";
import FaqImg from "../../img/onboarding/faq.png";
import ListingSettingImg from "../../img/onboarding/listing-setting.png";
import PriceSettingImg from "../../img/onboarding/price-setting.png";

type Props = {
    onHide: () => void;
    onFinish: () => void;
};

const items = [
    {
        imgUrl: ListingSettingImg,
        title: "Link all your listings and sync availability",
        description: (
            <>
                <p>
                    If you list a property on several channels, you will need to link those listings
                    on Host Tools.
                </p>
                <p>
                    You can also add iCal links for any channels that Host Tools doesn’t integrate
                    with, such as a personal website.
                </p>
                <p>
                    Once your listings are linked, turn on availability syncing so that Host Tools
                    will automatically block dates on all your channels when you get a new booking.
                </p>
            </>
        )
    },
    {
        imgUrl: PriceSettingImg,
        title: "Automate Messages, prices, smart locks and more!",
        description: (
            <>
                <p>
                    Set up message rules to send automated messages to your guests or automated
                    email or SMS text messages to support staff.
                </p>
                <p>
                    Set up pricing rules to set your price, minimum night requirement, or
                    availability.
                </p>
                <p>
                    Connect your smart locks so Host Tools can set your codes and send them to
                    guests.
                </p>
            </>
        )
    },
    {
        imgUrl: AppImg,
        title: "Download the app!",
        description: (
            <p>
                With the Host Tools mobile app for iOS and Android, you can manage day-to-day tasks
                on the go. You can see your calendars, add a manual reservation, edit details of an
                existing reservation, message with guests, and view, send, edit, or cancel a
                scheduled message.
            </p>
        )
    },
    {
        imgUrl: FaqImg,
        title: "Check out more features and contact us with any questions",
        description: (
            <p>
                Host Tools has a library with answers to over 75 frequently asked questions about
                all of our great features. If you can&apos;t find what you need there, just reach
                out to support on this site and we are happy to help!
            </p>
        )
    }
];

const Introduction: React.FC<Props> = ({onHide, onFinish}) => {
    const [index, setIndex] = useState(0);

    return (
        <div className="introduction-onboarding position-relative">
            <button type="button" className="close" onClick={onHide}>
                <span aria-hidden="true">×</span>
                <span className="sr-only">Close</span>
            </button>
            <Carousel
                className="custom-carousel"
                activeIndex={index}
                controls={false}
                indicators
                interval={null}
                onSelect={setIndex}
            >
                {items.map(({imgUrl, title, description}, index: number) => (
                    <Carousel.Item key={title}>
                        <img src={imgUrl} alt="profile" width="100%" height="390px" />
                        <div className="d-flex flex-column ht-400 pd-60">
                            <div className="title mg-b-20">{title}</div>
                            <div className="description flex-fill">{description}</div>
                            {index !== items.length - 1 && (
                                <button
                                    type="button"
                                    className="btn btn-primary wd-100p"
                                    onClick={() => setIndex(index + 1)}
                                >
                                    Continue
                                </button>
                            )}
                            {index === items.length - 1 && (
                                <div className="row">
                                    <div className="col-md-6">
                                        <a
                                            href="https://help.hosttools.com/"
                                            className="btn btn-outline-dark d-flex justify-content-center wd-100p"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <FiExternalLink className="mg-r-4" /> View Help Articles
                                        </a>
                                    </div>
                                    <div className="col-md-6">
                                        <button
                                            type="button"
                                            onClick={onFinish}
                                            className="btn btn-primary d-flex justify-content-center wd-100p"
                                        >
                                            Finish
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Carousel.Item>
                ))}
            </Carousel>
        </div>
    );
};

export default memo(Introduction);
