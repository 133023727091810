import {ArrowForwardIcon, Center, HStack, Pressable, Progress, VStack, View} from "native-base";
import React, {memo, useCallback} from "react";
import {useHistory} from "react-router-dom";

import useQuickGuide from "./useQuickGuide";

import Paragraph from "@/client/components/Paragraph";
import Text from "@/client/components/Text";

const QuickGuideButton: React.FC = () => {
    const history = useHistory();

    const {numberCompleted, totalSteps} = useQuickGuide();

    const handleClick = useCallback(() => {
        history.push("/quick-guide");
    }, [history]);
    // Don't show if loading or all steps are completed
    if (typeof numberCompleted === "undefined" || numberCompleted === totalSteps) {
        return null;
    }
    return (
        <Pressable
            display="flex"
            flexDir="row"
            alignItems="center"
            px={2}
            py={1.5}
            borderRadius={6}
            bgColor="success.600"
            onPress={handleClick}
        >
            <VStack space={2} mr={3} flex={1}>
                <View
                    display="flex"
                    flexDir="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Paragraph variant="xs" color="white">
                        Quick Start Guide
                    </Paragraph>
                    <HStack alignItems="baseline">
                        <Text variant="xs" color="white">
                            {numberCompleted}
                        </Text>
                        <Text variant="2xs" color="white">
                            /{totalSteps}
                        </Text>
                    </HStack>
                </View>
                <View w={32}>
                    <Progress
                        h={1}
                        value={(numberCompleted / totalSteps) * 100}
                        bg="rgba(255, 255, 255, 0.2)"
                        _filledTrack={{bg: "white"}}
                    />
                </View>
            </VStack>

            <Center
                p={1}
                flexShrink={0}
                flexGrow={0}
                rounded="full"
                background="rgba(240, 253, 244, 0.20)"
            >
                <ArrowForwardIcon size="sm" color="white" />
            </Center>
        </Pressable>
    );
};

export default memo(QuickGuideButton);
