import type {AccountType} from "@hosttools/core/constant";
import {useMemo} from "react";

import type {ChannelMapType} from "../../../models/channel";
import {buildChannel} from "../../../models/channel";

function useChannel<T extends AccountType | "internal">(channel: T): ChannelMapType[T];

function useChannel(
    channel: string,
    isChannelConnector?: boolean
): {
    label: string;
    value: string;
    colorScheme: "blueGray";
};

function useChannel(channel: AccountType | "internal" | (string & {})) {
    return useMemo(() => buildChannel(channel), [channel]);
}

export default useChannel;
