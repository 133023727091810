import "./table.scss";

import {View} from "native-base";
import React, {memo, useMemo} from "react";

import {TableContext} from "./TableContext";

type Props = {
    overflow?: "auto" | "visible";
    children: React.ReactNode;
    size?: "sm" | "md";
    testID?: string;
    backgroundColor?: "blueGray.50";
    gridTemplateColumns?: React.CSSProperties["gridTemplateColumns"];
};

const Table: React.FC<Props> = ({
    size = "md",
    gridTemplateColumns,
    children,
    overflow = "auto",
    ...rest
}) => {
    const tableValue = useMemo(() => ({size, gridTemplateColumns}), [size, gridTemplateColumns]);
    const style = useMemo(
        () => ({
            gridTemplateColumns
        }),
        [gridTemplateColumns]
    );

    return (
        <TableContext.Provider value={tableValue}>
            <View bgColor="white" maxW="100%" overflow={overflow} {...rest}>
                <View
                    display={gridTemplateColumns ? "grid" : "table"}
                    style={style as any}
                    role="table"
                    width="full"
                >
                    {children}
                </View>
            </View>
        </TableContext.Provider>
    );
};

export default memo(Table);
