import type {Theme} from "native-base";
import {theme} from "native-base";

type IconButton = Theme["components"]["IconButton"];

function variantGhost({colorScheme, disabled}: Record<string, any>) {
    return {
        borderWidth: "1px",
        borderColor: "transparent",
        bg: disabled ? `${colorScheme}.200` : "transparent",
        _icon: {
            color: disabled ? `${colorScheme}.400` : `${colorScheme}.600`
        },
        _hover: {
            bg: `${colorScheme}.100`,
            borderColor: `${colorScheme}.200`
        },
        _pressed: {
            bg: `${colorScheme}.100`,
            borderColor: `${colorScheme}.300`
        },
        _focus: {
            bg: `${colorScheme}.100`,
            borderColor: `${colorScheme}.300`
        },
        _disabled: {
            bg: `${colorScheme}.200`,
            _icon: {
                color: `${colorScheme}.400`
            }
        },
        _dark: {
            _icon: {
                color: `${colorScheme}.500`
            },
            _hover: {
                bg: `${colorScheme}.500:alpha.10`
            },
            _pressed: {
                bg: `${colorScheme}.500:alpha.20`
            }
        }
    };
}

function variantOutline({colorScheme}: Record<string, any>) {
    return {
        borderWidth: "1px",
        borderColor: colorScheme === "blueGray" ? "blueGray.400" : `${colorScheme}.600`,
        _icon: {
            color: colorScheme === "blueGray" ? "blueGray.400" : `${colorScheme}.600`
        },
        _hover: {
            bg: `${colorScheme}.700`,
            _icon: {
                color: "muted.50"
            }
        },
        _pressed: {
            bg: `${colorScheme}.800`,
            _icon: {
                color: "muted.50"
            }
        },
        _focus: {
            bg: `${colorScheme}.600`,
            _icon: {
                color: "muted.50"
            }
        },
        _dark: {
            borderColor: `${colorScheme}.500`,
            _icon: {
                color: `${colorScheme}.500`
            },
            _hover: {
                bg: `${colorScheme}.400`,
                _icon: {
                    color: "muted.900"
                }
            },
            _pressed: {
                bg: `${colorScheme}.300`,
                _icon: {
                    color: "muted.900"
                }
            },
            _focus: {
                bg: `${colorScheme}.500`,
                _icon: {
                    color: "muted.900"
                }
            }
        }
    };
}

function variantSubtle({colorScheme}: Record<string, any>) {
    return {
        _text: {
            color: `${colorScheme}.900`
        },
        _icon: {
            color: colorScheme === "blueGray" ? "blueGray.400" : `${colorScheme}.900`
        },
        bg: `${colorScheme}.100`,
        _hover: {
            bg: `${colorScheme}.200`
        },
        _pressed: {
            bg: `${colorScheme}.300`
        },
        _dark: {
            bg: `${colorScheme}.300`,
            _hover: {
                bg: `${colorScheme}.200`
            },
            _pressed: {
                bg: `${colorScheme}.100`
            }
        },
        borderWidth: "1px",
        borderColor: `${colorScheme}.200`,
        borderStyle: "solid"
    };
}

function variantSolid({colorScheme}: Record<string, any>) {
    return {
        bg: colorScheme === "blueGray" ? "blueGray.200" : `${colorScheme}.600`,
        _hover: {
            bg: colorScheme === "blueGray" ? "blueGray.300" : `${colorScheme}.700`
        },
        _pressed: {
            bg: colorScheme === "blueGray" ? "blueGray.300" : `${colorScheme}.800`
        },
        _icon: {
            color: colorScheme === "blueGray" ? "blueGray.400" : "muted.50"
        },
        _dark: {
            bg: `${colorScheme}.500`,
            _hover: {
                bg: `${colorScheme}.400`
            },
            _pressed: {
                bg: `${colorScheme}.300`,
                _icon: {
                    color: "muted.900"
                }
            },
            _icon: {
                color: "muted.900"
            }
        }
    };
}

export const IconButtonTheme: Partial<IconButton> = {
    baseStyle: (props: any) => {
        const baseStyleProps = theme.components.IconButton.baseStyle(props);

        return {
            ...baseStyleProps
        };
    },
    variants: {
        ...theme.components.IconButton.variants,
        solid: variantSolid,
        ghost: variantGhost,
        outline: variantOutline,
        subtle: variantSubtle
    },
    sizes: {
        lg: {
            p: "1",
            _icon: {
                size: "md"
            }
        },
        md: {
            p: "1",
            _icon: {
                size: "sm"
            }
        },
        sm: {
            p: "1",
            _icon: {
                size: "xs"
            }
        },
        xs: {
            p: "1",
            _icon: {
                size: "xs"
            }
        }
    },
    defaultProps: {
        colorScheme: "blueGray",
        variant: "ghost",
        size: "md"
    }
};
