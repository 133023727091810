function stringPathFromObject<T extends Record<string, any>>(
    obj: T,
    prefix = "",
    store: Record<string, any> = {}
) {
    Object.keys(obj).forEach(key => {
        const curPath = prefix ? `${prefix}.${key}` : key;

        if (typeof obj[key as keyof T] === "object") {
            stringPathFromObject(obj[key], curPath, store);
        } else {
            store[curPath] = obj[key as keyof T];
        }
    });
    return store;
}

export default stringPathFromObject;
