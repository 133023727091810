import {type Listing} from "@hosttools/frontend/models/listing";
import {type CellContext} from "@tanstack/react-table";
import React, {memo} from "react";

import ListingSlat from "@/client/components/ListingSlat";

const TableThumbnailColumn: React.FC<CellContext<Listing, string>> = props => {
    return <ListingSlat listing={props.row.original} size="sm" includeChannel={false} />;
};

export default memo(TableThumbnailColumn);
