import type {Permissions as PermissionsRaw, Role} from "@hosttools/core/shared/model/user";
import {getRoleByPermissions} from "@hosttools/core/shared/utils/utils.permission";
import type {SubUser as SubUserModel} from "@hosttools/web/server/routes/routes.users.types";

import {getFullName} from "./user";

export type SubUserRaw = MongooseModel2Client<SubUserModel>;

export class SubUser {
    _id: string;

    username: string;

    firstName: string;

    lastName: string;

    fullName: string;

    permissions: PermissionsRaw;

    listingGroupIDs: string[];

    role?: Role;

    constructor(raw: SubUserRaw) {
        this._id = raw._id;
        this.username = raw.username;
        this.firstName = raw.firstName;
        this.lastName = raw.lastName;
        this.permissions = raw.permissions as PermissionsRaw;
        this.listingGroupIDs = raw.listingGroupIDs;
        this.fullName = getFullName(raw);
        this.role = this.permissions ? getRoleByPermissions(this.permissions) : undefined;
    }
}
