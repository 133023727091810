export type AspectRatio = "1:1" | "16:9" | "4:3" | "3:2" | "5:4";

function calculateDimension(
    width?: number,
    height?: number,
    aspectRatio?: AspectRatio
): {width: number; height: number};
function calculateDimension(
    width?: number[] | number,
    height?: number[] | number,
    aspectRatio?: AspectRatio
): {width: number[] | number; height: number[] | number};
function calculateDimension(
    width?: number[] | number,
    height?: number[] | number,
    aspectRatio?: AspectRatio
) {
    if (width && height) {
        return {width, height};
    }
    const [wRatio, hRatio] = (aspectRatio ?? "5:4").split(":").map(Number);

    if (width) {
        height =
            typeof width === "number"
                ? (width / wRatio) * hRatio
                : width.map(w => (w / wRatio) * hRatio);
    } else if (height) {
        width =
            typeof height === "number"
                ? (height / hRatio) * wRatio
                : height.map(h => (h / hRatio) * wRatio);
    }

    return {width, height};
}

export default calculateDimension;
