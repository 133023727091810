import {formatDistanceToNow, parseISO} from "date-fns";
import React, {memo} from "react";

import {useInterval} from "../../hooks/useInterval";

interface Props {
    date: string;
    interval?: number;
    includeSeconds?: boolean;
}

const FormatTimeToNow: React.FC<Props> = ({date, interval = 10_000, includeSeconds = true}) => {
    const [format, setFormat] = React.useState<string>(
        formatDistanceToNow(parseISO(date), {
            includeSeconds,
            addSuffix: true
        })
    );

    useInterval(() => {
        setFormat(
            formatDistanceToNow(parseISO(date), {
                includeSeconds,
                addSuffix: true
            })
        );
    }, interval);

    return <>{format}</>;
};

export default memo(FormatTimeToNow);
