import "./notificationAlert.scss";

import {Box, HStack, Image, VStack, Pressable, Divider} from "native-base";
import React, {memo, useCallback} from "react";
import type {NativeSyntheticEvent, NativeTouchEvent} from "react-native";

import Text from "@/client/components/Text";

interface Props {
    toastId: string;
    title: string;
    subTitle: string;
    message: string;
    logoKey: string;
    logoUrl: string;
    onToastClick?: () => void;
    onCloseToast?: (toastId: string) => void;
}

const NotificationAlert: React.FC<Props> = ({
    toastId,
    title,
    subTitle,
    message,
    logoKey,
    logoUrl,
    onToastClick,
    onCloseToast
}) => {
    const handleToastClick = useCallback(() => {
        onToastClick?.();
        onCloseToast?.(toastId);
    }, [onToastClick, onCloseToast, toastId]);

    const handleCloseToast = useCallback(
        (e: NativeSyntheticEvent<NativeTouchEvent>) => {
            e.stopPropagation();
            onCloseToast?.(toastId);
        },
        [onCloseToast, toastId]
    );

    return (
        <Box px={4} testID="message-notification-alert">
            <Pressable onPress={handleToastClick}>
                <Box w="96" py={3} px={4} bg="white" borderRadius="lg" shadow="subtleShadow">
                    <HStack space={4} divider={<Divider bg="blueGray.200" height="unset" />}>
                        <HStack space={4} alignItems="center" flex={1}>
                            {!!logoUrl && (
                                <Image
                                    borderRadius="full"
                                    source={{uri: logoUrl}}
                                    alt={logoKey}
                                    size="40px"
                                />
                            )}
                            <VStack flex={1} space={1}>
                                <Text variant="sm" color="blueGray.800" isTruncated>
                                    {title}
                                </Text>
                                <Text variant="xs" color="blueGray.400" isTruncated>
                                    {subTitle}
                                </Text>
                                <Text variant="sm" noOfLines={2}>
                                    {message}
                                </Text>
                            </VStack>
                        </HStack>
                        <Pressable
                            alignItems="center"
                            justifyContent="center"
                            px={1}
                            onPress={handleCloseToast}
                        >
                            <Text variant="sm" color="blue.600">
                                Close
                            </Text>
                        </Pressable>
                    </HStack>
                </Box>
            </Pressable>
        </Box>
    );
};

export default memo(NotificationAlert);
