import React, {memo, useState} from "react";
import {Modal} from "react-bootstrap";

type Props = {
    // onHide: () => void;
    user: any;
    hideCloseButtons?: boolean;
};

const ModalMessage: React.FC<Props> = ({user, hideCloseButtons = false}) => {
    const [show, setShow] = useState<boolean>(true);

    function onHide() {
        setShow(false);
    }

    return (
        <Modal show={show} size="lg" backdrop="static" onHide={onHide}>
            <Modal.Header closeButton={!hideCloseButtons}>
                <Modal.Title>We need your help!</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="jumbotron alert-danger">
                    <h1 className="display-4">We need your help!</h1>
                    Host Tools has been accepted as a Preferred Partner with VRBO/Expedia group and
                    we need your help.
                    <br />
                    <br />
                    {"Tom sent you an email about joining the VRBO Pilot Program. "}
                    <strong>We need your response right away</strong>
                    <strong>
                        , please send Tom an email to
                        <a
                            target="_blank"
                            href={`mailto:t@hosttools.com?subject=VRBO Pilot Program&body=%0d%0a%0d%0a%0d%0a%0d%0a--------%0d%0aBeta UserID: ${user._id}`}
                            rel="noreferrer"
                        >
                            {" t@hosttools.com "}
                        </a>
                        now.
                    </strong>
                    <br />
                    <br />
                    We need a response from all Beta users right away.
                    <br />
                    <br />
                    <strong>
                        You are seeing this message because Tom is waiting for your response about
                        the VRBO Pilot Program and we need to hear from you.
                    </strong>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default memo(ModalMessage);
