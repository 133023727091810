import "./admin/aziadashboard/template/css/azia.css";
import "./admin/css/style.scss";

import * as Sentry from "@sentry/react";
import React from "react";
import {createRoot} from "react-dom/client";

import App from "./admin/App";

if (process.env.NODE_ENV === "production") {
    Sentry.init({
        dsn: "https://4a69bc2e527f4ff0a955b55da5dc515c@o115255.ingest.sentry.io/5493821",
        // eslint-disable-next-line no-undef
        environment: __IS_BETA__ ? "Beta" : "Main",
        integrations: [],
        ignoreErrors: [
            "ResizeObserver loop limit exceeded",
            "Failed to fetch",
            "AbortError: The user aborted a request.",
            "AbortError: Fetch is aborted"
        ]
        // // We recommend adjusting this value in production, or using tracesSampler
        // // for finer control
        // tracesSampleRate: 1.0,
        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        // replaysSessionSampleRate: 1.0,
        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        // replaysOnErrorSampleRate: 1.0
    });
}

render(App);

function render(Component: React.ComponentType) {
    const container = document.getElementById("root");

    if (container) {
        const root = createRoot(container);
        root.render(<Component />);
    } else {
        throw Error("No root element found");
    }
}
