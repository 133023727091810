import {memoWithType} from "@hosttools/frontend";
import type {Row} from "@tanstack/react-table";
import {Box, Pressable} from "native-base";
import React, {useCallback, useContext} from "react";

import {TableContext} from "./TableContext";

type SupportedRowProps<T> = {
    children: React.ReactNode;
    row: Row<T>;
    onRowClick?: (row: Row<T>) => void;
};

type Props<T> = StrictUnion<SupportedRowProps<T> | {children: React.ReactNode}>;

const TableRow = <T,>({children, row, onRowClick, ...rest}: Props<T>) => {
    const {gridTemplateColumns} = useContext(TableContext);

    const handleRowClick = useCallback(() => {
        if (row) {
            onRowClick?.(row);
        }
    }, [onRowClick, row]);

    if (onRowClick) {
        return (
            <Pressable
                testID="table-row"
                role="row"
                display={gridTemplateColumns ? "contents" : "table-row"}
                width="100%"
                onPress={handleRowClick}
                {...rest}
            >
                {children}
            </Pressable>
        );
    }

    return (
        <Box
            testID="table-row"
            role="row"
            display={gridTemplateColumns ? "contents" : "table-row"}
            width="100%"
            {...rest}
        >
            {children}
        </Box>
    );
};

export default memoWithType(TableRow);
