import {ConnectedRoundIcon, ImportIcon, MagnetIcon} from "@hosttools/frontend";
import {Box, VStack, View} from "native-base";
import React, {memo, useMemo} from "react";
import {matchPath, useLocation} from "react-router-dom";

import SocialValidation from "@/admin/components/SocialValidation";
import {ReactComponent as LoginBg} from "@/admin/img/login-bg.svg";
import {ReactComponent as LogoHostTool} from "@/admin/img/logo-black.svg";
import Steps, {type Props as StepsProps} from "@/client/components/Steps";

interface Props {
    paths: readonly [string, string, string];
}

const SideBarSteps: React.FC<Props> = ({paths}) => {
    const {pathname} = useLocation();

    const current = useMemo(() => {
        if (matchPath(pathname, {path: paths[0], exact: true})) {
            return 1;
        }
        if (matchPath(pathname, {path: paths[1], exact: true})) {
            return 2;
        }
        if (matchPath(pathname, {path: paths[2], exact: true})) {
            return 3;
        }
        return -1;
    }, [pathname, paths]);

    return (
        <VStack
            maxWidth={["100%", "30%"]}
            background="blueGray.50"
            justifyContent="space-between"
            overflow="auto"
        >
            <VStack position="relative">
                <Box position="absolute" top={0} left={0} right={0} bottom={0} pointerEvents="none">
                    <LoginBg width="100%" height="100%" />
                </Box>
                <VStack alignItems="start" p={9} pt={12} space={12}>
                    <LogoHostTool height={34} />
                    <Steps
                        current={current}
                        items={useMemo<StepsProps["items"]>(
                            () => [
                                {
                                    title: "Connect channel",
                                    description:
                                        "Connect your channel account to import listings, or create a listing manually",
                                    Icon: ConnectedRoundIcon
                                },
                                {
                                    title: "Import listings",
                                    description:
                                        "If importing listings, select the listings you would like to import into Host Tools",
                                    Icon: ImportIcon
                                },
                                {
                                    title: "Connect additional channels",
                                    description:
                                        "Are you listings on other channels? Connect additional channels and link the listings. Host Tools can message all your guests and sync your calendars across all channels",
                                    Icon: MagnetIcon
                                }
                            ],
                            []
                        )}
                    />
                </VStack>
            </VStack>
            <View p={9}>
                <SocialValidation size="sm" />
            </View>
        </VStack>
    );
};

export default memo(SideBarSteps);
