import axios from "axios";

import httpDebugger from "./httpDebugger";

function createHttp(baseURL: string) {
    const http = axios.create({
        baseURL,
        headers: {
            "Content-Type": "application/json"
        }
    });

    http.interceptors.request.use(reqConfig => {
        httpDebugger.request(
            `${reqConfig.method && reqConfig.method.toUpperCase()} ${reqConfig.baseURL}${
                reqConfig.url
            }`,
            reqConfig.method === "post" ? reqConfig.data : ""
        );
        return reqConfig;
    });

    http.interceptors.response.use(
        response => {
            httpDebugger.response(
                `${response.status} ${
                    response.config.method && response.config.method.toUpperCase()
                } ${response.config.baseURL}${response.config.url}`
            );
            return response;
        },
        error => {
            if (error.config) {
                httpDebugger.error(
                    `${error.response && error.response.status} ${
                        error.config.method && error.config.method.toUpperCase()
                    } ${error.config.url}`
                );
            } else {
                httpDebugger.error(`${error.name}: ${error.response?.data?.message}`);
            }
            throw error;
        }
    );

    return http;
}

export default createHttp;
