import {ListingIcon} from "@hosttools/frontend";
import {Box, Image, type IBoxProps} from "native-base";
import React, {memo, useMemo} from "react";

export type Size = "xs" | "sm" | "md";

export type Props = {
    size?: Size;
    thumbnail?: string;
} & IBoxProps;

const ListingThumbnail: React.FC<Props> = ({thumbnail, size = "md", ...rest}) => {
    const thumbnailSource = useMemo(() => {
        if (thumbnail) {
            return {
                uri: thumbnail
            };
        }
    }, [thumbnail]);

    const variants = useMemo(() => {
        return {
            xs: {
                size: "32px",
                borderRadius: 4,
                borderColor: "white",
                borderWidth: "2px"
            },
            sm: {
                size: "48px",
                borderRadius: 8
            },
            md: {
                size: "64px",
                borderRadius: 10
            }
        } satisfies Record<
            Size,
            {
                size: IBoxProps["size"];
                borderRadius: IBoxProps["borderRadius"];
                borderColor?: IBoxProps["borderColor"];
                borderWidth?: IBoxProps["borderWidth"];
                shadow?: IBoxProps["shadow"];
            }
        >;
    }, []);

    const sizeImage = variants[size];

    return (
        <Box alignItems="center" justifyContent="center" overflow="hidden" {...sizeImage} {...rest}>
            {thumbnailSource ? (
                <Image width="full" height="full" source={thumbnailSource} alt="listing image" />
            ) : (
                <ListingIcon size={sizeImage.size} />
            )}
        </Box>
    );
};

export default memo(ListingThumbnail);
