import {HStack, Pressable} from "native-base";
import React, {memo} from "react";
import {Link} from "react-router-dom";

import Text from "../Text";

export type Props = {
    children: React.ReactNode;
    path?: string;
    active?: boolean;
    icon?: React.ReactNode;
    testID?: string;
    onClick?: () => void;
};

const MenuItem: React.FC<Props> = ({children, path, active, icon, testID, onClick}) => {
    const childNode: React.ReactNode = (
        <HStack
            alignItems="center"
            justifyContent="space-between"
            bgColor={active ? "blueGray.100" : "transparent"}
            borderRadius={4}
            // changed this based on the design menu on setting page
            p={[3, 2]}
        >
            {typeof children === "string" ? (
                <Text variant="sm" color={active ? "blue.600" : undefined}>
                    {children}
                </Text>
            ) : (
                children
            )}
            {icon}
        </HStack>
    );
    return (
        <Pressable
            testID={testID}
            _hover={{bgColor: "blueGray.100"}}
            _focus={{
                bgColor: "blueGray.100"
            }}
            _pressed={{bgColor: "blueGray.100"}}
            borderRadius={4}
            onPress={onClick}
        >
            {path ? <Link to={path}>{childNode}</Link> : childNode}
        </Pressable>
    );
};

export default memo(MenuItem);
