import {Box} from "native-base";
import React, {memo} from "react";

type Props = {
    position?: "absolute";
    top?: number;
    right?: number;
    bg: "success.600" | "blueGray.400";
};

const Dot: React.FC<Props> = ({position, bg, top, right}) => {
    return (
        <Box
            width={1.5}
            height={1.5}
            position={position}
            top={top}
            right={right}
            borderWidth={1}
            borderStyle="solid"
            borderColor="white"
            borderRadius="full"
            bg={bg}
        />
    );
};

export default memo(Dot);
