import type {Icon as IconNB} from "native-base";
import {Box, Center, CheckCircleIcon, CloseIcon, HStack, WarningIcon} from "native-base";
import React, {memo, useMemo} from "react";

import Text from "../Text";

export type Status = "info" | "warning" | "success" | "error";

export interface ToastAlertProps {
    status?: Status;
    title: string;
}

const ToastAlert: React.FC<ToastAlertProps> = ({status = "success", title}) => {
    const {bg, Icon, shadow, color} = useMemo(() => {
        const statusProps: Record<
            Status,
            {
                bg: string;
                Icon?: typeof IconNB;
                shadow?: "subtleShadow";
                color: "white" | "blueGray.800";
            }
        > = {
            info: {
                bg: "white",
                shadow: "subtleShadow",
                color: "blueGray.800"
            },
            warning: {
                bg: "warning.500",
                Icon: WarningIcon,
                color: "white"
            },
            success: {
                bg: "success.600",
                Icon: CheckCircleIcon,
                color: "white"
            },
            error: {
                bg: "error.500",
                Icon: CloseIcon,
                color: "white"
            }
        };

        return {
            ...statusProps[status]
        };
    }, [status]);

    return (
        <Box w={96} py={3} px={4} bg={bg} borderRadius="lg" shadow={shadow}>
            <HStack space={2} w="100%">
                {Icon && (
                    <Center w={5} h={5}>
                        <Icon size="sm" color="white" />
                    </Center>
                )}
                <Text variant="md" color={color} flex={1}>
                    {title}
                </Text>
            </HStack>
        </Box>
    );
};

export default memo(ToastAlert);
