import {format, isSameDay, isValid, startOfDay} from "date-fns";

import type {Turnover} from "../models/turnover";

import {fromLocalToZoned} from "./timezone";

export type TurnoverTableType = Turnover & {
    nextCheckInTop?: string;
    nextCheckInBottom?: string;
    time?: string;
    numberOfGuests?: number;
    date: string;
    nextAirbnbNights?: string;
};

export const buildTurnover = (turnover: Turnover): TurnoverTableType => {
    const {airbnbCheckOutTime, nextReservation, isSameDayTurnover, timeZone, endDate} = turnover;
    const date = startOfDay(fromLocalToZoned(endDate, timeZone)).toISOString();
    let numberOfGuests: number | undefined;
    let {firstName} = turnover;
    let startDate: Date | undefined;
    let airbnbCheckInTime: number | undefined;
    let nextAirbnbNights: string | undefined;
    if (nextReservation) {
        firstName = nextReservation.airbnbFirstName;
        numberOfGuests = nextReservation.airbnbNumberOfGuests;
        startDate = nextReservation.startDate;
        airbnbCheckInTime = nextReservation.airbnbCheckInTime;
        nextAirbnbNights = nextReservation.airbnbNights;
    }
    let checkOutTime: string | undefined;
    let checkOutTimeAmPM: string | undefined;
    const hasCheckOut = airbnbCheckOutTime || airbnbCheckOutTime === 0;
    if (hasCheckOut) {
        checkOutTime = airbnbCheckOutTime.toString();
        checkOutTimeAmPM = "am";
        if (Number(checkOutTime) >= 12) {
            checkOutTimeAmPM = "pm";
            if (checkOutTime !== "12") {
                checkOutTime = (Number(checkOutTime) - 12).toString();
            }
        }
    }
    let checkInTime: string | undefined;
    const hasCheckIn = airbnbCheckInTime || airbnbCheckInTime === 0;
    if (hasCheckIn) {
        checkInTime = airbnbCheckInTime?.toString();
        if (checkInTime && Number(checkInTime) >= 12) {
            if (checkInTime !== "12") {
                checkInTime = (Number(checkInTime) - 12).toString();
            }
            if (checkOutTimeAmPM && checkOutTimeAmPM !== "pm") {
                checkOutTime += checkOutTimeAmPM;
            }
            checkInTime += "pm";
        } else {
            if (checkOutTime && checkOutTimeAmPM !== "am") {
                checkOutTime += checkOutTimeAmPM;
            }
            checkInTime += "am";
        }
    }
    let time: string = "-";
    if (hasCheckIn && !hasCheckOut) {
        time = `before ${checkInTime}`;
    } else if (!hasCheckIn && hasCheckOut) {
        time = `after ${checkOutTime}`;
    } else if (hasCheckIn && hasCheckOut) {
        time = `${checkOutTime} - ${checkInTime}`;
    }
    let nextCheckInTop: string | undefined;
    let nextCheckInBottom: string = "-";
    if (isSameDayTurnover) {
        nextCheckInTop = "Same Day";
        if (isSameDay(new Date(date), new Date(Date.now()))) {
            nextCheckInTop = "Today";
        }
    }
    if (startDate && isValid(new Date(startDate))) {
        const timezone = turnover.timeZone;
        nextCheckInBottom = format(fromLocalToZoned(startDate || "", timezone), "MMM do");
    }
    return {
        ...turnover,
        nextCheckInTop,
        nextCheckInBottom,
        time,
        firstName,
        numberOfGuests,
        date,
        nextAirbnbNights
    };
};
