import React, {memo, useCallback} from "react";

import FormField from "../FormField";
import Input from "../Input";

import type {CredentialsType, Errors} from "@/admin/components/ModalAddAccount/types";

type Props = {
    credentials: CredentialsType;
    errors?: Errors;
    onChangeCredentials: (field: "apiKey", value: string) => void;
};

const HoufyCredential: React.FC<Props> = ({credentials, errors, onChangeCredentials}) => {
    const handleChangeAPIKey = useCallback(
        (text: string) => {
            onChangeCredentials("apiKey", text);
        },
        [onChangeCredentials]
    );

    return (
        <FormField
            error={errors?.apiKey}
            label="API Access Token"
            tooltip="Enter your Houfy API key."
        >
            <Input
                testID="apiKey"
                id="apiKey"
                placeholder="API Access Token..."
                name="apiKey"
                autoComplete="off"
                value={credentials.apiKey}
                onChangeText={handleChangeAPIKey}
                isRequired
            />
        </FormField>
    );
};

export default memo(HoufyCredential);
