import {get} from "lodash";
import {useTheme, type IBoxProps, type ITheme} from "native-base";
import React, {memo, useMemo} from "react";
import {Link as LinkRouter} from "react-router-dom";

type PropsInternal = {
    to: string;
};

type PropsExternal = {
    type: "external";
    href: string;
    target?: "_blank" | "_self";
};

type Props = {
    children: React.ReactNode;
    color?: IBoxProps["color"];
    fullWidth?: boolean;
    fontWeight?: keyof ITheme["fontWeights"];
    fontSize?: keyof ITheme["fontSizes"];
} & StrictUnion<PropsInternal | PropsExternal>;

const Link: React.FC<Props> = ({
    children,
    color = "blue.600",
    fontWeight = "medium",
    fontSize = "sm",
    target = "_blank",
    type,
    href,
    to,
    fullWidth
}) => {
    const theme = useTheme();

    const style = useMemo(() => {
        const hex = get(theme, `colors.${color}`, color);
        const fontWeightValue = theme.fontWeights[fontWeight];
        const fontSizeValue = theme.fontSizes[fontSize];
        return {
            color: hex,
            fontWeight: fontWeightValue,
            fontSize: fontSizeValue,
            width: fullWidth ? "100%" : undefined
        };
    }, [color, fontSize, fontWeight, fullWidth, theme]);

    if (type === "external") {
        return (
            <a href={href} target={target} rel="noopener noreferrer" style={style}>
                {children}
            </a>
        );
    }

    return (
        <LinkRouter to={to} style={style}>
            {children}
        </LinkRouter>
    );
};

export default memo(Link);
