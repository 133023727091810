export enum Channel {
    internal = "internal",
    Airbnb = "Airbnb",
    HomeAway = "HomeAway",
    Booking = "Booking",
    Houfy = "Houfy",
    August = "August",
    Seam = "Seam",
    ChannelConnector = "ChannelConnector"
}
