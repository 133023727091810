import {HStack, Text, usePropsResolution, Icon as IconNB, type IBoxProps} from "native-base";
import type {InterfaceIconProps} from "native-base/lib/typescript/components/primitives/Icon/types";
import React, {forwardRef, memo, useImperativeHandle, useRef} from "react";

type Variants = "solid" | "outline" | "subtle";
type Colors =
    | "primary"
    | "error"
    | "danger"
    | "warning"
    | "success"
    | "blueGray"
    | "blue"
    // for prod badge, we probably use `brand` color for this
    | "amber";

interface Props {
    variant?: Variants;
    color?: Colors;
    maxWidth?: IBoxProps["maxWidth"];
    Icon?: React.ComponentType<any>;
    startIcon?: React.ReactElement<InterfaceIconProps>;
    endIcon?: React.ReactElement<InterfaceIconProps>;
    asIcon?: boolean;
    numberOfLines?: number;
    isTruncated?: boolean;
    backgroundColor?: IBoxProps["backgroundColor"];
    children: string;
    testID?: string;
    shrink?: boolean;
}

export type BadgeRef = {
    inputRef: React.RefObject<HTMLDivElement>;
};

const Badge: React.ForwardRefRenderFunction<BadgeRef, Props> = (
    {
        variant = "solid",
        color,
        Icon,
        startIcon: startIconProp,
        endIcon: endIconProp,
        children,
        maxWidth = "full",
        backgroundColor,
        asIcon = false,
        numberOfLines = 1,
        isTruncated = true,
        testID,
        shrink = false
    },
    ref
) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const {_icon, _text, ...rest} = usePropsResolution("Badge", {
        variant,
        colorScheme: color
    });

    let startIcon = startIconProp;
    let endIcon = endIconProp;

    useImperativeHandle(ref, () => ({inputRef}), [inputRef]);

    if (React.isValidElement(startIconProp)) {
        startIcon = React.cloneElement<InterfaceIconProps>(startIconProp, {
            ..._icon,
            ...startIconProp.props
        });
    }

    if (React.isValidElement(endIconProp)) {
        endIcon = React.cloneElement<InterfaceIconProps>(endIconProp, {
            ..._icon,
            ...endIconProp.props
        });
    }

    return (
        <HStack
            testID={testID ?? "badge"}
            width="fit-content"
            maxWidth={maxWidth}
            backgroundColor={backgroundColor}
            space={1}
            flexShrink={shrink ? 1 : 0}
            {...rest}
        >
            {Icon ? (
                asIcon ? (
                    <IconNB as={Icon} {..._icon} overflow="visible" size="xs" />
                ) : (
                    <Icon {..._icon} overflow="visible" size="xs" />
                )
            ) : (
                startIcon
            )}
            <Text
                {..._text}
                ref={inputRef}
                numberOfLines={numberOfLines}
                isTruncated={isTruncated}
                size="xs"
            >
                {children}
            </Text>
            {endIcon}
        </HStack>
    );
};

export default memo(forwardRef(Badge));
