import {yearInDays} from "@hosttools/core/constant";

import type {Listing} from "../models/listing";

export function filterVisibleListings<
    T extends {
        _id: string;
        hidden?: boolean;
    }
>(listings: T[]): T[] {
    return listings.reduce((result, listing) => {
        if (listing.hidden) {
            return result;
        }
        result.push(listing);
        return result;
    }, [] as T[]);
}

export function parseListingToSavePayload(listing: Listing) {
    const {
        airbnbName,
        nickname = "",
        priceSource,
        basePrice,
        minPrice,
        minNights,
        pricingEnabled,
        syncAvailability,
        calendars,
        bookingWindow,
        hidden,
        calendarExportCode,
        listingEnabled
    } = listing;

    const payload = {
        airbnbName,
        nickname,
        priceSource,
        basePrice,
        minPrice,
        listingEnabled: listingEnabled !== false,
        pricingEnabled,
        syncAvailability,
        calendars,
        calendarExportCode: calendarExportCode ?? "",
        minNights,
        bookingWindow,
        hidden: hidden && !listing.listingEnabled // If listing is enabled then it shouldn't be hidden
    };

    if (!priceSource) {
        payload.priceSource = "Amount";
    }
    // If the listing has pricing disabled but availability enabled, then the price source should be Beyond Pricing
    if (!pricingEnabled && syncAvailability) {
        payload.priceSource = "Beyond Pricing";
    }
    if (!basePrice) {
        payload.basePrice = 0;
    }
    if (!Number.isNaN(Number(minNights))) {
        payload.minNights = minNights;
    } else {
        payload.minNights = 1;
    }
    if (!bookingWindow && bookingWindow !== 0) {
        payload.bookingWindow = yearInDays;
    }

    return payload;
}
