import {UserContext} from "@hosttools/frontend";
import gravatar from "gravatar";
import upperFirst from "lodash/upperFirst";
import {HStack, VStack} from "native-base";
import React, {memo, useContext, useMemo} from "react";

import Avatar from "../Avatar";
import Paragraph from "../Paragraph";
import Text from "../Text";

const AvatarProfile: React.FC = () => {
    const {
        user: {fullName, username, subscriptionStatus}
    } = useContext(UserContext);

    const userImageUri = useMemo(
        () => ({uri: gravatar.url(username, {s: "120", r: "pg", d: "mp"})}),
        [username]
    );

    const displayName = fullName.trim() || username;

    return (
        <HStack space={2} alignItems="center">
            <Avatar size="sm" bg="cyan.600" source={userImageUri} name={displayName} />
            <VStack>
                <Text
                    testID="profile-name"
                    variant="sm"
                    color="blueGray.800"
                    textBreakStrategy="balanced"
                >
                    {displayName}
                </Text>
                {subscriptionStatus && (
                    <Paragraph variant="xs" color="blueGray.400">
                        {upperFirst(subscriptionStatus)}
                    </Paragraph>
                )}
            </VStack>
        </HStack>
    );
};

export default memo(AvatarProfile);
