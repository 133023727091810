import moment from "moment-timezone";

export const fromZonedToLocal = (date: string | Date, timezone: string) => {
    return moment(date).tz(timezone, true).toDate();
};

export const fromLocalToZoned = (date: string | Date, timezone: string) => {
    if (Intl.DateTimeFormat().resolvedOptions().timeZone === timezone) {
        return moment.tz(date, timezone).toDate();
    }
    return moment.tz(date, timezone).local(true).toDate();
};
