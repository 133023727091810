import type {IMenuProps} from "native-base";
import {Menu} from "native-base";
import React, {memo} from "react";

export type Props = IMenuProps;

const Dropdown: React.FC<Props> = ({children, testID = "dropdown", ...restProps}) => {
    return (
        <Menu
            testID={testID}
            borderRadius="md"
            background="white"
            colorScheme="blueGray"
            flexShrink={0}
            flexGrow={0}
            shadow="subtleShadow"
            borderWidth={1}
            borderColor="blueGray.200"
            borderStyle="solid"
            py={0}
            {...restProps}
        >
            {children}
        </Menu>
    );
};

export default memo(Dropdown);
