import {type CellContext} from "@tanstack/react-table";
import React from "react";

import Checkbox from "../CheckBox";

const TableCheckBoxColumn: React.FC<CellContext<any, any>> = ({row}) => {
    return (
        <Checkbox
            value="column" // not sure what this value is for?
            isChecked={row.getIsSelected()}
            isDisabled={!row.getCanSelect()}
            isIndeterminate={row.getIsSomeSelected()}
            onChange={row.getToggleSelectedHandler()}
        />
    );
};

export default TableCheckBoxColumn;
