import {useCallback, useState} from "react";

const useDisclose = (defaultValue = false) => {
    const [isOpen, setOpen] = useState(defaultValue);

    const onOpen = useCallback(() => {
        setOpen(true);
    }, []);

    const onClose = useCallback(() => {
        setOpen(false);
    }, []);

    const onToggle = useCallback(() => {
        setOpen(prev => !prev);
    }, []);

    return {isOpen, onOpen, onClose, onToggle};
};

export default useDisclose;
