import type {FC} from "react";
import React, {useMemo} from "react";

import {createHttp} from "../../http";
import HTTPContext from "../contexts/HTTPContext";

interface Props {
    url: string;
}

const HTTPProvider: FC<Props> = ({url, children}) => {
    return (
        <HTTPContext.Provider value={useMemo(() => createHttp(url), [url])}>
            {children}
        </HTTPContext.Provider>
    );
};

export default HTTPProvider;
