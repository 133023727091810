import {FormControl} from "native-base";
import React, {memo} from "react";

import PopoverInfo from "../PopoverInfo";
import Text from "../Text";

export type Props = {
    label?: string;
    size?: "xs" | "sm";
    children?: React.ReactNode;
    tooltip?: React.ReactNode;
};

const Label: React.FC<Props> = ({label, tooltip, size = "sm", children}) => {
    return (
        <FormControl.Label display="flex" flexDir="row" alignItems="center" mt={0}>
            {(label || typeof children === "string") && (
                <Text variant={size} color="blueGray.400" numberOfLines={1}>
                    {label || children}
                </Text>
            )}
            {children && typeof children !== "string" && children}
            {tooltip && (
                <>
                    &nbsp;
                    <PopoverInfo label={tooltip} />
                </>
            )}
        </FormControl.Label>
    );
};

export default memo(Label);
