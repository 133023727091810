import {InputRightAddon as InputRightAddonNB} from "native-base";
import React from "react";

import Text from "../Text";

type Props = {
    children?: React.ReactNode;
};

const InputRightAddon: React.FC<Props> = ({children}) => {
    return (
        <InputRightAddonNB
            bgColor="blueGray.50"
            height="full"
            {...(typeof children !== "string" ? {p: 0} : {})}
        >
            {typeof children === "string" ? <Text variant="sm">{children}</Text> : children}
        </InputRightAddonNB>
    );
};

export default InputRightAddon;
