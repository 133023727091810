import {useCallback, useContext, useRef} from "react";

import {AppContext} from "../provider/AppProvider";

const useCloseConfirmModal = (callback?: () => void) => {
    const callbackRef = useRef(callback);
    callbackRef.current = callback;

    const {setConfirmModal} = useContext(AppContext);

    return useCallback(() => {
        setConfirmModal(undefined);
        callbackRef.current?.();
    }, [setConfirmModal]);
};

export default useCloseConfirmModal;
