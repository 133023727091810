import buildChannels from "@hosttools/core/shared/buildChannels";
import {useMemo} from "react";

import type {Listing, ListingChannel} from "../../../models/listing";

const useChannelsFromListings = (
    listings: Listing | Listing[],
    includePricingEnabled: boolean = false
) => {
    return useMemo(() => {
        const nextListings = Array.isArray(listings) ? listings : [listings];
        return nextListings.reduce((result, listing) => {
            if (!listing.pricingEnabled && !includePricingEnabled) {
                return result;
            }
            result = buildChannels(listing).reduce((arr, channel) => {
                if (!arr.includes(channel)) {
                    arr.push(channel);
                }
                return arr;
            }, result);

            return result;
        }, [] as ListingChannel[]);
    }, [listings, includePricingEnabled]);
};

export default useChannelsFromListings;
