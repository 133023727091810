import {useDisclose} from "@hosttools/frontend";
import type {Listing} from "@hosttools/frontend/models/listing";
import {ChevronDownIcon} from "@hosttools/frontend/react/components/Icons";
import {Box, Center, Popover} from "native-base";
import React, {useState, useEffect, useCallback, memo} from "react";
import {Nav, NavDropdown} from "react-bootstrap";
import {FiCalendar, FiChevronDown, FiChevronLeft, FiSearch, FiX} from "react-icons/fi";
import {Link, NavLink, useHistory} from "react-router-dom";

import TopNavMenuItem from "@/client/components/Header/TopNavMenuItem";
import ListingsMenu from "@/client/components/NavMessaging/ListingsMenu";
import Text from "@/client/components/Text";
import useNewDesign from "@/client/hooks/useNewDesign";

type Props = {
    visibleListings: Listing[];
    isActive?: boolean;
};

const NavPricing: React.FC<Props> = ({visibleListings, isActive}) => {
    const isNewUIEnabled = useNewDesign();
    const history = useHistory();

    const [query, setQuery] = useState("");
    const [filteredListings, setFilteredListings] = useState<Listing[]>([]);
    const {isOpen: showPricingNav, onOpen, onClose, onToggle} = useDisclose();

    const handleClickMenu = useCallback(
        (path: string) => {
            history.push(path);
        },
        [history]
    );

    const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(event.target.value);
    }, []);

    const handleSearch = useCallback((event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
    }, []);

    const handleClickSearch = useCallback((event: React.SyntheticEvent) => {
        event.preventDefault();
    }, []);

    const triggerPricingMenu = useCallback(
        ({ref, onPress, noIcon, ...triggerProps}) => {
            return (
                <TopNavMenuItem
                    ref={ref}
                    testID="btn-pricing-menu"
                    isActive={isActive}
                    icon={noIcon ? undefined : <ChevronDownIcon color="blueGray.400" />}
                    to={onPress}
                    {...triggerProps}
                >
                    Pricing
                </TopNavMenuItem>
            );
        },
        [isActive]
    );

    useEffect(() => {
        function filterListings() {
            const filteredQuery = query.toLowerCase();
            const filteredListings = visibleListings.filter(listing => {
                let matchesQuery = true;
                if (filteredQuery !== "") {
                    if (
                        listing.airbnbName.toLowerCase().indexOf(filteredQuery) === -1 &&
                        (!listing.nickname ||
                            listing.nickname.toLowerCase().indexOf(filteredQuery) === -1)
                    ) {
                        matchesQuery = false;
                    }
                }
                return matchesQuery;
            });
            setFilteredListings(filteredListings);
        }
        filterListings();
    }, [visibleListings, query]);

    if (isNewUIEnabled) {
        let menu: React.ReactElement | null = null;

        const nothingFound = (
            <Box key="nothingFound" px={4} py={2}>
                <Center>
                    <Text variant="sm">Nothing Found</Text>
                </Center>
            </Box>
        );

        const genBasicDropdown = (menuContent: React.ReactNode) => (
            <Popover
                isOpen={showPricingNav}
                onOpen={onOpen}
                onClose={onClose}
                trigger={triggerPricingMenu}
            >
                <Popover.Content testID="popover-content">
                    <Popover.Arrow />
                    <Popover.Body testID="popover-body">{menuContent}</Popover.Body>
                </Popover.Content>
            </Popover>
        );

        if (visibleListings.length > 1) {
            const menuContent: React.ReactNode = (
                <Box px={3} py={2} maxW={96} maxH={96} overflowY="auto">
                    <ListingsMenu listings={visibleListings} to="pricing" onClick={onClose} />
                </Box>
            );

            menu = genBasicDropdown(menuContent);
        } else if (filteredListings.length === 1) {
            menu = triggerPricingMenu({
                noIcon: true,
                onPress: () => handleClickMenu(`/pricing/${filteredListings[0]._id}`)
            });
        } else {
            menu = genBasicDropdown(nothingFound);
        }

        return menu;
    }

    let menu: React.ReactElement | null = null;

    const pricingDropdownButton = (
        <span className="d-flex flex-nowrap align-items-center">
            <FiCalendar className="d-block d-sm-none" />
            <span className="d-none d-sm-block">Pricing</span>
            <FiChevronDown className="ml-1" />
        </span>
    );
    const nothingFound = (
        <div key="messageRuleNavNothingFound" className="pd-x-15 pd-y-8 tx-gray-600 text-center">
            Nothing Found
        </div>
    );

    const genBasicDropdown = (items: React.ReactNode) => (
        <NavDropdown
            id="NavDropdownPricing"
            title={pricingDropdownButton}
            className="mr-2"
            show={showPricingNav}
            onToggle={onToggle}
        >
            <div className="d-md-none pl-2 pr-2 pt-2 d-flex align-items-center justify-content-between">
                <button
                    className="az-header-arrow btn bg-white pd-l-10 pd-0"
                    type="button"
                    onClick={onClose}
                >
                    <FiChevronLeft />
                </button>
                <h4 className="m-0">Pricing</h4>
                <span className="p-3" />
            </div>
            <NavDropdown.Divider className="d-md-none" />
            <div className="pd-x-8 pd-b-8">
                <form className="search" onSubmit={event => handleSearch(event)}>
                    <input
                        type="search"
                        className="form-control"
                        placeholder="Filter..."
                        value={query}
                        onChange={handleChange}
                    />
                    {!query && (
                        <button type="button" className="btn" onClick={handleClickSearch}>
                            <FiSearch />
                        </button>
                    )}
                    {!!query && (
                        <button type="button" className="btn" onClick={() => setQuery("")}>
                            <FiX />
                        </button>
                    )}
                </form>
            </div>
            <div className="scrollable-menu">{items}</div>
        </NavDropdown>
    );

    if (visibleListings.length > 1) {
        const items = [];
        if (filteredListings.length) {
            items.push(
                <div
                    key="pricingRuleNavListingsLabel"
                    className="pd-x-15 pd-y-8 tx-11 tx-medium tx-gray-600 tx-uppercase"
                >
                    Listings
                </div>
            );
            for (let i = 0; i < filteredListings.length; i += 1) {
                const listing = filteredListings[i];
                items.push(
                    <NavDropdown.Item
                        as={Link}
                        to={`/pricing/${listing._id}`}
                        data-testid="link-listing-item"
                        className="nav-sub-link justify-content-between d-flex"
                        key={`priceRuleNavItem${listing._id}`}
                    >
                        <span className="text">{listing.name}</span>
                    </NavDropdown.Item>
                );
            }
        }
        if (!filteredListings.length) {
            items.push(nothingFound);
        }
        menu = genBasicDropdown(items);
    } else if (filteredListings.length === 1) {
        menu = (
            <Nav.Link as={NavLink} to={`/pricing/${filteredListings[0]._id}`} className="mr-2">
                <FiCalendar className="d-block d-md-none" />
                <span className="d-none d-md-block">Pricing</span>
            </Nav.Link>
        );
    } else {
        menu = genBasicDropdown(nothingFound);
    }

    return menu;
};

export default memo(NavPricing);
