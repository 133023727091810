import {Button, type IButtonProps} from "native-base";
import React, {memo} from "react";

type Props = IButtonProps & {
    isActive?: boolean;
};

const ButtonPage: React.FC<Props> = ({isActive, disabled, ...rest}) => {
    return (
        <Button
            variant={["ghost", "outline"]}
            size={8}
            minW="auto"
            rounded="full"
            borderWidth={1}
            borderStyle="solid"
            disabled={disabled}
            opacity={disabled ? 0.5 : 1}
            borderColor={["transparent", isActive ? "blue.600" : "blueGray.200"]}
            backgroundColor={["transparent", isActive ? "blue.600" : "white"]}
            _hover={{
                bgColor: isActive ? "blue.700" : "blueGray.300",
                borderColor: isActive ? "blue.700" : "blueGray.300"
            }}
            _text={{
                color: isActive ? ["blueGray.800", "white"] : ["blueGray.500", "blueGray.800"]
            }}
            {...rest}
        />
    );
};

export default memo(ButtonPage);
