import {useMemo} from "react";

type TimeOption = {
    label: string;
    value: number;
};

export const useCheckInOutTimes = () => {
    return useMemo(() => {
        const options: TimeOption[] = [];
        let i = 0;
        while (i < 24) {
            let period = "AM";
            if (i > 11) {
                period = "PM";
            }
            let timeNumber = i;
            if (i === 0) {
                timeNumber = 12;
            } else if (i > 12) {
                timeNumber = i - 12;
            }
            options.push({
                value: i,
                label: `At ${timeNumber}${period}`
            });
            i += 1;
        }

        return options;
    }, []);
};
