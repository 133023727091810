import type {FC} from "react";
import {memo, useEffect, useContext} from "react";

import type {Listing} from "../../../models/listing";
import {sanitizeListings} from "../../../models/listing";
import type {ListingGroup} from "../../../models/listingGroup";
import type {Lock} from "../../../models/lock";
import {sanitizeLock} from "../../../models/lock";
import {setListingsIntoAccounts} from "../../../utils/account";
import {buildGlobalErrors} from "../../../utils/errors";
import {filterVisibleListings} from "../../../utils/listings";
import updateListWS from "../../../utils/updateListWS";
import {UserContext, WebSocketContext} from "../../contexts";
import {useWatchAccount} from "../../hooks/useWatchAccount";
import {useWatchTag} from "../../hooks/useWatchTag";
import useWatchUser from "../../hooks/useWatchUser";

const UserWatchChange: FC = () => {
    const websocket = useContext(WebSocketContext);
    const {isAuthenticated, permissions, setState} = useContext(UserContext);
    const accounts = useWatchAccount();

    useWatchUser();

    // for tags
    const [tags] = useWatchTag(isAuthenticated && permissions.messageRules?.edit);
    useEffect(() => {
        setState(prev => ({
            ...prev,
            tags: tags ?? []
        }));
    }, [tags, setState]);

    // for accounts
    useEffect(() => {
        if (!accounts) {
            setState(prev => ({
                ...prev,
                isLoadingAccounts: true
            }));
            return;
        }
        setState(prev => {
            const {user, listings, visibleListings} = prev;
            const nextAccounts = setListingsIntoAccounts(accounts, listings);
            return {
                ...prev,
                isLoadingAccounts: false,
                accounts: nextAccounts,
                errors: buildGlobalErrors(user, accounts, listings, visibleListings)
            };
        });
    }, [accounts, setState]);

    // for others
    useEffect(() => {
        if (!accounts) {
            return;
        }

        function syncListing(payload: WSPayload<Listing>) {
            setState(prev => {
                if (!prev.listings) {
                    return prev;
                }

                const {user, accounts} = prev;
                let listings = updateListWS(prev.listings, payload);
                listings = sanitizeListings(listings);
                const visibleListings = filterVisibleListings(listings);
                return {
                    ...prev,
                    errors: buildGlobalErrors(user, accounts, listings, visibleListings),
                    listings,
                    visibleListings
                };
            });
        }

        function syncListingGroups(payload: WSPayload<ListingGroup>) {
            setState(prev => {
                const listingGroups: ListingGroup[] = updateListWS(prev.listingGroups, payload);
                const fixedListingGroups = listingGroups.map(elem => {
                    if (!prev.listings) {
                        return elem;
                    }
                    elem.listings = elem.listingIDs.reduce((result, listingID) => {
                        const listing = prev.listings.find(listing => listing._id === listingID);
                        if (listing) {
                            result.push(listing);
                        }
                        return result;
                    }, [] as Listing[]);
                    return elem;
                });
                return {
                    ...prev,
                    listingGroups: fixedListingGroups
                };
            });
        }

        function syncLock(payload: WSPayload<Lock>) {
            setState(prev => {
                const nextLocks = updateListWS(prev.locks, payload);
                return {
                    ...prev,
                    locks: nextLocks.map(sanitizeLock)
                };
            });
        }

        websocket?.on<WSRoom>("listing", syncListing);
        websocket?.on<WSRoom>("listingGroup", syncListingGroups);
        websocket?.on<WSRoom>("lock", syncLock);

        return () => {
            websocket?.off("listing", syncListing);
            websocket?.off("listingGroup", syncListingGroups);
            websocket?.off("lock", syncLock);
        };
    }, [websocket, accounts, setState]);

    return null;
};

export default memo(UserWatchChange);
