import type {AxiosInstance} from "axios";

import type {Lock} from "../../models/lock";

class LockService {
    private http: AxiosInstance;

    constructor(http: AxiosInstance) {
        this.http = http;
    }

    async saveLock(lock: Lock, pinLength?: number, removingListingIDs?: string[]) {
        await this.http.post<void>("/editLockSettings", {
            lockID: lock._id,
            nickname: lock.nickname?.trim(),
            checkInBuffer: lock.checkInBuffer,
            checkOutBuffer: lock.checkOutBuffer,
            pinLength,
            removingListingIDs
        });
    }

    async deleteLock(lockID: string) {
        return this.http.delete<void>("/deleteLock", {
            data: {
                lockID
            }
        });
    }
}

export default LockService;
