import type {IBoxProps} from "native-base";
import {Box} from "native-base";
import React, {memo} from "react";

const styles = {
    container: {
        display: {
            base: "none",
            md: "flex"
        }
    }
};

export type Props = IBoxProps;

const DesktopView: React.FC<Props> = ({children, ...restProps}) => {
    return (
        <Box {...styles.container} {...restProps}>
            {children}
        </Box>
    );
};

export default memo(DesktopView);
