import shadows from "@hosttools/frontend/theme/shadows";
import type {Theme} from "native-base";
import {theme} from "native-base";

type Input = Theme["components"]["Input"];

export const InputTheme: Partial<Input> = {
    baseStyle: props => {
        const {baseStyle} = theme.components.Input;
        const baseStyleProps = baseStyle(props);
        const {error} = props.theme.colors;
        return {
            ...baseStyleProps,
            _input: {
                bg: "transparent",
                flex: 1,
                w: "100%",
                h: "100%",
                minHeight: "34px" // Input is 1px border is on container
            },
            placeholderTextColor: "blueGray.400",
            color: "blueGray.800",
            boxShadow: "textField",
            _disabled: {
                opacity: "0.4",
                _web: {
                    disabled: true,
                    cursor: "not-allowed",
                    bgColor: "blueGray.100"
                }
            },
            _stack: {
                flexDirection: "row",
                alignItems: "center",
                overflow: "hidden"
            },
            _hover: {
                borderColor: "blueGray.200"
            },
            _invalid: {
                borderColor: "error.600",
                _hover: {borderColor: "error.600"},
                _stack: {
                    style: {
                        outlineWidth: "0",
                        boxShadow: `0 0 0 1px ${error[600]}`
                    }
                }
            },
            _focus: {
                ...baseStyleProps._focus,
                borderColor: "blueGray.200",
                _hover: {borderColor: "blueGray.200"},
                _invalid: {
                    borderColor: "error.600",
                    _hover: {borderColor: "error.600"},
                    _stack: {
                        style: {
                            outlineWidth: "0",
                            boxShadow: `0 0 0 2px ${error[600]}`
                        }
                    }
                },
                _disabled: {
                    placeholderTextColor: "blueGray.400",
                    _hover: {
                        borderColor: "blueGray.200"
                    }
                },
                _stack: {
                    style: {
                        outlineWidth: "0",
                        boxShadow: `${shadows.spread.shadowOffset.width}px ${shadows.spread.shadowOffset.height}px ${shadows.spread.shadowRadius}px ${shadows.spread.elevation}px ${shadows.spread.shadowColor}80`
                    }
                }
            }
        };
    }
};
