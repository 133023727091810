import {useContext, useMemo} from "react";

import {UserContext} from "../../contexts";

export function useEditListingGroupPermission(id: string | undefined): {
    canEdit: boolean;
    warnMessage?: string;
} {
    const {
        user: {isSubUser, canEditListingGroup, listingGroupIDs}
    } = useContext(UserContext);

    const isAssignedListingGroup = useMemo(
        () => isSubUser && id && listingGroupIDs?.includes(id),
        [id, isSubUser, listingGroupIDs]
    );

    return {
        canEdit: canEditListingGroup && !isAssignedListingGroup,
        warnMessage: canEditListingGroup
            ? isAssignedListingGroup
                ? "You cannot edit the listing group you are assigned to"
                : undefined
            : undefined
    };
}
