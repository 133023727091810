import {type AccountType} from "@hosttools/frontend/models/account";
import {VStack, Stack} from "native-base";
import React, {memo, useCallback, useMemo, useState} from "react";
import {Route, Switch, useHistory, useRouteMatch} from "react-router-dom";

import ImportListings from "./ImportListings";
import SelectChannels from "./SelectChannels";
import SideBarSteps from "./SideBarSteps";

import Loader from "@/admin/components/Loader";
import ModalAddAccount from "@/admin/components/ModalAddAccount";
import ContentWrapper from "@/client/components/ContentWrapper";
import useCreateAirbnbAccount from "@/client/hooks/useCreateAirbnbAccount";

const AddListing: React.FC = () => {
    const history = useHistory();
    const {path} = useRouteMatch();
    const [addAccountType, setAddAccountType] = useState<AccountType>();

    const {accountID: airbnbAccountID, code} = useCreateAirbnbAccount("/add-listing/import/Airbnb");

    const hideModal = useCallback(() => {
        setAddAccountType(undefined);
    }, []);

    const handleAccountConnected = useCallback(
        (accountID: string) => {
            hideModal();
            history.push(`/add-listing/import/${accountID}`);
        },
        [hideModal, history]
    );

    const supportedPaths = useMemo<readonly [string, string, string]>(
        () => [path, `${path}/import/:accountType`, `${path}/import/:accountType/connect-channels`],
        [path]
    );

    if (code && !airbnbAccountID) {
        return <Loader center>Creating account...</Loader>;
    }

    return (
        <ContentWrapper fitHeight fluid>
            <Stack direction={["column", "row"]} flex={1}>
                <SideBarSteps paths={supportedPaths} />
                <VStack
                    p={4}
                    flexGrow={1}
                    // shrink doesn't work well on mobile as stack turns columns
                    // it can't expand vertically
                    flexShrink={[0, 1]}
                    overflow={["visible", "auto"]}
                >
                    <Switch>
                        <Route path={`${path}`} exact>
                            <SelectChannels />
                        </Route>
                        <Route path={`${path}/import/:accountType`}>
                            <ImportListings />
                        </Route>
                    </Switch>
                </VStack>
            </Stack>

            {addAccountType && (
                <ModalAddAccount
                    show
                    oAuthReturnUrl={
                        typeof ONBOARDING_OAUTH_RETURN_URL !== "undefined"
                            ? ONBOARDING_OAUTH_RETURN_URL
                            : `${window.location.origin}/add-listing`
                    }
                    channel={addAccountType}
                    onHide={hideModal}
                    onConnected={handleAccountConnected}
                />
            )}
        </ContentWrapper>
    );
};

export default memo(AddListing);
