import {noop} from "@hosttools/core/constant";
import {type Permissions} from "@hosttools/core/shared/utils/utils.permission";
import type {Dispatch, SetStateAction} from "react";
import {createContext} from "react";

import {type AccountCustom} from "../../models/account";
import type {Listing} from "../../models/listing";
import type {ListingGroup} from "../../models/listingGroup";
import type {Lock} from "../../models/lock";
import type {Tag} from "../../models/tag";
import type {UserRaw} from "../../models/user";
import {User} from "../../models/user";
import type {ErrorsType} from "../../utils/errors";

export interface UserContextType {
    isLoading: boolean;
    isAuthenticated: boolean;
    isLoadingAccounts: boolean;
    user: User;
    accounts: AccountCustom[];
    listings: Listing[];
    visibleListings: Listing[];
    listingGroups: ListingGroup[];
    permissions: Permissions;
    locks: Lock[];
    tags: Tag[] | undefined;
    seamToken: string;
    errors: ErrorsType;
    updateUser(): Promise<void>;
    setUser: (payload: Partial<UserRaw>) => void;
    setState: Dispatch<SetStateAction<UserContextType>>;
    logout(): Promise<void>;
}

export const defaultValue = {
    isLoading: true,
    isAuthenticated: false,
    isLoadingAccounts: false,
    user: new User({
        _id: "",
        firstName: "",
        lastName: "",
        username: "",
        subscriptionStatus: "",
        isBeta: false,
        intercomHash: "",
        trialLengthEndDate: undefined,
        createdAt: ""
    } as UserRaw),
    seamToken: "",
    accounts: [],
    listings: [],
    visibleListings: [],
    listingGroups: [],
    locks: [],
    // if tags is undefined, it means it's still loading
    // it means we have to handle the loading state in the component
    tags: undefined,
    permissions: {},
    errors: {},
    logout: () => Promise.resolve(),
    setUser: noop,
    setState: noop,
    updateUser: () => Promise.resolve()
} as UserContextType;

export const UserContext = createContext<UserContextType>(defaultValue);
