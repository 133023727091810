import {updateListWS, WebSocketContext} from "@hosttools/frontend";
import type {MessageRule} from "@hosttools/frontend/models/messageRule";
import {useCallback, useContext, useEffect, useState} from "react";

import type {FetchMessageRulesPayload} from "../services/messageRule";
import {fetchMessageRules} from "../services/messageRule";

export const useWatchMessageRule = (payload?: FetchMessageRulesPayload, loadOnMount = false) => {
    const ws = useContext(WebSocketContext);
    const [messageRules, setMessageRules] = useState<MessageRule[] | undefined>();

    const fetch = useCallback(async (payload?: FetchMessageRulesPayload) => {
        const messageRules = await fetchMessageRules(payload);
        if (messageRules) {
            setMessageRules(messageRules);
        }
    }, []);

    useEffect(() => {
        if (loadOnMount) {
            fetch(payload);
        }
    }, [loadOnMount, payload, fetch]);

    useEffect(() => {
        ws?.on<WSRoom>("messageRule", syncMessageRule);

        return () => {
            ws?.off("messageRule", syncMessageRule);
        };
    }, [ws]);

    function syncMessageRule(payload: WSPayload<MessageRule>) {
        setMessageRules(prev => {
            if (!prev) {
                return prev;
            }
            const rules = updateListWS(prev, payload);
            return rules;
        });
    }

    return [messageRules, fetch] as const;
};
