import {CogIcon, LogoutIcon} from "@hosttools/frontend";
import {Box} from "native-base";
import type {ComponentProps} from "react";
import React, {memo, useMemo} from "react";

import DropdownItemLink from "../Dropdown/DropdownItemLink";

import AvatarProfile from "./AvatarProfile";

import useMobileView from "@/client/hooks/useMobileView";

const MenuProfile: React.FC = () => {
    const isMobile = useMobileView();

    const menuDisplay: ComponentProps<typeof DropdownItemLink>[] = useMemo(
        () => [
            {Icon: CogIcon, label: "Settings", link: "/settings", type: "internal"},
            {Icon: LogoutIcon, label: "Logout", link: "/logout", type: "external", target: "_self"}
        ],
        []
    );

    return (
        <>
            {!isMobile && (
                // this must be aligned with the `DropdownItem` component
                <Box px={3} py={2}>
                    <AvatarProfile />
                </Box>
            )}
            {menuDisplay.map(menu => {
                return <DropdownItemLink key={menu.label} {...menu} />;
            })}
        </>
    );
};

export default memo(MenuProfile);
