import {useToast as useToastNB} from "native-base";
import React, {useCallback, useMemo} from "react";

import ToastAlert from "../../components/ToastAlert";
import type {Status} from "../../components/ToastAlert";
import {useLatest} from "../useLatest";

type ToastOptions = {
    placement: "top-right" | "bottom";
    render: (props: {id: string}) => React.ReactNode;
};

export const useToast = () => {
    const toast = useToastNB();

    const toastRef = useLatest(toast);

    const show = useCallback(
        (options: ToastOptions) => {
            toastRef.current.show(options);
        },
        [toastRef]
    );

    const showNotify = useCallback(
        (title: string, status: Status = "success") => {
            show({
                placement: "bottom",
                render: () => {
                    return <ToastAlert title={title} status={status} />;
                }
            });
        },
        [show]
    );

    const close = useCallback(
        (toastId: string) => {
            toastRef.current.close(toastId);
        },
        [toastRef]
    );

    const closeAll = useCallback(() => {
        toastRef.current.closeAll();
    }, [toastRef]);

    return useMemo(() => {
        return {
            success: (title: string) => showNotify(title, "success"),
            info: (title: string) => showNotify(title, "info"),
            warning: (title: string) => showNotify(title, "warning"),
            error: (title: string) => showNotify(title, "error"),
            close,
            closeAll,
            show
        };
    }, [showNotify, close, closeAll, show]);
};
