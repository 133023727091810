import type {SortDirection} from "@tanstack/react-table";
import {View, ArrowDownIcon, ArrowUpIcon, HStack, Pressable, Box} from "native-base";
import React, {useContext} from "react";

import {TableContext} from "./TableContext";

import Text from "@/client/components/Text";

const TableHeader: React.FC<{
    align?: "end" | "start" | "center";
    sortDir?: false | SortDirection;
    onPress?: (event: unknown) => void;
}> = ({children, sortDir, onPress, align, ...rest}) => {
    const {size, gridTemplateColumns} = useContext(TableContext);

    return (
        <View
            testID="table-header"
            role="columnheader"
            flex={1}
            px={size === "md" ? [5, 5, 4] : [5, 5, 3]}
            py={size === "md" ? 3 : 2}
            display={gridTemplateColumns ? "flex" : "table-cell"}
            // @ts-expect-error
            verticalAlign="middle"
            justifyContent="center"
            background="blueGray.50"
            {...rest}
        >
            {typeof onPress === "function" ? (
                <Pressable alignItems={align} onPress={onPress}>
                    <HStack alignItems="center" space={1}>
                        <Text width="max-content" variant="table" color="blueGray.400">
                            {children}
                        </Text>
                        {sortDir === "asc" ? (
                            <ArrowUpIcon size="xs" />
                        ) : sortDir === "desc" ? (
                            <ArrowDownIcon size="xs" />
                        ) : null}
                    </HStack>
                </Pressable>
            ) : (
                <Box alignItems={align}>
                    <Text variant="table" color="blueGray.400">
                        {children}
                    </Text>
                </Box>
            )}
        </View>
    );
};

export default TableHeader;
