import {type UserApiKey} from "@hosttools/core/generated/models";
import type {Endpoints} from "@hosttools/web/server/routes/routes.users.types";

import type {Account} from "../../models/account";
import {sanitizeAccount} from "../../models/account";
import {SubUser} from "../../models/subUser";
import {type UpdateStaffParams, User, type UserRaw} from "../../models/user";
import {Base} from "../base";

export type UserProfileParams = {
    id: string;
    firstName: string;
    lastName: string;
};

type ApiPayload = Omit<UserApiKey, "_id">;

type RegisterForm = {
    firstName: string;
    lastName: string;
    password: string;
    termsPrivacy: boolean;
    username: string;
};

type LoginPayload = {
    username: string;
    password: string;
};

class UserService extends Base<Endpoints> {
    async fetchUser() {
        const {data} = await this.http.get<UserRaw>("/getUser");
        return new User(data);
    }

    async fetchAccounts() {
        const {data} = await this.http.get<Account[]>("/accounts");
        return data.map(sanitizeAccount);
    }

    async fetchSeamClientToken() {
        try {
            const {data} = await this.http.get<{token: string}>("/seam/clientToken");
            return data.token;
        } catch {
            // still allow to app keep running
            return "";
        }
    }

    async deleteStaffUser(userID: string, username: string) {
        const {data} = await this.http.post("/deleteStaffUser", {
            _id: userID,
            username
        });
        return data;
    }

    async fetchSubUsers(params: Endpoints["/getUsers"][0]) {
        const data = await this.get("/getUsers", undefined, params);

        return {
            ...data,
            docs: data.docs.map(doc => new SubUser(doc))
        };
    }

    async addSubUser(params: UpdateStaffParams) {
        await this.http.post("/addStaffUser", params);
    }

    async editSubUser(params: UpdateStaffParams) {
        await this.http.post("/editStaffUser", params);
    }

    async generateAuthToken() {
        const {data} = await this.http.post<{authToken: string}>("/generateAuthToken");
        return data;
    }

    async editApiKey(payload: ApiPayload) {
        const {data} = await this.http.post("/editApiKey", payload);
        return data;
    }

    async register(payload: RegisterForm) {
        const {data} = await this.http.post("/register", payload);
        return data;
    }

    async login(payload: LoginPayload) {
        const {data} = await this.http.post("/login", payload);
        return data;
    }

    async forgotPassword(username: string) {
        const {data} = await this.http.post("/forgot", {username});
        return data;
    }

    async resetPassword(resetPasswordToken: string, password: string) {
        const {data} = await this.http.post("/reset", {resetPasswordToken, password});
        return data;
    }
}

export default UserService;
