import {isReactElement} from "@hosttools/frontend";
import {Box, useBreakpointValue} from "native-base";
import React, {useContext} from "react";

import {TableContext} from "./TableContext";

import Text from "@/client/components/Text";

type VerticalAlign = "top" | "middle" | "bottom";

const TableCell: React.FC<{
    align?: "start" | "end" | "center";
    verticalAlign?: VerticalAlign | VerticalAlign[];
    visibleOnHover?: boolean;
}> = ({children, align = "start", verticalAlign = "middle", visibleOnHover = false, ...rest}) => {
    const {size, gridTemplateColumns} = useContext(TableContext);
    const verticalAlignValue = useBreakpointValue(
        Array.isArray(verticalAlign) ? verticalAlign : [verticalAlign]
    );

    return (
        <Box
            px={size === "md" ? [5, 5, 4] : [5, 5, 3]}
            py={size === "md" ? 4 : 3}
            flex={1}
            display={gridTemplateColumns ? "flex" : "table-cell"}
            role="cell"
            height="full"
            // this prop is still valid even if `nativebase` is not supported so far
            // @ts-expect-error
            verticalAlign={verticalAlignValue}
            borderBottomWidth={1}
            borderBottomStyle="solid"
            borderBottomColor="blueGray.200"
            justifyContent={
                verticalAlignValue === "middle"
                    ? "center"
                    : verticalAlignValue === "bottom"
                    ? "flex-end"
                    : undefined
            }
            {...rest}
        >
            {typeof children === "string" ||
            typeof children === "number" ||
            isReactElement(children, React.Fragment) ? (
                <Text
                    alignSelf={align}
                    variant={size === "md" ? "sm" : "xs"}
                    maxW="100%"
                    isTruncated
                >
                    {children}
                </Text>
            ) : (
                <Box
                    testID={visibleOnHover ? "content-visible-on-hover" : "content-visible"}
                    alignItems={align}
                >
                    {children}
                </Box>
            )}
        </Box>
    );
};

export default TableCell;
