import {type CellContext} from "@tanstack/react-table";
import React, {memo} from "react";

import Text from "../Text";

const TableCellTextColumn: React.FC<CellContext<any, string>> = ({getValue}) => {
    return (
        <Text variant="sm" isTruncated>
            {getValue()}
        </Text>
    );
};

export default memo(TableCellTextColumn);
