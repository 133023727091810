import moment from "moment-timezone";

import type {Languages, ReservationDisplaySource} from "../../constant";
import {providerChannels} from "../../constant";
import type {Listing, Lock, ReservationCustom} from "../../generated/models";
import type {ReservationDocument} from "../../models/user.account.listing.reservation";
import {Channel} from "../model/channel";
import type {Reservation} from "../model/reservation";

import {getListingTimezone} from "./utils.listing";

export const getSource = <T extends Pick<Reservation, "source">>(
    reservation: T
): ReservationDisplaySource => {
    const {source} = reservation;
    return source === "HomeAway" ? "VRBO" : source;
};

export const getFullName = <
    T extends {custom?: Pick<ReservationCustom, "firstName" | "lastName">} & Pick<
        Reservation,
        "airbnbFirstName" | "airbnbLastName"
    >
>(
    reservation: T
): string => {
    const {custom, airbnbFirstName, airbnbLastName} = reservation;
    let fullName = "";
    if (airbnbFirstName) {
        fullName = airbnbFirstName;
        if (airbnbLastName) {
            fullName += ` ${airbnbLastName}`;
        }
    }
    if (custom?.firstName) {
        fullName = custom.firstName;
        if (custom?.lastName) {
            fullName += ` ${custom.lastName}`;
        }
    }
    return fullName;
};

export const getFirstName = (
    reservation: Pick<ReservationDocument | Reservation, "custom" | "airbnbFirstName">
): string => {
    return reservation.custom?.firstName || reservation.airbnbFirstName || "";
};

export const getLastName = (
    reservation: Pick<ReservationDocument | Reservation, "custom" | "airbnbLastName">
): string => {
    return reservation.custom?.lastName || reservation.airbnbLastName || "";
};

export const isExternalReservation = (
    reservation: Pick<ReservationDocument | Reservation, "source">
): boolean => {
    return [Channel.Airbnb, Channel.Booking, Channel.HomeAway, Channel.Houfy].includes(
        reservation.source as Channel
    );
};

export type ChannelReservation<
    T extends {source: Reservation["source"]; accountID?: Reservation["accountID"]}
> = Omit<T, "accountID" | "source"> & {
    source: Exclude<T["source"], "internal">;
    accountID: RequiredKeys<T, "accountID">;
};

export const isChannelReservation = <
    // this can accept this form of reservation type
    T extends {source: Reservation["source"]; accountID?: Reservation["accountID"]}
>(
    reservation: any
): reservation is ChannelReservation<T> => providerChannels.includes(reservation?.source);

export const isInternalReservation = (channel: Reservation["source"]): channel is "internal" =>
    channel === "internal";

export const getEmail = (
    reservation: Pick<ReservationDocument | Reservation, "custom" | "airbnbEmail">
): string => {
    return reservation.custom?.email || reservation.airbnbEmail || "";
};

export const getPhone = (
    reservation: Pick<ReservationDocument | Reservation, "custom" | "airbnbPhone">
): string => {
    return reservation.custom?.phone || reservation.airbnbPhone || "";
};

export const getLockCode = (
    reservation: Pick<Reservation, "_id" | "custom" | "airbnbPhone" | "createdAt">,
    pinLength: number,
    lock?: Lock
): string => {
    let phoneNumber = getPhone(reservation);

    if (!phoneNumber && reservation.createdAt) {
        // if the reservation is missing a phone number, use the date it was created to generate a pin
        phoneNumber = new Date(reservation.createdAt).getTime().toString();
    } else if (!phoneNumber) {
        // if the reservation is missing a phone number, use the _id to generate a pin
        phoneNumber = reservation._id.toString();
    }
    phoneNumber = phoneNumber.replace(/[^0-9]/g, "");
    // Make sure there is at least X numbers in the phone number
    if (phoneNumber.length < pinLength) {
        return "";
    }
    let pin = phoneNumber.substring(phoneNumber.length - pinLength);

    // rules of `nuki` lock
    // https://docs.seam.co/latest/device-guides/get-started-with-nuki-locks#5-setting-access-code-on-nuki-lock
    if (lock?.manufacturer === "nuki") {
        pin = pin.replace(/0/g, "1");
        pin = pin.replace(/^12/, "13");
    }

    return pin;
};

export const getNotes = (
    reservation: Pick<ReservationDocument | Reservation, "custom">
): string => {
    return reservation.custom?.notes || "";
};

export const getPreferLanguage = (
    reservation: Pick<ReservationDocument | Reservation, "custom" | "airbnbPreferredLocale">
): Languages | "default" | undefined => {
    return reservation.custom?.preferredLocale || reservation.airbnbPreferredLocale || "default";
};

export const getCheckInTime = (
    reservation: {custom?: Pick<ReservationCustom, "checkInTime">},
    listing: Pick<Listing, "airbnbCheckInTime">
): number | undefined => {
    return reservation.custom?.checkInTime ?? listing.airbnbCheckInTime;
};

export const getCheckOutTime = (
    reservation: {custom?: Pick<ReservationCustom, "checkOutTime">},
    listing: Pick<Listing, "airbnbCheckOutTime">
): number | undefined => {
    return reservation.custom?.checkOutTime ?? listing.airbnbCheckOutTime;
};

export function getCheckInDate(
    reservation: Pick<Reservation, "custom" | "startDate">,
    listing: {
        airbnbCheckInTime?: Listing["airbnbCheckInTime"];
        timeZone?: Listing["timeZone"];
    }
) {
    const {startDate} = reservation;
    const checkInTime = getCheckInTime(reservation, listing);
    const checkInDate = moment
        .tz(startDate, getListingTimezone(listing))
        .startOf("day")
        // Date is sent as UTC so remove day light savings
        // .add(moment.tz(startDate, timeZone).isDST() ? 1 : 0, "hours")
        .add(checkInTime, "hour")
        .toDate();

    return checkInDate;
}

export function getCheckOutDate(
    reservation: Pick<Reservation, "custom" | "endDate">,
    listing: {
        airbnbCheckOutTime?: Listing["airbnbCheckOutTime"];
        timeZone?: Listing["timeZone"];
    }
) {
    const {endDate} = reservation;
    const checkOutTime = getCheckOutTime(reservation, listing);
    const checkOutDate = moment
        .tz(endDate, getListingTimezone(listing))
        .startOf("day")
        // Date is sent as UTC so remove day light savings
        // .add(moment.tz(endDate, timeZone).isDST() ? 1 : 0, "hours")
        .add(checkOutTime, "hour")
        .toDate();

    return checkOutDate;
}

export const getGuests = (
    reservation: Pick<Reservation, "airbnbNumberOfGuests">
): number | undefined => {
    return reservation.airbnbNumberOfGuests;
};

export const getNights = (reservation: Pick<Reservation, "airbnbNights">): number | undefined => {
    return reservation.airbnbNights;
};

export const getThumbnail = (
    reservation: Pick<Reservation, "airbnbThumbnailUrl">
): string | undefined => {
    return reservation.airbnbThumbnailUrl;
};

export const getGuestPrice = (reservation: Pick<Reservation, "guestPrice">) => {
    return reservation.guestPrice ? reservation.guestPrice.toFixed(2) : "";
};

export const getHostPayout = (reservation: Pick<Reservation, "hostPayout">) => {
    return reservation.hostPayout ? reservation.hostPayout.toFixed(2) : "";
};

export const getGuestPriceWithCurrency = (
    reservation: Pick<Reservation, "guestPrice">,
    currencySymbol: string
) => {
    return reservation.guestPrice ? currencySymbol + getGuestPrice(reservation) : "";
};

export const getHostPayoutWithCurrency = (
    reservation: Pick<Reservation, "hostPayout">,
    currencySymbol: string
) => {
    return reservation.hostPayout ? currencySymbol + getHostPayout(reservation) : "";
};

export const sanitize = <T extends {startDate?: string | Date; endDate?: string | Date}>(
    reservation: T
) => {
    const {startDate, endDate} = reservation;
    if (!startDate || !endDate) {
        return reservation;
    }

    if (new Date(endDate) < new Date(startDate)) {
        reservation.startDate = endDate;
        reservation.endDate = startDate;
    }
    return reservation;
};

export const getExternalUrl = (
    reservation: Pick<
        Reservation,
        | "source"
        | "airbnbConfirmationCode"
        | "airbnbListingID"
        | "airbnbThreadID"
        | "houfyReservationID"
    >
) => {
    switch (reservation.source) {
        case Channel.Airbnb:
            return `https://www.airbnb.com/messaging/qt_for_reservation/${reservation.airbnbConfirmationCode}`;
        case Channel.Booking:
            return "https://admin.booking.com/";
        case Channel.HomeAway:
            return `https://www.homeaway.com/rm/${reservation.airbnbListingID}/conversation/${reservation.airbnbThreadID}`;
        case Channel.Houfy:
            return `https://houfy.com/reservation/${reservation.houfyReservationID}`;
        default:
            return "";
    }
};
