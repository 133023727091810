import replace from "./replace";

function updateListWS<T extends {_id: string}>(
    list: T[],
    payload: WSPayload<T>,
    modifyPropsFn?: (elem: T) => Partial<T>
): T[] {
    if (payload.event === "update") {
        const arr = replace<T>(list, elem => elem._id === payload.document._id, {
            ...payload.document,
            ...modifyPropsFn?.(payload.document)
        });

        if (arr) {
            return arr;
        }
    } else if (payload.event === "insert") {
        return [
            ...list,
            {
                ...payload.document,
                ...modifyPropsFn?.(payload.document)
            }
        ];
    } else if (payload.event === "delete") {
        return list.filter(elem => elem._id !== payload.document._id);
    }

    return list;
}

export default updateListWS;
