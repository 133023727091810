import {parseISO, formatDistance, isAfter} from "date-fns";

import type {Timeline} from "../../../models/timeline";

const userTimelineSendTimeDistance = (timeline: Timeline | undefined) => {
    const sendDate = timeline?.sendDate;
    if (!sendDate) {
        return;
    }

    const today = new Date();
    const sendDateInDate = parseISO(sendDate);
    const isPast = isAfter(today, sendDateInDate);
    const durationFormatted = formatDistance(today, sendDateInDate, {
        includeSeconds: false
    });

    return isPast ? `Should have sent ${durationFormatted} ago` : `Sends in ${durationFormatted}`;
};

export default userTimelineSendTimeDistance;
