export default {
    primary: {
        50: "#eff6ff",
        100: "#dbeafe",
        200: "#bfdbfe",
        300: "#93c5fd",
        400: "#60a5fa",
        500: "#3b82f6",
        600: "#2563eb",
        700: "#1d4ed8",
        800: "#1e40af",
        900: "#1e3a8a"
    },
    Airbnb: {
        50: "#FFDFE5",
        100: "#FFDFE5", // background event
        200: "#FF8A9F", // border event
        300: "#FF385C", // text color event
        400: "#FF385C", // avatar color event
        500: "#FF385C",
        600: "#FF385C",
        700: "#FF385C",
        800: "#FF385C",
        900: "#FF385C"
    },
    Booking: {
        50: "#DAE9FF",
        100: "#DAE9FF", // background event
        200: "#76ABFB", // border event,
        300: "#003B95", // text color event
        400: "#003B95", // avatar color event
        500: "#003B95",
        600: "#003B95",
        700: "#003B95",
        800: "#003B95",
        900: "#003B95"
    },
    Houfy: {
        50: "#D3E2EC",
        100: "#D3E2EC", // background event
        200: "#789EB9", // border event,
        300: "#153D59", // text color event
        400: "#153D59", // avatar color event
        500: "#153D59",
        600: "#153D59",
        700: "#153D59",
        800: "#153D59",
        900: "#153D59"
    },
    HomeAway: {
        50: "#E4EEFF",
        100: "#E4EEFF", // background event
        200: "#779CDF", // border event,
        300: "#245ABC", // text color event
        400: "#245ABC", // avatar color event
        500: "#245ABC",
        600: "#245ABC",
        700: "#245ABC",
        800: "#245ABC",
        900: "#245ABC"
    },
    internal: {
        50: "#E0FFFD",
        100: "#E0FFFD", // background event
        200: "#7EEBE5", // border event,
        300: "#18827C", // text color event
        400: "#36BDB6", // avatar color event
        500: "#18827C",
        600: "#18827C",
        700: "#18827C",
        800: "#18827C",
        900: "#18827C"
    },
    icalendar: {
        50: "#DBEAFE",
        100: "#DBEAFE", // background event
        200: "#60A5FA", // border event,
        300: "#2563EB", // text color event
        400: "#2563EB", // avatar color event
        500: "#2563EB",
        600: "#2563EB",
        700: "#2563EB",
        800: "#2563EB",
        900: "#2563EB"
    }
};
