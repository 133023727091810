import {Text as TextNB, type ITextProps} from "native-base";
import React, {memo, useMemo} from "react";

import {type SupportedColors} from "../Text";

type Variants = "2xs" | "xs" | "sm" | "md" | "lg";

export type Props = Omit<
    ITextProps,
    "size" | "fontSize" | "fontWeight" | "lineHeight" | "letterSpacing"
> & {
    variant: Variants;
    color?: SupportedColors;
};

const Paragraph: React.FC<Props> = ({variant, children, ...rest}) => {
    const variants = useMemo(() => {
        return {
            "2xs": {
                size: "2xs",
                fontWeight: 400,
                lineHeight: "sm"
            },
            xs: {
                size: "xs",
                fontWeight: 400,
                lineHeight: "sm"
            },
            sm: {
                size: "sm",
                fontWeight: 400,
                lineHeight: "sm"
            },
            md: {
                size: "md",
                fontWeight: 400,
                lineHeight: "sm"
            },
            lg: {
                size: "lg",
                fontWeight: 400,
                lineHeight: "sm"
            }
        } satisfies Record<Variants, ITextProps>;
    }, []);

    return (
        <TextNB {...rest} fontFamily="paragraph" {...variants[variant]}>
            {children}
        </TextNB>
    );
};

export default memo(Paragraph);
