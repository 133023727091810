import {UserContext, usePrevious} from "@hosttools/frontend";
import gravatar from "gravatar";
import {
    Image,
    Modal,
    VStack,
    ChevronRightIcon,
    HStack,
    ChevronLeftIcon,
    Pressable,
    Box
} from "native-base";
import React, {memo, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useLocation} from "react-router-dom";

import Avatar from "../Avatar";
import MenuItem from "../MenuItem";
import ListingsMenu from "../NavMessaging/ListingsMenu";
import MessasingMenu from "../NavMessaging/MessagingMenu";
import Text from "../Text";

import AvatarProfile from "./AvatarProfile";
import MenuProfile from "./MenuProfile";

import {INBOX_URL, MULTICALENDAR_PATH} from "@/admin/constant";
import logoBlack from "@/admin/img/logo-black.svg";

type Props = {
    isOpen: boolean;
    onHide: () => void;
};

type MenuDisplay = "messaging" | "pricing" | "main" | "profile";

const logoSource = {uri: logoBlack};

const ModalMenuMobile: React.FC<Props> = ({isOpen, onHide}) => {
    const {
        user: {fullName, username},
        visibleListings,
        listingGroups
    } = useContext(UserContext);
    const location = useLocation();
    const prevPathName = usePrevious(location.pathname);
    const [menuDisplay, setMenuDisplay] = useState<MenuDisplay>("main");

    const userImage = useMemo(
        () => gravatar.url(username, {s: "120", r: "pg", d: "mp"}),
        [username]
    );

    const userImageUri = useMemo(() => ({uri: userImage}), [userImage]);

    const handleClickPricing = useCallback(() => {
        setMenuDisplay("pricing");
    }, []);

    const handleClickMessaging = useCallback(() => {
        setMenuDisplay("messaging");
    }, []);

    const handleClickProfile = useCallback(() => {
        setMenuDisplay("profile");
    }, []);

    const handleBack = useCallback(() => {
        setMenuDisplay("main");
    }, []);

    useEffect(() => {
        if (prevPathName !== location.pathname) {
            onHide();
        }
    }, [location.pathname, onHide, prevPathName]);

    return (
        <Modal isOpen={isOpen} onClose={onHide} size="xl">
            <Modal.Content maxH="600px" marginBottom="auto" marginTop={10}>
                <Modal.CloseButton />
                <Modal.Header testID="modal-header" borderBottomWidth={0}>
                    {menuDisplay === "main" && (
                        <Image source={logoSource} height={22} width={110} alt="Host Tools Logo" />
                    )}
                    {menuDisplay !== "main" && (
                        <Pressable
                            flexDir="row"
                            alignItems="center"
                            variant="ghost"
                            style={{gap: 6}}
                            onPress={handleBack}
                        >
                            <ChevronLeftIcon
                                color={menuDisplay === "profile" ? "blueGray.600" : "primary.600"}
                            />
                            <Text variant="md" color="blue.600">
                                {menuDisplay === "messaging" && "Messaging"}
                                {menuDisplay === "pricing" && "Pricing"}
                            </Text>
                            {menuDisplay === "profile" && <AvatarProfile />}
                        </Pressable>
                    )}
                </Modal.Header>
                <Box
                    mx={4}
                    my={1}
                    borderTopColor="blueGray.300"
                    borderTopStyle="dashed"
                    borderTopWidth={1}
                />
                <Modal.Body testID="modal-body">
                    {menuDisplay === "main" && (
                        <VStack space={6}>
                            <MenuItem path={MULTICALENDAR_PATH} onClick={onHide}>
                                Calendar
                            </MenuItem>
                            <MenuItem path={INBOX_URL} onClick={onHide}>
                                Inbox
                            </MenuItem>
                            <MenuItem
                                onClick={handleClickMessaging}
                                icon={<ChevronRightIcon color="blueGray.400" />}
                            >
                                Messaging
                            </MenuItem>
                            <MenuItem
                                onClick={handleClickPricing}
                                icon={<ChevronRightIcon color="blueGray.400" />}
                            >
                                Pricing
                            </MenuItem>
                            <Pressable
                                flexDir="row"
                                alignItems="center"
                                justifyContent="space-between"
                                bg="blueGray.100"
                                borderRadius={4}
                                p={2}
                                onPress={handleClickProfile}
                            >
                                <HStack space={2} alignItems="center">
                                    <Avatar
                                        name={fullName}
                                        source={userImageUri}
                                        size="sm"
                                        bg="cyan.600"
                                        borderWidth={1}
                                        borderStyle="solid"
                                        borderColor="white"
                                    />
                                    <Text variant="sm" color="blueGray.800">
                                        {fullName}
                                    </Text>
                                </HStack>
                                <ChevronRightIcon color="blueGray.400" />
                            </Pressable>
                        </VStack>
                    )}
                    {menuDisplay === "pricing" && (
                        <ListingsMenu listings={visibleListings} to="pricing" />
                    )}
                    {menuDisplay === "messaging" && (
                        <MessasingMenu
                            listingGroups={listingGroups}
                            visibleListings={visibleListings}
                        />
                    )}
                    {menuDisplay === "profile" && <MenuProfile />}
                </Modal.Body>
            </Modal.Content>
        </Modal>
    );
};

export default memo(ModalMenuMobile);
