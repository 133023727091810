import {mergeRefs, Text as TextNB, type ITextProps} from "native-base";
import React, {memo, useEffect, useMemo, useRef} from "react";
import {Platform} from "react-native";

import {useEllipsisVisible} from "../../hooks/useEllipsisVisible";

export type Variants = "2xs" | "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "table";
export type SupportedColors = Extract<
    ITextProps["color"],
    | "success.600"
    | "warning.600"
    | "danger.600"
    | "red.600"
    | "blueGray.400"
    // | "blueGray.600" // Default
    | "blueGray.800"
    | "blue.300"
    | "blue.600"
    | "blue.900"
    | "white"
    | "HomeAway.300"
    | "Airbnb.300"
    | "Booking.300"
    | "Houfy.300"
    | "HomeAway.300"
    | "internal.300"
>;

export type TextProps = Omit<
    ITextProps,
    "size" | "fontSize" | "fontWeight" | "lineHeight" | "letterSpacing" | "color"
> & {
    variant: Variants;
    color?: SupportedColors;
    tooltipAsTruncated?: boolean;
};

const Text: React.FC<TextProps> = ({
    variant,
    children,
    tooltipAsTruncated,
    numberOfLines,
    ...rest
}) => {
    const textRef = useRef<HTMLDivElement>(null);
    const [isEllipsisVisible, ellipsisVisibleRef] = useEllipsisVisible(
        !tooltipAsTruncated,
        numberOfLines
    );

    const variants = useMemo(() => {
        return {
            "2xs": {
                size: "2xs",
                fontWeight: "medium",
                lineHeight: "sm",
                fontFamily: "body"
            },
            xs: {
                size: "xs",
                fontWeight: "medium",
                lineHeight: "sm",
                fontFamily: "body"
            },
            sm: {
                size: "sm",
                fontWeight: "medium",
                lineHeight: "sm",
                fontFamily: "body"
            },
            md: {
                size: "md",
                fontWeight: "medium",
                lineHeight: "sm",
                fontFamily: "body"
            },
            lg: {
                size: "lg",
                fontWeight: "medium",
                lineHeight: "sm",
                fontFamily: "body"
            },
            xl: {
                size: "xl",
                fontWeight: "medium",
                lineHeight: "sm",
                fontFamily: "body"
            },
            "2xl": {
                size: "2xl",
                fontWeight: "medium",
                lineHeight: "sm",
                fontFamily: "body"
            },
            "3xl": {
                size: "3xl",
                fontWeight: "medium",
                lineHeight: "sm",
                fontFamily: "body"
            },
            table: {
                size: "xs",
                fontWeight: 600,
                letterSpacing: "2xl",
                textTransform: "uppercase",
                fontFamily: "paragraph"
            }
        } satisfies Record<Variants, ITextProps>;
    }, []);

    useEffect(() => {
        if (Platform.OS === "web" && tooltipAsTruncated && isEllipsisVisible && textRef.current) {
            textRef.current.title = textRef.current.textContent ?? "";
        }
    }, [tooltipAsTruncated, isEllipsisVisible]);

    return (
        <TextNB
            ref={mergeRefs([textRef, ellipsisVisibleRef])}
            numberOfLines={numberOfLines}
            {...rest}
            {...variants[variant]}
        >
            {children}
        </TextNB>
    );
};

export default memo(Text);
