import {CloseIcon, Pressable, SearchIcon, mergeRefs} from "native-base";
import React, {
    memo,
    useMemo,
    useCallback,
    useRef,
    forwardRef,
    type ForwardRefRenderFunction,
    useEffect
} from "react";

import Input from "../Input";

export type Props = {
    query: string;
    testID?: string;
    placeholder?: string;
    onChangeText: (text: string) => void;
    onClear: () => void;
    onSearch?: (text: string) => void;
    onBlur?: () => void;
};

const SearchInput: ForwardRefRenderFunction<HTMLInputElement, Props> = (
    {query, testID, placeholder = "Search", onSearch, onChangeText, onClear, onBlur},
    inputRef
) => {
    const innerRef = useRef<HTMLInputElement>(null);

    const handleKeyPress = useCallback(
        (event: KeyboardEvent) => {
            event.stopPropagation();
            if (event.key === "Enter") {
                event.preventDefault();
                onSearch?.(query);
            }
        },
        [query, onSearch]
    );

    const onClearInput = useCallback(() => {
        onClear();
        innerRef.current?.focus();
    }, [onClear]);

    const handleFocus = useCallback(() => {
        const inputEl = innerRef.current;
        inputEl?.setSelectionRange(query.length, query.length);
    }, [query]);

    const inputLeftElement = useMemo(() => <SearchIcon color="blueGray.400" />, []);
    const inputRightElement = useMemo(
        () =>
            query ? (
                <Pressable onPress={onClearInput}>
                    <CloseIcon size="xs" color="blueGray.400" mr={3} />
                </Pressable>
            ) : undefined,
        [onClearInput, query]
    );

    useEffect(() => {
        const inputEl = innerRef.current;
        inputEl?.addEventListener("keydown", handleKeyPress);
        return () => {
            inputEl?.removeEventListener("keydown", handleKeyPress);
        };
    }, [handleKeyPress]);

    return (
        // adding `form` helps to avoid the autofill somehow
        // setting `autoComplete="off"` doesn't work somehow
        <form action="#" role="search">
            <Input
                testID={testID}
                ref={mergeRefs([innerRef, ...(inputRef ? [inputRef] : [])])}
                variant="outlined"
                placeholder={placeholder}
                size="md"
                value={query}
                autoComplete="off"
                onChangeText={onChangeText}
                onBlur={onBlur}
                onFocus={handleFocus}
                InputLeftElement={inputLeftElement}
                InputRightElement={inputRightElement}
            />
        </form>
    );
};

export default memo(forwardRef<HTMLInputElement, Props>(SearchInput));
