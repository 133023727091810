import {Box, type IBoxProps} from "native-base";
import React, {forwardRef, memo} from "react";

import useMobileView from "@/client/hooks/useMobileView";

const CONTAINER_WIDTH = 1440;

export interface Props {
    fluid?: boolean;
    fitHeight?: boolean;
    px?: number;
    py?: number;
    background?: IBoxProps["bg"];
    children: React.ReactNode;
}

const ContentWrapper = (
    {children, fluid = false, fitHeight = false, px = 2, py = 0, background}: Props,
    ref: React.ForwardedRef<HTMLDivElement>
) => {
    const isMobile = useMobileView();

    return (
        <Box
            testID="box-content-wrapper"
            ref={ref}
            flexGrow={fitHeight ? 1 : 0}
            flexShrink={fitHeight ? 1 : 0}
            // `react-select` needs to be visible in case of tight view
            overflowY={fitHeight ? "auto" : "visible"}
            // overflowY="auto"
            // `auto` breaks `flex: 1` so that it can scroll nicely on mobile
            minHeight={["auto", 0]}
            background={background}
            px={isMobile ? 0 : px}
            py={py}
            {...(!fluid
                ? {
                      maxWidth: CONTAINER_WIDTH,
                      marginX: "auto",
                      width: "100%"
                  }
                : {
                      width: "100%"
                  })}
        >
            {children}
        </Box>
    );
};

ContentWrapper.displayName = "ContentWrapper";

const FowardRefComponent = forwardRef<HTMLDivElement, Props>(ContentWrapper);

FowardRefComponent.displayName = `forwardedRef(${ContentWrapper.displayName})`;

export default memo(FowardRefComponent);
