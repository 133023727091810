import {memoWithType} from "@hosttools/frontend";
import type {Column, FilterOption, Table} from "@tanstack/react-table";
import {type IBoxProps, Box, CheckIcon} from "native-base";
import React, {useMemo, useCallback, useState} from "react";
import type {GroupTypeBase, NamedProps} from "react-select";

import type {OptionType} from "../Select";
import Select from "../Select";

import Text from "@/client/components/Text";

interface Props<T> {
    table: Table<T>;
    column: Column<T, unknown>;
    name?: string;
    style?: IBoxProps;
    isMulti?: boolean;
    isOptionDisabled?: NamedProps["isOptionDisabled"];
}

const defaultStyle = {
    minWidth: 48,
    maxWidth: ["unset", 64]
};

const FilterSelect = <T,>({
    table,
    column,
    name,
    style = defaultStyle,
    isMulti,
    isOptionDisabled
}: Props<T>) => {
    if (column.columnDef.filter?.type !== "select") {
        throw new Error("Wrong type set!");
    }
    const filterOptions = column.columnDef.filter?.options;
    const headerName = column.columnDef.header;
    const [selectedOptions, setSelectedOptions] = useState<OptionType<string>[]>([]);
    const selectedValues = useMemo(
        () => selectedOptions.map(option => option.value),
        [selectedOptions]
    );

    const transformOption = useCallback(
        (option: FilterOption) => {
            return {
                value: option.value,
                label: option.label,
                Icon: selectedValues.includes(option.value) ? <CheckIcon size="sm" /> : <></>,
                prefix: (
                    <Text variant="sm" color="blueGray.800">
                        {`${name || headerName}: `}
                    </Text>
                )
            };
        },
        [headerName, name, selectedValues]
    );

    const options: Array<OptionType<string> | GroupTypeBase<OptionType<string>>> = useMemo(
        () =>
            filterOptions
                ? filterOptions.map(option => {
                      if ("value" in option) {
                          return transformOption(option);
                      }

                      option.options = option.options.map(transformOption);

                      return option;
                  })
                : [],
        [filterOptions, transformOption]
    );

    const handleChange = useCallback(
        (option: OptionType<string> | null) => {
            if (option) {
                setSelectedOptions(prev => {
                    table.setPageIndex(0);
                    if (isMulti) {
                        let newSelectedOption: OptionType<string>[];
                        const prevValues = prev.map(p => p.value);
                        if (prevValues.includes(option.value)) {
                            newSelectedOption = prev.filter(p => p.value !== option.value);
                        } else {
                            newSelectedOption = [...prev, option];
                        }

                        if (newSelectedOption.length > 1) {
                            const firstOption = newSelectedOption[0];
                            firstOption.suffix = `${
                                newSelectedOption.length > 1
                                    ? `(+${newSelectedOption.length - 1})`
                                    : ""
                            }`;
                        } else if (newSelectedOption.length > 0) {
                            newSelectedOption[0].suffix = "";
                        }

                        column.setFilterValue(newSelectedOption.map(o => o.value));

                        return newSelectedOption;
                    }
                    if (option?.value !== prev[0]?.value) {
                        column.setFilterValue(option?.value);
                        return [option];
                    }
                    column.setFilterValue(null);
                    return [];
                });
            }
        },
        [column, isMulti, table]
    );

    return (
        <Box zIndex="auto" {...style}>
            <Select
                variant="outline"
                placeholder={`${name || headerName}: None selected`}
                value={selectedOptions.length > 0 ? selectedOptions[0] : null}
                options={options}
                isClearable={false}
                isSearchable
                onChange={handleChange}
                isOptionDisabled={isOptionDisabled}
            />
        </Box>
    );
};

export default memoWithType(FilterSelect);
