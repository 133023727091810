import {HStack, View} from "native-base";
import React, {useEffect, useRef} from "react";
import {components} from "react-select";
import type {Components} from "react-select/src/components";

import Text from "@/client/components/Text";

export const Option: Components["Option"] = ({children, ...props}) => {
    const {
        data: {Icon},
        isDisabled
    } = props;
    const elementRef = useRef<HTMLDivElement>();

    useEffect(() => {
        if (props.isSelected && !props.selectProps.isMulti) {
            elementRef.current?.scrollIntoView({block: "center"});
        }
    }, [props.isSelected, props.selectProps.isMulti]);

    return (
        <components.Option {...props}>
            <HStack
                ref={elementRef}
                space={1}
                alignItems="center"
                opacity={isDisabled ? 50 : 100}
                justifyContent="space-between"
            >
                <Text variant="xs" isTruncated>
                    {children}
                </Text>
                <View flexShrink={0} flexGrow={0}>
                    {Icon}
                </View>
            </HStack>
        </components.Option>
    );
};
