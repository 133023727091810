import {useBreakpointValue} from "native-base";
import type React from "react";
import {memo, useEffect, useContext} from "react";

import {AppContext} from "@/client/provider/AppProvider";

const DetectScreenSize: React.FC = () => {
    const {setIsMobile} = useContext(AppContext);
    const isMobile = useBreakpointValue({base: true, md: false});

    useEffect(() => {
        setIsMobile(isMobile);
    }, [isMobile, setIsMobile]);

    return null;
};

export default memo(DetectScreenSize);
