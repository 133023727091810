import React from "react";
import {components} from "react-select";
import type {MultiValueProps} from "react-select";

import type {OptionType} from "./Select";

import Text from "@/client/components/Text";

export const MultiValueLabel: React.FC<MultiValueProps<OptionType>> = ({children, ...props}) => {
    const {data, selectProps} = props;

    return (
        <components.MultiValueLabel data={data} selectProps={selectProps} innerProps={{}}>
            <Text variant="xs" color={data.isFixed ? "white" : "blueGray.800"} isTruncated>
                {children}
            </Text>
        </components.MultiValueLabel>
    );
};
