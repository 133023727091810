import {originalChannels} from "@hosttools/core/constant";
import {type AccountType} from "@hosttools/frontend/models/account";
import {type Listing} from "@hosttools/frontend/models/listing";
import {buildChannelConnectorURL} from "@hosttools/frontend/utils/account";
import {Divider, HStack, VStack} from "native-base";
import React, {memo, useCallback, useMemo, useState, type FC} from "react";
import {useHistory, useRouteMatch} from "react-router-dom";

import HostToolPolicy from "../ModalAddAccount/HosttoolPolicy";

import ChannelItem from "./ChannelItem";

import ModalAddAccount from "@/admin/components/ModalAddAccount";
import Modal from "@/client/components/Modal";
import Text from "@/client/components/Text";

interface Props {
    listing: Listing;
    onClose: () => void;
    onToggleChannel?: (listing: Listing, accountType: AccountType, isConnected: boolean) => void;
}

const ModalConnectChannels: FC<Props> = ({listing, onClose, onToggleChannel}) => {
    const [addAccountType, setAddAccountType] = useState<AccountType>();
    const [selectedChannel, setSelectedChannel] = useState<AccountType>();
    const history = useHistory();
    const {url} = useRouteMatch();

    const connectUrlMaker = useCallback(
        (type: AccountType) => {
            if (type === "ChannelConnector") {
                return `/settings/listings/channel-connector/${listing._id}`;
            }
            return `/connect/${listing._id}/${type}?returnUrl=${url}`;
        },
        [url, listing]
    );

    const returnUrl = useMemo(() => {
        if (!addAccountType) {
            // warn
            return;
        }
        // default will redirect to connect page with current page as return url
        return connectUrlMaker(addAccountType);
    }, [addAccountType, connectUrlMaker]);

    const handleToggleChannel = useCallback(
        async (listing: Listing, accountType: AccountType, isConnected: boolean) => {
            if (accountType === "ChannelConnector" && isConnected) {
                const url = buildChannelConnectorURL(listing.channels.ChannelConnector.accountID);
                history.push(url);
                return;
            }
            setSelectedChannel(accountType);
            if (onToggleChannel) {
                onToggleChannel(listing, accountType, isConnected);
            } else if (!isConnected) {
                // use default behavior
                const connectPath = connectUrlMaker(accountType);
                if (connectPath) {
                    history.push(connectPath);
                }
            }
        },
        [history, onToggleChannel, connectUrlMaker]
    );

    const hideModal = useCallback(() => {
        setAddAccountType(undefined);
    }, []);

    if (addAccountType) {
        // this would end up navigate away from our page so keep everything on return url
        // set redirect url here for Airbnb
        // call the callback after this is done
        return (
            <ModalAddAccount
                show
                oAuthReturnUrl={
                    typeof ONBOARDING_OAUTH_RETURN_URL !== "undefined"
                        ? ONBOARDING_OAUTH_RETURN_URL
                        : `${window.location.origin}/add-listing`
                }
                oAuthStateParameter={returnUrl}
                channel={addAccountType}
                onHide={hideModal}
            />
        );
    }

    return (
        <Modal isOpen title="Connect Channels" subTitle={<HostToolPolicy />} onClose={onClose}>
            <VStack space={6} px={[5, 5, 0]}>
                <VStack>
                    {originalChannels.map((elem, idx, original) => (
                        <ChannelItem
                            key={elem}
                            listing={listing}
                            accountType={elem}
                            isConnected={!!listing.channels[elem]}
                            isDisabled={!!listing.channels[elem]}
                            isLoading={selectedChannel === elem}
                            position={
                                idx === 0
                                    ? "first"
                                    : idx === original.length - 1
                                    ? "last"
                                    : "middle"
                            }
                            onToggleChannel={handleToggleChannel}
                        />
                    ))}
                </VStack>
                {__IS_CC_ENABLED__ && (
                    <>
                        <HStack space={6} alignItems="center">
                            <Divider flex={1} />
                            <Text variant="md">Or</Text>
                            <Divider flex={1} />
                        </HStack>
                        <ChannelItem
                            listing={listing}
                            accountType="ChannelConnector"
                            isConnected={!!listing.channels.ChannelConnector}
                            isLoading={selectedChannel === "ChannelConnector"}
                            onToggleChannel={handleToggleChannel}
                        />
                    </>
                )}
            </VStack>
        </Modal>
    );
};

export default memo(ModalConnectChannels);
