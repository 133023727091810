import type {IAvatarProps} from "native-base";
import {Avatar as AvatarNB} from "native-base";
import React, {memo} from "react";

import {takeFirstLetter} from "@/admin/utils";

export type Props = {
    name?: string;
} & IAvatarProps;

const Avatar: React.FC<Props> = ({name, ...restProps}) => {
    const firstLetterTitle = name ? takeFirstLetter(name) : "";

    return <AvatarNB {...restProps}>{firstLetterTitle}</AvatarNB>;
};

export default memo(Avatar);
