import {useCallback, useContext} from "react";

import {ReservationService} from "../../../services";
import HTTPContext from "../../contexts/HTTPContext";
import useAbortController from "../useAbortController";
import type {RequestPayload} from "../usePaginatedFetch";
import usePaginatedFetch from "../usePaginatedFetch";

const useFetchReservationPosts = (reservationID: string | undefined, offset: number) => {
    const abortRef = useAbortController(reservationID);
    const http = useContext(HTTPContext);

    const fetchPosts = useCallback(
        async (params: RequestPayload) => {
            if (!reservationID) {
                return undefined;
            }
            const reservationService = new ReservationService(http);
            return reservationService.fetchReservationPosts(reservationID, params, {
                signal: abortRef.current?.signal
            });
        },
        [reservationID, http, abortRef]
    );

    return usePaginatedFetch(fetchPosts, offset, [reservationID], {infinite: false});
};

export default useFetchReservationPosts;
