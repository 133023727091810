import {buildChannel} from "@hosttools/frontend/models/channel";
import {VStack} from "native-base";
import React, {memo, useMemo, useCallback} from "react";

import FormField from "../FormField";
import Input from "../Input";

import type {CredentialsType, Errors} from "@/admin/components/ModalAddAccount/types";

type Props = {
    credentials: CredentialsType;
    errors?: Errors;
    onChangeCredentials: (field: "username" | "password", value: string) => void;
};

const HomeAwayCredential: React.FC<Props> = ({errors, credentials, onChangeCredentials}) => {
    const {label} = useMemo(() => buildChannel("HomeAway"), []);

    const handleChangeUsername = useCallback(
        (text: string) => {
            onChangeCredentials("username", text);
        },
        [onChangeCredentials]
    );

    const handleChangePassword = useCallback(
        (text: string) => {
            onChangeCredentials("password", text);
        },
        [onChangeCredentials]
    );

    return (
        <VStack space={4}>
            <FormField
                error={errors?.username}
                tooltip={`Enter the email address you use to login to your ${label} account.`}
                label={`${label} Email`}
            >
                <Input
                    id="username"
                    placeholder={`${label} Email...`}
                    name="username"
                    type="text"
                    autoComplete="off"
                    value={credentials.username}
                    onChangeText={handleChangeUsername}
                    isRequired
                />
            </FormField>
            <FormField
                error={errors?.password}
                tooltip={`Enter your ${label} password.`}
                label={`${label} Password`}
            >
                <Input
                    id="password"
                    placeholder={`${label} Password...`}
                    name="password"
                    type="password"
                    autoComplete="new-password"
                    value={credentials.password}
                    onChangeText={handleChangePassword}
                    isRequired
                />
            </FormField>
        </VStack>
    );
};

export default memo(HomeAwayCredential);
