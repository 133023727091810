import type {IButtonProps} from "native-base";
import {Button, Pressable, type IMenuProps} from "native-base";
import React, {type ReactElement, useCallback, cloneElement} from "react";

export function useDefaultTrigger(icon: ReactElement, useButton: boolean): IMenuProps["trigger"];
export function useDefaultTrigger(
    icon: ReactElement,
    useButton: false,
    buttonProps?: IButtonProps
): IMenuProps["trigger"];
export function useDefaultTrigger(
    icon: ReactElement,
    useButton: boolean,
    buttonProps?: IButtonProps
) {
    return useCallback<IMenuProps["trigger"]>(
        props =>
            useButton ? (
                <Pressable accessibilityLabel="menu" {...props}>
                    {cloneElement(icon, props)}
                </Pressable>
            ) : buttonProps ? (
                <Button variant="ghost" leftIcon={icon} {...buttonProps} {...props} />
            ) : (
                <Pressable accessibilityLabel="menu" {...props}>
                    {icon}
                </Pressable>
            ),
        [icon, buttonProps, useButton]
    );
}
