import {UserContext} from "@hosttools/frontend";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import type {ComponentType} from "react";
import React, {useContext} from "react";
import type {RouteComponentProps} from "react-router-dom";
import {Redirect, Route} from "react-router-dom";

import Footer from "./components/Footer";

interface PublicRouteProps {
    path: string;
    component: ComponentType<RouteComponentProps>;
    redirect?: boolean;
    enableReactQuery?: boolean;
}

const queryClient = new QueryClient();

function PublicRoute({
    component: Component,
    redirect,
    enableReactQuery,
    ...rest
}: PublicRouteProps) {
    const {isAuthenticated} = useContext(UserContext);

    const route = (
        <Route
            {...rest}
            render={props => {
                if (isAuthenticated === true && redirect) {
                    return <Redirect to={{pathname: "/"}} />;
                }
                return (
                    <>
                        <Component {...props} />
                        <Footer />
                    </>
                );
            }}
        />
    );

    if (enableReactQuery) {
        return <QueryClientProvider client={queryClient}>{route}</QueryClientProvider>;
    }

    return route;
}

export default PublicRoute;
