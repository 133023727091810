import React, {memo} from "react";
import {Modal} from "react-bootstrap";

import Introduction from "./Introduction";

export type Props = {
    onHide: () => void;
    onFinish: () => void;
};

const ModalIntroduction: React.FC<Props> = ({onHide, onFinish}) => {
    return (
        <Modal show size="lg" backdrop="static" onHide={onHide}>
            <Modal.Body className="pd-0">
                <Introduction onHide={onHide} onFinish={onFinish} />
            </Modal.Body>
        </Modal>
    );
};

export default memo(ModalIntroduction);
