import type {Channel} from "@hosttools/core/shared/model/channel";
import type {Reservation} from "@hosttools/core/shared/model/reservation";
import {useToast, WebSocketContext} from "@hosttools/frontend";
import React, {useCallback, useContext, useEffect, useRef} from "react";
import {useHistory} from "react-router-dom";

import useNotifySound from "../../hooks/useNotifySound";
import type {Message} from "../../hooks/useWatchNotification";
import {useWatchNotification} from "../../hooks/useWatchNotification";
import airbnbIconSolid from "../../img/airbnb-logo-solid-square.svg";
import bookingIconSolid from "../../img/booking-logo-solid-square.svg";
import avatarUrl from "../../img/default-avatar.png";
import houfyIconSolid from "../../img/houfy-logo-solid-square.svg";
import hostToolsIconSolid from "../../img/logo-icon-square.svg";
import vrboIconSolid from "../../img/vrbo-logo-solid-square.svg";
import {UserContext} from "../../providers/UserProvider";
import {BrowserNotification} from "../../utils/browserNotification";

import NotificationAlert from "./NotificationAlert";

interface ToastOptions extends Message {
    onClick: () => void;
}

interface BrowserNotificationOptions {
    title: string;
    body: string;
    onClick: () => void;
}

const toastImgUrl: Record<"Avatar" | keyof typeof Channel, string> = {
    Avatar: avatarUrl,
    Airbnb: airbnbIconSolid,
    HomeAway: vrboIconSolid,
    Booking: bookingIconSolid,
    Houfy: houfyIconSolid,
    ChannelConnector: hostToolsIconSolid,
    internal: "",
    August: "",
    Seam: ""
};

const MessagesNotification: React.FC = () => {
    const ws = useContext(WebSocketContext);
    const {permissions} = useContext(UserContext);
    const toast = useToast();
    const browserNotifyRef = useRef<BrowserNotification | null>(null);
    const newNotification = useWatchNotification<Reservation>(ws);
    const history = useHistory();
    const browserNotification = browserNotifyRef.current;
    const [, playSound] = useNotifySound();
    const hasPermissionViewInbox = permissions.inbox?.edit;

    const handleCloseToast = useCallback(
        (toastId: string) => {
            toast.close(toastId);
        },
        [toast]
    );

    const generateToastNotify = useCallback(
        ({title, subTitle, message, logoKey, onClick}: ToastOptions) => {
            toast.show({
                placement: "top-right",
                render: props => {
                    return (
                        <NotificationAlert
                            toastId={props.id}
                            title={title}
                            subTitle={subTitle}
                            message={message}
                            logoKey={logoKey}
                            logoUrl={toastImgUrl[logoKey]}
                            onToastClick={onClick}
                            onCloseToast={handleCloseToast}
                        />
                    );
                }
            });
        },
        [toast, handleCloseToast]
    );

    const generateBrowserNotification = useCallback(
        ({title, body, onClick}: BrowserNotificationOptions) => {
            if (browserNotification) {
                browserNotification.showNotification(
                    title,
                    {
                        body
                    },
                    onClick
                );
            }
        },
        [browserNotification]
    );

    useEffect(() => {
        browserNotifyRef.current = new BrowserNotification();
        browserNotifyRef.current.registerPermissionToNotifyToBrowser();
    }, []);

    useEffect(() => {
        if (!newNotification) {
            return;
        }

        const {type, document, message} = newNotification;
        const {_id} = document;
        const shouldOpenInboxPage = [
            "newInquiry",
            "newPending",
            "newReservation",
            "newCancelled",
            "newPost"
        ].includes(type);

        const onClick = () => {
            if (shouldOpenInboxPage) {
                history.push(`/inbox/${_id}`);
            } else {
                history.push(`/?reservationID=${_id}`);
            }
            toast.closeAll();
        };

        const toastOptions = {
            ...message,
            onClick
        };

        const browserNotificationOptions = {
            title: message.title,
            body: `${message.subTitle} \n${message.message}`,
            onClick
        };

        if (shouldOpenInboxPage && !hasPermissionViewInbox) {
            return;
        }

        generateToastNotify(toastOptions);
        generateBrowserNotification(browserNotificationOptions);
        playSound();
    }, [
        hasPermissionViewInbox,
        history,
        newNotification,
        toast,
        generateBrowserNotification,
        generateToastNotify,
        playSound
    ]);

    return null;
};

export default MessagesNotification;
