import {useContext, useEffect, useState} from "react";

import UserService from "../../../services/user";
import HTTPContext from "../../contexts/HTTPContext";

const useSeamClientToken = () => {
    const http = useContext(HTTPContext);
    const [token, setToken] = useState<string>();

    useEffect(() => {
        (async () => {
            const userService = new UserService(http);
            const token = await userService.fetchSeamClientToken();
            setToken(token);
        })();
    }, [http]);

    return token;
};

export default useSeamClientToken;
