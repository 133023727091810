import {Channel} from "@hosttools/core/shared/model/channel";
import {isWithinInterval} from "date-fns";
import orderBy from "lodash/orderBy";
import uniqBy from "lodash/uniqBy";
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";

import type {Reservation, ReservationPost, ReservationRaw} from "../../../models/reservation";
import {FrontendContext} from "../../contexts/Frontend";
import type {ChannelsThreadObj} from "../useChannelThread";
import useChannelThread from "../useChannelThread";
import useFetchReservationPosts from "../useFetchReservationPosts";
import useFetchTimelines from "../useFetchTimelines";

import type {Post} from "./share";
import {
    buildPostFromAirbnbReservationPost,
    buildPosts,
    filterDuplicatedItems,
    filterOutFillerMessages,
    sortPosts
} from "./share";

const defaultPostsOffset = 20;

interface Props {
    reservation: Reservation | ReservationRaw | undefined | null;
    showTimeline: boolean;
    order?: "asc" | "desc";
    onReloadThread: () => void;
}

const useReservationPosts = ({reservation, showTimeline, order = "asc", onReloadThread}: Props) => {
    const {baseUrl} = useContext(FrontendContext);
    const [posts, setPosts] = useState<Post[] | undefined>(undefined);
    const addedPostsRef = useRef<ReservationPost[]>();
    const addedIDsRef = useRef<Set<string>>();
    const {_id: reservationID, source} = reservation ?? {};

    const {
        data: paginatedPosts,
        loading: isLoadingPosts,
        hasMore,
        offset,
        refresh,
        fetchMore
    } = useFetchReservationPosts(reservationID, defaultPostsOffset);
    const {
        timelines,
        messages: sentMessages,
        isLoading: isLoadingTimelines,
        removeTimelines,
        removeMessages
    } = useFetchTimelines(reservationID, true);

    // only support to fetch thread for `Airbnb` for now
    const {thread: channelThread, refetch: refetchChannelThread} = useChannelThread(
        source === Channel.Airbnb ? reservationID : undefined
        // source === Channel.Booking ? offset : undefined
    );
    const isThreadLoading = source === Channel.Airbnb ? !channelThread : false;

    const addPosts = useCallback(
        (posts: ReservationPost[]) => {
            // added posts and wait for the update
            if (reservationID && source) {
                setPosts(prev => [
                    ...(prev ?? []),
                    ...buildPosts(reservationID, source, baseUrl, posts)
                ]);
            }
        },
        [reservationID, source, baseUrl]
    );

    const removePosts = useCallback(
        (postIds: string[], type: Post["post"]["type"]) => {
            if (type === "timeline") {
                removeTimelines(postIds);
            } else if (type === "message") {
                removeMessages(postIds);
            } else {
                setPosts(prev => {
                    if (prev) {
                        return prev.filter(post => !postIds.includes(post.id));
                    }
                    return prev;
                });
            }
        },
        [removeTimelines, removeMessages]
    );

    const buildNextPosts = useCallback(
        (prevPosts: Post[], thread?: ChannelsThreadObj) => {
            if (!reservationID || !source) {
                return prevPosts;
            }

            const addedPosts = addedPostsRef.current;
            if (addedPosts) {
                const nextPosts = buildPosts(reservationID, source, baseUrl, addedPosts, thread);
                addedPostsRef.current = undefined;

                return [...nextPosts, ...filterOutFillerMessages(prevPosts)];
            }
            addedPostsRef.current = undefined;
            return prevPosts;
        },
        [baseUrl, reservationID, source]
    );

    const setPostsAfterFetchThreadForAirbnb = useCallback(async () => {
        try {
            const thread = await refetchChannelThread();
            setPosts(prev => {
                if (prev) {
                    return buildNextPosts(prev, thread);
                }
                addedPostsRef.current = undefined;
                return prev;
            });
        } catch {
            addedPostsRef.current = undefined;
        }
    }, [refetchChannelThread, buildNextPosts]);

    const reloadIfExceededThreshold = useCallback(
        (addedOnes: string[]) => {
            const addedIDs = addedIDsRef.current;
            const nextSet = new Set([...(addedIDs ?? []), ...addedOnes]);

            // reload the thread if the next page doesn't have more items
            if (nextSet.size >= defaultPostsOffset) {
                onReloadThread();
                addedIDsRef.current = undefined;
                refresh();
                return true;
            }
            addedIDsRef.current = nextSet;
            return false;
        },
        [onReloadThread, refresh]
    );

    useEffect(() => {
        if (reservationID && source && paginatedPosts && !isLoadingPosts) {
            const {thread} = channelThread || {};
            const postsWithAttachments = buildPosts(
                reservationID,
                source,
                baseUrl,
                paginatedPosts,
                thread
            );
            setPosts(
                offset === 0
                    ? postsWithAttachments
                    : prev => [
                          ...(prev ?? []),
                          ...(offset > 0 && addedIDsRef.current && prev
                              ? filterDuplicatedItems(prev, postsWithAttachments)
                              : postsWithAttachments)
                      ]
            );
        }
    }, [reservationID, source, baseUrl, offset, isLoadingPosts, paginatedPosts, channelThread]);

    // re-update the images cause channel has changed
    useEffect(() => {
        const {thread} = channelThread || {};
        if (thread && !isThreadLoading) {
            setPosts(prev => {
                if (prev && thread && "posts" in thread && thread.posts) {
                    return prev.map(elem => {
                        if (elem.post.type === "post") {
                            const {data: post} = elem.post;
                            if (post.externalID && post.attachments?.length) {
                                const result = buildPostFromAirbnbReservationPost(post, thread);
                                if (result) {
                                    return result;
                                }
                            }
                        }
                        return elem;
                    });
                }
                return prev;
            });
        }
    }, [isThreadLoading, channelThread]);

    useEffect(() => {
        let timerId: NodeJS.Timeout | undefined;
        // sometimes it comes with bunch of updates at the same time
        if (reservation?.posts) {
            setPosts(prevPosts => {
                if (prevPosts) {
                    const limitedPosts = prevPosts.slice(0, defaultPostsOffset);
                    // find the elements which aren't in the current posts
                    const addedItems = sortPosts(reservation.posts)
                        .splice(0, defaultPostsOffset)
                        .filter(elem => {
                            return !limitedPosts.some(post => post.id === elem._id);
                        });

                    if (addedItems.length && addedItems.length < defaultPostsOffset) {
                        // has image from newly added posts
                        if (addedItems.some(elem => elem.attachments?.length)) {
                            // having the queues waiting for update
                            if (addedPostsRef.current) {
                                // just added the tracking list and wait for the update
                                addedPostsRef.current = uniqBy(
                                    [...addedPostsRef.current, ...addedItems],
                                    "_id"
                                );
                            } else if (
                                !reloadIfExceededThreshold(addedItems.map(elem => elem._id))
                            ) {
                                addedPostsRef.current = addedItems;
                                if (source === Channel.Airbnb) {
                                    setPostsAfterFetchThreadForAirbnb();
                                } else {
                                    return buildNextPosts(prevPosts);
                                }
                            }
                        } else {
                            if (prevPosts) {
                                // keep track the added items
                                if (!reloadIfExceededThreshold(addedItems.map(elem => elem._id))) {
                                    return [
                                        ...buildPosts(
                                            reservation._id,
                                            reservation.source,
                                            baseUrl,
                                            addedItems
                                        ),
                                        ...filterOutFillerMessages(prevPosts)
                                    ];
                                }
                            }
                            return prevPosts;
                        }
                    }
                }
                return prevPosts;
            });
        }

        return () => {
            if (timerId) {
                clearTimeout(timerId);
            }
        };
    }, [
        baseUrl,
        reservation,
        reloadIfExceededThreshold,
        source,
        setPostsAfterFetchThreadForAirbnb,
        buildNextPosts
    ]);

    useEffect(() => {
        if (reservationID) {
            setPosts(undefined);
            addedPostsRef.current = undefined;
            addedIDsRef.current = undefined;
        }
    }, [reservationID]);

    const isLoading = isLoadingPosts || isThreadLoading || isLoadingTimelines;

    const showingTimelines = useMemo(
        () =>
            timelines?.filter(
                timeline =>
                    timeline.status !== "sent" || timeline.emailEnabled || timeline.smsEnabled
            ),
        [timelines]
    );
    const sortedPosts = useMemo<Post[] | undefined>(() => {
        if (!posts) {
            return;
        }

        const sentMessagesDisplay = sentMessages?.filter(elem => {
            // ignore the unknown date which seems to be incorrect data
            if (!elem.failedDate && !elem.sentDate) {
                return false;
            }
            const timeline = timelines?.find(timeline => {
                return timeline.messageRuleID === elem.messageRuleID;
            });
            if (timeline) {
                return false;
            }

            if (hasMore) {
                const sortedPosts = orderBy(posts, "date", "desc");
                const firstPost = sortedPosts[0];
                const lastPost = sortedPosts[posts.length - 1];
                const messageDate = elem.failedDate || elem.sentDate;
                if (
                    !messageDate ||
                    !isWithinInterval(new Date(messageDate), {
                        start: new Date(lastPost.date),
                        end: new Date(firstPost.date)
                    })
                ) {
                    return false;
                }
            }
            return true;
        });
        const allTypesMessages = [...(posts ?? [])];

        if (sentMessagesDisplay) {
            const filteredsentMessages = sentMessagesDisplay.map(
                message =>
                    ({
                        id: message._id,
                        date: message.failedDate || message.sentDate,
                        post: {
                            type: "message",
                            data: message
                        }
                    } as Post)
            );

            allTypesMessages.push(...filteredsentMessages);
        }

        if (showTimeline) {
            const filteredScheduleTimelines = showingTimelines
                ? showingTimelines.filter(timeline => timeline.failedDate === undefined)
                : [];
            const filteredTimelines = filteredScheduleTimelines.map(
                timeline =>
                    ({
                        id: timeline._id,
                        date: timeline.sentDate || timeline.sendDate,
                        post: {
                            type: "timeline",
                            data: timeline
                        }
                    } as Post)
            );
            allTypesMessages.push(...filteredTimelines);
        }

        if (timelines) {
            const failedTimelines = timelines.reduce((arr, timeline) => {
                if (timeline.failedDate) {
                    arr.push({
                        id: timeline._id,
                        date: timeline.failedDate,
                        post: {
                            type: "timeline",
                            data: timeline
                        }
                    });
                }
                return arr;
            }, [] as Post[]);

            allTypesMessages.push(...failedTimelines);
        }

        return orderBy(allTypesMessages, "date", order);

        // ideally we should render everything at once to avoid flashing ...
    }, [order, showTimeline, posts, sentMessages, timelines, hasMore, showingTimelines]);

    return {
        posts,
        sortedPosts,
        offset,
        hasMore,
        timelines,
        showingTimelines,
        sentMessages,
        channelThread,
        isLoading,
        isThreadLoading,
        isLoadingPosts,
        isLoadingTimelines,
        setPosts,
        addPosts,
        removePosts,
        refresh,
        fetchMore,
        refetchChannelThread
    };
};

export default useReservationPosts;
