import {Spinner, View} from "native-base";
import type {IViewProps} from "native-base/lib/typescript/components/basic/View/types";
import type {FC, ForwardedRef, ReactNode} from "react";
import React, {forwardRef, memo} from "react";

import Text from "@/client/components/Text";

export type Props = IViewProps & {
    className?: string;
    /**
     * this requires parent element has to be `{flex: 1}`
     */
    center?: boolean;
    children?: ReactNode;
    forwardedRef?: ForwardedRef<HTMLDivElement>;
};

const Loader: FC<Props> = ({children = "Loading...", center, forwardedRef, ...restProps}) => {
    const centerProps: IViewProps = {
        width: "100%",
        height: "100%",
        flexGrow: 1,
        flexShrink: 1
    };

    return (
        <View
            ref={forwardedRef}
            testID="loader"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            {...(center && centerProps)}
            {...restProps}
        >
            <Spinner mr={1.5} />
            <Text variant="sm" color="blueGray.800">
                {children}
            </Text>
        </View>
    );
};

Loader.displayName = "Loader";

const FowardRefComponent = forwardRef<HTMLDivElement, Props>((props, ref) => (
    <Loader {...props} forwardedRef={ref} />
));

FowardRefComponent.displayName = `forwardedRef(${Loader.displayName})`;

export default memo(FowardRefComponent);
