import {useContext} from "react";

import {AppContext} from "@/client/provider/AppProvider";

const useNewDesign = () => {
    const {isNewDesignEnabled} = useContext(AppContext);
    return isNewDesignEnabled;
};

export default useNewDesign;
