import {Box, VStack, Button, NativeBaseProvider} from "native-base";
import React, {useCallback} from "react";

import theme from "./theme/theme";

import url from "@/admin/img/sad-face.svg";
import EmptyState from "@/client/components/EmptyState";
import Paragraph from "@/client/components/Paragraph";

const Error: React.FC = () => {
    const reload = useCallback(() => {
        window.location.reload();
    }, []);

    return (
        <NativeBaseProvider theme={theme}>
            <Box height="100vh" width="full">
                <EmptyState
                    primaryText="An error has occurred"
                    imgUrl={url}
                    imgAspectRatio="16:9"
                    secondaryText={
                        <VStack space={4}>
                            <Paragraph variant="md" textAlign="center">
                                Please let us know what happened. Send us a message telling us how
                                this happened so we can fix it
                            </Paragraph>
                            <Paragraph variant="md" textAlign="center">
                                email us:{" "}
                                <a href="mailto:support@hosttools.com">support@hosttools.com</a>
                            </Paragraph>
                        </VStack>
                    }
                >
                    <Button onPress={reload}>Refresh</Button>
                </EmptyState>
            </Box>
        </NativeBaseProvider>
    );
};

export default Error;
