import {useContext, useMemo} from "react";

import {UserContext} from "../../contexts/UserContext";

const useListing = (listingID: string | undefined) => {
    const {listings} = useContext(UserContext);

    return useMemo(() => {
        if (!listingID) {
            return undefined;
        }

        return listings.find(elem => elem._id === listingID);
    }, [listings, listingID]);
};

export default useListing;
