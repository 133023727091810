import {providerChannels, type AccountType} from "@hosttools/core/constant";
import {buildChannel} from "@hosttools/frontend/models/channel";
import classNames from "classnames";
import {Button, VStack, View} from "native-base";
import type {FC} from "react";
import React, {useEffect, useMemo, useState} from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {FiHelpCircle} from "react-icons/fi";

import airbnbLogoSolidSquare from "../../img/airbnb-logo-solid-square.svg";
import augustLogoSolidSquare from "../../img/august-logo-solid-square.svg";
import bookingLogoSolidSquare from "../../img/booking-logo-solid-square.svg";
import houfyLogoSolidSquare from "../../img/houfy-logo-solid-square.svg";
import smartLockSolidSquare from "../../img/smart-lock-solid-square.svg";
import vrboLogoSolidSquare from "../../img/vrbo-logo-solid-square.svg";

import type {CredentialsType, Errors} from "./types";

import Alert from "@/client/components/Alert";
import DesktopView from "@/client/components/DesktopView";
import MobileView from "@/client/components/MobileView";
import AirbnbCredential from "@/client/components/ModalAddAccount/AirbnbCredential";
import AugustCredential from "@/client/components/ModalAddAccount/AugustCredential";
import BookingCredential from "@/client/components/ModalAddAccount/BookingCredential";
import ChannelHeader from "@/client/components/ModalAddAccount/ChannelHeader";
import HomeAwayCredential from "@/client/components/ModalAddAccount/HomeAwayCredential";
import HostToolPolicy from "@/client/components/ModalAddAccount/HosttoolPolicy";
import HoufyCredential from "@/client/components/ModalAddAccount/HoufyCredential";
import SeamCredential from "@/client/components/ModalAddAccount/SeamCredential";
import Paragraph from "@/client/components/Paragraph";
import useNewDesign from "@/client/hooks/useNewDesign";

interface Props {
    channel: AccountType;
    credentials: CredentialsType;
    info: string;
    errors: Errors;
    showSpinner: boolean;
    onChange: (credentials: CredentialsType) => void;
    onSubmitCredentials?: () => void;
    onCancel?: () => void;
}

const Credentials: FC<Props> = props => {
    const {
        channel: channelType,
        credentials: initCredentials = {
            username: "",
            password: ""
        },
        errors: propErrors,
        info = "",
        showSpinner,
        onChange,
        onSubmitCredentials,
        onCancel
    } = props;
    const isNewUIEnabled = useNewDesign();

    const {label: channel, value} = useMemo(() => buildChannel(channelType), [channelType]);
    const [credentials, setCredentials] = useState<CredentialsType>({
        username: initCredentials.username ?? "",
        password: ""
    });

    const [logo, setLogo] = useState<string>();
    const [errors, setErrors] = useState<Errors>({});

    useEffect(() => {
        if (value === "Airbnb") {
            setLogo(airbnbLogoSolidSquare);
        }
        if (value === "Booking") {
            setLogo(bookingLogoSolidSquare);
        }
        if (value === "HomeAway") {
            setLogo(vrboLogoSolidSquare);
        }
        if (value === "Houfy") {
            setLogo(houfyLogoSolidSquare);
        }
        if (value === "August") {
            setLogo(augustLogoSolidSquare);
        }
        if (value === "Seam") {
            setLogo(smartLockSolidSquare);
        }
    }, [value]);

    useEffect(() => {
        setErrors(propErrors);
    }, [propErrors]);

    function handleChangeCredentials<K extends keyof CredentialsType>(
        field: K,
        value: Required<CredentialsType>[K]
    ) {
        const newCredentials: Partial<CredentialsType> = {};
        if (field === "username") {
            newCredentials[field] = value.trim();
        } else {
            newCredentials[field] = value;
        }
        setCredentials(credentials => {
            return {...credentials, ...newCredentials};
        });
        onChange(newCredentials);
    }

    const {label} = useMemo(() => buildChannel(value), [value]);

    const usernameOverlay = (
        <Tooltip id="usernameOverlay">
            Enter the email address you use to login to your {channel} account.
        </Tooltip>
    );
    const passwordOverlay = <Tooltip id="passwordOverlay">Enter your {channel} password.</Tooltip>;

    const usernameClasses = classNames("form-control", {
        "is-invalid": errors.username
    });
    const passwordClasses = classNames("form-control", {
        "is-invalid": errors.password
    });

    if (isNewUIEnabled) {
        return (
            <>
                <View height={["100%", "unset"]} justifyContent={["space-between", "unset"]}>
                    <View>
                        <MobileView mb={6}>
                            <ChannelHeader channel={value} />
                        </MobileView>
                        {providerChannels.includes(value) && <HostToolPolicy />}
                        {value === "Booking" && (
                            <View mt={4}>
                                <Paragraph variant="sm">
                                    {"You can find your Booking.com property ID in your "}
                                    <a
                                        href="https://admin.booking.com/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        account settings page
                                    </a>
                                    {" on Booking.com."}
                                </Paragraph>
                            </View>
                        )}
                        {value === "Houfy" && (
                            <View mt={4}>
                                <Paragraph variant="sm">
                                    {"You can find your Houfy.com 'API Access Token' in your "}
                                    <a
                                        href="https://www.houfy.com/account/settings?tab=2"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        account settings page
                                    </a>
                                    {" on Houfy.com."}
                                </Paragraph>
                            </View>
                        )}
                        <View
                            p={4}
                            mt={4}
                            borderWidth={1}
                            borderStyle="solid"
                            borderColor="blueGray.200"
                            borderRadius={8}
                            backgroundColor="blueGray.50"
                        >
                            <DesktopView mb={4}>
                                <ChannelHeader channel={value} />
                            </DesktopView>

                            {value === "HomeAway" && (
                                <HomeAwayCredential
                                    errors={errors}
                                    credentials={credentials}
                                    onChangeCredentials={handleChangeCredentials}
                                />
                            )}

                            {value === "Airbnb" && <AirbnbCredential />}

                            {value === "Booking" && (
                                <BookingCredential
                                    errors={errors}
                                    credentials={credentials}
                                    onChangeCredentials={handleChangeCredentials}
                                />
                            )}

                            {value === "Houfy" && (
                                <HoufyCredential
                                    errors={errors}
                                    credentials={credentials}
                                    onChangeCredentials={handleChangeCredentials}
                                />
                            )}

                            {value === "August" && (
                                <AugustCredential
                                    errors={errors}
                                    credentials={credentials}
                                    onChangeCredentials={handleChangeCredentials}
                                />
                            )}

                            {value === "Seam" && <SeamCredential />}

                            {showSpinner && (
                                <Alert mt={4}>
                                    <VStack space={2}>
                                        <View>Connecting...</View>
                                        <View>
                                            Host Tools is connecting to {label}. This process can
                                            take a few minutes. Please be patient.
                                        </View>
                                    </VStack>
                                </Alert>
                            )}

                            <View>
                                {info && <Alert mt={4}>{info}</Alert>}
                                {errors?.error && (
                                    <Alert color="danger" mt={4}>
                                        {errors?.error}
                                    </Alert>
                                )}
                                {errors?.errorCode === "SETUP_2FA" && (
                                    <Alert color="danger" mt={4}>
                                        {"Please setup "}
                                        <a
                                            href="https://help.vrbo.com/articles/How-do-I-set-up-two-factor-authentication"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            two-factor authentication
                                        </a>
                                        {
                                            " on VRBO before connecting your account to Host Tools.  Here is a help article on VRBO.com on "
                                        }
                                        <a
                                            href="https://help.vrbo.com/articles/How-do-I-set-up-two-factor-authentication"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            How do I set up two-factor authentication?
                                        </a>
                                    </Alert>
                                )}
                                {errors?.errorCode === "ACCOUNT_LOCKED" && (
                                    <Alert color="danger" mt={4}>
                                        {
                                            "Your VRBO account has run out of available sessions.  Please visit the "
                                        }
                                        <a
                                            href="https://www.vrbo.com/account-security"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Privacy and Security
                                        </a>
                                        {
                                            " page on VRBO and clear any sessions that have not been active in the last few days.  Then try connecting again.  If that doesn't work, please contact VRBO support by calling (877) 202-9331 and ask them to clear your sessions.  To avoid confusing the support rep, we recommend you tell them you're having issues logging into the mobile app."
                                        }
                                    </Alert>
                                )}
                                <Button
                                    mt={4}
                                    display={["flex", "flex", "none"]}
                                    isLoading={showSpinner}
                                    isLoadingText="Add Account"
                                    disabled={showSpinner}
                                    opacity={showSpinner ? 0.5 : 1}
                                    onPress={onSubmitCredentials}
                                >
                                    Add Account
                                </Button>
                            </View>

                            <DesktopView mt={6} flexDir="row" justifyContent="flex-end">
                                <Button variant="outline" onPress={onCancel} mr={4}>
                                    Cancel
                                </Button>
                                <Button
                                    isLoading={showSpinner}
                                    isLoadingText="Add Account"
                                    disabled={showSpinner}
                                    opacity={showSpinner ? 0.5 : 1}
                                    onPress={onSubmitCredentials}
                                >
                                    Add Account
                                </Button>
                            </DesktopView>
                        </View>
                    </View>
                </View>
            </>
        );
    }

    if (value === "HomeAway") {
        return (
            <>
                <p>
                    Host Tools will request an authentication token that will be used to
                    automatically send messages to your guests or set your listing&apos;s prices on
                    your behalf. It will only send messages you tell it to send and will never make
                    any changes to your account without your permission.
                </p>
                <p>
                    All network traffic is encrypted on Host Tools and all data is stored using the
                    latest compliance standards.
                </p>
                <div className="pd-md-20">
                    <div className="mg-b-20 d-flex justify-content-start align-items-center">
                        <img
                            src={logo}
                            alt={channel}
                            className="rounded-circle mg-r-20"
                            height="65"
                        />
                        <div>
                            <h2 className="mg-b-0">{channel} Account</h2>
                            <span className="text-muted text-uppercase tx-11">
                                Connect your account with {channel} credentials
                            </span>
                        </div>
                    </div>
                    <div className="form-group">
                        <label
                            htmlFor="username"
                            className="az-content-label tx-11 tx-medium tx-gray-600"
                        >
                            {channel} Email
                            <OverlayTrigger placement="top" overlay={usernameOverlay}>
                                <FiHelpCircle className="text-muted ml-1" />
                            </OverlayTrigger>
                        </label>
                        <input
                            id="username"
                            className={usernameClasses}
                            placeholder={`${channel} Email...`}
                            name="username"
                            type="text"
                            autoComplete="off"
                            value={credentials.username}
                            onChange={event => {
                                handleChangeCredentials("username", event.target.value);
                            }}
                            required
                        />
                        {errors.username && (
                            <div className="alert alert-danger">{errors.username}</div>
                        )}
                    </div>
                    <div className="form-group">
                        <label
                            htmlFor="password"
                            className="az-content-label tx-11 tx-medium tx-gray-600"
                        >
                            {channel} Password
                            <OverlayTrigger placement="top" overlay={passwordOverlay}>
                                <FiHelpCircle className="text-muted ml-1" />
                            </OverlayTrigger>
                        </label>
                        <input
                            id="password"
                            className={passwordClasses}
                            placeholder={`${channel} Password...`}
                            name="password"
                            type="password"
                            autoComplete="new-password"
                            value={credentials.password}
                            onChange={event => {
                                handleChangeCredentials("password", event.target.value);
                            }}
                            required
                        />
                        {errors.password && (
                            <div className="alert alert-danger">{errors.password}</div>
                        )}
                    </div>
                    {showSpinner && (
                        <div className="alert alert-info help-block">
                            <h6 className="text-center pd-20">{" Connecting..."}</h6>
                            <p className="mg-x-10">
                                Host Tools is connecting to {channel}. This process can take a few
                                minutes. Please be patient.
                            </p>
                        </div>
                    )}
                </div>
                {errors.error && <div className="alert alert-danger">{errors.error}</div>}
                {info && <div className="alert alert-info">{info}</div>}
                {errors.errorCode === "SETUP_2FA" && (
                    <div className="alert alert-danger">
                        {"Please setup "}
                        <a
                            href="https://help.vrbo.com/articles/How-do-I-set-up-two-factor-authentication"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            two-factor authentication
                        </a>
                        {
                            " on VRBO before connecting your account to Host Tools.  Here is a help article on VRBO.com on "
                        }
                        <a
                            href="https://help.vrbo.com/articles/How-do-I-set-up-two-factor-authentication"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            How do I set up two-factor authentication?
                        </a>
                    </div>
                )}
                {errors.errorCode === "ACCOUNT_LOCKED" && (
                    <div className="alert alert-danger">
                        {"Your VRBO account has run out of available sessions.  Please visit the "}
                        <a
                            href="https://www.vrbo.com/account-security"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Privacy and Security
                        </a>
                        {
                            " page on VRBO and clear any sessions that have not been active in the last few days.  Then try connecting again.  If that doesn't work, please contact VRBO support by calling (877) 202-9331 and ask them to clear your sessions.  To avoid confusing the support rep, we recommend you tell them you're having issues logging into the mobile app."
                        }
                    </div>
                )}
            </>
        );
    }
    // Airbnb Official
    if (value === "Airbnb") {
        return (
            <>
                <p>
                    Host Tools will automatically send messages to your guests or set your
                    listing&apos;s prices and availability on your behalf. It will only send
                    messages you tell it to send and will never make any changes to your account
                    without your permission.
                </p>
                <p>
                    All network traffic is encrypted on Host Tools and all data is stored using the
                    latest compliance standards.
                </p>

                <div className="pd-md-20">
                    <div className="mg-b-20 d-flex justify-content-start align-items-center">
                        <img
                            src={airbnbLogoSolidSquare}
                            alt="Airbnb"
                            className="rounded-circle mg-r-20"
                            height="65"
                        />
                        <div>
                            <h2 className="mg-b-0">Airbnb Account</h2>
                            <span className="text-muted text-uppercase tx-11">
                                Connect your Airbnb account to Host Tools.
                            </span>
                        </div>
                    </div>
                    <p>
                        After connecting your Airbnb account to Host Tools, some of the Airbnb
                        account settings will change.
                        <a
                            href="https://help.hosttools.com/en/articles/6289414-what-will-change-on-my-airbnb-account-when-i-connect-it-to-host-tools"
                            target="_blank"
                            rel="noreferrer"
                        >
                            {" Read more."}
                        </a>
                    </p>
                    <ul>
                        <li>
                            <b>Fee settings</b> may change to host-only.
                        </li>
                        <li>
                            <b>Instant book</b> will be enabled on all listings.
                        </li>
                        <li>
                            <b>All imported iCals</b> will be removed from Airbnb.
                        </li>
                    </ul>
                </div>
                {errors.error && <div className="alert alert-danger">{errors.error}</div>}
                {info && <div className="alert alert-info">{info}</div>}
            </>
        );
    }
    if (value === "Seam") {
        return (
            <>
                <p>
                    Host Tools will can help automate your smart locks. It will set a unique lock
                    code for each guest that only works during their stay. Please connect any locks
                    you would like to automate.
                </p>
                <p>
                    All network traffic is encrypted on Host Tools and all data is stored using the
                    latest compliance standards.
                </p>

                <div className="pd-md-20">
                    <div className="mg-b-20 d-flex justify-content-start align-items-center">
                        <img
                            src={logo}
                            alt={channel}
                            className="rounded-circle mg-r-20"
                            height="65"
                        />
                        <div>
                            <h2 className="mg-b-0">
                                {channel === "Seam" ? "Smart Lock" : channel} Account
                            </h2>
                            <span className="text-muted text-uppercase tx-11">
                                Connect your {channel === "Seam" ? "Smart Lock" : channel} account
                                to Host Tools.
                            </span>
                        </div>
                    </div>
                    <p>
                        Each smart lock linked to an enabled listing in Host Tools,
                        <strong> will incur a $3/month fee.</strong>
                        <a
                            href="https://help.hosttools.com/en/articles/8160685-how-much-do-smart-locks-cost"
                            target="_blank"
                            rel="noreferrer"
                        >
                            {" Read more."}
                        </a>
                    </p>
                </div>
                {errors.error && <div className="alert alert-danger">{errors.error}</div>}
                {info && <div className="alert alert-info">{info}</div>}
            </>
        );
    }
    if (value === "August") {
        return (
            <>
                <p>
                    Host Tools will can help automate your smart locks. It will set a unique lock
                    code for each guest that only works during their stay. Please connect any locks
                    you would like to automate.
                </p>
                <p>
                    All network traffic is encrypted on Host Tools and all data is stored using the
                    latest compliance standards.
                </p>
                <div className="pd-md-20">
                    <div className="mg-b-20 d-flex justify-content-start align-items-center">
                        <img
                            src={logo}
                            alt={channel}
                            className="rounded-circle mg-r-20"
                            height="65"
                        />
                        <div>
                            <h2 className="mg-b-0">{channel} Account</h2>
                            <span className="text-muted text-uppercase tx-11">
                                Connect your account with {channel} credentials
                            </span>
                        </div>
                    </div>
                    <div className="form-group">
                        <label
                            htmlFor="username"
                            className="az-content-label tx-11 tx-medium tx-gray-600"
                        >
                            {channel} Email
                            <OverlayTrigger placement="top" overlay={usernameOverlay}>
                                <FiHelpCircle className="text-muted ml-1" />
                            </OverlayTrigger>
                        </label>
                        <input
                            id="username"
                            className={usernameClasses}
                            placeholder={`${channel} Email...`}
                            name="username"
                            type="text"
                            autoComplete="off"
                            value={credentials.username}
                            onChange={event => {
                                handleChangeCredentials("username", event.target.value);
                            }}
                            required
                        />
                        {errors.username && (
                            <div className="alert alert-danger">{errors.username}</div>
                        )}
                    </div>
                    <div className="form-group">
                        <label
                            htmlFor="password"
                            className="az-content-label tx-11 tx-medium tx-gray-600"
                        >
                            {channel} Password
                            <OverlayTrigger placement="top" overlay={passwordOverlay}>
                                <FiHelpCircle className="text-muted ml-1" />
                            </OverlayTrigger>
                        </label>
                        <input
                            id="password"
                            className={passwordClasses}
                            placeholder={`${channel} Password...`}
                            name="password"
                            type="password"
                            autoComplete="new-password"
                            value={credentials.password}
                            onChange={event => {
                                handleChangeCredentials("password", event.target.value);
                            }}
                            required
                        />
                        {errors.password && (
                            <div className="alert alert-danger">{errors.password}</div>
                        )}
                    </div>
                    {showSpinner && (
                        <div className="alert alert-info help-block">
                            <h6 className="text-center pd-20">{" Connecting..."}</h6>
                            <p className="mg-x-10">
                                Host Tools is connecting to {channel}. This process can take a few
                                minutes. Please be patient.
                            </p>
                        </div>
                    )}
                </div>
                {errors.error && <div className="alert alert-danger">{errors.error}</div>}
                {info && <div className="alert alert-info">{info}</div>}
            </>
        );
    }
    if (value === "Booking") {
        const propertyIDOverlay = (
            <Tooltip id="propertyIDOverlay">Enter your Booking.com property ID.</Tooltip>
        );
        const propertyIDClasses = classNames("form-control", {
            "is-invalid": errors.propertyID
        });

        return (
            <>
                <p>
                    Host Tools will automatically send messages to your guests or set your
                    listing&apos;s prices and availability on your behalf. It will only send
                    messages you tell it to send and will never make any changes to your account
                    without your permission.
                </p>
                <p>
                    All network traffic is encrypted on Host Tools and all data is stored using the
                    latest compliance standards.
                </p>
                <p>
                    {"You can find your Booking.com property ID in your "}
                    <a href="https://admin.booking.com/" target="_blank" rel="noreferrer">
                        account settings page
                    </a>
                    {" on Booking.com."}
                </p>

                <div className="pd-md-20">
                    <div className="mg-b-20 d-flex justify-content-start align-items-center">
                        <img
                            src={bookingLogoSolidSquare}
                            alt="Booking.com"
                            className="rounded-circle mg-r-20"
                            height="65"
                        />
                        <div>
                            <h2 className="mg-b-0">Booking.com Account</h2>
                            <span className="text-muted text-uppercase tx-11">
                                Connect Host Tools to Booking.com
                            </span>
                        </div>
                    </div>

                    <ol>
                        <li>
                            Click the button below to select <b>Host Tools</b> as your{" "}
                            <b>Connectivity Provider</b> on Booking.com.
                            <button
                                type="button"
                                className="btn btn-primary btn-block my-2"
                                onClick={() => {
                                    window.open(
                                        "https://admin.booking.com/?groups_redirect=%2Fhotel%2Fhoteladmin%2Fextranet_ng%2Fmanage%2Fchannel-manager%2Fwidget.html?provider_id=1590",
                                        "_blank"
                                    );
                                }}
                            >
                                Select Connectivity Provider
                            </button>
                        </li>
                        <li>
                            Once you have selected <b>Host Tools</b> as your
                            <b> Connectivity Provider</b> it can take a few minutes for Booking.com
                            to complete the request. You will receive a &quot;Connection
                            activated&quot; message in your Booking.com <b>Inbox </b>
                            once the activation is complete.
                        </li>
                        <li>
                            Once your connection is activated, enter your
                            <b> Booking.com Property ID</b> below and click
                            <b> Add Account</b>. It can take a few minutes for your account
                            connection to be confirmed on Booking.com before it can be added to Host
                            Tools. If you have trouble connecting your account, please confirm that
                            Host Tools is your connectivity provider on Booking.com, wait a few
                            minutes and try again.
                        </li>
                    </ol>

                    <div className="form-group">
                        <label
                            htmlFor="propertyID"
                            className="az-content-label tx-11 tx-medium tx-gray-600"
                        >
                            Booking.com Property ID
                            <OverlayTrigger placement="top" overlay={propertyIDOverlay}>
                                <FiHelpCircle className="text-muted ml-1" />
                            </OverlayTrigger>
                        </label>
                        <input
                            id="propertyID"
                            className={propertyIDClasses}
                            placeholder="Booking.com Property ID..."
                            name="propertyID"
                            type="propertyID"
                            autoComplete="off"
                            value={credentials.propertyID}
                            onChange={event => {
                                handleChangeCredentials("propertyID", event.target.value);
                            }}
                            required
                        />
                        {errors.propertyID && (
                            <div className="alert alert-danger">{errors.propertyID}</div>
                        )}
                    </div>
                </div>
                {errors.error && <div className="alert alert-danger">{errors.error}</div>}
                {info && <div className="alert alert-info">{info}</div>}
            </>
        );
    }
    // Houfy
    const apiKeyOverlay = <Tooltip id="apiKeyOverlay">Enter your Houfy API key.</Tooltip>;
    const apiKeyClasses = classNames("form-control", {
        "is-invalid": errors.apiKey
    });

    return (
        <>
            <p>
                Host Tools will use your API key to automatically send messages to your guests or
                set your listing&apos;s prices and availability on your behalf. It will only send
                messages you tell it to send and will never make any changes to your account without
                your permission.
            </p>
            <p>
                All network traffic is encrypted on Host Tools and all data is stored using the
                latest compliance standards.
            </p>
            <p>
                {"You can find your Houfy.com 'API Access Token' in your "}
                <a
                    href="https://www.houfy.com/account/settings?tab=2"
                    target="_blank"
                    rel="noreferrer"
                >
                    account settings page
                </a>
                {" on Houfy.com."}
            </p>

            <div className="pd-md-20">
                <div className="mg-b-20 d-flex justify-content-start align-items-center">
                    <img
                        src={houfyLogoSolidSquare}
                        alt="Houfy"
                        className="rounded-circle mg-r-20"
                        height="65"
                    />
                    <div>
                        <h2 className="mg-b-0">Houfy Account</h2>
                        <span className="text-muted text-uppercase tx-11">
                            Link your account with your Houfy Access Token
                        </span>
                    </div>
                </div>
                <div className="form-group">
                    <label
                        htmlFor="apiKey"
                        className="az-content-label tx-11 tx-medium tx-gray-600"
                    >
                        API Access Token
                        <OverlayTrigger placement="top" overlay={apiKeyOverlay}>
                            <FiHelpCircle className="text-muted ml-1" />
                        </OverlayTrigger>
                    </label>
                    <input
                        data-testid="apiKey"
                        id="apiKey"
                        className={apiKeyClasses}
                        placeholder="API Access Token..."
                        name="apiKey"
                        type="apiKey"
                        autoComplete="off"
                        value={credentials.apiKey}
                        onChange={event => {
                            handleChangeCredentials("apiKey", event.target.value);
                        }}
                        required
                    />
                    {errors.apiKey && <div className="alert alert-danger">{errors.apiKey}</div>}
                </div>
            </div>
            {errors.error && <div className="alert alert-danger">{errors.error}</div>}
        </>
    );
};

export default Credentials;
