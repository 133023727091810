import {ChevronDownIcon} from "native-base";
import React from "react";
import {components} from "react-select";
import type {Components} from "react-select/src/components";

export const DropdownIndicator: Components["DropdownIndicator"] = props => {
    if (props.selectProps.hideDropdownIndicator) {
        return <></>;
    }
    return (
        <components.DropdownIndicator {...props}>
            <ChevronDownIcon color="blueGray.800" size="sm" />
        </components.DropdownIndicator>
    );
};
