import type {AirbnbThreadObj} from "@hosttools/core/channels/airbnb/types";
import {Channel} from "@hosttools/core/shared/model/channel";

import type {Message} from "../../../models/message";
import type {ReservationSource, ReservationPost} from "../../../models/reservation";
import type {Timeline} from "../../../models/timeline";
import type {ChannelsThreadObj} from "../useChannelThread";

export interface Post {
    id: string;
    date: string;
    post:
        | {
              type: "timeline";
              data: Timeline;
          }
        | {
              type: "message";
              data: Message;
          }
        | {
              type: "post";
              data: ReservationPost;
          };
}

export const postFillerPrefix = "addedAt-";
export const base64ImagePrefix = "data:image/png;base64,";

export function buildPostFromAirbnbReservationPost(
    post: ReservationPost,
    thread: AirbnbThreadObj
): Post | undefined {
    // use the attachments urls from channel thread
    const threadPost = thread.posts?.find(elem => elem.externalID === post.externalID);
    if (threadPost?.attachments) {
        return {
            id: post._id,
            date: post.sentTimestamp,
            post: {
                type: "post",
                data: {
                    ...post,
                    attachments: threadPost.attachments.map(elem => ({
                        _id: "",
                        publicId: "",
                        url: elem
                    }))
                }
            }
        };
    }
}

export const buildPosts = (
    reservationID: string,
    source: ReservationSource,
    baseUrl: string,
    posts: ReservationPost[],
    thread?: ChannelsThreadObj
) => {
    return posts.map(post => {
        // Airbnb
        if (
            thread &&
            "posts" in thread &&
            thread.posts &&
            post.externalID &&
            post.attachments?.length
        ) {
            // use the attachments urls from channel thread
            const result = buildPostFromAirbnbReservationPost(post, thread);
            if (result) {
                return result;
            }
        }

        // Booking
        if ((source === Channel.Booking || source === Channel.HomeAway) && post.attachments) {
            const attachments = post.attachments.reduce((arr, elem) => {
                // Do nothing if url is existing
                if (elem.url) {
                    arr.push(elem);
                    return arr;
                }

                // treat `publicId` as `attachmentID`
                const attachmentID = elem.publicId;
                arr.push({
                    _id: "", // not used for now
                    url: `${baseUrl}/getAttachment/${reservationID}/${attachmentID}`,
                    publicId: attachmentID
                });
                return arr;
            }, [] as Required<ReservationPost>["attachments"]);

            return {
                id: post._id,
                date: post.sentTimestamp,
                post: {
                    type: "post",
                    data: {...post, attachments}
                }
            } as Post;
        }

        return {
            id: post._id,
            date: post.sentTimestamp,
            post: {
                type: "post",
                data: {
                    ...post,
                    ...(post.attachments && {
                        attachments: post.attachments.filter(elem => elem.url)
                    })
                }
            }
        } as Post;
    });
};

export function sortPosts<T extends {sentTimestamp: string}>(posts?: T[]): T[] {
    return posts
        ? posts.sort((a, b) => {
              const dateA = new Date(a.sentTimestamp).getTime();
              const dateB = new Date(b.sentTimestamp).getTime();

              return dateA > dateB ? -1 : 1;
          })
        : [];
}

export const filterOutFillerMessages = (items: Post[]) =>
    items.filter(elem => !elem.id.startsWith(postFillerPrefix));

export const filterDuplicatedItems = <T extends {id: string}>(prevOnes: T[], addedOnes: T[]) => {
    const prevIDs = prevOnes.map(elem => elem.id);
    return addedOnes.filter(addedOne => {
        return !prevIDs.includes(addedOne.id);
    });
};
