import RCTooltip from "rc-tooltip";
import type {TooltipProps} from "rc-tooltip/lib/Tooltip";
import React, {forwardRef, memo, useCallback, useImperativeHandle, useState} from "react";

type Props = {
    label: React.ReactNode;
    children: React.ReactNode;
    open?: boolean;
    interaction?: boolean;
} & Pick<TooltipProps, "placement" | "trigger">;

export type TooltipRef = {
    openTooltip: () => void;
    closeTooltip: () => void;
};

const Tooltip: React.ForwardRefRenderFunction<TooltipRef, Props> = (
    {label, children, placement = "top", trigger = "hover", interaction, open: initOpen},
    ref
) => {
    const [visible, setVisible] = useState(initOpen);

    const handleVisibleChange = useCallback((newVisible: boolean) => {
        setVisible(newVisible);
    }, []);

    const hideTooltip = useCallback(() => {
        setVisible(false);
    }, []);

    useImperativeHandle(
        ref,
        () => ({openTooltip: () => setVisible(true), closeTooltip: hideTooltip}),
        [hideTooltip]
    );

    return (
        <RCTooltip
            visible={visible}
            onVisibleChange={handleVisibleChange}
            placement={placement}
            trigger={trigger}
            overlay={label}
        >
            <div onMouseLeave={interaction ? undefined : hideTooltip} style={{lineHeight: 0}}>
                {children}
            </div>
        </RCTooltip>
    );
};

export default memo(forwardRef(Tooltip));
