import {useMemo} from "react";

import {ZINDEX_POPOVER, ZINDEX_DRAWER, ZINDEX_MODAL} from "@/admin/theme/constant";

function useOverlayStyle(type: "popover" | "drawer" | "modal") {
    const overlay = useMemo(
        () => ({
            style: {
                // this helps to prevent user from scrolling from top down direction on Safari iOS
                overflow: "hidden" as const,
                zIndex:
                    type === "popover"
                        ? ZINDEX_POPOVER
                        : type === "modal"
                        ? ZINDEX_MODAL
                        : ZINDEX_DRAWER
            }
        }),
        [type]
    );

    return overlay;
}

export default useOverlayStyle;
