import {type AccountType} from "@hosttools/frontend/models/account";
import {ArrowDownIcon, Button, HStack, Pressable} from "native-base";
import React, {memo, useCallback, type FC} from "react";

import Badge from "@/client/components/Badge";
import ConnectChannelItemLabel from "@/client/components/ConnectChannelItemLabel";

interface Props {
    accountType: AccountType;
    isDisabled?: boolean;
    position: "first" | "last" | "middle";
    onPress?: (type: AccountType) => void;
}

const ChannelItem: FC<Props> = ({accountType, isDisabled, position, onPress}) => {
    return (
        <Pressable onPress={() => onPress?.(accountType)}>
            <HStack
                justifyContent="space-between"
                p={3}
                borderTopWidth={1}
                borderBottomWidth={position === "last" ? 1 : 0}
                borderColor="blueGray.200"
            >
                <HStack space={2} alignItems="center">
                    <ConnectChannelItemLabel size="xl" accountType={accountType} />
                    {(accountType === "Airbnb" || accountType === "Houfy") && (
                        // the Badge component shouldn't set the text color based on the passed color scheme
                        <Badge color="blue" variant="outline">
                            Recommended
                        </Badge>
                    )}
                </HStack>
                <Button
                    testID={`btn-import-listings-${accountType}`}
                    variant="outline"
                    isDisabled={isDisabled}
                    leftIcon={<ArrowDownIcon />}
                    onPress={useCallback(() => {
                        onPress?.(accountType);
                    }, [accountType, onPress])}
                >
                    Import listings
                </Button>
            </HStack>
        </Pressable>
    );
};

export default memo(ChannelItem);
