import type {ComponentType} from "react";
import React from "react";

export function isReactElement(element: React.ReactNode): element is React.ReactElement<unknown>;

export function isReactElement<P>(
    element: React.ReactNode,
    type: ComponentType<P>
): element is React.ReactElement<P>;

export function isReactElement<P>(
    element: React.ReactNode,
    type?: ComponentType<P>
): element is React.ReactElement<P> {
    if (!type) {
        return React.isValidElement(element);
    }
    return React.isValidElement(element) && element.type === type;
}
