import {getLocal, setLocal} from "./storage";

export function formatPhoneNumber(str: string | number) {
    const cleaned = `${str}`.replace(/\D/g, "");
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        // Remove the matched extension code
        // Change this to format for any country code.
        const intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }

    return `${str}`;
}

export function object2Array<T>(obj: Record<string, T>): Array<T> {
    return Object.keys(obj).map(key => obj[key]);
}

export function array2Object<T extends {_id: string}>(arr: Array<T>): Record<string, T> {
    return arr.reduce<Record<string, T>>((result, item) => {
        result[item._id] = item;
        return result;
    }, {});
}

export function arrayToObject<T extends {}, K extends keyof T>(arr: T[], key: K) {
    return arr.reduce((obj, item) => {
        const k = item[key];
        obj[k] = item;
        return obj;
        // @ts-ignore 5.x can fix this issue
    }, {} as Record<T[K], T>);
}

export function checkIsMacDevice() {
    return window.navigator.userAgent.toUpperCase().indexOf("MAC") >= 0;
}

export function takeFirstLetter(name: string) {
    const parts = name.split(" ");
    let firstLetter = "";
    if (parts.length >= 1) {
        firstLetter = parts[0].charAt(0);
    }
    if (parts.length >= 2) {
        firstLetter += parts[1].charAt(0);
    }
    return firstLetter.toUpperCase();
}

const LS_NEW_UI_KEY = "new-ui";

export const setNewUIFlag = (value: boolean) => {
    setLocal(LS_NEW_UI_KEY, value);
};

export const getNewUIFlag = (): boolean => getLocal(LS_NEW_UI_KEY);
