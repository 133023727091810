import {type AccountCustom} from "@hosttools/frontend/models/account";
import {HStack} from "native-base";
import React, {memo} from "react";

import ChannelLogo from "@/client/components/ChannelLogo";
import Paragraph from "@/client/components/Paragraph";

interface Props {
    account: AccountCustom;
}

const Header: React.FC<Props> = ({account}) => {
    return (
        <HStack alignItems="center" space={2}>
            <ChannelLogo channel={account.type} variant="circle" size="md" />
            <Paragraph variant="sm" color="blueGray.800">
                {account.username ?? "-"}
            </Paragraph>
        </HStack>
    );
};

export default memo(Header);
