import {useEffect, useRef, useLayoutEffect, useCallback, type RefObject} from "react";

// for window events
function useEventListener<K extends keyof WindowEventMap>(
    event: K,
    handler: (event: WindowEventMap[K]) => void,
    element?: undefined,
    options?: AddEventListenerOptions
): void;

// for html events
function useEventListener<K extends keyof HTMLElementEventMap, T extends Element = HTMLDivElement>(
    event: K,
    handler: (event: HTMLElementEventMap[K]) => void,
    element: RefObject<T>,
    options?: AddEventListenerOptions
): void;

function useEventListener<
    W extends keyof WindowEventMap,
    H extends keyof HTMLElementEventMap,
    T extends HTMLElement = HTMLElement
>(
    event: W | H,
    handler: (event: WindowEventMap[W] | HTMLElementEventMap[H] | Event) => void,
    element?: RefObject<T>,
    options?: AddEventListenerOptions
) {
    const savedHandler = useRef(handler);

    useLayoutEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    const listener = useCallback<typeof handler>(event => {
        savedHandler.current(event);
    }, []);

    useEffect(() => {
        const targetElement = element?.current ?? window;
        if (!(targetElement && targetElement.addEventListener)) {
            return;
        }

        targetElement.addEventListener(event, listener, options);

        return () => {
            targetElement.removeEventListener(event, listener, options);
        };
    }, [event, element, options, listener]);
}

export {useEventListener};
