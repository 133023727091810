import {useListingService} from "@hosttools/frontend";
import {type AccountType} from "@hosttools/frontend/models/account";
import {buildChannel} from "@hosttools/frontend/models/channel";
import type {Listing} from "@hosttools/frontend/models/listing";
import React, {useCallback} from "react";

import useConfirmModal from "./useConfirmModal";

import Token from "@/client/components/ModalConfirm/Token";

function useConfirmRemoveChannel(listing: Listing, channel?: AccountType) {
    const listingService = useListingService();

    const [onRemoveChannel, updateConfirmModal] = useConfirmModal({
        title: "Delete channel",
        message: "Are you sure you would like to remove this channel?",
        buttonText: "Delete",
        data: channel,
        onOk: async value => {
            if (value) {
                await listingService.disconnectListingFromChannel(listing._id, value);
            }
        }
    });

    return useCallback(
        (value: AccountType) => {
            onRemoveChannel();
            const {label} = buildChannel(value);
            // update the message & body as soon as gathering enough data
            updateConfirmModal({
                title: `Delete ${label}`,
                data: value,
                message: (
                    <>
                        Are you sure you want to remove <Token>{label}</Token> from{" "}
                        <Token>{listing.name}</Token>?
                    </>
                )
            });
        },
        [listing.name, onRemoveChannel, updateConfirmModal]
    );
}

export default useConfirmRemoveChannel;
