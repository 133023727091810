import colors from "@hosttools/frontend/theme/customColor";
import shadows from "@hosttools/frontend/theme/shadows";
import {extendTheme} from "native-base";

import {components} from "./components";
import {fonts} from "./font";

const theme = extendTheme<HostToolsTheme>({
    // the `HostToolsTheme['components']` isn't well-defined
    // so ignore it for now
    ...(components as any),
    ...fonts,
    colors,
    shadows
} as HostToolsTheme);

export default theme;
