import type {Listing} from "@hosttools/frontend/models/listing";
import {Center, VStack} from "native-base";
import React, {memo, useState, useCallback} from "react";

import ListingSlat from "../ListingSlat";
import MenuItem from "../MenuItem";
import SearchInput from "../SearchInput";

import useFilteredListings from "./useFilteredListings";

import Text from "@/client/components/Text";

type Props = {
    listings: Listing[];
    to: "messaging" | "pricing";
    onClick?: () => void;
};

const ListingsMenu: React.FC<Props> = ({listings, to, onClick}) => {
    const [query, setQuery] = useState("");

    const filteredListings = useFilteredListings(listings, query);

    const handleClearSearch = useCallback(() => setQuery(""), []);

    return (
        <VStack space={3}>
            <Text variant="md" color="blueGray.800">
                Listings
            </Text>
            <SearchInput
                testID="input-search-listing"
                query={query}
                onChangeText={setQuery}
                onClear={handleClearSearch}
            />
            <VStack>
                {filteredListings.map(listing => (
                    <MenuItem
                        key={listing._id}
                        testID="link-listing-item"
                        path={`/${to}/${listing._id}`}
                        onClick={onClick}
                    >
                        <ListingSlat listing={listing} size="xs" />
                    </MenuItem>
                ))}
                {filteredListings.length === 0 && (
                    <Center>
                        <Text variant="sm">No listing found</Text>
                    </Center>
                )}
            </VStack>
        </VStack>
    );
};

export default memo(ListingsMenu);
