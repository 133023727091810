import type {Reservation} from "../../../models/reservation";

import type {ReservationListing} from "./types";

export function mapListingReservation<L extends {_id: string}>(
    reservations: Reservation[],
    listings: L[]
) {
    // make map with an given 2D array
    const listingsInObject = new Map([...listings.map(elem => [elem._id, elem] as const)]);
    const results = reservations.reduce((result, reservation) => {
        const listing = listingsInObject.get(reservation.listingID);
        if (listing) {
            result = [
                ...result,
                {
                    reservation,
                    listing
                }
            ];
        }
        return result;
    }, [] as ReservationListing<L>[]);
    return results;
}
