import {useContext, useMemo} from "react";

import PricingService from "../../../services/pricing";
import {HTTPContext} from "../../contexts";

export const usePricingService = () => {
    const http = useContext(HTTPContext);
    const pricingService = useMemo(() => new PricingService(http), [http]);
    return pricingService;
};
