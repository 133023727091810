import {Heading as HeadingNB, type IHeadingProps} from "native-base";
import React, {memo, useMemo} from "react";

import {type SupportedColors} from "../Text";

export type Variants = "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "3xl";

type Props = Omit<
    IHeadingProps,
    "size" | "fontSize" | "fontWeight" | "lineHeight" | "letterSpacing"
> & {
    variant: Variants;
    color?: SupportedColors | "blueGray.600";
};

const Heading: React.FC<Props> = ({variant, children, ...rest}) => {
    const variants = useMemo(() => {
        return {
            xs: {
                size: "xs",
                fontWeight: "bold",
                lineHeight: "sm"
            },
            sm: {
                size: "sm",
                fontWeight: "bold",
                lineHeight: "sm"
            },
            md: {
                size: "md",
                fontWeight: "bold",
                lineHeight: "sm"
            },
            lg: {
                size: "lg",
                fontWeight: "bold",
                lineHeight: "sm"
            },
            xl: {
                size: "xl",
                fontWeight: "bold",
                lineHeight: "sm"
            },
            "2xl": {
                size: "2xl",
                fontWeight: "bold",
                lineHeight: "sm"
            },
            "3xl": {
                size: "3xl",
                fontWeight: "bold",
                lineHeight: "sm"
            }
        } satisfies Record<Variants, IHeadingProps>;
    }, []);

    return (
        <HeadingNB {...rest} fontFamily="heading" {...variants[variant]}>
            {children}
        </HeadingNB>
    );
};

export default memo(Heading);
