import type {ICheckboxProps} from "native-base";
import {Checkbox as CheckboxNB} from "native-base";
import React, {memo} from "react";

import PopoverInfo from "../PopoverInfo";

export type Props = {tooltip?: React.ReactNode} & ICheckboxProps;

const Checkbox: React.FC<Props> = ({children, tooltip, ...restProps}) => {
    return (
        <CheckboxNB
            borderColor="blueGray.400"
            borderWidth="1px"
            _text={{color: "blueGray.500", ml: 0}}
            {...restProps}
        >
            {children}
            {tooltip && <PopoverInfo label={tooltip} />}
        </CheckboxNB>
    );
};

export default memo(Checkbox);
