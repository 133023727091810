import type {Account, ReservationDocument} from "../generated/models";

export const DATE_FORMAT = "YYYY-MM-DD";

export type ReservationStatus = ReservationDocument["status"];
export type ReservationSource = ReservationDocument["source"];
export type ReservationDisplaySource = Exclude<ReservationDocument["source"], "HomeAway"> | "VRBO";
export type ReservationProviderSource = Exclude<ReservationDocument["source"], "internal">;
export type AccountType = Account["type"];

export const originalChannels = ["Airbnb", "HomeAway", "Booking", "Houfy"] as const;
export const providerChannels = [...originalChannels, "ChannelConnector"] as const;
export type Channels = (typeof providerChannels)[number];
export const channels = providerChannels;

// NOTE: setting `as const satisfies readonly IntegrationsType[]`
// the babel which is used in metro config might not be able to understand it
export const integrations = ["August", "Seam"] as const;
export type IntegrationsType = (typeof integrations)[number];

export const internal = "internal";

export const reservationSources: ReservationSource[] = [...providerChannels, internal];

export const validListingStatuses = ["listed", "unlisted"] as const;

export type ValidListingStatuses = (typeof validListingStatuses)[number];

export const listingStatuses = [...validListingStatuses, "not found", "pending"] as const;

export type ListingStatuses = (typeof listingStatuses)[number];

export const priceSourceValues = ["Amount", "HomeAway", "Houfy", "PriceLabs", "Beyond Pricing"];

export const bookingWindowValues = [30, 45, 60, 75, 90, 180, 365, 547, 0];

export const reservationStatuses: ReservationStatus[] = [
    "inquiry",
    "not_possible",
    "preapproved",
    "denied",
    "timedout",
    "pending",
    "checkpoint",
    "awaiting_payment",
    "accepted",
    "cancelled",
    "needs_update",
    "deny",
    "timeout",
    "pending_payment",
    "at_checkpoint",
    "checkpoint_voided",
    "pending_verification"
];

export type EventReservationStatus = "accepted" | "inquiry" | "timedout" | "pending" | "cancelled";
export const eventReservationStatus: EventReservationStatus[] = [
    "accepted",
    "inquiry",
    "timedout",
    "pending",
    "cancelled"
];

export function isValidStatus(status: string) {
    return [
        "inquiry",
        "pending",
        "accepted",
        "cancelled",
        "needs_update",
        "timedout",
        "preapproved",
        "not_possible",
        "denied",
        "awaiting_payment",
        "withdrawn"
    ].includes(status);
}

export const permanentlyFailedMessageStatus = [
    "review_already_left",
    "review_not_left_cohost",
    "review_failed",
    "pre-approval_dates_not_available",
    "not_us_ca_phone_number",
    "not_a_valid_phone_number",
    "phone_number_violates_blacklist_rule",
    "invalid_email",
    "email_rejected",
    "user_subscription_inactive",
    "airbnb_account_logged_out",
    "homeaway_account_logged_out",
    "booking_account_logged_out",
    "houfy_account_logged_out",
    "message_failed_to_send_within_24_hours"
];

export const temporaryFailedMessageStatus = ["review_sent", "review_pending"];

export const failedMessageStatus = [
    ...permanentlyFailedMessageStatus,
    "do_not_have_permission",
    "send_message_failed",
    "sms_message_failed",
    "webhook_post_failed"
];

export const successMessageStatus = ["sent"];

export const disabledMessageStatus = ["disabled"];

export const enabledMessageStatus = ["enabled"];

export const messageCompleteStatus = [
    ...successMessageStatus,
    ...disabledMessageStatus,
    ...permanentlyFailedMessageStatus
];

export const messageStatus = [
    ...successMessageStatus,
    ...disabledMessageStatus,
    ...failedMessageStatus,
    ...enabledMessageStatus,
    ...temporaryFailedMessageStatus
] as const;

export type MessageStatus = (typeof messageStatus)[number];

export const UTC_TIMEZONE = "Etc/UTC";

export const languages = [
    "id",
    "ms",
    "ca",
    "da",
    "de",
    "en",
    "es",
    "el",
    "fr",
    "hr",
    "it",
    "hu",
    "nl",
    "no",
    "pl",
    "pt",
    "fi",
    "sv",
    "tr",
    "is",
    "cs",
    "ru",
    "he",
    "ar",
    "th",
    "ja",
    "zh",
    "zh-TW",
    "ko"
] as const;

export type Languages = (typeof languages)[number];

export const secondInMs = 1000;
export const minuteInMs = 60 * secondInMs;
export const hourInMs = 60 * minuteInMs;
export const dayInMs = 24 * hourInMs;

export const yearInDays = 365;
export const twoYearsInDays = 2 * yearInDays;

export const apiKeyNames = ["OpenAI"] as const;
export const maxImagesOnMessageRule = 5;
export const maxImagesOnListing = 20;

export type ApiKeyName = (typeof apiKeyNames)[number];

export const rateNames = [
    // PRICE
    "Base price",
    // DEPOSIT
    "Security deposit",
    // FEES
    "Cleaning fee",
    "Short term cleaning fee",
    "Security deposit",
    "Community fee",
    "Management fee",
    "Resort fee",
    "Pet fee",
    "Gas fee",
    "Electricity fee",
    "Water fee",
    "Heating fee",
    "Linens fee",
    "Air conditioning fee",
    "Utility fee",
    "Channel guest fee",
    "Channel guest VAT",
    "Channel host fee",
    "Channel host VAT",
    "Payment processing fee",
    "Additional guest fee",
    // PASS THROUGH TAXES
    "Hotel tax",
    "Lodging tax",
    "Room tax",
    "Tourist tax",
    "Transient occupancy tax", // "City tax"?
    "Sales tax",
    "VAT/GST",
    "Tourism Assessment/Fee",
    "Total taxes", // This is added because Houfy only returns a total tax amount
    // COLLECTED TAXES
    "Accommodations Tax",
    "General Sales and Use Tax"
];

export type RateNames = (typeof rateNames)[number];

export const rateTypes = ["fee", "tax", "price", "deposit"];

export type RateTypes = (typeof rateTypes)[number];

export const BETA_HOST = "https://beta.hosttools.com";
export const PROD_HOST = "https://app.hosttools.com";

export const helpLink = "https://help.hosttools.com";
export const supportEmail = "support@hosttools.com";
export const systemStatusLink = "https://hosttools.instatus.com/";

export const noop = () => {};
