import {memoWithType, useDebounce} from "@hosttools/frontend";
import {type Table, type Column} from "@tanstack/react-table";
import {Box, type IBoxProps} from "native-base";
import React, {useCallback, useState} from "react";

import SearchInput from "../SearchInput";

interface BaseProps {
    placeholder?: string;
    style?: IBoxProps;
}

interface PropsColumn<T> extends BaseProps {
    kind: "column";
    table: Table<T>;
    column: Column<T, unknown>;
}

interface PropsTable<T> extends BaseProps {
    kind: "table";
    table: Table<T>;
}

type Props<T> = StrictUnion<PropsTable<T> | PropsColumn<T>>;

const FilterText = <T,>({placeholder, kind, column, table, style}: Props<T>) => {
    const [searchText, setSearchText] = useState("");

    useDebounce(searchText, 350, value => {
        // probably reset the paging for every new search
        table.setPageIndex(0);
        if (kind === "table") {
            table.setGlobalFilter(value);
        } else {
            column.setFilterValue(value);
        }
    });

    const handleClearSearch = useCallback(() => {
        setSearchText("");
    }, []);

    return (
        <Box {...style}>
            <SearchInput
                query={searchText}
                placeholder={placeholder}
                onChangeText={setSearchText}
                onClear={handleClearSearch}
                testID="input-search"
            />
        </Box>
    );
};

export default memoWithType(FilterText);
