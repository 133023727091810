import {VStack, type IBoxProps} from "native-base";
import React, {memo} from "react";

import StepItem from "./StepItem";
import type {Item} from "./types";

export interface Props {
    items: Item[];
    current: number;
    maxWidth?: IBoxProps["maxWidth"];
}

const Steps: React.FC<Props> = ({items, current, ...rest}) => {
    const currentIdx = current - 1;
    return (
        <VStack testID="steps-container" {...rest}>
            {items.map((elem, idx) => (
                <StepItem
                    key={elem.title}
                    item={elem}
                    idx={idx}
                    status={idx < currentIdx ? "past" : idx === currentIdx ? "active" : "future"}
                    isLast={idx === items.length - 1}
                />
            ))}
        </VStack>
    );
};

export default memo(Steps);
