import {UserContext} from "@hosttools/frontend";
import {VStack} from "native-base";
import React, {memo, useCallback, useContext, useState} from "react";

import Banner from "../Banner";
import ModalDownloading from "../ModalAddAccount/ModalDownloading";
import ModalIntroduction from "../ModalAddAccount/ModalIntroduction";

import ModalMessage from "./ModalMessage";

import Link from "@/client/components/Link";
import Text from "@/client/components/Text";

const Errors: React.FC = () => {
    const [showDownloading, setShowDownloading] = useState(false);
    const [showIntroduction, setShowIntroduction] = useState(false);
    const {errors, user} = useContext(UserContext);

    const isSubUser = !!user.originUserID;

    const handleFinishIntroduction = useCallback(() => {
        setShowIntroduction(false);
    }, []);

    const handleHideIntroduction = useCallback(() => {
        setShowDownloading(true);
        setShowIntroduction(false);
    }, []);

    const handleHideDownloadingModal = useCallback(() => {
        setShowDownloading(false);
    }, []);

    if (!errors) {
        return null;
    }

    let bannerRenderer: React.ReactElement | null = null;

    if (errors.userNotActive) {
        bannerRenderer = (
            <Banner color="danger">
                <Text variant="sm">Subscribe Today! </Text>
                Your account is no longer active which means that all Host Tools automation has
                stopped for your listings. Please visit the&nbsp;
                <Link to="/settings/billing" fontWeight="bold">
                    billing page
                </Link>
                &nbsp;and add your credit card to subscribe and re-activate your account.
            </Banner>
        );
    }

    // if (errors.userNotActive && errors.accountAddedAfterTrial) {
    //     bannerRenderer = (
    //         <Banner alertClass="alert-danger">
    //             <p className="card-text">
    //                 <strong>Your data will download as soon as you have subscribed.&nbsp;</strong>
    //                 You added an account after your trial has finished. Please subscribe and Host
    //                 Tools will immediately download your your data.
    //             </p>
    //         </Banner>
    //     );
    // }

    if (!errors.userNotActive && (errors.noActiveAccounts || errors.nonActiveAccounts)) {
        bannerRenderer = (
            <Banner color="danger" actionText="View Channels" action="/settings/channels">
                <Text variant="sm">Your account is disconnected. </Text>
                Your account needs to be connected to Host Tools. Please browse the settings page
                and connect your account.
            </Banner>
        );
    }

    if (
        !errors.userNotActive &&
        !errors.noAccounts &&
        !errors.noActiveAccounts &&
        errors.noListings
    ) {
        bannerRenderer = (
            <Banner color="warning" actionText="Listings" action="/settings/listings">
                <Text variant="sm">No listings found! </Text>
                Host Tools didn&apos;t find any listings associated with your account. If
                you&apos;re account was recently added it can take a few minutes for Host Tools to
                download your listings. Just wait a few minutes and then hit the refresh button.
                <br />
                If you recently added a new listing, go to the&nbsp;
                <Link to="/settings/listings" fontWeight="bold">
                    settings page
                </Link>
                &nbsp;on Host Tools and click the &quot;Refresh&quot; button next to your account to
                download your latest listings.
            </Banner>
        );
    }

    if (
        !errors.userNotActive &&
        !errors.noActiveAccounts &&
        // !errors.listingsUpdating &&
        errors.noActiveListings
    ) {
        bannerRenderer = (
            <Banner color="warning" actionText="Listings" action="/settings/listings">
                <Text variant="sm">All your listings are disabled </Text>
                All listings are imported as &quot;disabled&quot; by default. Host Tools will not
                sync calendars or message guests until you enable your listings from the&nbsp;
                <Link to="/settings/listings" fontWeight="bold">
                    Settings
                </Link>
                &nbsp;page.
                <br />
                <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://help.hosttools.com/en/articles/7061829-enabled-disabled-and-hidden-what-is-the-difference"
                >
                    Read more.
                </a>
            </Banner>
        );
    }

    if (
        !errors.userNotActive &&
        !errors.noActiveAccounts &&
        errors.noVisibleListings &&
        isSubUser
    ) {
        bannerRenderer = (
            <Banner color="warning">
                This account is associated with a Listing Group that has child listings linked to
                it. Please ask the owner to remove the listings and then add them again to this
                listing group. Doing this will remove and child listings and only link parent
                listings.
            </Banner>
        );
    }

    //
    //
    //
    // Temp banner for beta users we need to talk to
    //
    //
    const isBetaUserWeNeedToTalkTo = [
        // "604f9301b7ef9d23ff3c7798", // testtest
        // "5b11dcdd44b20200087bd13d", // neil.aneja@gmail.com
        // "5b8745cecdda3300087fcfaa", // vannaguo@gmail.com
        // "5ae2377189fab400084846e7", // str@cgstl.com
        "5b61ad9eeb39810008e85854" // stefanpauloswald@gmail.com
        // "5b6b787699cb7a0008a4c8b6", // jose.g.yapor@gmail.com
        // "5b85c0a8c8056c000823c5a3" // gerardi.florian@gmail.com
    ].includes(user._id);
    const isBetaUserThatHaveNotEmailed = [
        // "604f9301b7ef9d23ff3c7798", // testtest
        // "5b11dcdd44b20200087bd13d", // neil.aneja@gmail.com
        // "5b8745cecdda3300087fcfaa", // vannaguo@gmail.com
        // "5ae2377189fab400084846e7", // str@cgstl.com
        "5b61ad9eeb39810008e85854" // stefanpauloswald@gmail.com
        // "5b6b787699cb7a0008a4c8b6" // jose.g.yapor@gmail.com
        // "5b85c0a8c8056c000823c5a3" // gerardi.florian@gmail.com
    ].includes(user._id);
    if (isBetaUserWeNeedToTalkTo) {
        bannerRenderer = (
            <Banner color="danger">
                <Text variant="sm">We need your help! </Text>
                <br />
                Host Tools has been accepted as a Preferred Partner with VRBO/Expedia group and we
                need your help.
                <br />
                <br />
                {"Tom sent you an email about joining the VRBO Pilot Program. "}
                <strong>We need your response right away</strong>
                <strong>
                    , please send Tom an email to
                    <a
                        target="_blank"
                        href={`mailto:t@hosttools.com?subject=VRBO Pilot Program&body=%0d%0a%0d%0a%0d%0a%0d%0a--------%0d%0aBeta UserID: ${user._id}`}
                        rel="noreferrer"
                    >
                        {" t@hosttools.com "}
                    </a>
                    now.
                </strong>
                <br />
                <br />
                We need a response from all Beta users right away.
                <br />
                <br />
                <strong>
                    You are seeing this message because Tom is waiting for your response about the
                    VRBO Pilot Program and we need to hear from you.
                </strong>
            </Banner>
        );
    }

    return (
        <VStack testID="error-container">
            {bannerRenderer}
            {isBetaUserWeNeedToTalkTo && (
                <ModalMessage user={user} hideCloseButtons={isBetaUserThatHaveNotEmailed} />
            )}
            {showIntroduction && (
                <ModalIntroduction
                    onFinish={handleFinishIntroduction}
                    onHide={handleHideIntroduction}
                />
            )}
            {showDownloading && <ModalDownloading onHide={handleHideDownloadingModal} />}
        </VStack>
    );
};

export default memo(Errors);
