import {fromLocalToZoned} from "@hosttools/frontend/utils";
import {
    addDays,
    format,
    isToday,
    isTomorrow,
    isValid,
    isWithinInterval,
    isYesterday,
    subDays
} from "date-fns";

export function formatDateRange(
    startDate: Date | string,
    endDate: Date | string,
    timezone: string
) {
    const startDateTz = fromLocalToZoned(startDate, timezone);
    const endDateTz = fromLocalToZoned(endDate, timezone);

    let reservationDateRange = "";
    if (format(startDateTz, "MMM") === format(endDateTz, "MMM")) {
        reservationDateRange = `${format(startDateTz, "MMM d")} - ${format(endDateTz, "d, y")}`;
    } else {
        reservationDateRange = `${format(startDateTz, "MMM d")} - ${format(endDateTz, "MMM d, y")}`;
    }

    return reservationDateRange;
}

export function formatDistanceFromNow(timestamp: Date) {
    let dateString = format(timestamp, "eee, MMMM do yyyy");
    if (isToday(timestamp)) {
        dateString = "Today";
    } else if (isTomorrow(timestamp)) {
        dateString = "Tomorrow";
    } else if (
        isWithinInterval(timestamp, {
            start: new Date(),
            end: addDays(new Date(), 7)
        })
    ) {
        dateString = format(timestamp, "eeee");
    } else if (isYesterday(timestamp)) {
        dateString = "Yesterday";
    } else if (
        isWithinInterval(timestamp, {
            start: subDays(new Date(), 7),
            end: new Date()
        })
    ) {
        dateString = `Last ${format(timestamp, "eeee")}`;
    }
    return dateString;
}

export const formatDate = (date: string | Date, formatPattern: string): string => {
    if (typeof date === "string") {
        return format(new Date(date), formatPattern);
    }
    return format(date, formatPattern);
};

export const formatMMMMyyyy = (date: string | Date): string => {
    return formatDate(date, "MMMM yyyy");
};

export function formatMMMDDYYYY(date: Date | string) {
    const formatPattern = "MMM dd, yyyy";
    return formatDate(date, formatPattern);
}

export function formatyyyyMMdd(date: Date | string) {
    const formatPattern = "yyyy-MM-dd";
    return formatDate(date, formatPattern);
}

export function formatMMddyyyy(date: Date | string) {
    const formatPattern = "MM/dd/yyyy";
    return formatDate(date, formatPattern);
}

export function isValidDateParam(date: any): date is number | string | Date {
    return isValid(new Date(date));
}
