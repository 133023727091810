import {type HeaderContext} from "@tanstack/react-table";
import React, {useCallback} from "react";

import Checkbox from "../CheckBox";

const TableCheckBoxHeader: React.FC<HeaderContext<any, any>> = ({table}) => {
    return (
        <Checkbox
            value="header"
            isChecked={table.getIsAllRowsSelected()}
            isIndeterminate={table.getIsSomeRowsSelected()}
            onChange={useCallback(value => table.toggleAllRowsSelected(value), [table])}
        />
    );
};

// putting `memo` might cause error here due to `table` value
export default TableCheckBoxHeader;
