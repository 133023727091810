import type {AxiosInstance, AxiosRequestConfig} from "axios";
import qs from "qs";

import {replacePathParams} from "../utils";

export class Base<E extends Record<string, any[]>> {
    constructor(protected http: AxiosInstance) {
        this.http = http;
    }

    async get<T extends keyof E & string>(url: T, params?: E[T][0], searchParams?: E[T][0]) {
        let urlWithParam = replacePathParams(url, params);
        if (searchParams) {
            urlWithParam += `?${qs.stringify(searchParams)}`;
        }
        const {data} = await this.http.get<MongooseModel2Client<E[T][2]>>(urlWithParam, params);
        return data;
    }

    async post<T extends keyof E & string>(url: T, payload?: E[T][1], config?: AxiosRequestConfig) {
        const {data} = await this.http.post<MongooseModel2Client<E[T][2]>>(url, payload, config);
        return data;
    }

    // newer version forces to pass the required params while allow not to pass the optional params
    // post = (async (url: string, payload?: Record<string, unknown>, config?: AxiosRequestConfig) => {
    //     const {data} = await this.http.post(url, payload, config);
    //     return data;
    // }) as Fn<E>;
}

// there is an error with this type that disables the hint although it still validates properly
// so temporarily disable it until we find a solution
// type Fn<E extends Record<string, any[]>> = UnionToIntersection<
//     Values<{
//         [K in keyof E]: IsNever<E[K][1]> extends true
//             ? (
//                   ...arg: [K, Record<string, never>?, AxiosRequestConfig?]
//               ) => Promise<MongooseModel2Client<E[K][2]>>
//             : (...arg: [K, E[K][1], AxiosRequestConfig?]) => Promise<MongooseModel2Client<E[K][2]>>;
//     }>
// >;
