import "../../../admin/css/react-select.css";

import {memoWithType} from "@hosttools/frontend";
import shadows from "@hosttools/frontend/theme/shadows";
import {useTheme} from "native-base";
import React, {useMemo} from "react";
import type {
    ActionMeta,
    OptionTypeBase,
    OptionsType,
    Props as ReactSelectProps,
    StylesConfig,
    ValueType
} from "react-select";
import ReactSelect from "react-select";
import type {Props as AsyncSelectProps} from "react-select/async";
import AsyncSelect from "react-select/async";

import {DropdownIndicator} from "./DropdownIndicator";
import {MenuList} from "./MenuList";
import {MultiValueLabel} from "./MultiValueLabel";
import {Option} from "./Option";
import {SingleValue} from "./SingleValue";

export interface OptionType<TValue = string | number> extends OptionTypeBase {
    label: string;
    value: TValue;
    Icon?: React.ReactElement;
    prefix?: React.ReactNode;
    suffix?: React.ReactNode;
    color?: "white";
    isFixed?: boolean;
}

type AsycnOptions<Option extends OptionType, IsMulti extends boolean> = {
    loadOptions: NonNullable<AsyncSelectProps<Option, IsMulti>["loadOptions"]>;
    options?: NonNullable<ReactSelectProps<Option>["options"]>;
};

type SyncOptions<Option extends OptionType> = {
    options: NonNullable<ReactSelectProps<Option>["options"]>;
};

export type Props<Option extends OptionType, IsMulti extends boolean = false> = StrictUnion<
    AsycnOptions<Option, IsMulti> | SyncOptions<Option>
> & {
    value?: Option | OptionsType<Option> | null;
    isMulti?: IsMulti;
    variant?: "outline" | "solid";
    error?: boolean;
    maxOptions?: number;
    menuIsOpen?: boolean;
    isInvalid?: boolean;
    hideDropdownIndicator?: boolean;
    onChange?: (selectedOption: ValueType<Option, IsMulti>, action: ActionMeta<Option>) => void;
} & ReactSelectProps<Option, IsMulti>;

const emptyObject = {};

function Select<Option extends OptionType, IsMulti extends boolean = false>({
    options,
    value,
    isMulti,
    variant = "outline",
    styles: stylesProps = emptyObject,
    error,
    maxOptions = 50,
    isInvalid = false,
    loadOptions,
    onChange,
    ...props
}: Props<Option, IsMulti>) {
    const {colors} = useTheme();
    const {singleValue, multiValue, multiValueRemove, ...rest} = stylesProps;

    const variantStyles = useMemo(() => {
        const styles = {
            solid: {
                control: {
                    backgroundColor: colors.blueGray["100"],
                    borderColor: error ? colors.error["600"] : "transparent",
                    borderWidth: "1px"
                }
            },
            outline: {
                control: {
                    backgroundColor: colors.white,
                    borderColor: error ? colors.error["600"] : colors.blueGray["200"],
                    borderWidth: "1px"
                }
            }
        };
        return styles[variant];
    }, [colors.blueGray, colors.error, colors.white, error, variant]);

    const {spread, textField} = shadows;
    const styles = useMemo(() => {
        return {
            control: (baseStyles, state) => ({
                ...baseStyles,
                borderRadius: "4px",
                ...variantStyles.control,
                ...(state.isFocused
                    ? {
                          boxShadow: `${spread.shadowOffset.width}px ${spread.shadowOffset.height}px ${spread.shadowRadius}px ${spread.elevation}px ${spread.shadowColor}80`
                      }
                    : {
                          boxShadow: `${textField.shadowOffset.width}px ${textField.shadowOffset.height}px ${textField.shadowRadius}px ${textField.elevation}px ${textField.shadowColor}1A`
                      }),
                opacity: state.isDisabled ? 0.5 : 1,
                ":hover": {
                    borderColor: isInvalid ? colors.danger["600"] : colors.blueGray["200"]
                },
                borderColor: colors.blueGray["200"],
                lineHeight: "18px", // To match Text/sm line height
                fontSize: "14px", // To match Text/sm line height
                fontWeight: "500", // To match Text/sm line height
                minHeight: "auto",
                paddingTop: 8,
                paddingBottom: 8,
                paddingLeft: 12,
                paddingRight: 12,
                ...(isInvalid && {borderColor: `${colors.danger["600"]}`})
            }),
            menu: baseStyles => ({
                ...baseStyles,
                width: "unset",
                minWidth: "100%",
                maxWidth: "300px",
                borderRadius: "4px",
                borderColor: colors.blueGray["200"],
                borderWidth: "1px",
                overflow: "hidden",
                marginLeft: "1px",
                marginRight: "1px"
            }),
            menuList: baseStyles => ({
                ...baseStyles,
                padding: 0
            }),
            option: baseStyles => ({
                ...baseStyles,
                color: colors.blueGray["800"],
                fontWeight: "500",
                cursor: "pointer",
                "&:hover": {
                    backgroundColor: colors.blueGray["100"]
                },
                padding: "12px"
            }),
            indicatorSeparator: baseStyles => ({
                ...baseStyles,
                backgroundColor: "transparent"
            }),
            dropdownIndicator: baseStyles => ({
                ...baseStyles,
                color: colors.blueGray["800"],
                padding: 0
            }),
            valueContainer: baseStyles => ({
                ...baseStyles,
                padding: 0,
                flexWrap: isMulti ? "wrap" : "nowrap"
            }),
            singleValue: (baseStyles, state) => {
                return {
                    ...baseStyles,
                    color: state.isDisabled ? colors.blueGray["400"] : colors.blueGray["800"]
                };
            },
            groupHeading: baseStyles => {
                return {
                    ...baseStyles,
                    marginRight: "8px",
                    marginLeft: "8px"
                };
            },
            placeholder: baseStyles => ({
                ...baseStyles,
                maxWidth: "100%",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                color: colors.blueGray["400"],
                margin: 0
            }),
            input: baseStyles => ({
                ...baseStyles,
                margin: 0,
                padding: 0
            }),
            clearIndicator: baseStyles => {
                return {
                    ...baseStyles,
                    display: "flex",
                    alignItems: "center",
                    padding: 0,
                    width: "16px",
                    height: "16px"
                };
            },
            multiValue: (base, state) => {
                return {
                    ...base,
                    borderRadius: "2px",
                    lineHeight: "15px",
                    backgroundColor: colors.blueGray[50],
                    border: `1px solid ${colors.blueGray[200]}`,
                    padding: "0px 4px",
                    margin: "0 8px 0px 0",
                    ...(state.data.isFixed && {backgroundColor: "gray"})
                };
            },
            multiValueRemove: (base, state) => {
                return {
                    ...base,
                    padding: 0,
                    borderRadius: "2px",
                    marginLeft: "2px",
                    color: colors.blueGray[600],
                    ":hover": {
                        color: colors.blueGray[600],
                        backgroundColor: colors.blueGray[100]
                    },
                    ...(state.data.isFixed && {display: "none"})
                };
            },
            ...rest
        } as StylesConfig<Option, IsMulti>;
    }, [isInvalid, spread, textField, rest, colors, variantStyles, isMulti]);

    if (loadOptions) {
        return (
            <AsyncSelect<Option, IsMulti>
                styles={styles}
                value={value}
                isMulti={isMulti}
                loadOptions={loadOptions}
                onChange={(value, action) => {
                    onChange?.(
                        isMulti ? value ?? ([] as unknown as ValueType<Option, IsMulti>) : value,
                        action
                    );
                }}
                components={{
                    Option,
                    SingleValue,
                    MenuList,
                    DropdownIndicator,
                    MultiValueLabel
                }}
                {...props}
            />
        );
    }

    return (
        <ReactSelect<Option, IsMulti>
            styles={styles}
            value={value}
            isMulti={isMulti}
            options={options}
            onChange={(value, action) => {
                onChange?.(
                    isMulti ? value ?? ([] as unknown as ValueType<Option, IsMulti>) : value,
                    action
                );
            }}
            components={{
                Option,
                SingleValue,
                MenuList,
                DropdownIndicator,
                MultiValueLabel
            }}
            maxOptions={maxOptions}
            {...props}
        />
    );
}

export default memoWithType(Select);
