import {Button} from "native-base";
import React, {memo, useCallback} from "react";

import type {QuickSelectDate} from "./type";

type Props = {
    day: QuickSelectDate;
    onQuickSelectDate: (day: QuickSelectDate) => void;
};

const QuickSelectDateButton: React.FC<Props> = ({day, onQuickSelectDate}) => {
    const onPress = useCallback(() => {
        onQuickSelectDate(day);
    }, [onQuickSelectDate, day]);

    return (
        <Button
            minW="auto"
            onPress={onPress}
            justifyContent={["center", "flex-start"]}
            colorScheme="blueGray"
            variant="ghost"
        >
            {day.name}
        </Button>
    );
};

export default memo(QuickSelectDateButton);
