import {useContext, useMemo} from "react";

import ListingService from "../../../services/listing";
import {HTTPContext} from "../../contexts";

export const useListingService = () => {
    const http = useContext(HTTPContext);
    const userService = useMemo(() => new ListingService(http), [http]);
    return userService;
};
