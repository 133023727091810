import {useCallback, useContext, useEffect, useMemo} from "react";

import {UserContext} from "../../contexts/UserContext";
import {useLatest} from "../useLatest";

interface UseDraftProps {
    id: string;
    setMessage: (message: string | null) => void;
    storage: {
        getItem: (key: string) => string | null | Promise<string | null>;
        setItem: (key: string, value: string) => void | Promise<void>;
        removeItem: (key: string) => void | Promise<void>;
    };
}

interface UseDraftReturn {
    clearDraft: () => void;
    saveDraftMessage: (message: string) => void;
}

export const useDraftMessage = ({
    id,
    setMessage,
    storage: localStorage
}: UseDraftProps): UseDraftReturn => {
    const {user} = useContext(UserContext);
    const setMessageRef = useLatest(setMessage);

    const getDraftMessageId = useCallback(() => {
        return `draftMessage_${id}_${user?._id}`;
    }, [id, user?._id]);

    const saveDraftMessage = useCallback(
        (message: string) => {
            if (message) {
                localStorage.setItem(getDraftMessageId(), message);
            } else {
                localStorage.removeItem(getDraftMessageId());
            }
        },
        [getDraftMessageId, localStorage]
    );

    const clearDraft = useCallback(() => {
        localStorage.removeItem(getDraftMessageId());
    }, [getDraftMessageId, localStorage]);

    useEffect(() => {
        const draftMessagePromise = localStorage.getItem(getDraftMessageId());
        if (draftMessagePromise instanceof Promise) {
            (async () => {
                const draftMessage = await draftMessagePromise;
                setMessageRef.current(draftMessage);
            })();
            return;
        }
        setMessageRef.current(draftMessagePromise);
    }, [getDraftMessageId, localStorage, setMessageRef]);

    return useMemo(
        () => ({
            saveDraftMessage,
            clearDraft
        }),
        [saveDraftMessage, clearDraft]
    );
};
