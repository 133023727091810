import {useEffect, useRef} from "react";

const useAbortController = (...deps: any[]) => {
    const abortRef = useRef<AbortController>();

    useEffect(() => {
        abortRef.current = new AbortController();
        return () => {
            abortRef.current?.abort();
        };
    }, deps);

    return abortRef;
};

export default useAbortController;
