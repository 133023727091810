import {
    DoubleChevronLeftIcon,
    DoubleChevronRightIcon
} from "@hosttools/frontend/react/components/Icons";
import buildTablePageNumbers from "@hosttools/frontend/utils/buildTablePageNumbers";
import type {IStackProps} from "native-base";
import {ChevronLeftIcon, ChevronRightIcon, HStack, View} from "native-base";
import React, {memo, useCallback, useMemo} from "react";

import Text from "../Text";

import ButtonPage from "./ButtonPage";

interface Props extends IStackProps {
    totalPages: number;
    currentPage: number;
    hasPrevPage: boolean;
    hasNextPage: boolean;
    onPrevious: () => void;
    onNext: () => void;
    onChangePage: (page: number) => void;
}

const TablePagination: React.FC<Props> = ({
    totalPages,
    currentPage,
    hasNextPage,
    hasPrevPage,
    onNext,
    onPrevious,
    onChangePage,
    ...css
}) => {
    const paginationNumbers = useMemo(
        () =>
            typeof totalPages === "number" ? buildTablePageNumbers(currentPage, totalPages) : [],
        [currentPage, totalPages]
    );

    const lastPage = paginationNumbers[paginationNumbers.length - 1];

    const goLast = useCallback(() => {
        if (typeof lastPage === "number") {
            onChangePage(lastPage - 1);
        }
    }, [lastPage, onChangePage]);

    const goFirst = useCallback(() => {
        onChangePage(0);
    }, [onChangePage]);

    return (
        <HStack space={2} {...css}>
            <ButtonPage isDisabled={currentPage === 1} onPress={goFirst}>
                <DoubleChevronLeftIcon color="blueGray.800" />
            </ButtonPage>
            <ButtonPage isDisabled={!hasPrevPage} onPress={onPrevious}>
                <ChevronLeftIcon size="xs" color="blueGray.800" />
            </ButtonPage>
            {paginationNumbers.map(page =>
                typeof page === "string" ? (
                    <View
                        key={page}
                        width={8}
                        height={8}
                        alignContent="flex-end"
                        justifyContent="center"
                    >
                        <Text variant="xs" textAlign="center">
                            ...
                        </Text>
                    </View>
                ) : (
                    <ButtonPage
                        key={page}
                        isActive={page === currentPage}
                        onPress={() => onChangePage(page - 1)}
                    >
                        {page}
                    </ButtonPage>
                )
            )}
            <ButtonPage isDisabled={!hasNextPage} onPress={onNext}>
                <ChevronRightIcon size="xs" color="blueGray.800" />
            </ButtonPage>
            <ButtonPage isDisabled={currentPage === lastPage} onPress={goLast}>
                <DoubleChevronRightIcon color="blueGray.800" />
            </ButtonPage>
        </HStack>
    );
};

export default memo(TablePagination);
