import "./TopNavMenuItem.scss";

import {Pressable, HStack} from "native-base";
import React, {forwardRef, memo} from "react";
import {Link} from "react-router-dom";

import Text from "@/client/components/Text";

type Props = {
    to: string | (() => void);
    children: string;
    isActive?: boolean;
    icon?: React.ReactNode;
    testID?: string;
};

const TopNavMenuItem: React.ForwardRefRenderFunction<unknown, Props> = (
    {to, children, isActive, icon, testID},
    ref
) => {
    const textNode = (
        <Text variant="sm" color={isActive ? "blue.600" : undefined}>
            {children}
        </Text>
    );

    return (
        <Pressable
            testID={testID}
            ref={ref}
            height="full"
            justifyContent="center"
            color={isActive ? "blue.600" : "blueGray.600"}
            borderStyle="solid"
            onPress={typeof to === "function" ? to : undefined}
        >
            <HStack role="button" space={1} px={2} py={2} borderRadius={4}>
                {typeof to === "string" ? <Link to={to}>{textNode}</Link> : textNode}
                {icon}
            </HStack>
        </Pressable>
    );
};

export default memo(forwardRef(TopNavMenuItem));
