import {providerChannels} from "@hosttools/core/constant";
import {useMemo, useRef} from "react";

import type {ChannelMapType} from "../../../models/channel";
import {buildChannel} from "../../../models/channel";

type Channels = (typeof providerChannels)[number];

export function useChannels<T extends Channels[]>(
    version: "short",
    exclude: T
): Exclude<Channels, T[number]>[];
export function useChannels<T extends Channels[]>(
    version: "full",
    exclude: T
): ChannelMapType[Exclude<Channels, T[number]>][];

export function useChannels<T extends Channels[]>(version: "short" | "full", exclude: T) {
    // don't necessarily re-calculate as soon as this value change for now
    // this goal is to allow to pass `exclude` without memo
    const excludeRef = useRef(exclude);
    excludeRef.current = exclude;

    const channels = useMemo(() => {
        if (excludeRef.current.length) {
            return providerChannels
                .filter(elem => excludeRef.current.some(channel => elem !== channel))
                .map(elem => {
                    return buildChannel(elem);
                });
        }
        return providerChannels.map(elem => {
            return buildChannel(elem);
        });
    }, []);

    if (version === "short") {
        return channels.map(elem => elem.value);
    }
    return channels;
}
