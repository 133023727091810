import usePathColors from "@hosttools/frontend/react/hooks/usePathColors";
import {useRootTheme} from "css-vars-hook";
import {useTheme} from "native-base";
import React, {memo, useEffect} from "react";

export type Props = {
    children: React.ReactNode;
};

const CSSVarsProvider: React.FC<Props> = ({children}) => {
    const {space} = useTheme();
    const flatColors = usePathColors();
    const {setTheme} = useRootTheme();

    const spacing = Object.fromEntries(
        Object.entries(space).map(([k, v]) => [`space-${k}`, `${v}px`])
    );

    useEffect(() => {
        setTheme({
            ...flatColors,
            ...spacing
        });
    }, [flatColors, spacing, setTheme]);

    return <>{children}</>;
};

export default memo(CSSVarsProvider);
