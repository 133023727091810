import type {IntegrationsType} from "@hosttools/core/constant";
import type {Account as AccountModel} from "@hosttools/core/generated/models";

import type {Listing} from "./listing";
import type {Lock} from "./lock";

export type AccountRaw = MongooseModel2Client<AccountModel>;
export type AccountType = AccountRaw["type"];

export type AccountIntegration = Account<IntegrationsType>;
export type AccountChannel = Account<Exclude<AccountType, IntegrationsType>>;
export type AccountChannelType = AccountChannel["type"];

export type AccountCustomIntegration = AccountCustom<IntegrationsType>;
export type AccountCustomChannel = AccountCustom<Exclude<AccountType, IntegrationsType>>;

type Accounts = Values<{
    [K in AccountType]: AccountRaw & {
        type: K;
        scope?: string;
    };
}>;

export type Account<T extends AccountType = AccountType> = Extract<Accounts, {type: T}>;

// flattern account into union type
type AccountsCustom = Values<{
    [K in AccountType]: AccountRaw & {
        type: K;
        logo?: string;
        scope?: string;
    } & (K extends "Seam" | "August" ? {locks: Lock[]} : {listings: Listing[]});
}>;

export type AccountCustom<T = AccountType> = Extract<AccountsCustom, {type: T}>;

export const sanitizeAccount = (account: Account): Account => {
    return {
        ...account,
        username: account.username ?? account.airbnbUsername
        // listings: [],
        // locks: []
    };
};
