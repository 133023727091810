import {VStack} from "native-base";
import React, {memo} from "react";

import Paragraph from "../Paragraph";

const texts = [
    "Host Tools can connect to channels to help automate your listing management. All features are disabled by default, Host Tools will only send messages, sync calendars, etc. once you&apos;ve enabled the feature.",
    "All network traffic is encrypted on Host Tools and all data is stored using the latest compliance standards."
];

const HostToolPolicy: React.FC = () => {
    return (
        <VStack space={4}>
            {texts.map(text => (
                <Paragraph key={text} variant="sm">
                    {text}
                </Paragraph>
            ))}
        </VStack>
    );
};

export default memo(HostToolPolicy);
