import type {Lock as LockModel} from "@hosttools/core/generated/models";
import {getLockName} from "@hosttools/core/shared/utils/utils.lock";

export type LockRaw = MongooseModel2Client<LockModel>;

export type Lock = LockRaw & {
    name: string;
    manufacturer: string;
    logo?: string;
    isLock: boolean;
};

export function sanitizeLock(lock: LockRaw): Lock {
    return {
        ...lock,
        // `nickname` could be an empty string
        name: getLockName(lock),
        manufacturer: lock.manufacturer ?? "",
        batteryLevel: lock.seam ? lock.batteryLevel : lock.august?.lock?.battery,
        isLock: true,
        listingIDs: lock.listingIDs ?? []
    };
}
