import {useAccountService} from "@hosttools/frontend";
import {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";

import useSearchQuery from "@/admin/hooks/useSearchQuery";

// `to` is a fallback url as account has been created
// if the `state` is not set on Airbnb OAuth url
const useCreateAirbnbAccount = (to: string) => {
    const query = useSearchQuery();
    const history = useHistory();
    const [accountID, setAccountID] = useState<string | null>();
    const accountService = useAccountService();
    // from query search
    const code = query.get("code");
    const state = query.get("state");

    useEffect(() => {
        if (accountID) {
            if (state) {
                const path = decodeURIComponent(state);
                history.push(path);
            } else {
                history.replace(to);
            }
        } else if (accountID === null) {
            // fail to create account still remove the code
            history.replace(to);
        }
    }, [to, state, accountID, history]);

    useEffect(() => {
        (async () => {
            if (code) {
                try {
                    const {accountID} = await accountService.connectAirbnbAccount(code);
                    setAccountID(accountID);
                } catch {
                    setAccountID(null);
                }
            }
        })();
    }, [code, accountService]);

    return {
        code,
        accountID
    };
};

export default useCreateAirbnbAccount;
