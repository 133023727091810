import type {ChannelObject} from "@hosttools/frontend/models/channel";
import classNames from "classnames";
import {Text, VStack} from "native-base";
import type {FC} from "react";
import React, {useEffect, useState} from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {FiHelpCircle} from "react-icons/fi";

import type {Errors} from "./types";

import FormField from "@/client/components/FormField";
import Input from "@/client/components/Input";
import useNewDesign from "@/client/hooks/useNewDesign";

interface Props {
    channel: ChannelObject;
    method: string;
    info: string;
    errors: Errors;
    onChange: (code: string) => void;
}

const Code: FC<Props> = props => {
    const {channel, method, info, errors: propErrors, onChange} = props;
    const isNewUIEnabled = useNewDesign();

    const [code, setCode] = useState("");
    const [errors, setErrors] = useState(propErrors);

    useEffect(() => {
        setErrors(propErrors);
    }, [propErrors]);

    function handleChangeCode(code: string) {
        setCode(code);
        onChange(code);
    }

    const codeOverlay = (
        <Tooltip id="codeOverlay">
            Enter the verification code that was sent to you by {channel.label}
        </Tooltip>
    );

    const codeClasses = classNames("form-control", {
        "is-invalid": errors.verificationCode
    });

    if (isNewUIEnabled) {
        return (
            <VStack space={5}>
                <Text>
                    {channel.label} has sent you a code to <b>{method}</b>, please enter it below.
                </Text>
                <FormField
                    label={`${channel.label} Verification Code`}
                    error={errors.verificationCode}
                    tooltip={`Enter the verification code that was sent to you by ${channel.label}`}
                >
                    <Input
                        id="code"
                        placeholder={`${channel.label} Verification Code...`}
                        name="code"
                        type="text"
                        value={code}
                        onChangeText={handleChangeCode}
                    />
                </FormField>
            </VStack>
        );
    }

    return (
        <>
            <p>
                {channel.label} has sent you a code to <b>{method}</b>, please enter it below.
            </p>
            <div className="pd-md-20">
                <div className="form-group">
                    <label htmlFor="code" className="az-content-label tx-11 tx-medium tx-gray-600">
                        {channel.label} Verification Code
                        <OverlayTrigger placement="top" overlay={codeOverlay}>
                            <FiHelpCircle className="text-muted ml-1" />
                        </OverlayTrigger>
                    </label>
                    <input
                        id="code"
                        className={codeClasses}
                        placeholder={`${channel.label} Verification Code...`}
                        name="code"
                        type="text"
                        autoComplete="off"
                        value={code}
                        onChange={event => {
                            handleChangeCode(event.target.value);
                        }}
                    />
                    {errors.verificationCode && (
                        <div className="alert alert-danger">{errors.verificationCode}</div>
                    )}
                </div>
            </div>
            {errors.error && <div className="alert alert-danger">{errors.error}</div>}
            {info && <div className="alert alert-info">{info}</div>}
        </>
    );
};

export default Code;
