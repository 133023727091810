import get from "lodash/get";
import type {IIconProps} from "native-base";
import {Icon as NBIcon, useTheme} from "native-base";
import type {IColors} from "native-base/lib/typescript/theme/base/colors";
import React, {memo, useMemo} from "react";

export type Props = {
    color?: IColors;
    fillStyle?: "fill" | "stroke";
} & Omit<IIconProps, "color">;

const Icon: React.FC<Props> = ({color, fillStyle = "fill", ...props}) => {
    const {colors} = useTheme();

    const colorProps = useMemo(() => {
        const colorHex = color ? get(colors, color) : undefined;

        return {
            fill: fillStyle === "fill" ? colorHex : "none",
            stroke: fillStyle === "stroke" ? colorHex : "none"
        };
    }, [color, fillStyle, colors]);

    return <NBIcon {...colorProps} {...props} />;
};

export default memo(Icon);
