import {useListingService} from "@hosttools/frontend";
import {useQuery} from "@tanstack/react-query";
import {useCallback} from "react";

// not sure why web server restarts as soon as saving this file?
const useFetchListingsFromAccount = (accountID: string | undefined) => {
    const listingService = useListingService();

    return useQuery({
        queryKey: ["listingsFromChannel", accountID],
        retry: 0,
        queryFn: useCallback(async () => {
            if (!accountID) {
                return Promise.resolve([]);
            }
            return listingService.fetchListingsFromAccount(accountID);
        }, [accountID, listingService])
    });
};

export default useFetchListingsFromAccount;
