import type {Endpoints} from "@hosttools/web/server/routes/routes.channels.types";

import {Base} from "../base";

class AccountService extends Base<Endpoints> {
    async forceUpdate(id: string) {
        await this.http.post("/forceUpdate", {
            _id: id
        });
    }

    async deleteAccount(id: string) {
        await this.http.post("/deleteAccount", {
            accountID: id
        });
    }

    async connectAirbnbAccount(code: string) {
        const {data} = await this.http.post<{accountID: string}>("/connectAirbnbAccount", {
            code
        });
        return data;
    }

    async getChannelConnectorURL(accountID: string) {
        const {data} = await this.http.post<{url: string}>("/getChannelConnectorURL", {
            accountID
        });
        return data.url;
    }
}

export default AccountService;
