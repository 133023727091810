import {CogIcon} from "@hosttools/frontend";
import type {Column, VisibilityState} from "@tanstack/react-table";
import type {IMenuProps} from "native-base";
import {Button, HStack} from "native-base";
import React, {memo, useCallback} from "react";

import CheckBox from "../CheckBox";
import Dropdown, {DropdownItem} from "../Dropdown";

import Text from "@/client/components/Text";

type Props = {
    columns: Column<{}, unknown>[];
    columnVisibility: VisibilityState;
};

const EditColumns: React.FC<Props> = ({columns, columnVisibility}) => {
    const triggerListingDropdown: IMenuProps["trigger"] = useCallback(triggerProps => {
        return (
            <Button
                {...triggerProps}
                variant="outline"
                colorScheme="blueGray"
                leftIcon={<CogIcon />}
            >
                Edit Columns
            </Button>
        );
    }, []);

    return (
        <Dropdown trigger={triggerListingDropdown} closeOnSelect={false}>
            {columns.map(column => (
                <DropdownItem key={column.id} onPress={() => column.toggleVisibility()}>
                    <HStack space={2} alignItems="center">
                        <CheckBox
                            value={column.id}
                            isChecked={columnVisibility[column.id] || column.getIsVisible()}
                            onChange={column.toggleVisibility}
                        />
                        <Text variant="xs" selectable={false}>
                            {column.columnDef.header}
                        </Text>
                    </HStack>
                </DropdownItem>
            ))}
        </Dropdown>
    );
};

export default memo(EditColumns);
