import {useDisclose} from "@hosttools/frontend";
import type {Listing} from "@hosttools/frontend/models/listing";
import type {ListingGroup} from "@hosttools/frontend/models/listingGroup";
import {ChevronDownIcon} from "@hosttools/frontend/react/components/Icons";
import type {IPopoverProps} from "native-base";
import {Box, Center, Popover} from "native-base";
import React, {useState, useEffect, useCallback, memo} from "react";
import {Nav, NavDropdown} from "react-bootstrap";
import {FiChevronDown, FiChevronLeft, FiMail, FiSearch, FiX} from "react-icons/fi";
import {Link, NavLink} from "react-router-dom";

import TopNavMenuItem from "@/client/components/Header/TopNavMenuItem";
import MessagingMenu from "@/client/components/NavMessaging/MessagingMenu";
import Text from "@/client/components/Text";
import useNewDesign from "@/client/hooks/useNewDesign";

type Props = {
    visibleListings: Listing[];
    listingGroups: ListingGroup[];
    isActive?: boolean;
};

const NothingFoundNode = (
    <Box px={4} py={2}>
        <Center>
            <Text variant="sm">Nothing Found</Text>
        </Center>
    </Box>
);

type LocalListingGroup = Omit<ListingGroup, "userID">;

const NavMessaging: React.FC<Props> = ({visibleListings, listingGroups, isActive}) => {
    const isNewUIEnabled = useNewDesign();

    const [query, setQuery] = useState("");
    const [filteredListings, setFilteredListings] = useState<Listing[]>([]);
    const [filteredListingGroups, setFilteredListingGroups] = useState<LocalListingGroup[]>([]);
    const {isOpen: showMessagesNav, onOpen, onClose, onToggle} = useDisclose();

    const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(event.target.value);
    }, []);

    const handleSearchByQuery = useCallback((query: string) => {
        setQuery(query);
    }, []);

    const handleSearch = useCallback((event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
    }, []);

    const handleClickSearch = useCallback((event: React.SyntheticEvent) => {
        event.preventDefault();
    }, []);

    const triggerMessagingMenu: IPopoverProps["trigger"] = useCallback(
        ({ref, onPress, ...triggerProps}) => {
            return (
                <TopNavMenuItem
                    ref={ref}
                    isActive={isActive}
                    icon={<ChevronDownIcon color="blueGray.400" />}
                    to={onPress}
                    {...triggerProps}
                >
                    Messaging
                </TopNavMenuItem>
            );
        },
        [isActive]
    );

    useEffect(() => {
        function filterListings() {
            const filteredQuery = query.toLowerCase();
            const filteredListings = visibleListings.filter(listing => {
                let matchesQuery = true;
                if (filteredQuery !== "") {
                    if (
                        listing.airbnbName.toLowerCase().indexOf(filteredQuery) === -1 &&
                        (!listing.nickname ||
                            listing.nickname.toLowerCase().indexOf(filteredQuery) === -1)
                    ) {
                        matchesQuery = false;
                    }
                }
                return matchesQuery;
            });
            let filteredListingGroups: LocalListingGroup[] = [...listingGroups];
            if (visibleListings.length > 1) {
                filteredListingGroups.unshift({
                    _id: "all",
                    name: "All Listings",
                    listings: [],
                    listingIDs: []
                });
            }
            filteredListingGroups = filteredListingGroups.filter(listingGroup => {
                let matchesQuery = true;
                if (filteredQuery !== "") {
                    if (listingGroup.name.toLowerCase().indexOf(filteredQuery) === -1) {
                        matchesQuery = false;
                    }
                }
                return matchesQuery;
            });
            setFilteredListings(filteredListings);
            setFilteredListingGroups(filteredListingGroups);
        }
        filterListings();
    }, [visibleListings, query, listingGroups]);

    if (isNewUIEnabled) {
        let menu: React.ReactElement | null = null;

        const genBasicDropdown = (menuContent: React.ReactNode) => (
            <Popover
                isOpen={showMessagesNav}
                onOpen={onOpen}
                onClose={onClose}
                trigger={triggerMessagingMenu}
            >
                <Popover.Content testID="popover-content">
                    <Popover.Arrow />
                    <Popover.Body testID="popover-body">{menuContent}</Popover.Body>
                </Popover.Content>
            </Popover>
        );

        if (visibleListings.length > 1 || listingGroups.length) {
            const menuContent: React.ReactNode = (
                <MessagingMenu
                    listingGroups={listingGroups}
                    visibleListings={visibleListings}
                    onClick={onClose}
                />
            );

            menu = genBasicDropdown(menuContent);
        } else if (visibleListings.length === 1) {
            menu = (
                <TopNavMenuItem to={`/messaging/${visibleListings[0]._id}`}>
                    Messaging
                </TopNavMenuItem>
            );
        } else {
            menu = genBasicDropdown(NothingFoundNode);
        }

        return menu;
    }

    let menu: React.ReactElement | null = null;
    const messagesDropdownButton = (
        <span className="d-flex flex-nowrap align-items-center">
            <FiMail className="d-block d-sm-none" />
            <span className="d-none d-sm-block">Messaging</span>
            <FiChevronDown className="ml-1" />
        </span>
    );
    const nothingFound = (
        <div key="messageRuleNavNothingFound" className="pd-x-15 pd-y-8 tx-gray-600 text-center">
            Nothing Found
        </div>
    );
    const genBasicDropdown = (items: React.ReactNode) => (
        <NavDropdown
            id="messagesDropdown"
            title={messagesDropdownButton}
            className="mr-2"
            show={showMessagesNav}
            onToggle={onToggle}
        >
            <div className="d-md-none pl-2 pr-2 pt-2 d-flex align-items-center justify-content-between">
                <button
                    className="az-header-arrow btn bg-white pd-l-10 pd-0"
                    type="button"
                    onClick={onClose}
                >
                    <FiChevronLeft />
                </button>
                <h4 className="m-0">Messaging</h4>
                <span className="p-3" />
            </div>
            <NavDropdown.Divider className="d-md-none" />
            <div className="pd-x-8 pd-b-8">
                <form className="search" onSubmit={event => handleSearch(event)}>
                    <input
                        type="search"
                        className="form-control"
                        placeholder="Filter..."
                        value={query}
                        onChange={handleChange}
                    />
                    {!query && (
                        <button type="button" className="btn" onClick={handleClickSearch}>
                            <FiSearch />
                        </button>
                    )}
                    {!!query && (
                        <button
                            type="button"
                            className="btn"
                            onClick={() => handleSearchByQuery("")}
                        >
                            <FiX />
                        </button>
                    )}
                </form>
            </div>
            <div className="scrollable-menu">{items}</div>
        </NavDropdown>
    );

    if (visibleListings.length > 1 || listingGroups.length) {
        const items = [];
        if (filteredListingGroups.length) {
            items.push(
                <div
                    key="messageRuleNavListingGroupsLabel"
                    className="pd-x-15 pd-y-8 tx-11 tx-medium tx-gray-600 tx-uppercase justify-content-between d-flex"
                >
                    Listing Groups
                </div>
            );
            for (let i = 0; i < filteredListingGroups.length; i += 1) {
                const listingGroup = filteredListingGroups[i];
                items.push(
                    <NavDropdown.Item
                        as={Link}
                        to={`/messaging/group/${listingGroup._id}`}
                        className="nav-sub-link justify-content-between d-flex"
                        key={`messageRuleNavItem${listingGroup._id}`}
                    >
                        <span className="text">{listingGroup.name}</span>
                        {"uniqueMessageRulesCount" in listingGroup &&
                            listingGroup.uniqueMessageRulesCount}
                    </NavDropdown.Item>
                );
            }
        }
        if (filteredListingGroups.length && filteredListings.length) {
            items.push(<NavDropdown.Divider key="messageRuleNavDivider" />);
        }
        if (filteredListings.length) {
            items.push(
                <div
                    key="messageRuleNavListingsLabel"
                    className="pd-x-15 pd-y-8 tx-11 tx-medium tx-gray-600 tx-uppercase"
                >
                    Listings
                </div>
            );
            for (let i = 0; i < filteredListings.length; i += 1) {
                const listing = filteredListings[i];
                items.push(
                    <NavDropdown.Item
                        as={Link}
                        to={`/messaging/${listing._id}`}
                        className="nav-sub-link justify-content-between d-flex"
                        key={`messageRuleNavItem${listing._id}`}
                    >
                        <span className="text">{listing.name}</span>
                    </NavDropdown.Item>
                );
            }
        }
        if (!filteredListingGroups.length && !filteredListings.length) {
            items.push(nothingFound);
        }
        menu = genBasicDropdown(items);
    } else if (filteredListings.length === 1) {
        menu = (
            <Nav.Link as={NavLink} to={`/messaging/${filteredListings[0]._id}`} className="mr-2">
                <FiMail className="d-block d-md-none" />
                <span className="d-none d-md-block">Messaging</span>
            </Nav.Link>
        );
    } else {
        menu = genBasicDropdown(nothingFound);
    }

    return menu;
};

export default memo(NavMessaging);
