export function setLocal(key: string, value: any): void {
    try {
        window.localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
        // Do Nothing
    }
}

export function getLocal(key: string): any {
    let value: any = null;

    try {
        value = window.localStorage.getItem(key);
        value = JSON.parse(value);
    } catch (e) {
        // Do Nothing
    }

    return value;
}
