import {type IntegrationsType, integrations} from "@hosttools/core/constant";
import {useMemo} from "react";

import type {
    Account,
    AccountChannel,
    AccountCustom,
    AccountIntegration,
    AccountCustomChannel,
    AccountCustomIntegration,
    AccountType
} from "../../../models/account";

type Acc<T extends AccountType = AccountType> = Account<T> | AccountCustom<T>;

interface ResultAccount {
    integrations: AccountIntegration[];
    badIntegrations: AccountIntegration[];
    channels: AccountChannel[];
    badChannels: AccountChannel[];
}

interface ResultAccountCustom {
    integrations: AccountCustomIntegration[];
    badIntegrations: AccountCustomIntegration[];
    channels: AccountCustomChannel[];
    badChannels: AccountCustomChannel[];
}

// use overload due to can't really use generic in this case
// T extends Account then using with T<"Seam"> and this is not working
// which is seemingly an issue with TS for now and it remains unsolved
function useAccounts(accounts: Account[]): ResultAccount;
function useAccounts(accounts: AccountCustom[]): ResultAccountCustom;
function useAccounts(accounts: (Account | AccountCustom)[]): ResultAccount | ResultAccountCustom {
    return useMemo(() => {
        const isLockAccount = (acc: Account): acc is Acc<IntegrationsType> => {
            return integrations.includes<true>(acc.type);
        };

        const [integrationAccounts, channelAccounts] = accounts.reduce(
            (arr, account) => {
                if (isLockAccount(account)) {
                    arr[0].push(account);
                } else {
                    arr[1].push(account);
                }
                return arr;
            },
            [
                [] as Acc<IntegrationsType>[],
                [] as Acc<Exclude<Account["type"], IntegrationsType>>[]
            ] as const
        );
        return {
            integrations: integrationAccounts.filter(account => account.lastLoginAttemptSuccessful),
            badIntegrations: integrationAccounts.filter(
                account => account.lastLoginAttemptSuccessful === false
            ),
            channels: channelAccounts.filter(account => {
                return account.lastLoginAttemptSuccessful;
            }),
            badChannels: channelAccounts.filter(account => {
                return account.lastLoginAttemptSuccessful === false;
            })
        };
    }, [accounts]);
}

export default useAccounts;
