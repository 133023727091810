function buildTablePageNumbers(currentPage: number, totalPage: number): Array<number | string> {
    const current = currentPage;
    const last = totalPage;
    const delta = 2;
    const left = current - delta;
    const right = current + delta + 1;
    const ranges: number[] = [];

    for (let i = 1; i <= last; i += 1) {
        if (i === 1 || i === last || (i >= left && i < right)) {
            ranges.push(i);
        }
    }

    const rangeWithDots = ranges.reduce((rangeWithDots, range, index, ranges) => {
        let lastRange = 0;
        if (index) {
            lastRange = ranges[index - 1];
        }
        if (lastRange) {
            if (range - lastRange === 2) {
                rangeWithDots.push(lastRange + 1);
            } else if (range - lastRange !== 1) {
                rangeWithDots.push("...");
            }
        }
        rangeWithDots.push(range);
        return rangeWithDots;
    }, [] as Array<number | string>);

    return rangeWithDots;
}

export default buildTablePageNumbers;
