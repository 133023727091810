import {memoWithType} from "@hosttools/frontend";
import type {Column, Table} from "@tanstack/react-table";
import {type IBoxProps, Box} from "native-base";
import React, {useCallback, useState} from "react";

import DateRangePicker from "../DateRangePicker";

interface Props<T> {
    table: Table<T>;
    column: Column<T, unknown>;
    style?: IBoxProps;
    defaultStartDate?: Date;
    defaultEndDate?: Date;
}

const FilterDateRange = <T,>({
    table,
    column,
    style,
    defaultStartDate,
    defaultEndDate
}: Props<T>) => {
    if (column.columnDef.filter?.type !== "dateRange") {
        throw new Error("Wrong type set!");
    }

    const [startDate, setStartDate] = useState<Date | undefined>(defaultStartDate);
    const [endDate, setEndDate] = useState<Date | undefined>(defaultEndDate);

    const handleDatePickerChange = useCallback(
        (startDate?: Date, endDate?: Date, isValidRangeDate?: boolean) => {
            setStartDate(startDate);
            setEndDate(endDate);
            if (startDate && endDate && isValidRangeDate) {
                table.setPageIndex(0);
                column.setFilterValue({startDate, endDate});
            }
        },
        [column, table]
    );

    return (
        <Box zIndex="auto" {...style}>
            <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                variant="filter"
                onSelectedDates={handleDatePickerChange}
                allowQuickSelectDate
            />
        </Box>
    );
};

export default memoWithType(FilterDateRange);
