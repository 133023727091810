import {useContext, useMemo} from "react";

import {UserContext} from "../../contexts";

export const useListingsByAccountID = (accountID: string | undefined) => {
    const {listings, accounts} = useContext(UserContext);

    return useMemo(() => {
        if (!accountID) {
            return [];
        }

        const account = accounts.find(elem => elem._id === accountID);
        if (!account || account.type === "August" || account.type === "Seam") {
            // invalid account
            return [];
        }
        if (listings.length) {
            return listings.filter(elem => {
                return elem.channels[account.type]?.accountID === accountID;
            });
        }
        return [];
    }, [accountID, accounts, listings]);
};
