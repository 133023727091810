import {useMemo} from "react";

export type Size = "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "3xl";

export function useChannelLogoVariant(size: Size) {
    return useMemo(() => {
        const channelLogoVariants = {
            xs: {
                logoSize: 12,
                textSize: "xs",
                space: 1
            },
            sm: {
                logoSize: 16,
                textSize: "sm",
                space: 1
            },
            md: {
                logoSize: 20,
                textSize: "md",
                space: 1
            },
            lg: {
                logoSize: 24,
                textSize: "lg",
                space: 1
            },
            xl: {
                logoSize: 32,
                textSize: "lg",
                space: 1
            },
            "2xl": {
                logoSize: 36,
                textSize: "lg",
                space: 1
            },
            "3xl": {
                logoSize: 48,
                textSize: "lg",
                space: 1
            }
        } satisfies Record<
            Size,
            {
                logoSize: number;
                textSize: "xs" | "sm" | "md" | "lg";
                space: number;
            }
        >;
        return channelLogoVariants[size];
    }, [size]);
}
