import type {Theme} from "native-base";
import {theme} from "native-base";

import {ZINDEX_TOAST} from "./constant";

type ToastType = Theme["components"]["Toast"];
export const Toast: Partial<ToastType> = {
    baseStyle: props => {
        const baseStyleProps = theme.components.Toast.baseStyle(props);
        return {
            ...baseStyleProps,
            _stack: {
                ...baseStyleProps._stack,
                testID: "toast-stack"
            },
            _overlay: {
                style: {
                    zIndex: ZINDEX_TOAST
                }
            },
            _presenceTransition: {
                animate: {
                    opacity: 1,
                    transition: {
                        duration: 250
                    }
                },
                exit: {
                    opacity: 0,
                    scale: 0.85,
                    transition: {
                        duration: 100
                    }
                }
            } as ReturnType<ToastType["baseStyle"]>["_presenceTransition"]
        };
    },
    defaultProps: {}
};
