import {useCallback, useLayoutEffect, useState} from "react";

import useDebounceFn from "../useDebounceFn";
import {useEventListener} from "../useEventListener";

type WindowSize = {
    width: number;
    height: number;
};

export function useWindowSize(options: {delay?: number}) {
    const [size, setSize] = useState<WindowSize>();

    const debouncedSetWindowSize = useDebounceFn(setSize, options.delay ?? 0);

    const handleSize = useCallback(() => {
        const setFn = options.delay ? debouncedSetWindowSize : setSize;
        setFn({
            width: window.innerWidth,
            height: window.innerHeight
        });
    }, [options.delay, debouncedSetWindowSize]);

    // register event on window
    useEventListener("resize", handleSize);

    useLayoutEffect(() => {
        handleSize();
    }, [handleSize]);

    return size;
}
