import type {Theme} from "native-base";
import {theme} from "native-base";

type AlertType = Theme["components"]["Alert"];

export const Alert: Partial<AlertType> = {
    baseStyle: {
        alignItems: "flex-start",
        justifyContent: "flex-start",
        p: 3,
        space: 3,
        borderRadius: "0"
    },
    variants: {
        ...theme.components.Alert.variants,
        solid: (props: Record<string, any>) => {
            let {colorScheme} = props;
            const {status} = props;
            colorScheme = status || colorScheme;
            return {
                px: 8,
                py: 3,
                bg: `${colorScheme}.50`,
                borderTopWidth: 0,
                borderTopColor: "transparent",
                borderBottomWidth: 1,
                borderBottomColor: `${colorScheme}.300`,
                _icon: {color: `${colorScheme}.600`}
            };
        },
        subtle: (props: Record<string, any>) => {
            let {colorScheme} = props;
            const {status} = props;

            colorScheme = status || colorScheme;

            return {
                p: 0,
                bg: "transparent",
                _icon: {color: `${colorScheme}.600`}
            };
        }
    },
    defaultProps: {
        colorScheme: "success",
        variant: "solid"
    }
};
