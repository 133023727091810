import {format} from "date-fns";
import React, {memo} from "react";
import type {DayContentProps} from "react-day-picker";
import {DayContent} from "react-day-picker";

const DateTime: React.FC<DayContentProps> = ({date, ...rest}) => {
    const dateTime = format(date, "yyyy-MM-dd");
    return (
        <span data-testid={dateTime}>
            <DayContent date={date} {...rest} />
        </span>
    );
};

export default memo(DateTime);
