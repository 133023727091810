import {Center} from "native-base";
import React, {type FC, memo} from "react";

import Text from "@/client/components/Text";

interface Props {
    children?: string;
}

const EmptyState: FC<Props> = ({children = "No data"}) => (
    <Center p={4} borderColor="blueGray.200" borderWidth={1} borderRadius={4}>
        <Text variant="sm" color="blueGray.400">
            {children}
        </Text>
    </Center>
);

export default memo(EmptyState);
