import type {AxiosInstance} from "axios";

import {type Billing, type Frequency} from "../../models/billing";

export class BillingService {
    private http: AxiosInstance;

    constructor(http: AxiosInstance) {
        this.http = http;
    }

    async fetchBilling() {
        const {data} = await this.http.get<Billing>("/getBilling");

        return data;
    }

    async updateFrequency(frequency: Billing["frequency"]) {
        const url = "/updateFrequency";
        const fields = {frequency};
        await this.http.post(url, fields);
    }

    async resubscribe() {
        const {data} = await this.http.post("/resubscribe");
        return data;
    }

    async cancelSubscription() {
        await this.http.post("/cancelSubscription");
    }

    async checkout(frequency: Frequency) {
        const url = "/checkout";
        const fields = {frequency};
        const {data} = await this.http.post<{url: string}>(url, fields);
        return data;
    }
}
