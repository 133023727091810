import {useContext, useMemo} from "react";

import AccountService from "../../../services/account";
import {HTTPContext} from "../../contexts";

export const useAccountService = () => {
    const http = useContext(HTTPContext);
    const userService = useMemo(() => new AccountService(http), [http]);
    return userService;
};
