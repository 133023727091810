import {useContext} from "react";

import {AppContext} from "../provider/AppProvider";

function useMobileView(): boolean;
function useMobileView<T = any>(mobileValue: T, desktopValue?: T): T;
function useMobileView(mobileValue?: any, desktopValue?: any) {
    const {isMobile} = useContext(AppContext);

    if (isMobile) {
        return typeof mobileValue === "undefined" ? true : mobileValue;
    }
    return typeof desktopValue === "undefined" ? false : desktopValue;
}

export default useMobileView;
