export function replacePathParams(url: string, params?: Record<string, string | number | boolean>) {
    if (!params) {
        return url;
    }
    return url.replace(/\/:([^/?]+)\??/g, (_, key) => {
        if (params[key] !== undefined) {
            return `/${encodeURIComponent(params[key])}`;
        }
        return "";
    });
}
