import gravatar from "gravatar";
import type {IMenuProps} from "native-base";
import {Avatar, HStack, HamburgerIcon, Image, Pressable} from "native-base";
import React, {memo, useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {Link, Redirect, matchPath, useLocation} from "react-router-dom";

import {INBOX_PATH, INBOX_URL, MESSAGING_PATH, MULTICALENDAR_PATH, PRICING_PATH} from "../constant";
import logoBlack from "../img/logo-black.svg";
import {UserContext} from "../providers/UserProvider";
import {BrowserNotification} from "../utils/browserNotification";

import Banner from "./Banner";
import ModalAddAccount from "./ModalAddAccount";
import NavMessaging from "./NavMessaging";
import NavPricing from "./NavPricing";

import Badge from "@/client/components/Badge";
import ButtonIcon from "@/client/components/ButtonIcon";
import Dropdown from "@/client/components/Dropdown";
import MenuProfile from "@/client/components/Header/MenuProfile";
import ModalMenuMobile from "@/client/components/Header/ModalMenuMobile";
import TopNavMenuItem from "@/client/components/Header/TopNavMenuItem";
import Text from "@/client/components/Text";
import QuickGuideButton from "@/client/features/QuickGuide/QuickGuideButton";
import SupportMenu from "@/client/features/SupportMenu";
import useMobileView from "@/client/hooks/useMobileView";

const logoSource = {uri: logoBlack};

const Header: React.FC = () => {
    const {
        user: {username, subscriptionStatus},
        visibleListings,
        listingGroups,
        permissions
    } = useContext(UserContext);
    const location = useLocation();
    const isMobile = useMobileView();
    const {pathname} = location;

    const [showAddAccountModal, setShowAddAccountModal] = useState(false);
    const [showModalMenuMobile, setShowModalMenuMobile] = useState(false);
    const [showBannerAskNotification, setShowBannerAskNotification] = React.useState(false);
    const browserNotifyRef = useRef<BrowserNotification | null>(null);
    const browserNotification = browserNotifyRef.current;
    const hasPermissionEditInbox = permissions.inbox?.edit;
    const hasPermissionEditPricing = permissions.pricing?.edit;
    const hasPermissionEditMessageRules = permissions.messageRules?.edit;

    const userImage = useMemo(
        () => gravatar.url(username, {s: "120", r: "pg", d: "mp"}),
        [username]
    );

    const triggerUserProfile: IMenuProps["trigger"] = useCallback(
        props => {
            return (
                <Pressable testID="user-profile" accessibilityLabel="User profile" {...props}>
                    <Avatar size="sm" source={{uri: userImage}} />
                </Pressable>
            );
        },
        [userImage]
    );

    useEffect(() => {
        browserNotifyRef.current = new BrowserNotification();
    }, []);

    const handleCloseBanner = () => {
        setShowBannerAskNotification(false);
    };

    const bannerNotificationRenderer = useMemo(() => {
        if (showBannerAskNotification) {
            const handleAskPermission = () => {
                browserNotification?.requestPermission(() => {
                    setShowBannerAskNotification(false);
                });
            };

            return (
                browserNotification?.shouldAskPermission() && (
                    <Banner
                        color="warning"
                        action={handleAskPermission}
                        actionText="Enable/Disable"
                        onClose={handleCloseBanner}
                    >
                        <Text variant="md" color="blueGray.800">
                            Browser Notifications.{" "}
                        </Text>
                        <Text variant="sm">
                            Would you like message and reservation notifications in your browser?
                        </Text>
                    </Banner>
                )
            );
        }

        return null;
    }, [showBannerAskNotification, browserNotification]);

    function handleCloseModal(isSuccess?: boolean) {
        setShowAddAccountModal(false);
        if (typeof isSuccess === "boolean" && isSuccess) {
            setShowBannerAskNotification(true);
        }
    }

    function handleContactMenu() {
        const intercom = window.Intercom;
        if (typeof intercom === "function") {
            intercom("show");
        }
    }

    const handleToglleMenuMobile = useCallback(() => {
        setShowModalMenuMobile(prev => !prev);
    }, []);

    const regexCalendar = /#!\/calendar\/(.*)/;
    const foundCalendar = location.hash.match(regexCalendar);
    if (foundCalendar) {
        return <Redirect to={`/pricing/${foundCalendar[1]}`} />;
    }
    const regexMessageRules = /#!\/messageRules\/(.*)/;
    const foundMessageRules = location.hash.match(regexMessageRules);
    if (foundMessageRules) {
        return <Redirect to={`/messaging/${foundMessageRules[1]}`} />;
    }
    const regexListings = /#!\/listings/;
    const foundListings = location.hash.match(regexListings);
    if (foundListings) {
        return <Redirect to="/listings" />;
    }
    const regexBilling = /#!\/billing/;
    const foundBilling = location.hash.match(regexBilling);
    if (foundBilling) {
        return <Redirect to="/billing" />;
    }
    const regexSettings = /#!\/settings/;
    const foundSettings = location.hash.match(regexSettings);
    if (foundSettings) {
        return <Redirect to="/settings" />;
    }

    const isCalendarActive = !!matchPath(pathname, {path: MULTICALENDAR_PATH, exact: true});
    const isInboxActive = !!matchPath(pathname, {path: INBOX_PATH, exact: true});
    const isMessagingActive = !!matchPath(pathname, {
        path: MESSAGING_PATH,
        exact: true
    });
    const isPricingActive = !!matchPath(pathname, {path: PRICING_PATH, exact: true});

    let subscriptionBadge: React.ReactNode;
    if (__IS_BETA__) {
        subscriptionBadge = (
            <Badge color="amber" variant="solid">
                Beta
            </Badge>
        );
    } else if (subscriptionStatus === "trialing") {
        subscriptionBadge = (
            <Badge color="amber" variant="solid">
                Trial
            </Badge>
        );
    }

    return (
        <>
            <HStack
                height={60}
                width="100vw"
                position="sticky"
                top={0}
                zIndex={100}
                px={[4, 7]}
                justifyContent="space-between"
                alignItems="center"
                bgColor="white"
                borderBottomColor="blueGray.200"
                borderBottomWidth={1}
                borderBottomStyle="solid"
            >
                <HStack
                    height="100%"
                    alignItems="center"
                    space={{
                        base: 4,
                        xl: 24
                    }}
                >
                    <HStack space={2} alignItems="center">
                        {isMobile && (
                            <ButtonIcon
                                tooltip="Menu"
                                size="lg"
                                onPress={handleToglleMenuMobile}
                                icon={HamburgerIcon}
                            />
                        )}
                        <Link to="/">
                            <Image
                                source={logoSource}
                                height={[22, 30]}
                                width={[110, 150]}
                                alt="Host Tools Logo"
                            />
                        </Link>
                    </HStack>
                    {!isMobile && (
                        <HStack testID="nav-menu-wrapper" height="100%">
                            <TopNavMenuItem to={MULTICALENDAR_PATH} isActive={isCalendarActive}>
                                Calendar
                            </TopNavMenuItem>
                            {hasPermissionEditInbox && (
                                <TopNavMenuItem to={INBOX_URL} isActive={isInboxActive}>
                                    Inbox
                                </TopNavMenuItem>
                            )}
                            {hasPermissionEditMessageRules && (
                                <NavMessaging
                                    isActive={!!isMessagingActive}
                                    listingGroups={listingGroups}
                                    visibleListings={visibleListings}
                                />
                            )}
                            {hasPermissionEditPricing && (
                                <NavPricing
                                    isActive={!!isPricingActive}
                                    visibleListings={visibleListings}
                                />
                            )}
                        </HStack>
                    )}
                </HStack>
                <HStack space={2} justifyContent="center" alignItems="center">
                    {subscriptionStatus !== "active" && subscriptionStatus !== "canceled" && (
                        <QuickGuideButton />
                    )}
                    {subscriptionBadge}
                    <SupportMenu onSupport={handleContactMenu} />
                    <Dropdown minWidth={64} trigger={triggerUserProfile} placement="bottom right">
                        <MenuProfile />
                    </Dropdown>
                </HStack>
            </HStack>
            {showAddAccountModal && (
                <ModalAddAccount show={showAddAccountModal} onHide={handleCloseModal} />
            )}
            {showModalMenuMobile && (
                <ModalMenuMobile isOpen={showModalMenuMobile} onHide={handleToglleMenuMobile} />
            )}
            {bannerNotificationRenderer}
        </>
    );
};

export default memo(Header);
