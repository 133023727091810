import {isReactElement} from "@hosttools/frontend";
import type {IFormControlProps} from "native-base";
import {FormControl} from "native-base";
import React from "react";

import FormAnchor from "../Form/FormAnchor";
import Label from "../Label";
import type {Props as SelectProps} from "../Select";
import Select from "../Select";

type Props = {
    name?: string;
    label?: string;
    size?: "xs" | "sm";
    tooltip?: React.ReactNode;
    error?: React.ReactNode;
    isDisabled?: boolean;
    children: React.ReactNode;
} & Omit<IFormControlProps, "isInvalid" | "size">;

const FormField: React.FC<Props> = ({
    name,
    error,
    isDisabled,
    children,
    label,
    size = "xs",
    tooltip,
    ...formControlProps
}) => {
    let newChildren: React.ReactNode = children;

    if (isReactElement<SelectProps<any, any>>(children, Select)) {
        newChildren = React.cloneElement(children, {
            error: !!error
        });
    }

    return (
        <FormControl
            isInvalid={!!error}
            isDisabled={isDisabled}
            flexShrink={1}
            flexGrow={1}
            {...formControlProps}
        >
            {name && <FormAnchor name={name} />}
            {label && (
                <Label tooltip={tooltip} size={size}>
                    {label}
                </Label>
            )}
            {newChildren}
            <FormControl.ErrorMessage>{error}</FormControl.ErrorMessage>
        </FormControl>
    );
};

export default FormField;
