export const REGEX_EMAIL =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

export const CALENDAR_SLOT_WIDTH = 80;

export const CALENDAR_SLOT_WIDTH_NATIVE_BASE = 74;

export const CALENDAR_SLOT_WIDTH_MOBILE = 80;

export const REGEX_URL =
    /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\\.-]+)+[\w\-\\._~:/?#[\]@!\\$&'\\(\\)\\*\\+,;=.]+$/i;

export const base64ImagePrefix = "data:image/png;base64,";

export const MULTICALENDAR_PATH = "/";
export const INBOX_PATH = "/inbox/:reservationID?";
export const MESSAGING_PATH = "/messaging/:listingID/:listingGroupID?";
export const PRICING_PATH = "/pricing/:listingID";
export const INBOX_URL = "/inbox";

export const MAX_NUMBER_OF_NEXT_YEARS = 2;
