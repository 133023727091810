import type {IAlertProps} from "native-base";
import {
    Alert as AlertNB,
    Center,
    CheckCircleIcon,
    CloseIcon,
    HStack,
    IconButton,
    InfoIcon,
    Pressable,
    Spinner,
    VStack,
    WarningIcon
} from "native-base";
import React, {memo, useMemo} from "react";

import Heading from "../Heading";
import Link from "../Link";
import Paragraph from "../Paragraph";
import Text from "../Text";

import useMobileView from "@/client/hooks/useMobileView";

type Variants = "solid" | "subtle";

type Colors = "blueGray" | "success" | "warning" | "danger" | "blue";

export type Props = {
    children: React.ReactNode;
    variant?: Variants;
    color?: Colors;
    primaryText?: string;
    primaryLoading?: boolean;
    secondaryText?: string;
    onClose?: () => void;
    primaryAction?: string | (() => void);
    secondaryAction?: string | (() => void);
} & Omit<IAlertProps, "colorScheme" | "variant">;

const Alert: React.FC<Props> = ({
    children,
    variant = "solid",
    color = "blue",
    primaryText,
    primaryLoading,
    secondaryText,
    primaryAction,
    secondaryAction,
    onClose,
    ...restAlert
}) => {
    const isMobile = useMobileView();

    const Icon = useMemo(() => {
        const iconMap = {
            blueGray: InfoIcon,
            success: CheckCircleIcon,
            warning: WarningIcon,
            danger: WarningIcon,
            blue: InfoIcon
        } satisfies Record<Colors, typeof InfoIcon>;

        return iconMap[color];
    }, [color]);

    const variantStyle = useMemo(() => {
        const variantConfig = {
            solid: {
                space: 4
            },
            subtle: {
                space: 1
            }
        } satisfies Record<Variants, {space: number}>;

        return variantConfig[variant];
    }, [variant]);

    const textColorScheme = color === "danger" ? "red" : color;

    const textColor =
        color !== "blueGray" ? (`${color === "danger" ? "red" : color}.600` as const) : undefined;

    const IconNode =
        variant === "solid" ? (
            <Center
                h={10}
                w={10}
                flexShrink={0}
                borderRadius="full"
                borderWidth={2}
                borderStyle="solid"
                borderColor={`${color}.600:alpha.10`}
            >
                <Center
                    h={7}
                    w={7}
                    borderRadius="full"
                    borderWidth={2}
                    borderStyle="solid"
                    borderColor={`${color}.600:alpha.30`}
                >
                    <Icon size="sm" color={`${textColorScheme}.600`} />
                </Center>
            </Center>
        ) : (
            <Icon size="sm" my={0.5} color={`${textColorScheme}.600`} />
        );

    const ActionNode =
        secondaryText || primaryText ? (
            <HStack space={4} height={isMobile ? "auto" : "full"} alignItems="center">
                {secondaryText && (
                    <>
                        {typeof secondaryAction === "string" ? (
                            <Link to={secondaryAction} color={`${textColorScheme}.600`}>
                                {secondaryText}
                            </Link>
                        ) : (
                            <Pressable onPress={secondaryAction}>
                                <Text variant="sm" color={textColor}>
                                    {secondaryText}
                                </Text>
                            </Pressable>
                        )}
                    </>
                )}
                {primaryText && (
                    <>
                        {typeof primaryAction === "string" ? (
                            <Link
                                to={primaryAction}
                                color={`${textColorScheme}.600`}
                                fontWeight="bold"
                            >
                                {primaryText}
                            </Link>
                        ) : (
                            <Pressable variant="unstyled" onPress={primaryAction}>
                                {primaryLoading ? <Spinner /> : null}
                                <Heading variant="xs" color={textColor}>
                                    {primaryText}
                                </Heading>
                            </Pressable>
                        )}
                    </>
                )}
            </HStack>
        ) : undefined;

    return (
        <AlertNB {...restAlert} variant={variant} colorScheme={color} overflow="hidden">
            <HStack
                width="full"
                space={variantStyle.space}
                alignItems="flex-start"
                justifyContent="space-between"
            >
                {IconNode}
                <VStack
                    flex={1}
                    height={isMobile ? "auto" : "full"}
                    justifyContent="center"
                    space={2}
                >
                    <Paragraph variant="sm" color={textColor}>
                        {children}
                    </Paragraph>
                    {isMobile && ActionNode}
                </VStack>
                {onClose && (
                    <IconButton
                        size="xs"
                        colorScheme={textColorScheme}
                        variant="ghost"
                        icon={<CloseIcon />}
                        onPress={onClose}
                    />
                )}
                {!isMobile && ActionNode}
            </HStack>
        </AlertNB>
    );
};

export default memo(Alert);
