import type {AxiosInstance} from "axios";

import {type Tag} from "../../models/tag";

type AddTagParams = {
    name: string;
    text: string;
};

type EditTagParams = {
    _id: string;
    name: string;
    text: string;
};

class TagService {
    private http: AxiosInstance;

    constructor(http: AxiosInstance) {
        this.http = http;
    }

    async deleteTag(tagID: string) {
        const url = "/deleteTag";
        await this.http.post(url, {
            _id: tagID
        });
    }

    async fetchTags() {
        const url = "/tags";
        const {data} = await this.http.get<Tag[]>(url);
        return data;
    }

    async addTag(params: AddTagParams) {
        await this.http.post("/addTag", params);
    }

    async editTag(params: EditTagParams) {
        await this.http.post("/addTag", params);
    }
}

export default TagService;
