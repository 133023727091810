import type {AxiosInstance} from "axios";

import type {Price} from "../../models/price";

export interface AirbticsRates {
    date: string;
    available_median_rate: number;
    booked_median_rate: number;
    occupancy_rate_median: number;
    available_rate_25_percentile: number;
    booked_rate_25_percentile: number;
    available_rate_75_percentile: number;
    booked_rate_75_percentile: number;
}
export interface AirbticsData {
    message: {
        radius_used_in_meters: number;
        no_of_sample: number;
        last_snapshot: string;
        current_snapshot: string;
        next_update: string;
        next_365_days_available_booked_rates: AirbticsRates[];
    };
}

class PricingService {
    private http: AxiosInstance;

    constructor(http: AxiosInstance) {
        this.http = http;
    }

    async getPrices(listingID: string, starting: "today" | "yesterday" | "lastMonth" | "lastYear") {
        const url = `/getPrices/${listingID}/${starting}`;
        const {data} = await this.http.get<Price[]>(url);

        return data;
    }

    async getAirbticsData(listingID: string) {
        const url = `/getAirbtics/${listingID}`;
        const {data} = await this.http.get<AirbticsData>(url);

        return data;
    }
}

export default PricingService;
