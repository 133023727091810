export type UploadFilePayload = {
    uri?: string;
    file?: Blob;
    filename?: string;
};

export function generateImageUploadFormData({uri, file, filename}: UploadFilePayload): FormData {
    const formData = new FormData();
    // media part
    if (uri) {
        const name = filename ?? uri.split("/").pop();
        const match = name && /\.(\w+)$/.exec(name);
        const type = match ? `image/${match[1]}` : "image";
        // it's confusing why it works?
        formData.append("image", {uri, name, type} as any);
    } else if (file) {
        formData.append("image", file, filename);
    }

    return formData;
}

export function downloadFileBlob(blob: Blob, name?: string) {
    const url = window.URL.createObjectURL(blob);
    downloadRawFile(url, name);
}

export function downloadRawFile(rawFile: string, fileName?: string) {
    const a = document.createElement("a");
    a.href = rawFile;
    if (fileName) {
        a.download = fileName;
    }
    document.body.appendChild(a);
    a.click();
}
