import {useChannel} from "@hosttools/frontend";
import type {AccountType} from "@hosttools/frontend/models/account";
import {HStack} from "native-base";
import React, {memo} from "react";

import ChannelLogo from "../ChannelLogo";

import PopoverInfo from "@/client/components/PopoverInfo";
import Text from "@/client/components/Text";

interface Props {
    accountType: AccountType;
    size: "md" | "xl";
}

const ConnectChannelItemLabel: React.FC<Props> = ({accountType, size}) => {
    const {label} = useChannel(accountType);

    return (
        <HStack space={4} alignItems="center">
            <ChannelLogo
                channel={accountType}
                variant="circle"
                size={accountType === "ChannelConnector" ? "3xl" : "2xl"}
            />
            {accountType === "HomeAway" ? (
                <HStack space={1} alignItems="center">
                    <Text variant={size}>{`${label}${
                        __IS_CC_ENABLED__ ? "(unofficial)" : ""
                    }`}</Text>
                    {__IS_CC_ENABLED__ && (
                        <PopoverInfo
                            icon="info"
                            label={
                                <>
                                    There are two options for connecting to VRBO. The unofficial
                                    connection is less reliable but offers instant onboarding and
                                    doesn&apos;t require a merchant of record.
                                </>
                            }
                        />
                    )}
                </HStack>
            ) : (
                <Text variant="md">{label}</Text>
            )}
        </HStack>
    );
};

export default memo(ConnectChannelItemLabel);
