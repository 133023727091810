import {useContext, useMemo} from "react";

import {UserContext} from "../../contexts";

const useListingsByIDs = (listingIDs: string[] | string | undefined) => {
    const {listings} = useContext(UserContext);

    return useMemo(() => {
        if (!listingIDs) {
            return null;
        }
        return listings.filter(elem => {
            return Array.isArray(listingIDs)
                ? listingIDs.includes(elem._id)
                : listingIDs === elem._id;
        });
    }, [listingIDs, listings]);
};

export default useListingsByIDs;
