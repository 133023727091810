import {useCallback, useContext, useMemo, useRef} from "react";

import {AppContext} from "../provider/AppProvider";

import useCloseConfirmModal from "./useCloseConfirmModal";

import type {PropsWithData, PropsNoData} from "@/admin/components/ModalConfirm";

type Transform<T> = Omit<T, "show" | "onHide"> & {onClose?: () => void};

function useConfirmModal<T>(props: Transform<PropsWithData<T>> | Transform<PropsNoData<T>>) {
    const {confirmModal, setConfirmModal} = useContext(AppContext);

    const propsRef = useRef(props);
    propsRef.current = props;

    const onHide = useCloseConfirmModal(propsRef.current.onClose);

    // dangerously update the same state where this hook could be used multiple
    // places which could end up wrongly display a modal
    // useEffect(() => {
    //     if (confirmModal?.id && confirmModal?.id === idRef.current) {
    //         // allow to update/refresh the modal
    //         // this trigger the modal
    //     }
    // }, [confirmModal?.id, props]);

    const onOpen = useCallback(() => {
        setConfirmModal({
            ...propsRef.current,
            show: true,
            onHide
        });
    }, [onHide, setConfirmModal]);

    const refresh = useCallback(
        (values?: Partial<typeof props>) => {
            setConfirmModal(prev => {
                return {
                    ...prev,
                    ...((values ?? propsRef.current) as typeof props) // this cast is unsafe, fix later on
                };
            });
        },
        [setConfirmModal]
    );

    return useMemo(
        () => [onOpen, refresh, onHide, confirmModal] as const,
        [onOpen, refresh, onHide, confirmModal]
    );
}

export default useConfirmModal;
