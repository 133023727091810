import {Center, HStack, VStack, View} from "native-base";
import type {IColors} from "native-base/lib/typescript/theme/base/colors";
import React, {memo} from "react";

import Text from "../Text";

import type {Item} from "./types";

interface Props {
    item: Item;
    idx: number;
    status: "active" | "past" | "future";
    isLast: boolean;
}

const StepItem: React.FC<Props> = ({item, idx, status, isLast}) => {
    const color: IColors =
        status === "past" || status === "active" ? "blueGray.400" : "blueGray.400";

    return (
        <VStack space={1}>
            <HStack space={3}>
                <VStack alignItems="center" pt={3}>
                    {item.Icon ? (
                        <item.Icon fillStyle="stroke" color={color} size={5} />
                    ) : (
                        <Center
                            size={8}
                            borderRadius="full"
                            borderWidth={1}
                            background={color}
                            borderColor="white"
                            _text={{color: "white"}}
                        >
                            {idx + 1}
                        </Center>
                    )}
                </VStack>
                <VStack space={1} flex={1}>
                    <Text variant="md" color="blueGray.800">
                        {item.title}
                    </Text>
                    <Text variant="sm">{item.description}</Text>
                </VStack>
            </HStack>

            {!isLast && (
                <VStack alignItems="center" width={5} height={9}>
                    <View
                        borderLeftWidth="1px"
                        borderLeftStyle="dashed"
                        flex={1}
                        borderLeftColor={color}
                    />
                </VStack>
            )}
        </VStack>
    );
};

export default memo(StepItem);
