import {InputLeftAddon as InputLeftAddonNB} from "native-base";
import React from "react";

import Paragraph from "../Paragraph";

type Props = {
    children?: React.ReactNode;
    padding?: number;
};

const InputLeftAddon: React.FC<Props> = ({children, padding}) => {
    return (
        <InputLeftAddonNB borderRightWidth={1} borderColor="blueGray.200" p={padding}>
            {typeof children === "string" ? (
                <Paragraph variant="sm">{children}</Paragraph>
            ) : (
                children
            )}
        </InputLeftAddonNB>
    );
};

export default InputLeftAddon;
